import { Box } from '@chakra-ui/react';
import {
  Slider,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VegaLite } from 'react-vega';
import { getThemeData } from '~/utils/theme';
import CustomSlider, { CustomThumbComponent } from './CustomSlider';
import RelativeFactorHistogramChart from './RelativeFactorHistogramChart';
import { ChartControllerProps, FactorControlValue } from './types';

function ChartController({
  factorId,
  bins,
  freqs,
  range,
  onUpdateRange,
  setUpdateByInput,
  updateByInput,
  isAbsolute,
}: ChartControllerProps) {
  const getFactorControlValueByFreqIndex = (
    freqIndex: number,
    isAbsolute: boolean,
  ): FactorControlValue => {
    if (isAbsolute) {
      if (freqIndex === 0) {
        return { value: 'min', freqIndex, isAbsolute };
      }
      if (freqIndex === bins.length) {
        return { value: 'max', freqIndex, isAbsolute };
      }

      return { value: bins[freqIndex - 1], freqIndex, isAbsolute };
    }
    return { value: freqIndex, freqIndex, isAbsolute };
  };
  const themeData = getThemeData();

  const [t] = useTranslation();
  const selectedColor = themeData.colors.primary[300];
  const unSelectedColor = '#EDEDED';

  const values = [
    {
      x: 0,
      y: 0,
      v: 0,
      c: 'white',
    },
    ...freqs.map((number, i) => {
      return {
        x: i + 1,
        y: number,
        v: getFactorControlValueByFreqIndex(i, isAbsolute),
        c:
          i + 1 >= range[0].freqIndex && i <= range[1].freqIndex - 2
            ? selectedColor
            : unSelectedColor,
      };
    }),
  ];

  const absoluteChart = (
    <VegaLite
      style={{ width: '100%' }}
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 'container',
        // @ts-ignore
        background: null,
        height: 50,
        autosize: 'fit',
        data: {
          values,
        },
        layer: [
          {
            mark: {
              type: 'bar',
              strokeWidth: 0,
            },
            encoding: {
              x: { field: 'x', scale: { paddingInner: 0 } },
              y: { field: 'y', type: 'quantitative' },
              color: { field: 'c', type: 'nominal', scale: null },
              tooltip: [
                {
                  field: 'y',
                  type: 'nominal',
                  title: t('text.result_count'),
                },
              ],
            },
          },
        ],
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },
          padding: 0,
          axisX: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
          axisY: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
        },
      }}
    />
  );
  return (
    <Stack flexDirection="column">
      <Box pt="8px">
        {isAbsolute ? (
          absoluteChart
        ) : (
          <RelativeFactorHistogramChart
            key={`relative-${factorId}`}
            factorId={factorId}
          />
        )}
      </Box>

      <CustomSlider
        disableSwap
        components={{
          Thumb: CustomThumbComponent,
        }}
        onFocus={(v) => {
          if (v.target.title === 'right') {
            setUpdateByInput([updateByInput[0], false]);
          } else if (v.target.title === 'left') {
            setUpdateByInput([false, updateByInput[1]]);
          }
        }}
        onChange={(
          event: Event,
          newValue: number | number[],
          activeThumb: number,
        ) => {
          if (Array.isArray(newValue)) {
            // console.log([
            //   updateByInput[0]
            //     ? null
            //     : getFactorControlValueByFreqIndex(newValue[0], isAbsolute),
            //   updateByInput[1]
            //     ? null
            //     : getFactorControlValueByFreqIndex(newValue[1], isAbsolute),
            // ]);

            const minDistance = isAbsolute ? 1 : 10;
            if (activeThumb === 0) {
              onUpdateRange([
                getFactorControlValueByFreqIndex(
                  Math.max(
                    0,
                    Math.min(
                      newValue[0],
                      (isAbsolute
                        ? range[1].freqIndex
                        : (range[1].value as number)) - minDistance,
                    ),
                  ),
                  isAbsolute,
                ),
                null,
              ]);
              // setValue1();
            } else {
              onUpdateRange([
                null,
                getFactorControlValueByFreqIndex(
                  Math.max(
                    newValue[1],
                    (isAbsolute
                      ? range[0].freqIndex
                      : (range[0].value as number)) + minDistance,
                  ),
                  isAbsolute,
                ),
              ]);
            }

            // onUpdateRange([
            //   // updateByInput[0]
            //   //   ? null
            //   //   :
            //   getFactorControlValueByFreqIndex(newValue[0], isAbsolute),
            //   // updateByInput[1]
            //   //   ? null
            //   //   :
            //   getFactorControlValueByFreqIndex(newValue[1], isAbsolute),
            // ]);
          }
        }}
        min={0}
        max={isAbsolute ? bins.length : 100}
        step={isAbsolute ? 1 : 10}
        // marks
        value={range.map((e) =>
          isAbsolute ? e.freqIndex : (e.value as number),
        )}
        // // defaultValue={[0, 10]}
        // colorScheme="primary"
        // color={themeData.colors.primary[900]}
        // minStepsBetweenThumbs={1}
      >
        {!isAbsolute ? (
          <>
            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((v) => (
              <SliderMark
                key={`unit-${v}`}
                defaultValue={v}
                // sx={{
                //     marginTop:'-2.5',
                //     marginLeft:'-0.5'
                // }}
                // fontSize="sm"
                // color={
                //   v % 50 === 0
                //     ? themeData.colors.gray[400]
                //     : themeData.colors.gray[200]
                // }
              >
                |
              </SliderMark>
            ))}
          </>
        ) : (
          <div />
        )}
        {/* <SliderTrack>
          <RangeSliderFilledTrack />
        </SliderTrack> */}
        {/* <SliderThumb
          //   index={0}
          title="left"
          //   boxSize={5}
          //   zIndex="auto"
          //   borderRadius={5}
          //   borderWidth={2}
          //   bg={themeData.colors.primary[500]}
          //   borderColor={themeData.colors.primary[900]}
        />
        <SliderThumb
          //   index={1}
          title="right"
          //   zIndex="auto"
          //   boxSize={5}
          //   borderRadius={5}
          //   borderWidth={2}
          //   bg={isAbsolute ? 'white' : themeData.colors.primary[500]}
          //   borderColor={themeData.colors.primary[900]}
        /> */}
      </CustomSlider>
    </Stack>
  );
}

export default ChartController;
