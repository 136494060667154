import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  HStack,
  Link,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Stack, Button, Typography } from '@mui/material';
import { Body2, H2, H3, H5 } from '~/components/Typography';
import NegativeBarChart from '~/components/NegativeBarChart';
import { intToLocaledString } from '~/utils/number';
import { getThemeData } from '~/utils/theme';
import {
  CompanyDetailInfo,
  CompanyGptReport,
  CompanyMarketInfo,
  CompanySummaryInfoResponse,
  FinancialStatementValuesResponse,
} from '~/api/screener';
import api from '~/api';
import SpiderChart from '~/components/company_detail/SpiderChart';
import PriceRangeComponent from '~/components/company_detail/PriceRange';
import logoChatGpt from '~/assets/image/logo_chat_gpt.svg';

type Props = {
  cosmosCode: number;
};

function Summary({ cosmosCode }: Props) {
  const [companySummaryInfo, setCompanySummaryInfo] =
    useState<CompanySummaryInfoResponse>();

  const [companyGptReport, setCompanyGptReport] =
    useState<CompanyGptReport | null>(null);

  const [isWaitReport, setIsWaitReport] = useState<boolean>(false);

  useEffect(() => {
    api.screener.getCompanySummary(cosmosCode).then((v) => {
      setCompanySummaryInfo(v.data);
    });
    api.screener.getCompanyReport(cosmosCode).then((v) => {
      setCompanyGptReport(v.data);
    });
  }, [cosmosCode]);

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | null | undefined;
    if (isWaitReport) {
      intervalId = setInterval(() => {
        api.screener.getCompanyReport(cosmosCode).then((v) => {
          setCompanyGptReport(v.data);
          if (v.data?.content?.length) {
            setIsWaitReport(false);
          }
        });
      }, 15000);
    }
    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, [isWaitReport]);

  const onRequestReport = useCallback(() => {
    api.screener.requestCompanyReport(cosmosCode).then((v) => {
      setCompanyGptReport(v.data);
      setIsWaitReport(true);
    });
  }, [companyGptReport]);

  if (!companySummaryInfo) return <div />;

  const {
    companyDetailInfo,
    companyMarketInfo,
    financialValueTimeSeries,
    scoreFactorMap,
    statisticFactorMap,
    maxScoreValueMap,
  } = companySummaryInfo;
  return (
    <Stack
      sx={{
        marginTop: '16px',
        height: '100%',
      }}
    >
      <VStack
        overflowX="hidden"
        height="100%"
        overflow="auto"
        w={['100%', 'initial']}
        m={['4px', '0px 4px']}
        bg="white"
        borderRadius="8px"
      >
        <CompanySummary
          companyName={companyDetailInfo.companyName}
          companyLocalName={companyDetailInfo.companyLocalName}
          scoreFactorMap={scoreFactorMap}
          maxScoreValueMap={maxScoreValueMap}
          companyGptReport={companyGptReport}
          onRequestReport={onRequestReport}
        />
        <Price companyMarketInfo={companyMarketInfo} />
        {/* <Valuation /> */}
        <Financials financialValueTimeSeries={financialValueTimeSeries} />
        <CompanyInformation
          companyDetailInfo={companyDetailInfo}
          statisticFactorMap={statisticFactorMap}
        />
      </VStack>
    </Stack>
  );
}

function CompanySummary({
  companyName,
  companyLocalName,
  scoreFactorMap,
  maxScoreValueMap,
  companyGptReport,
  onRequestReport,
}: {
  companyName: string;
  companyLocalName: string;
  scoreFactorMap: Map<string, number>;
  maxScoreValueMap: Map<string, number>;
  companyGptReport: CompanyGptReport | null;
  onRequestReport?: () => void;
}) {
  const getSpiderChartData = (): [string, number][] => {
    const order = [10, 7, 4, 2, 5];
    const result = Array<[string, number]>();
    const scores = new Map(Object.entries(scoreFactorMap));

    order.forEach((v) => {
      const score = scores.get(v.toString()) ?? 0;
      result.push([
        // @ts-ignore
        t(`companyDetail.${v}`),
        score / (maxScoreValueMapObj[v] ?? 10),
      ]);
    });

    return result;
  };
  const [t] = useTranslation();
  const maxScoreValueMapObj = Object(maxScoreValueMap);

  const reportView = useMemo(() => {
    switch (companyGptReport?.status) {
      case 'pending':
        return (
          <>
            <Typography variant="body2" color="text.secondary">
              {t('text.gptPreview', {
                value: Math.floor(companyGptReport.wait / 60),
              })}
            </Typography>
            <HStack mt="8px" justifyContent="end">
              <Button disabled variant="contained">
                {t('text.gptProcessing')}
              </Button>
            </HStack>
          </>
        );
      case 'completed':
        return companyGptReport?.content?.split(/\n+/).map((text, line) => {
          return (
            <Typography variant="body2" key={text}>
              {text}
            </Typography>
          );
        });
      default:
        return companyGptReport !== null ? (
          <>
            <Typography variant="body2" color="text.secondary">
              {t('text.gptPreview', {
                value: Math.floor(companyGptReport.wait / 60),
              })}
            </Typography>
            <HStack mt="8px" justifyContent="end">
              <Button variant="contained" onClick={() => onRequestReport?.()}>
                {t('text.checkGptReport')}
              </Button>
            </HStack>
          </>
        ) : null;
    }
  }, [companyGptReport?.status, t]);

  return (
    <Box w="100%" marginBottom="32px">
      <Box marginBottom="8px">
        <H2 bold noOfLines={2}>
          {companyLocalName && companyLocalName !== ''
            ? companyLocalName
            : companyName}
        </H2>
      </Box>
      {/* <Body2 noOfLines={10} color={themeData.colors.gray[600]}>
        Apple Inc. designs, manufactures, and markets smartphones, personal
        computers, tablets, wearables, and accessories worldwide.
      </Body2> */}
      <Center>
        <SpiderChart data={getSpiderChartData()} />
      </Center>
      <HStack>
        <H3 bold noOfLines={1}>
          {t('text.investmentReport')}
        </H3>

        <Image src={logoChatGpt} />
      </HStack>
      <Box
        mt="16px"
        sx={{
          backgroundColor: '#F9F9F9',
          borderRadius: '16px',
          px: '24px',
          py: '20px',
        }}
      >
        {reportView}
        {(companyGptReport?.content?.length ?? 0) > 0 ? (
          <Typography color="text.secondary" marginTop="8px" fontSize="12px">
            {t('text.gptCaption')}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

CompanySummary.defaultProps = {
  onRequestReport: undefined,
};

const getMarketCapPositionRatio = (
  rank: number,
  totalCount: number,
): number => {
  const ratio = rank / totalCount;

  const top = 0.05;
  const middle = 0.3;
  const topCount = totalCount * top;
  const middleCount = totalCount * (middle - top);

  if (ratio <= top) {
    const topRange = 1 / 3 / (topCount - 1);

    return 1 - topRange * (rank - 1);
  }
  if (ratio <= middle) {
    const rankOfPart = rank - topCount;
    const middleRange = 1 / 3 / (middleCount - 1);
    return 2 / 3 - middleRange * (rankOfPart - 1);
  }
  const smallCount = totalCount * (1 - middle);
  const rankOfPart = rank - topCount - middleCount;
  const smallRange = 1 / 3 / (smallCount - 1);

  return 1 / 3 - smallRange * (rankOfPart - 1);
};

function Price({
  companyMarketInfo,
}: {
  companyMarketInfo: CompanyMarketInfo;
}) {
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Box w="100%">
      <HStack>
        <H5 bold>{t('companyDetail.52WeekPriceRange.title')}</H5>
        <Body2 color={themeData.colors.gray[300]}>
          {companyMarketInfo.currency}
        </Body2>
      </HStack>
      <Box marginTop="12px">
        <PriceRangeComponent
          lowUnit={t('companyDetail.52WeekPriceRange.low')}
          highUnit={t('companyDetail.52WeekPriceRange.high')}
          currentUnit={t('companyDetail.52WeekPriceRange.current')}
          positionRatio={
            (companyMarketInfo.currentMarketPrice -
              companyMarketInfo.yearLowMarketPrice) /
            (companyMarketInfo.yearHighMarketPrice -
              companyMarketInfo.yearLowMarketPrice)
          }
          low={companyMarketInfo.yearLowMarketPrice}
          high={companyMarketInfo.yearHighMarketPrice}
          current={companyMarketInfo.currentMarketPrice}
        />
      </Box>

      <HStack>
        <H5 bold>{t('companyDetail.marketCapRank.title')}</H5>
        <Body2 color={themeData.colors.gray[300]}>
          {companyMarketInfo.currency}
        </Body2>
      </HStack>

      <Box marginTop="12px">
        <PriceRangeComponent
          lowUnit={t('companyDetail.marketCapRank.low')}
          highUnit={t('companyDetail.marketCapRank.high')}
          currentUnit={t('companyDetail.marketCapRank.current')}
          positionRatio={getMarketCapPositionRatio(
            companyMarketInfo.nationMarketCapRank,
            companyMarketInfo.maxNationMarketCapRank,
          )}
          low={companyMarketInfo.minNationLocalMarketCap}
          high={companyMarketInfo.maxNationLocalMarketCap}
          current={companyMarketInfo.localMarketCap}
        />
      </Box>
    </Box>
  );
}

// const Valuation = () => {
//   const [t] = useTranslation();
//   return (
//     <Box w="100%">
//       <SubtitleWithTooltip label="Valuation" description="Valuation" />
//       <DotPlot />
//     </Box>
//   );
// };

function Financials({
  financialValueTimeSeries,
}: {
  financialValueTimeSeries: FinancialStatementValuesResponse;
}) {
  const themeData = getThemeData();
  const [t] = useTranslation();

  return (
    <Box w="100%" paddingBottom="32px">
      <H3 bold noOfLines={1}>
        {t('companyDetail.summaryFinancials')}
      </H3>
      {(financialValueTimeSeries.financialItemList ?? []).map((v, i) => {
        const data: [string, number][] =
          financialValueTimeSeries.periodInfoList.map((period) => {
            const { year, calcEndDate } = period;
            const value: number =
              (new Map(
                Object.entries(
                  new Map(
                    Object.entries(financialValueTimeSeries.financialValueMap),
                  ).get(calcEndDate),
                ),
              ).get(i.toString()) as number) ?? 0;

            return [year.toString(), value];
          });

        return (
          <VStack align="start" marginTop="36px">
            <HStack>
              {/* @ts-ignore */}
              <H5 bold>{t(`companyDetailFactor.${v}`)}</H5>
              <Body2 color={themeData.colors.gray[300]}>
                {financialValueTimeSeries.currency}
              </Body2>
            </HStack>

            <NegativeBarChart data={data} />
          </VStack>
        );
      })}
    </Box>
  );
}

function CompanyInformation({
  companyDetailInfo,
  statisticFactorMap,
}: {
  companyDetailInfo: CompanyDetailInfo;
  statisticFactorMap: Map<string, number>;
}) {
  const [t] = useTranslation();
  return (
    <Box w="100%">
      <H3 bold noOfLines={1}>
        {t('companyDetail.companyInfo')}
      </H3>
      <Table
        display="inherit"
        variant="simple"
        w="100%"
        overflow="hidden"
        mt="18px"
      >
        <Tbody w="100%" display="inline-table">
          {companyDetailInfo.incorporationDate ? (
            <Tr>
              <Td p="12px 0px" minW="100px">
                <Body2> {t('companyDetail.foundedDate')}</Body2>
              </Td>
              <Td p="12px 0px" isNumeric>
                <Body2 align="right">
                  {companyDetailInfo.incorporationDate}
                </Body2>
              </Td>
            </Tr>
          ) : (
            <div />
          )}
          <Tr>
            <Td p="12px 0px">
              <Body2>{t('companyDetail.website')}</Body2>
            </Td>

            <Td p="12px 0px" isNumeric>
              <Link isExternal href={companyDetailInfo.url}>
                <Body2 align="right" decoration="underline" noOfLines={3}>
                  {companyDetailInfo.url}
                </Body2>
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td p="12px 0px">
              <Body2>{t('companyDetail.exchange')}</Body2>
            </Td>
            <Td p="12px 0px" isNumeric>
              <Body2 align="right"> {companyDetailInfo.exchange}</Body2>
            </Td>
          </Tr>
          {Object.entries(statisticFactorMap).map((v) => {
            return (
              <Tr>
                <Td p="12px 0px">
                  {/* @ts-ignore */}
                  <Body2 noOfLines={2}>{t(`factor.${v[0]}.name`)}</Body2>
                </Td>
                <Td p="12px 0px" isNumeric>
                  <Body2 noOfLines={2} align="right">
                    {intToLocaledString(v[1])}
                  </Body2>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Summary;
