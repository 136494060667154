import { Button } from '@chakra-ui/button';
import { Image, HStack, ChakraProvider } from '@chakra-ui/react';
import PaginationItem from './PaginationItem';
import iconArrowPageRight from '~/assets/icon/icon_arrow_page_right.svg';
import iconArrowPageLeft from '~/assets/icon/icon_arrow_page_left.svg';

type Props = {
  length: number;
  current: number;
  onChange: (value: number) => void;
};
function Pagination({ length, current, onChange }: Props) {
  const showedCount = 5;
  const min = current - (current % 5);

  const currentPaginationLength = Math.min(5, length - min);
  const hasPrev = min !== 0;
  const hasNext = currentPaginationLength === 5 && length > min + 5;

  return (
    <ChakraProvider>
      <HStack>
        {hasPrev ? (
          <Button onClick={() => onChange(min - showedCount + 1)}>
            <Image src={iconArrowPageLeft} />
          </Button>
        ) : (
          <div />
        )}
        {Array.from({ length: currentPaginationLength }).map((_, i) => (
          <PaginationItem
            value={min + i}
            onClick={onChange}
            focused={current === i + min}
          />
        ))}
        {hasNext ? (
          <Button onClick={() => onChange(min + showedCount)}>
            <Image src={iconArrowPageRight} />
          </Button>
        ) : (
          <div />
        )}
      </HStack>
    </ChakraProvider>
  );
}
export default Pagination;
