import { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { VegaLite } from 'react-vega';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import SubtitleWithTooltip from '~/components/SubtitleWithTooltip';
import { intToCompressedString } from '~/utils/number';
import api from '~/api';
import FinancialTable from './FinancialTable';
import {
  CompanyFinancialRatioValuesResponse,
  FinancialStatementValuesResponse,
} from '~/api/screener';
import { getIsMobile } from '~/utils/mediaQuery';

type Props = {
  cosmosCode: number;
};
type ValueItemProps = {
  cosmosCode: number;

  type: 'BALANCE_SHEET' | 'CASH_FLOW' | 'INCOME';
};
type RatioItemProps = {
  cosmosCode: number;

  type: 'EFFICIENCY' | 'GROWTH' | 'PROFITABILITY' | 'SHARE' | 'STABILITY';
};

function FinancialValues({ cosmosCode }: Props) {
  const [t] = useTranslation();

  return (
    <Stack
    // align="start"
    >
      <Box marginTop="12px">
        <SubtitleWithTooltip label={t('companyDetail.financialRatio')} />
      </Box>
      <FinancialRatioItem cosmosCode={cosmosCode} type="SHARE" />

      <FinancialRatioItem cosmosCode={cosmosCode} type="GROWTH" />
      <FinancialRatioItem cosmosCode={cosmosCode} type="PROFITABILITY" />

      <FinancialRatioItem cosmosCode={cosmosCode} type="EFFICIENCY" />
      <FinancialRatioItem cosmosCode={cosmosCode} type="STABILITY" />

      <Box marginTop="12px">
        <SubtitleWithTooltip label={t('companyDetail.financialStatement')} />
      </Box>
      <FinancialValueItem cosmosCode={cosmosCode} type="INCOME" />

      <FinancialValueItem cosmosCode={cosmosCode} type="BALANCE_SHEET" />

      <FinancialValueItem cosmosCode={cosmosCode} type="CASH_FLOW" />
    </Stack>
  );
}

function FinancialRatioItem({ cosmosCode, type }: RatioItemProps) {
  const [t] = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const [companyFinancialRatio, setCompanyFinancialRatio] =
    useState<CompanyFinancialRatioValuesResponse>();

  useEffect(() => {
    api.screener.getCompanyFinancialRatio(cosmosCode, type).then((v) => {
      setCompanyFinancialRatio(v.data);
    });
  }, [cosmosCode]);

  if (!companyFinancialRatio) return <div />;
  const columns = getColumn(
    companyFinancialRatio!,
    t(`companyDetail.${type}`),
    t(`companyDetail.trend`),
    // @ts-ignore
  ).filter((e) => !isMobile || !e.hideMobile);
  return (
    <FinancialTable
      columns={columns}
      data={getRowData(companyFinancialRatio!)}
    />
  );
}
function FinancialValueItem({ cosmosCode, type }: ValueItemProps) {
  const [t] = useTranslation();
  const isMobile = getIsMobile();

  const [financialStatementValues, setFinancialStatementValues] =
    useState<FinancialStatementValuesResponse>();

  useEffect(() => {
    api.screener.getCompanyFinancialStatement(cosmosCode, type).then((v) => {
      setFinancialStatementValues(v.data);
    });
  }, [cosmosCode]);

  if (!financialStatementValues) return <div />;

  const columns = getColumn(
    financialStatementValues!,
    t(`companyDetail.${type}`),
    t(`companyDetail.trend`),
    // @ts-ignore
  ).filter((e) => !isMobile || !e.hideMobile);
  return (
    <FinancialTable
      columns={columns}
      data={getRowData(financialStatementValues!)}
      currency={financialStatementValues!.currency}
    />
  );
}

function getColumn(
  financialStatementValuesResponse: FinancialStatementValuesResponse,
  type: string,
  trend: string,
): Array<Column> {
  const value = (financialStatementValuesResponse.periodInfoList ?? []).map(
    (periodInfo) => {
      return {
        Header: periodInfo.calcEndDate.split('-').splice(0, 2).join('-'),
        accessor: periodInfo.calcEndDate,
        isNumeric: true,
        hideMobile: true,
      };
    },
  );

  return [
    // @ts-ignore
    { Header: type, accessor: 'title', sticky: 'left', hideMobile: false },
    // @ts-ignore
    { Header: trend, accessor: 'chart', hideMobile: false },
    // @ts-ignore
    ...value,
  ];
}
function getRowData(
  financialStatementValuesResponse: FinancialStatementValuesResponse,
): Array<Record<string, any>> {
  return (financialStatementValuesResponse.financialItemList ?? []).map(
    (title: number, i: number) => {
      return {
        title,
        ...getMapFromRecord(financialStatementValuesResponse, i),
      };
    },
  );
}

function getChart(data: Array<number>): any {
  if (data.length === 0) return <div />;
  return (
    <VegaLite
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 40,
        height: 20,
        description:
          'A bar chart with negative values. We can hide the axis domain line, and instead use a conditional grid color to draw a zero baseline.',
        data: {
          values: data.map((v, i) => {
            return {
              i,
              v,
              c: v < 0 ? '#FF5D5D' : '#4A87FF',
            };
          }),
        },
        mark: 'bar',
        encoding: {
          x: {
            field: 'i',
            type: 'nominal',
            axis: {
              domain: false,
              ticks: false,
              labelAngle: 0,
              labelPadding: 4,
            },
          },
          y: {
            field: 'v',
            type: 'quantitative',
            axis: {
              gridColor: {
                condition: { test: 'datum.value === 0', value: 'black' },
                value: '#ddd',
              },
            },
          },
          color: { field: 'c', type: 'nominal', scale: null },
        },
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },
          padding: 0,
          axisX: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
          axisY: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
        },
      }}
    />
  );
}

function getMapFromRecord(
  financialStatementValuesResponse: FinancialStatementValuesResponse,
  i: number,
): Record<string, any> {
  const r: Record<string, any> = {};
  const obj = Object.entries(
    financialStatementValuesResponse.financialValueMap,
  );
  const chartData = Array<number>();
  obj.forEach((v: any) => {
    if (
      v === undefined ||
      v === null ||
      v[1][i] === undefined ||
      v[1][i] === null
    ) {
      r[v?.[0]] = '-';
      chartData.push(0);
      return;
    }

    r[v[0]] = intToCompressedString(v[1][i]);

    chartData.push(v[1][i]);
  });
  r['chart'] = getChart(chartData);
  return r;
}
export default FinancialValues;
