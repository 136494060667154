import { AxiosInstance, AxiosResponse } from 'axios';

import { groupBy, isNil, map, maxBy, minBy } from 'lodash';
import { Company } from '~/api/strategy';

export type BacktetingConditionOperator =
  | 'EQUAL'
  | 'GREATER'
  | 'GREATER_EQUAL'
  | 'LESS'
  | 'LESS_EQUAL'
  | 'NOT_EQUAL';

export const compareOperations = ['<', '<=', '=', '>=', '>', '='];
export type CompareOperator = typeof compareOperations[number];

export type QueryType = 'FILTER' | 'F_SCORE' | 'R_SCORE';

export type ValueType = 'ABSOLUTE' | 'RELATIVE';

export type QueryFactor = {
  factorId: number;
  gt: number | null;
  gte: boolean;
  lt: number | null;
  lte: boolean;
  queryType: QueryType;
  valueType: ValueType;
  weight: number;
};

export type FactorFilterCondition = {
  factorId: number;
  isAbsolute?: boolean;
  symbol: CompareOperator;
  value: number | 'min' | 'max' | null;
};
export type FactorQueryCondition =
  | (
      | { queryType: 'F_SCORE' | 'R_SCORE'; weight: number }
      | {
          queryType: 'FILTER';
        }
    ) & {
      factorId: number;
      gt?: number | null;
      gte?: boolean;
      lt?: number | null;
      lte?: boolean;
      valueType: 'RELATIVE' | 'ABSOLUTE';
    };

export type Order = 'desc' | 'asc';

export type FactorOrder = {
  factorId: number;
  order: Order;
};

// export type BackTestingBody = {
//   nationCode: number;
//   conditions: FactorFilterCondition[];
//   orderByFactor: BacktestingFactorOrder;
//   startDate: string;
//   endDate: string;
//   rebalancingPeriod: string;
//   top: number;
//   tradeCost: number;
// };

export type RebalancingPeriod =
  | 'ANNUALLY'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'SEMIANNUALLY';
export type BacktestingRequestStatus = 'COMPLETED' | 'PENDING' | 'PROCESSING';

export type FactorWeightCondition = {
  factorId: number;
  percent: number;
  symbol: BacktetingConditionOperator;
  weight: number;
};

export type PortWeightCondition = {
  cosmosCode: number;
  weight: number;
};

export type FactorValueResult = {
  [key: number]: number;
};

export type BenchmarkInfo = {
  code: number;
  nationCode: number;
  name: string;
  region: string;
};

export type BacktestingRebalancingStat = {
  date: Date;
  totalCompanyCount: number;
};
export type BacktestingComapnyRebalancingInfo = {
  cosmosCode: number;
  date: Date;
  factorValueMap: FactorValueResult;
  marketCap: number;
  marketDate: Date;
  marketPrice: number;
  score: number;
  weight: number;
};
export type BacktestingRebalancingResult = {
  companyInfos: Company[]; // -> portResult
  rebalancingStats: BacktestingRebalancingStat[];
  companyRebalancingInfos: BacktestingComapnyRebalancingInfo[];
};

export type YieldStat = {
  accumYield: number;
  annualSharp: number;
  annualStdev: number;
  annualYield: number;
  minMdd: number;
};

export type YieldBenchmarkSnapshot = {
  bmIndex: number;
  bmMdd: number;
  bmYield: number;

  portIndex: number;
  portMdd: number;
  portYield: number;

  exIndex: number;
  exYield: number;

  startDate: string;
  endDate: string;
};

export type CompanyYield = {
  cosmosCode: number;
  startDate: Date;
  endDate: Date;
  weight: number;
  yield: number;
};

export type YieldIndex = {
  bmStats: YieldStat;
  portStats: YieldStat;
  companyYieldInfos: CompanyYield[];
  portBMYieldIndexes: YieldBenchmarkSnapshot[]; // portBMYieldIndexes
  // portBenchResultList?: YieldBenchmarkSnapshot[];
};

export type ExcludedCompany = {
  id: number;
  ticker: string;
  name: string;
};

export type PortWeightPolicy = 'EQUAL' | 'MARKET_CAP' | 'RANK';

export interface Backtesting {
  id: string;
  uid: string;
  userId?: string;
  version: string;
  requestKey?: string;
  nationCode?: number;
  indexCode?: number;
  startDate?: Date;
  endDate?: Date;
  count?: number;
  tradeCost?: number;
  factorFilter?: FactorFilterCondition[];
  factorQuery?: FactorQueryCondition[];
  factorWeight?: FactorWeightCondition[];
  factorOrder?: FactorOrder[];
  portWeightPolicy?: PortWeightPolicy;
  categoryIds?: number[] | null;
  excludedCompanies?: ExcludedCompany[];
  rebalancingPeriod: RebalancingPeriod;
  benchmarkInfo?: BenchmarkInfo;
  yieldIndex?: YieldIndex;
  rebalancingResult?: BacktestingRebalancingResult;
  // portfolioSnapshots?: Company[];
  status: BacktestingRequestStatus;
  createdAt: Date;
  updatedAt: Date;
}

export type BacktestingPerformance = {
  id: string;
  uid: string;
  userId?: string;
  requestKey?: string;
  indexCode?: number;
  nationCode?: number;
  startDate?: Date;
  endDate?: Date;
  rebalancingPeriod: RebalancingPeriod;
  tradeCost?: number;
  accumYield: number;
  annualYield: number;
  annualStdev: number;
  annualSharpeRatio: number;
  minMdd: number;
  createdAt: Date;
  updatedAt: Date;
};

export const convertFactorFilterToQuery = (
  filters: FactorFilterCondition[] | null | undefined,
): FactorQueryCondition[] => {
  return map(
    groupBy(filters, (f) => `${f.factorId}:${f.isAbsolute}`),
    (conditions) => {
      if (conditions.length > 2) {
        throw new Error('duplicated filter condition error');
      }

      const minCondition = maxBy(
        conditions.filter((f) => ['>=', '>'].includes(f.symbol)),
        (f) => f.value,
      );
      const maxCondition = minBy(
        conditions.filter((f) => ['<=', '<'].includes(f.symbol)),
        (f) => f.value,
      );

      const isAbsolute = minCondition?.isAbsolute ?? maxCondition?.isAbsolute;

      return <FactorQueryCondition>{
        queryType: 'FILTER',
        factorId: minCondition?.factorId ?? maxCondition?.factorId,
        valueType: isAbsolute ? 'ABSOLUTE' : 'RELATIVE',
        gt: !isNil(minCondition?.value)
          ? isAbsolute
            ? Number(minCondition?.value)
            : 100 - Number(minCondition?.value ?? 100)
          : null,
        gte: minCondition?.symbol.includes('=') ?? false,
        lt: !isNil(maxCondition?.value)
          ? isAbsolute
            ? Number(maxCondition?.value)
            : 100 - Number(maxCondition?.value ?? 0)
          : null,
        lte: maxCondition?.symbol?.includes('=') ?? false,
      };
    },
  );
};

export const createService = (client: AxiosInstance) => {
  return {
    async getBacktesting(uid: string): Promise<AxiosResponse<Backtesting>> {
      return client.get<Backtesting>(`/backtesting/${uid}`);
    },
    async requestBacktesting(requestForm: {
      indexCode?: number;
      nationCode?: number;
      categoryIds?: number[] | null;
      factorFilter?: FactorFilterCondition[];
      factorQuery?: FactorQueryCondition[];
      factorWeight?: FactorWeightCondition[];
      portWeight?: PortWeightCondition[];
      portWeightPolicy?: PortWeightPolicy;
      excludedCompanies?: ExcludedCompany[];
      count?: number;
      startDate?: Date;
      endDate?: Date;
      rebalancingPeriod: RebalancingPeriod;
      orders?: FactorOrder[];
      tradeCost?: number;
    }) {
      return client.post<{
        uid: string;
        portfolio: Company[];
        yieldIndex: YieldIndex;
      }>(
        '/backtesting', // /backtesting
        requestForm,
      );
    },
    async downloadBacktestingMonthlyReportXlsx(uid: string) {
      return client.get(`/backtesting/${uid}/monthly-report.xlsx`, {
        responseType: 'blob',
      });
    },
  };
};
