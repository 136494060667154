import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  VStack,
  Modal,
  ModalBody,
  FormLabel,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormControl,
  Input,
  Center,
  ChakraProvider,
} from '@chakra-ui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '~/api';
import { Strategy } from '~/api/screener';
import { Body1, Body2, Caption } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { FactorStateContext } from '../factor/context';

type Props = {
  onSelectStrategy: (strategy: Strategy) => void;
  onRemoveStrategy: (strategy: Strategy) => void;
  onEditStrategy: (prev: Strategy | undefined, next: Strategy) => void;
  checkDuplicatedStrategy: (strategy: Strategy) => boolean;
  strategies: Array<Strategy>;
};

function ConditionsList({
  onSelectStrategy,
  onRemoveStrategy,
  onEditStrategy,
  checkDuplicatedStrategy,
  strategies,
}: Props) {
  const factorState = useContext(FactorStateContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isRemoveOpen, setIsRemoveOpen] = useState<boolean>(false);
  const [t] = useTranslation();
  const [strategy, setStrategy] = useState<Strategy>();
  const [prevStrategy, setPrevStrategy] = useState<Strategy>();
  const [errorMessage, setErrorMessage] = useState<string>();
  if (!factorState) return <div />;
  const themeData = getThemeData();

  function ConditionItem({ strategy }: { strategy: Strategy }) {
    return (
      <Flex
        w="100%"
        _hover={{
          backgroundColor: themeData.colors.primary[100],
        }}
        onClick={() => {
          onSelectStrategy(strategy);
        }}
        align="center"
        p="8px 0px"
      >
        <Box zIndex={0}>
          <Body2 color="black">{strategy.name}</Body2>
        </Box>
        <Spacer />

        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="unstyled"
                size="xs"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <MenuList
                zIndex={isOpen ? 1 : 0}
                sx={{
                  border: 'solid 1px #808080',
                  padding: '4px 8px',
                  bg: 'white',
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setPrevStrategy(strategy);
                    setStrategy(strategy);
                    onOpen();
                  }} // show edit modal
                  icon={<EditIcon sx={{ width: '16px', height: '16px' }} />}
                >
                  <Body1>{t('strategy.edit')}</Body1>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();

                    setStrategy(strategy);
                    setIsRemoveOpen(true);
                  }}
                  icon={<DeleteIcon sx={{ width: '16px', height: '16px' }} />}
                >
                  <Body1>{t('strategy.delete')}</Body1>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    );
  }

  return (
    <VStack w="100%" h="100%">
      {strategies.length === 0 ? (
        <Center h="100%">
          <Body2
            whiteSpace="break-spaces"
            noOfLines={10}
            align="center"
            color={themeData.colors.text3}
          >
            {t('strategy.empty')}
          </Body2>
        </Center>
      ) : (
        strategies.map((strategy) => {
          return <ConditionItem key={strategy.name} strategy={strategy} />;
        })
      )}
      <ChakraProvider>
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent borderRadius="16px">
            <ModalHeader fontWeight="bold">{t('strategy.edit')}</ModalHeader>
            <ModalCloseButton color={themeData.colors.gray[300]} />
            <ModalBody pb={2}>
              <FormControl>
                <Input
                  value={strategy?.name}
                  onChange={(event) => {
                    if (event.target.value.length > 15) {
                      setErrorMessage(t('strategy.error.tooLong'));
                    } else if (errorMessage) {
                      setErrorMessage(undefined);
                    }
                    const newStrategy: Strategy = {
                      ...strategy!,
                      name: event.target.value,
                    };
                    // strategy.name =  event.target.value

                    setStrategy(newStrategy);
                  }}
                  placeholder={t('strategy.alias')}
                />
                <Flex marginTop="8px">
                  {errorMessage ? (
                    <Box marginTop="8px">
                      <Caption color={themeData.colors.error}>
                        {errorMessage}
                      </Caption>
                    </Box>
                  ) : (
                    <div />
                  )}
                  <Spacer />
                  <Caption
                    color={themeData.colors.text3}
                  >{`${strategy?.name.length} / 15`}</Caption>
                </Flex>
              </FormControl>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button
                // p="0px 24px" colorScheme="gray" mr={3}
                variant="outlined"
                sx={{
                  marginRight: '8px',
                }}
                onClick={onClose}
              >
                {t('text.cancel')}
              </Button>
              <Button
                // p="0px 24px"
                variant="contained"
                disabled={
                  strategy?.name.replaceAll(' ', '').length === 0 ||
                  errorMessage !== undefined
                }
                // _disabled={{ backgroundColor: 'gray' }}
                // colorScheme="primary"
                onClick={async () => {
                  if (!strategy) return;
                  if (prevStrategy === strategy) {
                    onClose();
                    return;
                  }

                  if (checkDuplicatedStrategy(strategy)) {
                    setErrorMessage(
                      t('strategy.error.duplicated', { value: strategy }),
                    );
                    return;
                  }
                  onEditStrategy(prevStrategy, strategy);

                  onClose();
                }}
              >
                {t('text.save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isCentered
          isOpen={isRemoveOpen}
          onClose={() => {
            setIsRemoveOpen(false);
          }}
        >
          <ModalOverlay />
          <ModalContent borderRadius="16px">
            <ModalHeader fontWeight="bold"> {t('strategy.delete')}</ModalHeader>
            <ModalCloseButton color={themeData.colors.gray[300]} />
            <ModalBody pb={2}>
              <FormLabel
                marginBottom="12px"
                whiteSpace="break-spaces"
                textAlign="center"
              >
                {t('strategy.deleteDescription', { value: strategy?.name })}
              </FormLabel>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button
                // p="0px 24px"
                // colorScheme="gray"
                // mr={3}
                sx={{
                  marginRight: '8px',
                }}
                variant="outlined"
                onClick={() => {
                  setIsRemoveOpen(false);
                }}
              >
                {t('text.cancel')}
              </Button>
              <Button
                // p="0px 24px"
                // colorScheme="red"
                variant="contained"
                onClick={() => {
                  if (!strategy) return;
                  onRemoveStrategy(strategy);
                  setIsRemoveOpen(false);
                }}
              >
                {t('strategy.deleteButton')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </VStack>
  );
}
export default ConditionsList;
