import { useContext, useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '@mui/material';
import { Spacer } from '@chakra-ui/react';

import useFetch from '~/hooks/useFetch';
import { Nation } from '~/api/screener';
import api from '~/api';
import { getThemeData } from '~/utils/theme';
import { AppContext } from '~/AppContext';

type NationSelectorComponentProps = {
  nations: Array<Nation>;
  onSelectNation: (nation: Nation) => void;
  selectedNation?: Nation;
};

type NationSelectorProps = {
  onSelectNation: (nation: Nation) => void;
  selectedNation: Nation;
};

const NationSelectorComponent = ({
  nations,
  onSelectNation,
  selectedNation,
}: NationSelectorComponentProps) => {
  const { t } = useTranslation();
  const themeData = getThemeData();
  return useMemo(
    () => (
      <Box>
        <Typography variant="caption" color={themeData.colors.text3}>
          {t('text.nation')}
        </Typography>
        <Spacer h="8px" />
        <ReactSelect
          className="nation-selector"
          defaultValue={{
            value: selectedNation?.code,
            // @ts-ignore
            label: t(`nation.name.${selectedNation?.name}`),
          }}
          value={{
            value: selectedNation?.code,
            // @ts-ignore
            label: t(`nation.name.${selectedNation?.name}`),
          }}
          isSearchable={false}
          // @ts-ignore
          onChange={(v) => onSelectNation({ code: v.value, name: v.name })}
          options={[
            ...(nations.map((e) => {
              return {
                value: e.code,
                // @ts-ignore
                label: t(`nation.name.${e.name}`),
                name: e.name,
              };
            }) as Array<any>),
          ]}
          styles={{
            option: (provided, state) => ({
              ...provided,
              background: state.isSelected ? '#F6EDFF' : 'white',
              color: themeData.colors.text1,
              fontSize: themeData.typography.h5.fontSize,
            }),
            control: (provided) => ({
              ...provided,
              border: `solid 1px ${themeData.colors.primary[900]}`,
              borderRadius: 8,
              // color: themeData.colors.text2,
              // fontSize: themeData.typography.h5.fontSize,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
        />
      </Box>
    ),
    [nations, selectedNation],
  );
};
const NationSelector = ({
  onSelectNation,
  selectedNation,
}: NationSelectorProps) => {
  const context = useContext(AppContext);
  const [nations, setNations] = useState<Array<Nation>>([]);

  useEffect(() => {
    if (context?.state?.user) {
      api.screener.getNation().then((v) => {
        setNations(v.data);
      });
    }
  }, [context?.state?.user]);

  // const [nationListResponseState] = useFetch<Array<Nation>>(
  //   () => repository.getNationList(),
  //   [],
  // );

  return useMemo(() => {
    // if (nationListResponseState.loading) {
    //   return <Skeleton height="60px" />;
    // }

    return (
      <NationSelectorComponent
        onSelectNation={onSelectNation}
        nations={nations}
        selectedNation={selectedNation}
      />
    );
  }, [nations, selectedNation]);
};

export default NationSelector;
