import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  chain,
  findLast,
  groupBy,
  last,
  map,
  nth,
  orderBy,
  some,
  sum,
  sumBy,
  transform,
  uniq,
  sortBy,
  isNil,
  zip,
  chunk,
} from 'lodash';
import { CustomLayerProps, ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import {
  alpha,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Refresh,
  Public as PublicIcon,
  Close as CloseIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
  AddBox,
  Factory,
  RemoveCircle,
  Download,
  Upload,
  AccountBalance as AccountBalanceIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  SyncAlt as SyncAltIcon,
  DashboardCustomize,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import dayjs from '~/utils/dayjs';
import api from '~/api';
import { AppContext } from '~/AppContext';
import {
  Portfolio,
  Account,
  AccountBalance,
  ModelAsset,
  ModelRebalancingLog,
  Positioning,
  ModelAllocationRatios,
  PortfolioBacktestingResult,
  nationCurrencies,
} from '~/api/portfolio';
import { Category } from '~/api/strategy';
import PortfolioStrategyApplier from '~/containers/PortfolioStrategyApplier';
import ModelAssetBuyingGuide from '~/containers/ModelAssetBuyingGuide';
import { IndexSummery } from '~/components/strategy/creation/BacktestingResult';
import PortfolioPositioning from '~/containers/PortfolioPositioning';
import portfolioMask from '~/assets/image/portfolio_mask.png';
import samplePortfolioChart from '~/assets/image/sample_portfolio_chart.png';
import bannerAccountLink from '~/assets/image/banner_account_link.png';
import benchmarkMask from '~/assets/image/benchmark_mask.png';
import examplePie from '~/assets/image/example_pie.svg';
import StyledModal from '~/components/StyledModal';
import StyledButton from '~/components/StyledButton';
import { convertFactorFilterToQuery } from '~/api/backtesting';

type RebalancingAssetSnapshot = {
  ticker: string;
  name: string;
  value: number;
  ratio: number;
};

type RebalancingLog = {
  value: number;
  date: Date;
  allocations: RebalancingAssetSnapshot[];
};

const scheduleStates = [
  'skip',
  'pending',
  'wait',
  'pass',
  'completed',
] as const;
type ScheduleState = typeof scheduleStates[number];

export const stockPieTemplateColors = [
  '#9E7DF9',
  '#C2ADFB',
  '#4070C9',
  '#7094D7',
  '#FF8785',
  '#FFAFAD',
  '#FFC95C',
];

function CustomPoint(props: CustomLayerProps) {
  /* @ts-ignore */
  const { currentSlice, pointBorderWidth, pointBorderColor } = props;
  // it will show the current point
  if (currentSlice) {
    /* @ts-ignore */
    return currentSlice.points.map((point) => {
      return (
        <g key={point.id}>
          <circle r={4} fill={point.color} cx={point.x} cy={point.y} />
          <circle r={4} fill={point.color} cx={point.x} cy={point.y} />
        </g>
      );
    });
  }
  return <div />;
}

function DifferencePortfolioListView({
  buyList,
  sellList,
}: {
  buyList: { asset: RebalancingAssetSnapshot; value: number }[];
  sellList: { asset: RebalancingAssetSnapshot; value: number }[];
}) {
  return (
    <Stack direction="column" spacing="12px">
      {buyList.length > 0 ? (
        <>
          <Typography variant="subtitle2">매수 TOP5 종목</Typography>
          <Stack direction="column" spacing={1}>
            {buyList.slice(0, 5).map((t, index) => {
              return (
                <Stack
                  key={t.asset.ticker}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor:
                        stockPieTemplateColors[
                          index % stockPieTemplateColors.length
                        ],
                    }}
                  />
                  <Typography
                    variant="body2"
                    flex={1}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {t.asset.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {(t.value * 100).toFixed(2)}%
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
          <Divider />
        </>
      ) : null}
      {sellList.length > 0 ? (
        <>
          <Typography variant="subtitle2">매도 TOP5 종목</Typography>
          <Stack direction="column" spacing={1}>
            {sellList.slice(0, 5).map((t, index) => {
              return (
                <Stack
                  key={t.asset.ticker}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor:
                        stockPieTemplateColors[
                          index % stockPieTemplateColors.length
                        ],
                    }}
                  />
                  <Typography
                    variant="body2"
                    flex={1}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {t.asset.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {(t.value * 100).toFixed(2)}%
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </>
      ) : null}
    </Stack>
  );
}

export default function PortfolioView() {
  const [t] = useTranslation();
  const { state } = useContext(AppContext) ?? {};
  const { uid } = useParams<{ uid: string }>();
  const history = useHistory();
  const { search } = useLocation();
  const { logEvent } = useAmplitude();

  const waitRebalancingCardRef = useRef<HTMLDivElement>(null);

  const [nowDate, setNowDate] = useState<Date>(new Date());
  const [categoryList, setCategoryList] = useState<Category[] | null>(null);
  const [portfolio, setPortfolio] = useState<Portfolio | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [positioning, setPositioning] = useState<Positioning | null>(null);
  const [positioningLogs, setPositioningLogs] = useState<Positioning[] | null>(
    null,
  );
  const [modelRebalancingHistory, setModelRebalancingHistory] = useState<
    ModelRebalancingLog[] | undefined
  >();
  const [balance, setBalance] = useState<AccountBalance | null>(null);

  const [openNamingModalName, setOpenNamingModalName] = useState<string | null>(
    null,
  );
  const [isOpenStrategyDetailModal, setIsOpenStrategyDetailModal] =
    useState<boolean>(false);
  const [isOpenStrategyApplier, setIsOpenStrategyApplier] =
    useState<boolean>(false);
  const [isOpenBuyingGuideModal, setIsOpenBuyingGuideModal] =
    useState<boolean>(false);
  const [openRebalancingDetail, setOpenRebalrancingDetail] = useState<{
    sellList: { asset: RebalancingAssetSnapshot; value: number }[];
    buyList: { asset: RebalancingAssetSnapshot; value: number }[];
  } | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [modelPortfolioYield, setModelPortfolioYield] =
    useState<PortfolioBacktestingResult | null>(null);

  const [waitingRebalancingModelAssets, setWaitingRebalancingModelAssets] =
    useState<ModelAsset[]>([]);

  const [openPositioningModel, setOpenPositioningBuilderModel] =
    useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.has('now')) {
      setNowDate(dayjs(query.get('now')).toDate());
    }
  }, [search]);

  const requestPortfolioState = () => {
    (async () => {
      const portfolioRes = await api.portfolio.portfolio(uid);
      const portfolio = portfolioRes.data;

      const accountRes = await api.portfolio.linkInfo(portfolio.id);

      setPortfolio(portfolio);
      setAccount(accountRes.data);

      if (accountRes.data?.accountNumber) {
        const targetCurrency =
          portfolio.strategy?.nationCode &&
          portfolio.strategy.nationCode in nationCurrencies
            ? nationCurrencies[portfolio.strategy.nationCode]
            : undefined;

        const [balanceRes, positioningRes, positioningLogsRes, backtestingRes] =
          await Promise.all([
            api.portfolio.realBalance(portfolio.id, targetCurrency),
            api.portfolio.latestPositioning(portfolio.id),
            api.portfolio.executedPositioningLogs(portfolio.id),
            api.portfolio.backtestingPositioningLogs(portfolio.id),
          ]);

        setCurrency(targetCurrency ?? null);
        setBalance(balanceRes.data);
        setPositioning(positioningRes.data);
        setPositioningLogs(positioningLogsRes.data);
        setModelPortfolioYield(backtestingRes.data);
        setModelRebalancingHistory(
          positioningLogsRes.data.map((log) => {
            return {
              date: log.createdAt,
              ratios:
                log.modelAssets?.reduce((r, m) => {
                  r[m.id] = m;
                  return r;
                }, {} as ModelAllocationRatios) ?? {},
              value: 0,
            };
          }),
        );
      } else {
        const backtestingRes =
          await api.portfolio.backtestingModelAllocationLogs(portfolio.id);
        setModelPortfolioYield(backtestingRes.data);
        setModelRebalancingHistory(modelRebalancingHistory);
      }
    })().catch((error) => {
      console.log('fail', error);
    });
  };

  const requestChangePortfolioName = (portfolioUid: string, name: string) => {
    api.portfolio
      .updatePortfolio(portfolioUid, { name })
      .then(() => {
        requestPortfolioState();
      })
      .catch((error) => {
        console.error('fail', error);
      });
  };

  const passRebalancing = (portfolio: Portfolio, date: Date) => {
    if (portfolio.id) {
      api.portfolio.addRebalancingPassDate(portfolio.id, date).then(
        () => {
          requestPortfolioState();
        },
        (error) => {
          console.log('fail', error);
        },
      );
    }
  };

  const requestRebalancing = (
    portfolio: Portfolio,
    modelAssets: ModelAsset[],
  ) => {
    // apply-strategy로 가능
    if (portfolio.strategyId) {
      api.portfolio
        .applyStrategy(portfolio.id, portfolio.strategyId, modelAssets)
        .then(
          () => {
            requestPortfolioState();
          },
          (error) => {
            console.log('fail', error);
          },
        );
    }
  };

  useEffect(() => {
    if (state?.user) {
      api.strategy
        .getSectors()
        .then((response) => {
          setCategoryList(response.data);
        })
        .catch((error) => {
          console.log('fail', error);
        });
      requestPortfolioState();
    }
  }, [state?.user, uid]);

  const isNowPositioning = useMemo(() => {
    return !!positioning && positioning.phase !== 'done';
  }, [positioning?.phase]);

  const linkAccountBox = useMemo(() => {
    if (!portfolio) {
      return null;
    }

    if (account) {
      return (
        <Stack
          p="24px"
          spacing={1}
          direction="column"
          flex={1}
          boxShadow="0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)"
          borderRadius="8px"
        >
          <Stack direction="row" spacing={1}>
            {account?.accountNumber ? (
              <>
                <LinkIcon color="success" />
                <Typography color="success" flex={1}>
                  포트폴리오 연동 완료
                </Typography>
                <StyledButton
                  color="error"
                  size="small"
                  variant="contained"
                  disabled={isNowPositioning ?? true}
                  onClick={() => {
                    api.portfolio
                      .unlinkAccount(account.portfolioId)
                      .then(() => {
                        requestPortfolioState();
                      });
                  }}
                >
                  연동해제
                </StyledButton>
              </>
            ) : (
              <>
                <LinkOffIcon color="error" />
                <Typography color="error" flex={1}>
                  포트폴리오 연동 안됨
                </Typography>
                <StyledButton
                  disabled={
                    !portfolio?.strategy?.nationCode ||
                    !(portfolio?.strategy.nationCode in nationCurrencies)
                  }
                  color="success"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setOpenAccountModal({
                      provider: 'kis',
                      environment: null,
                      providerKey: '',
                      providerSecret: '',
                      accountNumber: '',
                    });
                  }}
                >
                  연동하기
                </StyledButton>
              </>
            )}
          </Stack>
        </Stack>
      );
    }

    const isRegisterable =
      portfolio?.strategy?.nationCode &&
      portfolio.strategy.nationCode in nationCurrencies &&
      state?.permission?.trading &&
      (state.permission.trading === true ||
        (state.permission?.trading?.[portfolio.strategy.nationCode] ??
          state.permission.trading?.default));

    return (
      <Stack
        borderRadius="4px"
        width="100%"
        position="relative"
        sx={{ backgroundColor: '#332D41' }}
        overflow="hidden"
      >
        <img
          style={{
            position: 'absolute',
            right: 0,
            height: '100%',
          }}
          alt="banner-account-link"
          src={bannerAccountLink}
        />
        <Stack m="24px" flex={1}>
          {isRegisterable ? ( // 국장
            <>
              <Stack flex={1}>
                <Typography
                  variant="headline1"
                  color="white"
                  sx={{ fontSize: ['14px', '20px'] }}
                >
                  {t('text.try_portfolio')}
                </Typography>
                <Typography color="white">
                  {t('text.try_portfolio_desc')}
                </Typography>
              </Stack>
              <Stack direction="row" spacing="6px" mt="16px">
                <StyledButton
                  variant="contained"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    ':hover': {
                      color: (theme) => theme.palette.primary.contrastText,
                    },
                    backgroundColor: 'white',
                  }}
                  onClick={() =>
                    setOpenAccountModal({
                      provider: 'kis',
                      environment: null,
                      providerKey: '',
                      providerSecret: '',
                      accountNumber: '',
                    })
                  }
                >
                  {t('text.connect_korea_investment_api')}
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    window.open(
                      'https://doomoolmori.notion.site/809d8e8636c34d7683d6fb9167d48191',
                      '_blank',
                    );
                  }}
                >
                  {t('text.view_api_integration_trading_guide')}
                </StyledButton>
              </Stack>
            </>
          ) : (
            <>
              <Stack flex={1}>
                <Typography
                  variant="headline1"
                  color="white"
                  sx={{ fontSize: ['14px', '20px'] }}
                >
                  {t('text.operate_portfolio_question')}
                </Typography>
                <Typography color="white">
                  {t('text.operate_portfolio_guide')}
                </Typography>
              </Stack>
              <Stack direction="row" spacing="6px" mt="16px">
                <StyledButton
                  variant="contained"
                  color="success"
                  sx={{ width: 'fit-content' }}
                  onClick={() => {
                    logEvent('invest to portfolio');
                    setIsOpenBuyingGuideModal(true);
                  }}
                >
                  {t('text.operate')}
                </StyledButton>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    );
  }, [portfolio, account, isNowPositioning]);

  const changeNameModal = useMemo(() => {
    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={openNamingModalName !== null}
      >
        <Stack
          sx={{
            borderRadius: '8px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 315,
            maxWidth: '90vw',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: '16px',
          }}
          spacing="16px"
        >
          <Stack spacing="8px">
            <Typography variant="headline2">포트폴리오 이름 변경</Typography>
            <Typography variant="body2" color="text.secondary">
              변경할 포트폴리오 이름을 입력해주세요. <br />
              *전략명은 변경되지 않습니다.
            </Typography>
          </Stack>
          <TextField
            sx={{
              flex: 1,
              marginTop: '12px',
            }}
            size="small"
            type="text"
            variant="outlined"
            value={openNamingModalName}
            onChange={(e) => {
              setOpenNamingModalName(e.target.value);
            }}
          />

          <Stack direction="row" spacing="10px">
            <StyledButton
              sx={{ flex: 1 }}
              variant="outlined"
              onClick={() => setOpenNamingModalName(null)}
            >
              {t('text.cancel')}
            </StyledButton>
            <StyledButton
              sx={{ flex: 1 }}
              variant="contained"
              disabled={(openNamingModalName?.length ?? 0) < 1}
              onClick={() => {
                if (
                  portfolio &&
                  openNamingModalName !== null &&
                  openNamingModalName !== portfolio?.name
                ) {
                  requestChangePortfolioName(
                    portfolio.uid,
                    openNamingModalName,
                  );
                  setOpenNamingModalName(null);
                }
              }}
            >
              변경하기
            </StyledButton>
          </Stack>
        </Stack>
      </StyledModal>
    );
  }, [openNamingModalName, portfolio]);

  const strategyApplierModal = useMemo(() => {
    if (!isOpenStrategyApplier || !portfolio) {
      return null;
    }
    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={isOpenStrategyApplier}
        onClose={() => setIsOpenStrategyApplier(false)}
      >
        <PortfolioStrategyApplier
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            minWidth: '600px',
            width: '50%',
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          p={2}
          portfolioId={portfolio.id}
          onCompleted={(strategy, assetModel, recipe) => {
            if (portfolio) {
              api.portfolio
                .applyStrategy(portfolio.id, strategy.id, assetModel)
                .then(
                  () => {
                    requestPortfolioState();
                  },
                  (error) => {
                    console.log('fail', error);
                  },
                );
            }
            setIsOpenStrategyApplier(false);
          }}
        />
      </StyledModal>
    );
  }, [isOpenStrategyApplier]);

  const strategyDetailModal = useMemo(() => {
    if (!portfolio?.strategy) {
      return null;
    }

    const appliedFactors = [
      ...(portfolio?.strategy?.factorQuery ?? []),
      ...convertFactorFilterToQuery(portfolio?.strategy.factorFilter),
    ];

    const appliedFactorCount = sum(
      appliedFactors.map((f) => [f.gt, f.lt].filter((v) => !isNil(v)).length),
    );

    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={isOpenStrategyDetailModal}
        onClose={() => setIsOpenStrategyDetailModal(false)}
      >
        <Stack
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            minWidth: [null, '600px'],
            width: ['100%', null],
            overflow: 'auto',
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          p={2}
          spacing="8px"
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="headline1" flex={1}>
              전략 세부정보
            </Typography>
            <IconButton
              size="small"
              onClick={() => setIsOpenStrategyDetailModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <Refresh />
            <Typography variant="subtitle2" flex={1}>
              리밸런싱 주기
            </Typography>
            <Typography>
              {portfolio?.strategy?.rebalancingPeriod
                ? t(`period.${portfolio?.strategy?.rebalancingPeriod}`)
                : '없음'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <PublicIcon />
            <Typography variant="subtitle2" flex={1}>
              선택한 시장
            </Typography>
            {portfolio.strategy.indexCode ? (
              <Typography variant="body2">
                {t(`index.${portfolio.strategy.indexCode}`)}
              </Typography>
            ) : (
              <Typography variant="body2">
                {t(`nation.${portfolio.strategy.nationCode}.flag`)}{' '}
                {t(`nation.${portfolio.strategy.nationCode}.name`)}
              </Typography>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <Factory />
            <Typography variant="subtitle2" flex={1}>
              선택한 산업
            </Typography>
            <Typography>
              {(portfolio.strategy.categoryIds?.length ?? 0) > 0
                ? `${portfolio.strategy.categoryIds?.length}개`
                : t('text.all')}
            </Typography>
          </Stack>
          {(portfolio.strategy.categoryIds?.length ?? 0) > 0 ? (
            <Stack maxWidth="500px" direction="row" flexWrap="wrap">
              {portfolio.strategy.categoryIds?.map((categoryId) => {
                const category = findLast(categoryList, (c) =>
                  categoryId.toString().startsWith(c.cosmosGroupId.toString()),
                );
                if (!category) {
                  return null;
                }
                return (
                  <Box
                    sx={{ background: '#E5E7EB' }}
                    key={category.cosmosGroupId}
                    mt="8px"
                    ml="8px"
                    p="4px"
                    borderRadius="4px"
                    width="fit-content"
                  >
                    <Typography variant="body2" color="#677380">
                      {t(`category.${category.name}`)}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          ) : null}
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <AddBox />
            <Typography variant="subtitle2" flex={1}>
              추가 팩터
            </Typography>
            <Typography>
              {appliedFactorCount > 0 ? `${appliedFactorCount}개` : '없음'}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" spacing="8px">
            {map(appliedFactors, (condition) => {
              return (
                <Typography
                  key={condition.factorId}
                  variant="body2"
                  color="#677380"
                  sx={{ background: '#E5E7EB' }}
                  width="fit-content"
                  p="4px"
                  borderRadius="4px"
                >
                  {!isNil(condition.gt)
                    ? `${condition.gt} ${condition.gte ? '<=' : '<'} `
                    : null}
                  {t(`factor.${condition.factorId}.name`)}
                  {!isNil(condition.lt)
                    ? ` ${condition.lte ? '<=' : '<'} ${condition.lt}`
                    : null}
                </Typography>
              );
            })}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <RemoveCircle />
            <Typography variant="subtitle2" flex={1}>
              {t('text.excluded_stocks')}
            </Typography>
            <Typography>
              {(portfolio?.strategy?.excludedCompanies?.length ?? 0) > 0
                ? `${portfolio?.strategy?.excludedCompanies?.length}개`
                : t('text.none')}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" spacing="8px">
            {portfolio?.strategy?.excludedCompanies?.map?.((company) => {
              return (
                <Typography
                  key={company.id}
                  variant="body2"
                  color="#677380"
                  sx={{ background: '#E5E7EB' }}
                  width="fit-content"
                  p="4px"
                  borderRadius="4px"
                >
                  {company.name}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
      </StyledModal>
    );
  }, [portfolio?.strategy, categoryList, isOpenStrategyDetailModal]);

  const rebalancingDetailModal = useMemo(() => {
    if (!openRebalancingDetail) {
      return null;
    }

    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={!!openRebalancingDetail}
        onClose={() => setOpenRebalrancingDetail(null)}
      >
        <Stack
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            minWidth: '348px',
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          pt="32px"
          px="24px"
          pb="24px"
          spacing={2}
          overflow="auto"
        >
          <Stack
            height="fit-content"
            direction="row"
            alignItems="center"
            pb="24px"
          >
            <Typography variant="headline1" flex={1}>
              매수/매도 상세 종목
            </Typography>
            <IconButton
              size="small"
              onClick={() => setOpenRebalrancingDetail(null)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack spacing="12px">
            <Stack direction="row" spacing={1}>
              <Download />
              <Typography variant="subtitle2">매수 종목</Typography>
            </Stack>
            <Divider />
            {openRebalancingDetail.buyList.map((item, index) => {
              return (
                <Stack
                  key={item.asset.ticker}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor:
                        stockPieTemplateColors[
                          index % stockPieTemplateColors.length
                        ],
                    }}
                  />
                  <Typography
                    variant="body2"
                    flex={1}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {item.asset.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {(item.value * 100).toFixed(2)}%
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
          <Stack spacing="12px">
            <Stack direction="row" spacing={1}>
              <Upload />
              <Typography variant="subtitle2">매도 종목</Typography>
            </Stack>
            <Divider />
            {openRebalancingDetail.sellList.map((item, index) => {
              return (
                <Stack
                  key={item.asset.ticker}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor:
                        stockPieTemplateColors[
                          index % stockPieTemplateColors.length
                        ],
                    }}
                  />
                  <Typography
                    variant="body2"
                    flex={1}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {item.asset.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {(item.value * 100).toFixed(2)}%
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </StyledModal>
    );
  }, [openRebalancingDetail]);

  // price 차트
  const priceChart = useMemo(() => {
    if (
      !modelPortfolioYield ||
      (modelPortfolioYield?.yieldIndexResult?.portBMYieldIndexes?.length ?? 0) <
        2
    ) {
      return null;
    }

    const firstLog =
      modelPortfolioYield?.yieldIndexResult?.portBMYieldIndexes?.[0];
    const indexData = [
      {
        id: 'benchmark-yield',
        color: '#D9DCE0',
        data: [
          {
            x: firstLog?.startDate,
            y: 0,
          },
          ...(modelPortfolioYield?.yieldIndexResult?.portBMYieldIndexes?.map(
            (log) => {
              return {
                x: log.endDate,
                y: ((log.bmIndex - 1) * 100).toFixed(2),
              };
            },
          ) ?? []),
        ],
      },
      {
        id: 'yield',
        color: '#9E7DF9',
        data: [
          {
            x: firstLog?.startDate,
            y: 0,
          },
          ...(modelPortfolioYield?.yieldIndexResult?.portBMYieldIndexes?.map(
            (log) => {
              return {
                x: log.endDate,
                y: ((log.portIndex - 1) * 100).toFixed(2),
              };
            },
          ) ?? []),
        ],
      },
    ];

    const min = Math.min(
      -20,
      ...indexData.map((chart) => {
        return Math.min(...chart.data.map((v) => Number(v.y)));
      }),
    );

    const lastLog = last(
      modelPortfolioYield.yieldIndexResult.portBMYieldIndexes,
    );

    const benchmarkName = modelPortfolioYield.bmIndexInfo.name;

    return (
      <Stack
        direction="column"
        spacing={2}
        p={2}
        boxShadow="0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)"
        borderRadius="8px"
      >
        <Stack
          direction={['column', 'row']}
          width="100%"
          height={['calc(80vw + 140px)', '350px']}
        >
          <Stack direction="column" flex={1}>
            <Stack
              height="32px"
              direction="row"
              alignItems="center"
              spacing={1}
              pr={1}
            >
              <Stack flex={1} direction="row" alignItems="center">
                <Typography variant="headline2" fontWeight="bold">
                  {t('text.accumulatedReturn')}
                </Typography>
                <Tooltip
                  title="누적 수익률은 계좌 수익률이 아니라 투자 기준에 의해 만들어진 추정 수익률입니다."
                  arrow
                  placement="right"
                >
                  <HelpOutlineIcon
                    sx={{
                      height: '16px',
                    }}
                  />
                </Tooltip>
              </Stack>
              <Box
                sx={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '3px',
                  backgroundColor: '#9E7DF9',
                }}
              />
              <Typography fontSize="12px">{t('text.myStrategy')}</Typography>
              <Box
                sx={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '3px',
                  backgroundColor: '#D9DCE0',
                }}
              />
              <Typography fontSize="12px">
                {t(`benchmark.${benchmarkName}`)}
              </Typography>
            </Stack>
            <Box width="100%" height={['80vw', 'calc(100% - 32px)']}>
              {/* @ts-ignore */}

              <ResponsiveLine
                data={indexData}
                margin={{ top: 24, right: 24, bottom: 24, left: 36 }}
                curve="catmullRom"
                lineWidth={3}
                colors={{ datum: 'color' }}
                enableGridX={false}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                  useUTC: false,
                  precision: 'day',
                }}
                // eslint-disable-next-line react/no-unstable-nested-components
                layers={[
                  'grid',
                  'markers',
                  'axes',
                  'areas',
                  'crosshair',
                  'lines',
                  'slices',
                  'points',
                  'mesh',
                  'legends',
                  CustomPoint,
                ]}
                enablePoints={false}
                enableSlices="x"
                sliceTooltip={({ slice }) => (
                  <Box
                    // @ts-ignore
                    key={slice.id}
                    bgcolor="background.paper"
                    p="8px"
                    sx={{
                      borderRadius: '8px',
                      boxShadow:
                        '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);',
                    }}
                  >
                    <div>{slice.points[0].data.xFormatted}</div>
                    {slice.points.map((point, i) => {
                      return (
                        <Stack
                          key={point.id}
                          direction="row"
                          alignItems="center"
                        >
                          <Box
                            sx={{
                              width: '6px',
                              height: '6px',
                              borderRadius: '3px',
                              backgroundColor: point.color,
                              marginRight: '8px',
                            }}
                          />
                          <Typography color="text.secondary" marginRight="8px">
                            {point.serieId === 'yield'
                              ? t('text.my_investments')
                              : t(`benchmark.${benchmarkName}`)}
                          </Typography>

                          <Typography
                            color="text.primary"
                            marginRight="8px"
                            fontWeight="bold"
                          >
                            {point?.data?.y.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                            %
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Box>
                )}
                yScale={{
                  type: 'linear',
                  stacked: false,
                  min,
                }}
                xFormat="time:%Y-%m-%d"
                axisLeft={{
                  legendOffset: 12,
                  format: (v) => `${v}%`,
                }}
                axisBottom={{
                  format:
                    (indexData?.[0]?.data.length ?? 0) >= 15
                      ? '%Y/%m'
                      : '%m/%d',
                  tickValues:
                    (indexData?.[0]?.data.length ?? 0) >= 15
                      ? 'every 1 month'
                      : 'every 1 day',
                }}
                useMesh
                theme={{ textColor: '#888888' }}
              />
            </Box>
          </Stack>
          <Stack
            direction={['row', 'column']}
            spacing="24px"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <IndexSummery
              sx={{
                color: '#9E7DF9',
                width: '100%',
                height: ['120px', null],
                flex: 1,
                borderRadius: ['8px 0px 0px 8px', '8px'],
                backgroundColor: 'rgba(236, 233, 241, 0.4)',
                backgroundImage: [null, `url(${portfolioMask})`],
              }}
              title={t('text.my_investments')}
              delta={((lastLog?.exIndex ?? 1) - 1) * 100}
              value={((lastLog?.portIndex ?? 1) - 1) * 100}
              benchmarkName={t(`benchmark.${benchmarkName}`)}
            />
            <IndexSummery
              sx={{
                color: 'rgba(0, 0, 0, 0.6);',
                width: '100%',
                flex: 1,
                height: ['120px', null],
                borderRadius: ['0px 8px 8px 0px', '8px'],
                backgroundColor: 'rgba(236, 233, 241, 0.4)',
                backgroundImage: [null, `url(${benchmarkMask})`],
              }}
              title={t(`benchmark.${benchmarkName}`)}
              value={((lastLog?.bmIndex ?? 1) - 1) * 100}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }, [modelPortfolioYield]);

  const portfolioRatios = useMemo(() => {
    if (balance) {
      const modelAssets = [
        ...(portfolio?.modelAllocation.assets ?? []),
        ...(positioningLogs?.flatMap((log) => log.modelAssets ?? []) ?? []),
      ];
      return orderBy(
        balance.assets.map((realAsset) => {
          const modelAsset = findLast(
            modelAssets,
            (ma) => ma.ticker === realAsset.ticker,
          );
          return {
            ticker: realAsset.ticker,
            categoryId: modelAsset?.categoryId ?? null,
            name: realAsset.name,
            ratio:
              balance.evaluatedAmount > 0
                ? realAsset.evaluatedAmount / balance.evaluatedAmount
                : 0,
          };
        }),
        ['ratio'],
        ['desc'],
      );
    }
    return portfolio?.modelAllocation?.assets.map((asset, index) => {
      return {
        ticker: asset.ticker,
        categoryId: asset.categoryId,
        name: asset.name,
        ratio: asset.ratio,
      };
    });
  }, [portfolio?.modelAllocation?.assets, balance]);

  const [portfolioRatioChart, portfolioRatioList] = useMemo(() => {
    if (!portfolio || !portfolioRatios) {
      return [];
    }

    return [
      <ResponsivePie
        data={portfolioRatios.map((asset, index) => {
          return {
            id: asset.name,
            label: asset.name,
            value: asset.ratio,
            color:
              stockPieTemplateColors[index % stockPieTemplateColors.length],
          };
        })}
        colors={{ datum: 'data.color' }}
        margin={{ top: 16, right: 10, bottom: 16, left: 10 }}
        valueFormat=".02%"
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
      />,
      <Grid
        container
        width="100%"
        direction="row"
        spacing={2}
        flexDirection="row"
      >
        {portfolioRatios?.map((asset, index) => {
          return (
            <Grid item key={asset.ticker} xs={6}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor:
                      stockPieTemplateColors[
                        index % stockPieTemplateColors.length
                      ],
                  }}
                />
                <Typography
                  variant="body2"
                  flex={1}
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {asset.name}
                </Typography>
                <Typography variant="subtitle2">
                  {(asset.ratio * 100).toFixed(2)}%
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>,
    ];
  }, [portfolio, portfolioRatios, positioning]);

  const [categoryRatioChart, categoryRatioList] = useMemo(() => {
    if (!portfolio || !portfolioRatios || !categoryList) {
      return [];
    }

    const groupedCategories: {
      id: string;
      label: string;
      value: number;
      color: string;
    }[] = chain(portfolioRatios)
      .groupBy((asset) => {
        if (!asset.categoryId) {
          return 'unknown';
        }
        return (
          nth(asset.categoryId.toString().match(/.{1,2}/g), 0) ?? 'unknown'
        );
      })
      .map((assets, id) => {
        return [id, assets] as [string, ModelAsset[]];
      })
      .sort(([, aList], [, bList]) => bList.length - aList.length)
      .map(([id, assets], index) => {
        const category = categoryList.find(
          (c) => c.cosmosGroupId.toString() === id,
        );
        return {
          id: category ? t(`category.${category.name}`) : 'unknown',
          // label: category ? t(`category.${category.name}`) : 'unknown',
          // id: category?.name ?? 'unknown',
          label: category?.name ?? 'unknown',
          value: sumBy(assets, (asset) => asset.ratio),
          color: stockPieTemplateColors[index % stockPieTemplateColors.length],
        };
      })
      .value();

    return [
      <ResponsivePie
        data={groupedCategories}
        colors={{ datum: 'data.color' }}
        margin={{ top: 16, right: 10, bottom: 16, left: 10 }}
        valueFormat=".02%"
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
      />,
      <Grid
        container
        width="100%"
        direction="row"
        spacing={2}
        flexDirection="row"
      >
        {groupedCategories.map((item) => {
          return (
            <Grid item key={item.id} xs={6}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: item.color,
                  }}
                />
                <Typography variant="body2" flex={1}>
                  {t(`category.${item.label}`)}
                </Typography>
                <Typography variant="subtitle2">
                  {(item.value * 100).toFixed(2)}%
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>,
    ];
  }, [categoryList, portfolioRatios]);

  const buyingGuideModal = useMemo(() => {
    if (!portfolio?.modelAllocation?.assets) {
      return null;
    }

    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={isOpenBuyingGuideModal}
        onClose={() => setIsOpenBuyingGuideModal(false)}
      >
        <ModelAssetBuyingGuide
          modelAssets={portfolio?.modelAllocation.assets}
          onClose={() => setIsOpenBuyingGuideModal(false)}
        />
      </StyledModal>
    );
  }, [portfolio?.modelAllocation?.assets, isOpenBuyingGuideModal]);

  function linkAccount(
    portfolioId: string,
    info: {
      provider: string;
      environment: Account['environment'];
      providerKey: string;
      providerSecret: string;
      accountNumber: string;
    },
  ) {
    api.portfolio
      .linkAccount(portfolioId, info)
      .then(async (res) => {
        const infoRes = await api.portfolio.linkInfo(portfolioId);
        setAccount(infoRes.data);
        setOpenPositioningBuilderModel(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [openAccountModal, setOpenAccountModal] = useState<{
    provider: 'kis';
    environment: Account['environment'] | null;
    providerKey: string;
    providerSecret: string;
    accountNumber: string;
  } | null>(null);
  const accountModal = useMemo(() => {
    if (!openAccountModal) {
      return null;
    }

    const handleAccountInfoChange =
      (prop: keyof typeof openAccountModal) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value;
        setOpenAccountModal((state) => {
          if (state) {
            return { ...state, [prop]: v };
          }
          return state;
        });
      };

    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={!!openAccountModal}
        onClose={() => setOpenAccountModal(null)}
      >
        <Stack
          tabIndex={-1}
          sx={{
            borderRadius: [0, '8px'],
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            minWidth: [null, '424px'],
            width: ['100%', '424px'],
            height: ['100%', 'fit-content'],

            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          overflow="hidden"
        >
          <Stack
            direction="column"
            spacing="16px"
            pt="16px"
            flex={1}
            overflow="hidden"
          >
            <Stack direction="row" alignItems="center" px="16px">
              <Box
                display="flex"
                width="40px"
                height="40px"
                bgcolor="#F6EDFF"
                borderRadius="20px"
                justifyContent="center"
                alignItems="center"
              >
                <AccountBalanceIcon color="secondary" />
              </Box>
              <Typography ml="8px" fontWeight="bold" flex={1}>
                {openAccountModal?.provider === 'kis'
                  ? t('text.connect_korea_investment_account')
                  : '계좌 연동'}
              </Typography>
              <IconButton onClick={() => setOpenAccountModal(null)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack flex={1} spacing="16px" px="16px" overflow="auto">
              <Stack spacing="8px">
                <Typography>{t('text.select_real_or_demo_account')}</Typography>
                <RadioGroup
                  row
                  value={openAccountModal?.environment}
                  onChange={handleAccountInfoChange('environment')}
                >
                  <FormControlLabel
                    value="real"
                    control={<Radio />}
                    label="실계좌"
                  />
                  <FormControlLabel
                    value="virtual"
                    control={<Radio />}
                    label="모의계좌"
                  />
                </RadioGroup>
              </Stack>
              <Stack spacing="8px">
                <Typography>{t('text.enter_app_key')}</Typography>
                <TextField
                  placeholder="발급받은 APP Key를 입력해 주세요"
                  value={openAccountModal?.providerKey ?? ''}
                  onChange={handleAccountInfoChange('providerKey')}
                />
              </Stack>
              <Stack spacing="8px">
                <Typography>{t('text.enter_secret_key')}</Typography>
                <TextField
                  placeholder="발급받은 Secret Key를 입력해 주세요"
                  type="text"
                  value={openAccountModal?.providerSecret ?? ''}
                  onChange={handleAccountInfoChange('providerSecret')}
                />
              </Stack>
              <Stack spacing="8px">
                <Typography>
                  {t('text.enter_korea_investment_account_number')}
                </Typography>
                <TextField
                  placeholder="12345678-01"
                  value={openAccountModal?.accountNumber ?? ''}
                  onChange={handleAccountInfoChange('accountNumber')}
                />
              </Stack>
              <Typography variant="body1" fontWeight="bold">
                {t('text.important_note')}
              </Typography>
              <Box component="ul" pl={2}>
                <Typography component="li" variant="body2">
                  {t('text.trading_instruction_note')}
                </Typography>
                <Typography component="li" variant="body2">
                  {t('text.order_execution_note')}
                </Typography>
                <Typography component="li" variant="body2">
                  {t('text.low_trading_volume_note')}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box
            sx={{
              background: '#FAFAFA',
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
            }}
            py="16px"
            pr="16px"
          >
            <StyledButton
              sx={{
                width: 'fit-content',
              }}
              disabled={some(
                openAccountModal,
                (prop) => (prop?.length ?? 0) < 1,
              )}
              variant="contained"
              onClick={() => {
                if (portfolio) {
                  if (!account) {
                    linkAccount(portfolio.id, {
                      provider: openAccountModal.provider,
                      environment: openAccountModal.environment!,
                      providerKey: openAccountModal?.providerKey,
                      providerSecret: openAccountModal?.providerSecret,
                      accountNumber: openAccountModal.accountNumber,
                    });
                  }
                  setOpenAccountModal(null);
                }
              }}
            >
              {t('text.operate')}
            </StyledButton>
          </Box>
        </Stack>
      </StyledModal>
    );
  }, [openAccountModal]);

  const positioningModal = useMemo(() => {
    if (!openPositioningModel || !portfolio || !account) {
      return null;
    }

    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={openPositioningModel}
      >
        <PortfolioPositioning
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            // width: 'fit-contents',
            // minWidth: '760px',
            minWidth: [null, '760px'],
            width: ['100%', '760px'],

            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          pt="32px"
          px="24px"
          pb="24px"
          spacing={2}
          overflow="hidden"
          portfolio={portfolio}
          account={account}
          onClose={() => {
            requestPortfolioState();
            setOpenPositioningBuilderModel(false);
          }}
        />
      </StyledModal>
    );
  }, [openPositioningModel]);

  const rebalancingData = useMemo(() => {
    if (!portfolio) {
      return null;
    }

    let next: (date: dayjs.Dayjs) => dayjs.Dayjs;
    let inbound: (
      baseDate: dayjs.Dayjs,
      targetDate: dayjs.Dayjs | Date | string | number,
    ) => boolean;
    switch (portfolio?.strategy?.rebalancingPeriod) {
      case 'MONTHLY': {
        next = (date) => date.add(1, 'month');
        inbound = (baseDate, targetDate) =>
          baseDate.isSame(targetDate, 'month');
        break;
      }
      case 'QUARTERLY': {
        // 가까운 1, 4, 7, 10 말일로 가야한다
        next = (date) => {
          const rebalancingDates = [
            dayjs(date).set('month', 0).endOf('month').startOf('date'),
            dayjs(date).set('month', 3).endOf('month').startOf('date'),
            dayjs(date).set('month', 6).endOf('month').startOf('date'),
            dayjs(date).set('month', 9).endOf('month').startOf('date'),
            dayjs(date)
              .add(1, 'year')
              .set('month', 0)
              .endOf('month')
              .startOf('date'),
          ];
          return rebalancingDates.find((rDate) => date.isBefore(rDate))!;
        };
        inbound = (baseDate, targetDate) =>
          next(dayjs(baseDate)).isSame(next(dayjs(targetDate)));
        break;
      }
      case 'SEMIANNUALLY': {
        // 가까운 4, 10 말일로 가야한다
        next = (date) => {
          const rebalancingDates = [
            dayjs(date).set('month', 3).endOf('month').startOf('date'),
            dayjs(date).set('month', 9).endOf('month').startOf('date'),
            dayjs(date)
              .add(1, 'year')
              .set('month', 3)
              .endOf('month')
              .startOf('date'),
          ];
          return rebalancingDates.find((rDate) => date.isBefore(rDate))!;
        };
        inbound = (baseDate, targetDate) =>
          next(dayjs(baseDate)).isSame(next(dayjs(targetDate)));
        break;
      }
      case 'ANNUALLY':
      default: {
        // 가까운 4월 말일로 가야한다
        next = (date) => {
          const rebalancingDates = [
            dayjs(date).set('month', 3).endOf('month').startOf('date'),
            dayjs(date)
              .add(1, 'year')
              .set('month', 3)
              .endOf('month')
              .startOf('date'),
          ];
          return rebalancingDates.find((rDate) => date.isBefore(rDate))!;
        };
        inbound = (baseDate, targetDate) =>
          next(dayjs(baseDate)).isSame(next(dayjs(targetDate)));
        break;
      }
    }

    // 모델로 다룰지, 실자산으로 다룰지
    let logs: RebalancingLog[];
    if (account) {
      logs =
        positioningLogs?.map((log) => {
          // {id: number, name: string, categoryId: number, ticker: string, value: number, ratio: number}
          const totalValue = sum(log.realAssets?.map((a) => a.evaluatedAmount));
          return {
            value: totalValue,
            date: log.createdAt,
            allocations:
              log.realAssets?.map((asset) => {
                return {
                  ticker: asset.ticker,
                  name: asset.name,
                  value: asset.evaluatedAmount,
                  ratio: asset.evaluatedAmount / totalValue,
                };
              }) ?? [],
          };
        }) ?? [];
    } else {
      logs =
        portfolio?.modelAllocation?.rebalancingHistory?.map((log) => {
          return {
            value: log.value,
            date: log.date,
            allocations: map(log.ratios, (ratio) => ratio),
          };
        }) ?? [];
    }

    // 최초 날짜로부터 실행
    const now = dayjs(nowDate);
    let targetDate = dayjs(logs?.[0]?.date).startOf('day');

    const schedules = [];
    while (targetDate.isBefore(now)) {
      const completedLogs =
        logs?.filter((log) => inbound(targetDate, log.date)) ?? [];

      let state: ScheduleState;
      if (completedLogs.length < 1) {
        const passed =
          portfolio?.modelAllocation?.passRebalancingDates?.some((date) =>
            inbound(targetDate, date),
          ) ?? false;

        if (passed) {
          state = 'pass';
        } else {
          const notifyStartAt = dayjs(targetDate).add(24 + 14, 'hours');
          const notifyEndAt = dayjs(notifyStartAt).add(14, 'day');
          if (targetDate.isBefore(now) && notifyEndAt.isAfter(now)) {
            if (notifyStartAt.isBefore(now)) {
              // 14일 이내 skip상태는 대기중으로 간주
              state = 'wait';
            } else {
              state = 'pending';
            }
          } else {
            state = 'skip';
          }
        }
      } else {
        state = 'completed';
      }

      schedules.push({
        date: targetDate.toDate(),
        logs: completedLogs,
        state,
      });
      targetDate = next(targetDate);
    }

    // 테스트용 강제
    // const t = last(schedules);
    // if (t) {
    //   t.state = 'wait'; // test
    // }

    return {
      next,
      inbound,
      schedules,
      lastSchedule: last(schedules),
      nextDate: dayjs(targetDate).toDate(),
    };
  }, [nowDate, portfolio, account, positioningLogs]);

  function diffPortfolio(
    currentList: RebalancingAssetSnapshot[],
    previousList?: RebalancingAssetSnapshot[],
  ) {
    const buyList = [];
    const sellList = [];
    if (currentList) {
      buyList.push(
        ...transform(
          currentList,
          (r, t) => {
            const previousAsset = previousList?.find(
              (a) => a.ticker === t.ticker,
            );
            if (t.ratio > (previousAsset?.ratio ?? 0)) {
              r.push({
                asset: t,
                value: t.ratio - (previousAsset?.ratio ?? 0),
              });
            }
          },
          [] as { asset: RebalancingAssetSnapshot; value: number }[],
        ),
      );
    }
    if (previousList) {
      sellList.push(
        ...transform(
          previousList,
          (r, t) => {
            const currentAsset = currentList.find((a) => a.ticker === t.ticker);
            if (t.ratio > (currentAsset?.ratio ?? 0)) {
              r.push({
                asset: t,
                value: t.ratio - (currentAsset?.ratio ?? 0),
              });
            }
          },
          [] as { asset: RebalancingAssetSnapshot; value: number }[],
        ),
      );
    }

    return {
      buyList: buyList.sort((a, b) => a.value - b.value).reverse(),
      sellList: sellList.sort((a, b) => a.value - b.value).reverse(),
    };
  }

  useEffect(() => {
    if (
      portfolio?.strategyId &&
      rebalancingData?.lastSchedule?.state === 'wait'
    ) {
      api.strategy
        .getStrategyCompanies(portfolio?.strategyId)
        .then((response) => {
          setWaitingRebalancingModelAssets(response.data);
        })
        .catch((error) => {
          console.log('fail', error);
        });
    }
  }, [rebalancingData?.lastSchedule?.state]);

  const waitingRebalancingCard = useMemo(() => {
    const lastSchedule = rebalancingData?.lastSchedule;

    if (
      !portfolio ||
      !portfolio.strategy ||
      !lastSchedule ||
      lastSchedule?.state !== 'wait' ||
      !waitingRebalancingModelAssets
    ) {
      return null;
    }

    const currentList =
      last(
        rebalancingData?.schedules.find(
          (s) => s.state === 'completed' && s.logs.length > 0,
        )?.logs,
      )?.allocations ?? [];
    const { buyList, sellList } = diffPortfolio(
      waitingRebalancingModelAssets,
      currentList,
    );

    const previousTop5Stocks = sortBy(currentList, (a) => a.ratio)
      .reverse()
      .slice(0, 5);
    const currentTop5Stocks = waitingRebalancingModelAssets.slice(0, 5);

    return (
      <Stack
        key={`${lastSchedule.date.toString()}-wait`}
        ref={waitRebalancingCardRef}
        borderRadius="8px"
        sx={{
          backgroundColor: 'background.grey',
        }}
        p="24px"
        width="340px"
        flex={1}
        direction="column"
        spacing="24px"
      >
        <Stack direction="row">
          <Typography variant="subtitle1" flex={1}>
            {isNowPositioning ? '리밸런싱 중' : '리밸런싱 대기중'}
          </Typography>
        </Stack>
        <Stack width="100%" direction="column" spacing={1}>
          <Typography variant="subtitle2">
            {t('text.rebalancing_before')}
          </Typography>
          <Stack
            width="100%"
            height="24px"
            direction="row"
            sx={{
              backgroundColor:
                stockPieTemplateColors[6 % stockPieTemplateColors.length],
            }}
            borderRadius="4px"
            overflow="hidden"
          >
            {previousTop5Stocks.map((stock, index) => {
              return (
                <Box
                  key={stock.ticker}
                  width={`${stock.ratio * 100}%`}
                  height="100%"
                  sx={{
                    backgroundColor:
                      stockPieTemplateColors[
                        index % stockPieTemplateColors.length
                      ],
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
        <Stack width="100%" direction="column" spacing={1}>
          <Typography variant="subtitle2">
            {t('text.rebalancing_after')}
          </Typography>
          <Stack
            width="100%"
            height="24px"
            direction="row"
            sx={{
              backgroundColor:
                stockPieTemplateColors[6 % stockPieTemplateColors.length],
            }}
            borderRadius="4px"
            overflow="hidden"
          >
            {currentTop5Stocks.map((stock, index) => {
              return (
                <Box
                  key={stock.id}
                  width={`${stock.ratio * 100}%`}
                  height="100%"
                  sx={{
                    backgroundColor:
                      stockPieTemplateColors[
                        index % stockPieTemplateColors.length
                      ],
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
        <DifferencePortfolioListView buyList={buyList} sellList={sellList} />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          underline="always"
          onClick={() =>
            setOpenRebalrancingDetail({
              buyList,
              sellList,
            })
          }
        >
          매수/매도 상세종목 보기
        </Link>
        <Stack width="100%" direction="row" spacing={2}>
          <StyledButton
            size="small"
            variant="contained"
            color={isNowPositioning ? 'error' : undefined}
            onClick={() => {
              if (portfolio && waitingRebalancingModelAssets?.length > 0) {
                if (account) {
                  setOpenPositioningBuilderModel(true);
                } else {
                  requestRebalancing(portfolio, waitingRebalancingModelAssets);
                }
              }
            }}
          >
            리밸런싱하기
          </StyledButton>
          <StyledButton
            size="small"
            variant="outlined"
            disabled={isNowPositioning ?? false}
            onClick={() => {
              if (portfolio) {
                passRebalancing(
                  portfolio,
                  dayjs(nowDate).startOf('day').toDate(),
                );
              }
            }}
          >
            하지않고 유지하기
          </StyledButton>
        </Stack>
      </Stack>
    );
  }, [
    nowDate,
    rebalancingData,
    waitingRebalancingModelAssets,
    isNowPositioning,
  ]);

  const rebalancingHistory = useMemo(() => {
    if (!rebalancingData) {
      return null;
    }
    const { schedules } = rebalancingData;

    let previousLog: RebalancingLog;
    const cards = schedules
      ?.filter(({ state }) => state !== 'wait') // wait는 별도로 처리
      .flatMap((schedule) => {
        if (['skip', 'pass'].includes(schedule.state)) {
          return [];
        }

        return schedule.logs?.map((log) => {
          const { buyList, sellList } = diffPortfolio(
            log.allocations,
            previousLog?.allocations,
          );

          const previousTopSampleStocks = Object.values(
            previousLog?.allocations ?? [],
          )
            .sort((a, b) => a.ratio - b.ratio)
            .reverse();
          const currentSampleStocks = Object.values(log?.allocations ?? [])
            .sort((a, b) => a.ratio - b.ratio)
            .reverse();

          const view = (
            <Stack
              key={`${log.date.toString()}`}
              borderRadius="8px"
              sx={{
                backgroundColor: 'background.grey',
              }}
              p="24px"
              width="340px"
              flex={1}
              direction="column"
              spacing="24px"
            >
              <Stack direction="row">
                <Typography variant="subtitle1" flex={1}>
                  {dayjs(log.date).format('YYYY-MM-DD')}
                </Typography>
                <Typography variant="body2">
                  {dayjs(log.date).format('HH:mm')}
                </Typography>
              </Stack>
              <Stack width="100%" direction="column" spacing={1}>
                <Typography variant="subtitle2">
                  {t('text.rebalancing_before')}
                </Typography>
                <Stack
                  width="100%"
                  height="24px"
                  direction="row"
                  sx={{
                    backgroundColor:
                      stockPieTemplateColors[6 % stockPieTemplateColors.length],
                  }}
                  borderRadius="4px"
                  overflow="hidden"
                >
                  {previousTopSampleStocks.map((stock, index) => {
                    return (
                      <Box
                        key={stock.ticker}
                        width={`${stock.ratio * 100}%`}
                        height="100%"
                        sx={{
                          backgroundColor:
                            stockPieTemplateColors[
                              index % stockPieTemplateColors.length
                            ],
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Stack width="100%" direction="column" spacing={1}>
                <Typography variant="subtitle2">
                  {t('text.rebalancing_after')}
                </Typography>
                <Stack
                  width="100%"
                  height="24px"
                  direction="row"
                  sx={{
                    backgroundColor:
                      stockPieTemplateColors[6 % stockPieTemplateColors.length],
                  }}
                  borderRadius="4px"
                  overflow="hidden"
                >
                  {currentSampleStocks.map((stock, index) => {
                    return (
                      <Box
                        key={stock.ticker}
                        width={`${stock.ratio * 100}%`}
                        height="100%"
                        sx={{
                          backgroundColor:
                            stockPieTemplateColors[
                              index % stockPieTemplateColors.length
                            ],
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <DifferencePortfolioListView
                buyList={buyList}
                sellList={sellList}
              />
              {account || previousLog ? (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <Link
                  underline="always"
                  onClick={() =>
                    setOpenRebalrancingDetail({
                      buyList,
                      sellList,
                    })
                  }
                >
                  매수/매도 상세종목 보기
                </Link>
              ) : null}
              {!account && previousLog ? (
                <Typography
                  variant="body2"
                  py="5px"
                  px="10px"
                  sx={{ backgroundColor: '#FFF', borderRadius: '4px' }}
                >
                  리밸런싱을 수락하여 포트폴리오에 적용되었습니다.
                </Typography>
              ) : null}
            </Stack>
          );

          previousLog = log;
          return view;
        });
      });

    return cards.reverse();
  }, [rebalancingData]);

  return (
    <Box
      display="flex"
      height="100%"
      width="100%"
      overflow="auto"
      justifyContent="center"
    >
      <Stack
        direction="column"
        p={2}
        width="100%"
        height="fit-content"
        maxWidth="1024px"
        spacing="32px"
      >
        <Stack width="100%" alignItems="start" height="80px">
          <Stack direction="row" width="100%">
            <Typography variant="h2" fontWeight="bold">
              {portfolio?.name}
            </Typography>
            <IconButton
              onClick={() => {
                setOpenNamingModalName(portfolio?.name ?? '');
              }}
            >
              <EditIcon />
            </IconButton>
            <Stack flex={1} direction="row" justifyContent="end">
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Typography variant="body2" color="gray">
            {t('text.portfolio_created')} :{' '}
            {portfolio?.createdAt
              ? dayjs(portfolio.createdAt).format('YYYY-MM-DD')
              : null}
          </Typography>
          <Stack direction="row" alignItems="center" width="100%">
            <Stack direction="row" flex={1}>
              <Typography variant="body2" color="gray" mr="4px">
                {`${t('directIndex.appliedStrategies')} :  `}
              </Typography>

              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                sx={{ ':hover': { cursor: 'pointer' } }}
                flex={1}
                onClick={() => {
                  if (portfolio) {
                    if (portfolio?.strategyId) {
                      setIsOpenStrategyDetailModal(true);
                    } else {
                      setIsOpenStrategyApplier(true);
                    }
                  }
                }}
              >
                <Typography variant="body2" color="gray">
                  {portfolio?.strategy?.name ?? '없음'}
                </Typography>
              </Link>
            </Stack>

            <Box display={['flex', 'none']}>
              <Button
                variant="outlined"
                onClick={() => {
                  if (portfolio) {
                    if (portfolio?.strategyId) {
                      setIsOpenStrategyDetailModal(true);
                    } else {
                      setIsOpenStrategyApplier(true);
                    }
                  }
                }}
              >
                <Typography variant="caption">
                  {t('text.viewAppliedStrategiesDetail')}
                </Typography>
              </Button>
            </Box>
          </Stack>
        </Stack>
        {waitingRebalancingCard ? (
          <Stack
            direction="row"
            borderRadius="8px"
            boxShadow="0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)"
            sx={{ background: '#019E6F' }}
            px="24px"
            py="16px"
          >
            <Stack direction="column" flex={1}>
              <Typography variant="subtitle1" color="white">
                포트폴리오 리밸런싱 제안 도착
              </Typography>
              <Typography color="white">
                제안 확인 후 리밸런싱을 진행하거나 현재 포트폴리오를 유지할 수
                있습니다.
              </Typography>
            </Stack>
            <StyledButton
              sx={{ color: 'white' }}
              onClick={() => waitRebalancingCardRef.current?.scrollIntoView?.()}
            >
              리밸런싱 확인하기
            </StyledButton>
          </Stack>
        ) : null}
        {linkAccountBox}

        {portfolio?.strategy ? (
          <>
            {priceChart ?? (
              <Stack
                position="relative"
                width="100%"
                height="fit-content"
                boxShadow="0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)"
                borderRadius="8px"
                p="24px"
              >
                <img
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                  }}
                  src={samplePortfolioChart}
                  alt="empty"
                />
                <Stack
                  position="absolute"
                  left={0}
                  right={0}
                  top={0}
                  bottom={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body2">
                    {t('text.update_profit_calculation')}
                  </Typography>
                </Stack>
                <Typography
                  position="absolute"
                  left="24px"
                  top="24px"
                  variant="headline2"
                >
                  {t('text.accumulatedReturn')}
                </Typography>
              </Stack>
            )}
            <Stack
              direction="column"
              spacing={2}
              p={2}
              boxShadow="0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)"
              borderRadius="8px"
            >
              <Stack
                px={1}
                direction={['column', 'row']}
                alignItems={['start', 'center']}
              >
                <Stack
                  flex={1}
                  width="100%"
                  direction="row"
                  display="flex"
                  alignItems="center"
                  spacing="8px"
                >
                  <Typography variant="headline2" flex={1}>
                    {t('text.portfolio_composition')}
                  </Typography>
                  {account && !isNowPositioning ? (
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  ) : null}
                </Stack>
                {account ? (
                  <>
                    {isNowPositioning ||
                    waitingRebalancingCard ||
                    (positioningLogs && positioningLogs.length < 1) ? (
                      <Button
                        variant="contained"
                        color={isNowPositioning ? 'error' : 'primary'}
                        onClick={() => setOpenPositioningBuilderModel(true)}
                      >
                        {isNowPositioning
                          ? '리밸런싱 마저 하기'
                          : '투자 기준에 맞게 리밸런싱'}
                      </Button>
                    ) : null}
                    <Menu
                      anchorEl={anchorEl}
                      open={!!anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={(theme) => ({
                        '& .MuiPaper-root': {
                          marginTop: theme.spacing(1),
                          minWidth: 180,
                          boxShadow:
                            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                          '& .MuiMenu-list': {
                            padding: '4px 0',
                          },
                          '& .MuiMenuItem-root': {
                            '& .MuiSvgIcon-root': {
                              fontSize: 18,
                              marginRight: theme.spacing(1.5),
                            },
                            '&:active': {
                              backgroundColor: alpha(
                                theme.palette.primary.main,
                                theme.palette.action.selectedOpacity,
                              ),
                            },
                          },
                        },
                      })}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          setAnchorEl(null);
                          setOpenPositioningBuilderModel(true);
                        }}
                      >
                        <SyncAltIcon />
                        리밸런싱하기
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        disabled={
                          (isNowPositioning ?? true) ||
                          (balance?.assets?.length ?? 0) < 1
                        }
                        onClick={() => {
                          setAnchorEl(null);
                          if (currency) {
                            api.portfolio
                              .createPositioning(portfolio.id, {
                                currency,
                                phase: 'sellPlanning',
                                modelAssets: [],
                              })
                              .then(() => {
                                setOpenPositioningBuilderModel(true);
                              });
                          }
                        }}
                        sx={{
                          color: (theme) => theme.palette.error.main,
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            color: (theme) => theme.palette.error.main,
                          }}
                        />
                        모두 매도하기
                      </MenuItem>
                    </Menu>
                  </>
                ) : null}
              </Stack>
              {isNowPositioning ? (
                <Stack
                  height="240px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      backgroundColor: 'background.grey',
                      borderRadius: '12px',
                    }}
                    p="12px"
                    variant="body1"
                    color="text.disabled"
                  >
                    리밸런싱을 완료해주세요.
                  </Typography>
                </Stack>
              ) : portfolioRatios?.length === 0 ? (
                <Stack
                  height="240px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body1" color="text.disabled">
                    보유중인 종목이 없습니다.
                  </Typography>
                </Stack>
              ) : (
                <Stack px={1} width="100%" direction={['column', 'row']}>
                  <Stack flex={1} direction="column" spacing={1}>
                    <Stack width="100%">
                      <Typography variant="subtitle2">
                        {t('text.asset_allocation')}
                      </Typography>
                      <Box width="100%" height="240px">
                        {portfolioRatioChart}
                      </Box>
                    </Stack>
                    {portfolioRatioList}
                  </Stack>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      mx: 1,
                    }}
                  />
                  <Stack
                    maxWidth={['100%', '40%']}
                    flex={1}
                    direction="column"
                    spacing={1}
                  >
                    <Stack width="100%">
                      <Typography variant="subtitle2">
                        {t('text.industry_weight')}
                      </Typography>
                      <Box width="100%" height="240px">
                        {categoryRatioChart}
                      </Box>
                    </Stack>
                    {categoryRatioList}
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              p={2}
              boxShadow="0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)"
              borderRadius="8px"
            >
              <Typography variant="headline2">
                {t('text.portfolio_rebalancing_history')}
              </Typography>
              <Typography variant="caption" whiteSpace="pre-line" mt="4px">
                {portfolio.strategy.rebalancingPeriod === 'QUARTERLY'
                  ? t('text.quarterly_rebalancing_schedule')
                  : portfolio.strategy.rebalancingPeriod === 'SEMIANNUALLY'
                  ? t('text.semiannually_rebalancing_schedule')
                  : t('text.annually_rebalancing_schedule')}
              </Typography>
              <Box width="100%" overflow="auto">
                <Stack width="fit-content" direction="row" spacing={2}>
                  {waitingRebalancingCard}
                  {rebalancingHistory}
                </Stack>
              </Box>
            </Stack>
          </>
        ) : null}
      </Stack>
      {changeNameModal}
      {strategyApplierModal}
      {buyingGuideModal}
      {strategyDetailModal}
      {rebalancingDetailModal}
      {positioningModal}
      {accountModal}
    </Box>
  );
}
