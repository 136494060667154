import { FactorControlSpec, FactorDefaultRange } from '~/api/strategy';
import { FactorControlValue, FactorHistogramDefaultRange } from '../types';

export const getReletiveInitialRange = (
  factorHistogram: FactorControlSpec,
  defaultRange: FactorHistogramDefaultRange,
): [FactorControlValue, FactorControlValue] => {
  const totalCount = factorHistogram.freqs.reduce((a, b) => a + b, 0);
  let count = 0;
  let i = 0;
  let start = 0;

  switch (defaultRange) {
    case 'BOTTOM20':
      return [
        { freqIndex: 0, value: 0, isAbsolute: false },
        { freqIndex: 0, value: 20, isAbsolute: false },
      ];
    case 'BOTTOM20POSITIVE':
      start = factorHistogram.bins.findIndex((e) => e >= 0);
      i = start;
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }
      return [
        {
          freqIndex: start + 1,
          value: factorHistogram.bins[start],
          isAbsolute: true,
        },
        { freqIndex: i + 1, value: factorHistogram.bins[i], isAbsolute: true },
      ];

    case 'MIDDLE20':
      return [
        { freqIndex: 0, value: 40, isAbsolute: false },
        { freqIndex: 0, value: 60, isAbsolute: false },
      ];

    case 'TOP20':
      return [
        { freqIndex: 0, value: 80, isAbsolute: false },
        { freqIndex: 0, value: 100, isAbsolute: false },
      ];

    case 'ALL':
    default:
      return [
        { freqIndex: 0, value: 0, isAbsolute: false },
        { freqIndex: 0, value: 100, isAbsolute: false },
      ];
  }
};

export const getAbsoluteInitialRange = (
  factorHistogram: FactorControlSpec,
  defaultRange: FactorHistogramDefaultRange,
): [FactorControlValue, FactorControlValue] => {
  const totalCount = factorHistogram.freqs.reduce((a, b) => a + b, 0);
  let count = 0;
  let i = 0;
  let start = 0;
  const end = factorHistogram.bins.length;

  switch (defaultRange) {
    case 'BOTTOM20':
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }

      return [
        { freqIndex: 0, value: 'min', isAbsolute: true },
        { freqIndex: i + 1, value: factorHistogram.bins[i], isAbsolute: true },
      ];
    case 'BOTTOM20POSITIVE':
      start = factorHistogram.bins.findIndex((e: number) => e >= 0);
      i = start;
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }
      return [
        {
          freqIndex: start + 1,
          value: factorHistogram.bins[start],
          isAbsolute: true,
        },
        { freqIndex: i + 1, value: factorHistogram.bins[i], isAbsolute: true },
      ];

    case 'MIDDLE20':
      while (count <= (totalCount * 2) / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }
      start = i;

      while (count <= (totalCount * 3) / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }

      return [
        {
          freqIndex: start + 1,
          value: factorHistogram.bins[start],
          isAbsolute: true,
        },
        {
          freqIndex: i + 1,
          value: factorHistogram.bins[i],
          isAbsolute: true,
        },
      ];

    case 'TOP20':
      i = end - 2;
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i -= 1;
      }

      return [
        {
          freqIndex: i + 2,
          value: factorHistogram.bins[i + 1],
          isAbsolute: true,
        },
        { freqIndex: end, value: 'max', isAbsolute: true },
      ];

    case 'ALL':
    default:
      return [
        { freqIndex: 0, value: 'min', isAbsolute: true },
        { freqIndex: end, value: 'max', isAbsolute: true },
      ];
  }
};

export const getDefaultFreqIndex = (
  factorHistogram: FactorControlSpec,
): Map<FactorDefaultRange, [number, number]> => {
  const result: Map<FactorDefaultRange, [number, number]> = new Map();

  let count = 0;
  let i = 0;

  const totalCount = factorHistogram.freqs.reduce((a, b) => a + b, 0);
  let start = 0;
  const end = factorHistogram.bins.length;

  while (count <= totalCount / 5) {
    count += factorHistogram.freqs[i];
    i += 1;
  }

  result.set('BOTTOM20', [0, i + 1]);

  count = 0;
  i = 0;
  start = factorHistogram.bins.findIndex((e: number) => e > 0);
  i = start;
  while (count <= totalCount / 5) {
    count += factorHistogram.freqs[i];
    i += 1;
  }
  result.set('BOTTOM20POSITIVE', [start + 1, i + 1]);

  count = 0;
  i = 0;
  while (count <= (totalCount * 2) / 5) {
    count += factorHistogram.freqs[i];
    i += 1;
  }
  start = i;

  while (count <= (totalCount * 3) / 5) {
    count += factorHistogram.freqs[i];
    i += 1;
  }

  result.set('MIDDLE20', [start + 1, i + 1]);

  count = 0;
  i = 0;

  i = end - 2;
  while (count <= totalCount / 5) {
    count += factorHistogram.freqs[i];
    i -= 1;
  }

  result.set('TOP20', [i + 2, end]);
  result.set('ALL', [0, end]);

  return result;
};
