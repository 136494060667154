import { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { chunk, flatten, isNumber, transform } from 'lodash';
import bluebird from 'bluebird';

import dayjs from '~/utils/dayjs';
import { Portfolio, ModelAsset, SimulationRecipe } from '~/api/portfolio';
import { CompanyPricesResult, Strategy } from '~/api/strategy';
import api, { CurrencyRates } from '~/api';
import AssetAllocationSimulator, {
  Currency,
  findCurrencyLabel,
} from '~/components/portfolio/AssetAllocationSimulator';
import StyledButton from '~/components/StyledButton';

export type PortfolioStrategyApplierProps = {
  portfolioId: string;
  onCompleted: (
    strategy: Strategy,
    modelAsset: ModelAsset[],
    recipe: SimulationRecipe,
  ) => void;
} & StackProps;

const PortfolioStrategyApplier = forwardRef(
  (
    { portfolioId, onCompleted, ...stackProps }: PortfolioStrategyApplierProps,
    ref,
  ) => {
    const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);
    const [phase, setPhase] = useState<'input' | 'simulation'>('input');
    const [recipe, setRecipe] = useState<SimulationRecipe | null>(null);
    const [portfolio, setPortfolio] = useState<Portfolio | null>(null);

    const [strategies, setStrategies] = useState<Strategy[]>([]);
    const [selectedStrategyIndex, setSelectedStrategyIndex] = useState<
      number | ''
    >('');
    const [assets, setAssets] = useState<ModelAsset[]>([]);

    const [priceHistory, setPriceHistory] = useState<{
      [c: number]: CompanyPricesResult;
    }>({});
    const [assetSize, setAssetSize] = useState<number>(10000);

    const [currency, setCurrency] = useState<Currency>('USD');
    const [currencyRates, setCurrencyRates] = useState<CurrencyRates | null>(
      null,
    );

    useEffect(() => {
      // api.currencyRatesBaseUSD().then(
      //   (response) => {
      //     setCurrencyRates(response.data.rates);
      //   },
      //   (error) => {
      //     console.log('fail', error);
      //   },
      // );
      Promise.all([
        api.portfolio.portfolio(portfolioId),
        api.strategy.strategies(),
      ]).then(
        ([portfolioResponse, strategiesResponse]) => {
          setPortfolio(portfolioResponse.data);
          setStrategies(strategiesResponse.data);
        },
        (error) => {
          console.log('fail', error);
        },
      );
    }, []);

    useEffect(() => {
      if (selectedStrategyIndex === '') {
        setAssets((state) => {
          if (state.length > 0) {
            return [];
          }
          return state;
        });
        return;
      }
      setIsLoadingPrices(true);
      const selectedStrategy = strategies[selectedStrategyIndex];
      api.strategy
        .getStrategyCompanies(selectedStrategy.id)
        .then(
          async (response) => {
            setAssets(response.data);

            const codes = response.data
              .map((asset) => asset.id)
              .filter((code) => !(code in priceHistory));

            const priceResponses = flatten(
              await bluebird.mapSeries(
                chunk(codes, 10),
                async (chuckedCodes) => {
                  return bluebird.map(
                    chuckedCodes,
                    (code) => {
                      return api.strategy
                        .getCompanyPriceHistory(
                          code,
                          dayjs().subtract(7, 'day').toDate(),
                          new Date(),
                        )
                        .catch((err) => {
                          console.log('fail price', err);
                          return null;
                        });
                    },
                    {
                      concurrency: 10,
                    },
                  );
                },
              ),
            );

            setCurrency(
              ((response.data?.[0].id
                ? priceResponses?.[0]?.data?.currency
                : null) ?? 'USD') as typeof currency,
            );
            setPriceHistory((state) => {
              return transform(
                codes,
                (r, code, i) => {
                  if (priceResponses?.[i]?.data) {
                    r[code] = priceResponses?.[i]?.data as CompanyPricesResult;
                  }
                },
                {
                  ...state,
                },
              );
            });
          },
          (error) => {
            console.log('fail', error);
          },
        )
        .finally(() => {
          setIsLoadingPrices(false);
        });
    }, [selectedStrategyIndex]);

    const currencyLabel = useMemo(() => {
      return findCurrencyLabel(currency);
    }, [currency]);

    return (
      <Stack
        width="100%"
        height="100%"
        direction="column"
        p={2}
        {...stackProps}
        ref={ref}
        spacing={2}
      >
        <Stack direction="row" width="100%" height="60px" alignItems="center">
          <Box flex={1}>
            <Typography>전략 적용하기</Typography>
          </Box>
          {phase === 'simulation' ? (
            <StyledButton onClick={() => setPhase('input')}>
              다시 설정하기
            </StyledButton>
          ) : null}
        </Stack>
        {phase === 'input' ? (
          <Stack spacing={2}>
            {strategies.length > 0 ? (
              <FormControl variant="outlined">
                <InputLabel id="target-strategy-label">
                  적용 가능한 전략
                </InputLabel>
                <Select
                  labelId="target-strategy-label"
                  label="적용 가능한 전략"
                  displayEmpty
                  value={selectedStrategyIndex}
                  onChange={(e) =>
                    setSelectedStrategyIndex(
                      isNumber(e.target.value) ? Number(e.target.value) : '',
                    )
                  }
                >
                  {strategies.map((strategy, index) => {
                    return (
                      <MenuItem key={strategy.id} value={index}>
                        {strategy.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <Typography>적용할 수 있는 전략이 없습니다.</Typography>
            )}
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">금액</InputLabel>
              <OutlinedInput
                type="number"
                value={assetSize}
                onChange={(e) => {
                  setAssetSize(Number(e.target.value));
                }}
                startAdornment={
                  <InputAdornment position="start">
                    {currencyLabel}
                  </InputAdornment>
                }
                inputProps={{
                  min: 100,
                  max: 10000000,
                  step: 100,
                }}
                label="금액"
              />
            </FormControl>
            <StyledButton
              variant="contained"
              disabled={!portfolio || selectedStrategyIndex < 0}
              onClick={() => setPhase('simulation')}
            >
              포트폴리오 미리보기
            </StyledButton>
          </Stack>
        ) : (
          <>
            <AssetAllocationSimulator
              overflow="auto"
              assets={assets}
              assetSize={assetSize}
              priceHistory={priceHistory}
              currency={currency}
              onUpdate={(recipe) => setRecipe(recipe)}
            />
            <LoadingButton
              variant="contained"
              sx={{ width: 'fit-content', alignSelf: 'end' }}
              loading={isLoadingPrices}
              disabled={!isNumber(selectedStrategyIndex) || !recipe}
              onClick={() => {
                if (selectedStrategyIndex !== '' && recipe) {
                  onCompleted?.(
                    strategies[selectedStrategyIndex],
                    assets,
                    recipe,
                  );
                }
              }}
            >
              포트폴리오 생성하고 자동 리밸런싱 받기
            </LoadingButton>
          </>
        )}
      </Stack>
    );
  },
);

PortfolioStrategyApplier.displayName = 'PortfolioStrategyApplier';

export default PortfolioStrategyApplier;
