import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Spacer, VStack } from '@chakra-ui/react';
import { CompanyMarketPricesResponse } from '~/api/screener';
import PriceChart from '~/components/company_detail/PriceChart';
// import { HStack, Spacer, VStack } from '@chakra-ui/react';
// import PriceChart from '~/components/PriceChart';
// import LoadingComponent from '~/components/LoadingComponent';
// import useFetch from '~/hooks/useFetch';
// import { CompanyMarketPricesResponse } from '~/models/companyDetail';
// import { ResponseBody } from '~/models/responseBody';
// import { Repository } from '~/repository';
import { getFormatedDate } from '~/utils/datetime';
// import { getThemeData } from '~/utils/theme';
import api from '~/api';
import { Body2, H3 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';

type Props = {
  cosmosCode: number;
  startDate: Date;
  endDate: Date;
};

function PriceHistoryChart({ cosmosCode, startDate, endDate }: Props) {
  const [t] = useTranslation();

  const themeData = getThemeData();
  const [companyMarketPricesResponse, setCompanyMarketPricesResponse] =
    useState<CompanyMarketPricesResponse>();

  useEffect(() => {
    api.screener.getCompanyPrice(cosmosCode).then((v) => {
      setCompanyMarketPricesResponse(v.data);
    });
  }, [cosmosCode]);

  if (!companyMarketPricesResponse) return <div />;

  const { priceList, currency } = companyMarketPricesResponse;
  const chartValues = priceList.filter(
    (v) => v.open != null && v.close != null,
  );
  if (chartValues.length === 0) return <div />;

  return (
    <VStack align="start">
      <HStack w="100%">
        <H3 bold noOfLines={1}>
          {t('companyDetail.priceHistory')}
        </H3>

        <Spacer />
        <Body2 color={themeData.colors.gray[300]}>{currency}</Body2>
      </HStack>
      <PriceChart
        values={chartValues}
        // currency={currency}
      />
    </VStack>
  );
}

export default PriceHistoryChart;
