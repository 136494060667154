import { useContext } from 'react';
import { Button, Center, CircularProgress, Image } from '@chakra-ui/react';
import { ArrowRightOutlined } from '@mui/icons-material';
import { Body2 } from './Typography';
import { getThemeData } from '~/utils/theme';

type CustomButtonProps = {
  text: string | undefined;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: 'solid' | 'outline' | 'ghost' | 'link';
};

export function BorderButton({
  text,
  onClick,
  color,
  variant,
  disabled,
  isLoading,
}: CustomButtonProps) {
  return (
    <Button
      variant={variant}
      size="sm"
      isLoading={isLoading}
      disabled={disabled}
      borderColor={color}
      background="white"
      color={color}
      onClick={onClick}
      borderWidth="1px"
      borderRadius="100px"
      p="4px 8px"
    >
      {text}
    </Button>
  );
}

BorderButton.defaultProps = {
  variant: 'outline',
  disabled: false,
  isLoading: false,
  color: 'primary',
};

export function ExecButton({
  text,
  onClick,
  variant,
  disabled,
  isLoading,
  color,
}: CustomButtonProps) {
  const themeData = getThemeData();
  return (
    <Button
      p={['4px 0px', '8px']}
      variant={variant}
      _hover={{
        background: disabled
          ? themeData.colors.gray[100]
          : themeData.colors.primary[400],
      }}
      colorScheme={disabled ? 'blackAlpha' : 'primary'}
      bg={disabled ? themeData.colors.gray[200] : themeData.colors.primary[500]}
      w={['100%', null]}
      h="100%"
      size="xl"
      color={color}
      borderRadius="100px"
      onClick={disabled ? () => null : onClick}
    >
      {isLoading ? (
        <CircularProgress isIndeterminate color="primary.500" />
      ) : (
        <>
          <Center flexDirection="row" display="flex" margin="0px 16px">
            <Body2
              whiteSpace="break-spaces"
              bold
              color={disabled ? themeData.colors.gray[300] : 'white'}
            >
              {text}
            </Body2>
          </Center>
          <Center
            display={['none', 'inherit']}
            borderRadius="100px"
            w="40px"
            h="40px"
            bg={
              disabled
                ? themeData.colors.gray[300]
                : themeData.colors.primary[700]
            }
          >
            <ArrowRightOutlined sx={{ color: 'white' }} />
          </Center>
        </>
      )}
    </Button>
  );
}
ExecButton.defaultProps = {
  variant: 'solid',
  disabled: false,
  isLoading: false,
  color: 'primary',
};
