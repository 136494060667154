import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { chunk, groupBy, map, mapValues, nth, uniqBy } from 'lodash';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAmplitude } from 'react-amplitude-hooks';

import { Category } from '~/api/strategy';

type CategoryListListProps = {
  topCategoryList: Category[];
  categoryList: Category[];
  excludedValues: number[];
  onExclude?: (id: number | number[], excluded: boolean) => void;
  onClickAllSelect: (isChecked: boolean) => void;
} & Omit<StackProps, 'onChange'>;

function CategoryList({
  topCategoryList,
  categoryList,
  excludedValues,
  onExclude,
  onClickAllSelect,
  ...stackProps
}: CategoryListListProps) {
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();

  const groupedCategory = useMemo(() => {
    return mapValues(
      groupBy(categoryList, (category) => {
        return nth(category.cosmosGroupId.toString().match(/.{1,2}/g), 0) ?? 0;
      }),
      (categories) => uniqBy(categories, (c) => c.cosmosGroupId),
    );
  }, [categoryList]);

  return (
    <Stack {...stackProps} direction="column" spacing={1}>
      <Box flex={1} width="100%" overflow="auto">
        <Stack direction="row" alignItems="center" mb="8px">
          <Checkbox
            size="small"
            color="secondary"
            sx={{
              width: '20px',
              marginRight: '4px',
            }}
            checked={excludedValues.length === 0}
            onChange={(e) => {
              onClickAllSelect?.(e.target.checked);
            }}
          />
          <Typography variant="subtitle2">{`${t('text.checkAll')}(${
            categoryList.length
          })`}</Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            '> div:not(:last-of-type)': {
              borderBottom: '1px solid #ccc',
            },
          }}
        >
          {map(groupedCategory, (categories, id) => {
            return (
              <Stack key={`group-${id}`} direction="column">
                <Stack direction="row" alignItems="center" spacing="6px">
                  <Checkbox
                    size="small"
                    color="secondary"
                    sx={{
                      width: '20px',
                    }}
                    checked={
                      !excludedValues?.some((v) =>
                        v.toString().startsWith(id),
                      ) ?? true
                    }
                    onChange={(e) => {
                      onExclude?.(
                        categories.map((c) => c.cosmosGroupId),
                        !e.target.checked,
                      );
                    }}
                  />
                  <Typography variant="subtitle2">
                    {t(
                      `category.${
                        topCategoryList.find(
                          (t) => t.cosmosGroupId === Number(id),
                        )?.name ?? 'unknown'
                      }`,
                    )}
                    {categories.length > 1 ? ` (${categories.length})` : null}
                  </Typography>
                </Stack>
                {categories.length > 1 ? (
                  <Grid container>
                    {categories.map((category) => {
                      return (
                        <Grid
                          key={`category-${category.cosmosGroupId}`}
                          item
                          xs={4}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing="6px"
                          >
                            <Checkbox
                              size="small"
                              color="secondary"
                              sx={{
                                width: '20px',
                              }}
                              checked={
                                !excludedValues?.some(
                                  (v) => v === category.cosmosGroupId,
                                ) ?? true
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  logEvent('sector checkbox clicked', {
                                    'sector title': category.name,
                                  });
                                }
                                onExclude?.(
                                  category.cosmosGroupId,
                                  !e.target.checked,
                                );
                              }}
                            />
                            <Typography variant="body2">
                              {t(`category.${category.name}`)}
                            </Typography>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : null}
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
}

CategoryList.defaultProps = {
  onExclude: null,
};

export default CategoryList;
