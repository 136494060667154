import { ReactElement, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { AppContextProvider } from '~/AppContext';
import Layout from '~/views/layout';
import Landing from '~/views/landing';
import PortfolioListView from '~/views/PortfolioListView';
import StrategyListView from '~/views/StrategyListView';
import StrategyCreationView from '~/views/StrategyCreationView';
import PortfolioView from '~/views/PortfolioView';
import { initI18n } from '~/i18n';
import StrategySelectionView from '~/views/StrategySelectionView';
import AmplitudeWrapper from '~/components/AmplitudeWrapper';
import ServiceTermsView from '~/views/ServiceTermsView';
import CompanyDetailView from '~/views/CompanyDetailView';
import ScreenerView from '~/views/ScreenerView';
import BacktestView from '~/views/BacktestView';
import { FactorContextProviderWrapper } from '~/containers/factor/context/FactorContextWrapper';
import ScreenerResultView from '~/views/ScreenerResultView';
import BacktestResultView from '~/views/BacktestResultView';
import { useTheme } from '~/hooks/useTheme';
import { light } from '~/utils/theme';
import MigrationSuccessView from '~/views/MigrationSuccessView';
import MigrationFailView from '~/views/MigrationFailView';

type WrapperProps = {
  children: ReactElement;
};

function ThemeProviderWrapper({ children }: WrapperProps) {
  // const breakpoints = ['0em', '60em', '48em'];
  const { theme, getThemeData } = useTheme();
  const chakraTheme = extendTheme({
    // breakpoints,
    colors: light,
    fonts: {
      body: 'Pretendard',
      heading: 'Pretendard',
      mono: 'Pretendard',
    },
  });

  return useMemo(
    () => <ChakraProvider theme={chakraTheme}>{children}</ChakraProvider>,
    [theme],
  );
}

function App() {
  initI18n();
  return (
    <ThemeProviderWrapper>
      <Router>
        <AppContextProvider>
          <FactorContextProviderWrapper>
            <AmplitudeWrapper>
              <Switch>
                <Route path="/service-terms">
                  <ServiceTermsView />
                </Route>
                <Route path="/migration-success">
                  <MigrationSuccessView />
                </Route>
                <Route path="/migration-fail">
                  <MigrationFailView />
                </Route>
                <Layout>
                  <Switch>
                    <Route path="/portfolio/:uid">
                      <PortfolioView />
                    </Route>
                    <Route path="/portfolio">
                      <PortfolioListView />
                    </Route>
                    <Route path="/strategy">
                      <StrategyListView />
                    </Route>
                    <Route path="/select-strategy">
                      <StrategySelectionView />
                    </Route>
                    <Route path="/create-strategy">
                      <StrategyCreationView />
                    </Route>
                    <Route exact path="/">
                      <Landing />
                    </Route>
                    <Route path="/company-detail/:cosmosCode">
                      <CompanyDetailView />
                    </Route>

                    <Route path="/screener">
                      <ScreenerView />
                    </Route>
                    <Route path="/backtest">
                      <BacktestView />
                    </Route>
                    <Route path="/screener-result">
                      <ScreenerResultView />
                    </Route>
                    <Route path="/backtest-result">
                      <BacktestResultView />
                    </Route>
                  </Switch>
                </Layout>
              </Switch>
            </AmplitudeWrapper>
          </FactorContextProviderWrapper>
        </AppContextProvider>
      </Router>
    </ThemeProviderWrapper>
  );
}

export default App;
