import { useContext } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Spacer } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';

import { Factor, FactorCategory } from '~/api/screener';
import FactorAccordion from '~/components/factor/FactorAccordion';
import FactorListItem from '~/components/factor/FactorListItem';
import { FactorControlValue } from '~/components/factor/types';
import { FactorDispatchContext, FactorStateContext } from './context';
import { Caption, H3 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';

type FactorListProps = {
  factors: Array<Factor>;
  selectedFactors: Map<Factor, [FactorControlValue, FactorControlValue]>;
  onChange: (isSelected: boolean, factor: Factor) => void;
};
function FactorList({ factors, selectedFactors, onChange }: FactorListProps) {
  return (
    <>
      {factors.map((factor) => (
        <FactorListItem
          key={factor.id}
          factor={factor}
          isSelected={
            Array.from(selectedFactors.keys()).filter((v) => v.id === factor.id)
              .length > 0
          }
          onChange={(isSelected: boolean) => {
            onChange(isSelected, factor);
          }}
        />
      ))}
    </>
  );
}

function FactorListContainer() {
  const factorDispatch = useContext(FactorDispatchContext);
  const { logEvent } = useAmplitude();
  const [t] = useTranslation();
  const themeData = getThemeData();

  return (
    <Box className="factor-list-container" w="100%">
      <Flex padding="24px 24px">
        <H3 bold>{t('text.factorSettings')}</H3>
        <Spacer />
        <Button
          variant="link"
          onClick={() => {
            if (factorDispatch) {
              factorDispatch({ type: 'CLEAR_FACTORS' });
            }
          }}
        >
          <Caption color={themeData.colors.primary[800]}>
            {t('text.factorSettingsReset')}
          </Caption>
        </Button>
      </Flex>
      <FactorStateContext.Consumer>
        {(factorContext) => {
          return (
            <Box width="100%" pb="72px">
              {factorContext &&
                (factorContext?.factorCategoryList ?? [])?.map(
                  (factorCategory) => {
                    const factors =
                      (factorContext?.factorList ?? [])?.filter(
                        (factor) => factor.categoryId === factorCategory.id,
                      ) ?? [];
                    return (
                      <FactorAccordion
                        key={`factor_category_${factorCategory.id}`}
                        title={factorCategory.name}
                        length={factors.length}
                        hasNew={factors.some((f) => f.isNew)}
                      >
                        <FactorList
                          onChange={(isSelected, factor) => {
                            if (factorDispatch != null) {
                              if (isSelected) {
                                logEvent('factor selected', {
                                  'factor category': factor.categoryName,
                                });
                                factorDispatch({
                                  type: 'ADD_FACTOR',
                                  factor,
                                  range: [
                                    {
                                      freqIndex: -1,
                                      value: 'min',
                                      isAbsolute: false,
                                    },
                                    {
                                      freqIndex: -1,
                                      value: 'max',
                                      isAbsolute: false,
                                    }, // TODO : MAX FREQ INDEX?
                                  ],
                                });
                              } else {
                                factorDispatch({
                                  type: 'REMOVE_FACTOR',
                                  factor,
                                });
                              }
                            }
                          }}
                          selectedFactors={factorContext.selectedFactors}
                          factors={factors}
                        />
                      </FactorAccordion>
                    );
                  },
                )}
            </Box>
          );
        }}
      </FactorStateContext.Consumer>
    </Box>
  );
}

export default FactorListContainer;
