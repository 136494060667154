import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay === null || delay <= 0) {
      return;
    }
    const id = setInterval(() => {
      savedCallback.current?.();
    }, delay);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
