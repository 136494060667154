import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  BoxProps,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { uniq } from 'lodash';

import { BackTestStateContext } from './context';
import { ExecButton } from '~/components/CustomButton';
import useFetch from '~/hooks/useFetch';
import { getThemeData } from '~/utils/theme';
import { H5 } from '~/components/Typography';
import { getExecConditions } from '~/utils/factor';
import { getFormatedDate, getYYYYMMDD } from '~/utils/datetime';
import { FactorStateContext } from '../factor/context';
import api from '~/api';
import { AppContext, MAX_QUERY_FACTOR_COUNT } from '~/AppContext';
import { getIsMobile } from '~/utils/mediaQuery';
import ToastLimitComponent from '~/components/ToastLimitComponent';
import StyledButton from '~/components/StyledButton';
import { PostBacktestingBody, SortData } from '~/api/screener';

function BacktestingExecButton({ sx, ...props }: Omit<BoxProps, 'children'>) {
  const history = useHistory();
  const { logEvent } = useAmplitude();
  const isMobile = getIsMobile();
  const placement = isMobile ? 'top' : 'bottom';

  const { state } = useContext(AppContext) ?? {};
  const factorState = useContext(FactorStateContext);

  const backtestState = useContext(BackTestStateContext);
  const [t] = useTranslation();
  const themeData = getThemeData();
  const [isLoading, setIsLoading] = useState(false);
  const selectGroups = Array.from(factorState?.companyGroupList.entries() ?? [])
    .map(([key, value]) => {
      if (value.length === 0) {
        return [];
      }
      if (value.length === 1) {
        return [key];
      }
      return value;
    })
    .flatMap((v) => v)
    .filter((v) => !factorState?.unselectedGroups.has(v.cosmosGroupId));

  const [screenerCount, setScreenerCount] = useState<number>(-1);

  useEffect(() => {
    if (state?.user) {
      const conditions = factorState?.selectedFactors
        ? getExecConditions(
            factorState!.selectedFactors,
            factorState!.sortData[0],
          )
        : [];

      api.screener
        .getScreeningCount({
          nationCode: factorState!.selectedNation.code,
          query: conditions,
          categoryIds: selectGroups.map((v) => v.cosmosGroupId),
        })
        .then((v) => {
          setScreenerCount(v.data.totalCount);
        });
    }
  }, [
    factorState?.selectedFactors,
    factorState?.selectedNation,
    factorState?.sortData,
    factorState?.unselectedGroups,
    state?.user,
  ]);

  const disabled =
    (factorState?.selectedFactors.size ?? 0) > MAX_QUERY_FACTOR_COUNT ||
    factorState?.selectedFactors.size === 0 ||
    screenerCount === 0 ||
    selectGroups.length === 0;

  const popOverState =
    (factorState?.selectedFactors.size ?? 0) > MAX_QUERY_FACTOR_COUNT
      ? 'disabled'
      : screenerCount === 0 || selectGroups.length === 0
      ? 'disabled'
      : screenerCount <= 10
      ? 'warning'
      : 'enable';

  const backtestingRequestBody = useMemo<PostBacktestingBody | null>(() => {
    if (!factorState || !backtestState) {
      return null;
    }
    const conditions = factorState?.selectedFactors
      ? getExecConditions(factorState.selectedFactors, factorState!.sortData[0])
      : [];

    const selectedCategoryIds = uniq(selectGroups.map((v) => v.cosmosGroupId));

    return {
      categoryIds:
        (factorState?.unselectedGroups.size ?? 0) > 0
          ? selectedCategoryIds
          : undefined,
      count: backtestState?.top,
      factorQuery: conditions,
      nationCode: factorState.selectedNation.code,
      orders:
        typeof factorState.sortData[0].data === 'string'
          ? []
          : (factorState.sortData.map((v) => v.data) as Array<SortData>),
      tradeCost: backtestState?.tradeCost,
      rebalancingPeriod: backtestState?.rebalancingPeriod,
      startDate: getFormatedDate({ date: backtestState.startDate }),
      endDate: getFormatedDate({ date: backtestState.endDate }),
      portWeightPolicy: 'EQUAL',
    };
  }, [selectGroups, factorState, backtestState]);

  return (
    // @ts-ignore
    <Popover isOpen placement={placement}>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <Box sx={{ height: '100%', ...sx }} {...props}>
          <StyledButton
            sx={{ height: '100%', width: '100%', borderRadius: '8px' }}
            variant="contained"
            disabled={disabled}
            onClick={() => {
              if (!backtestingRequestBody) return;
              setIsLoading(true);

              api.screener
                .postBacktesting(backtestingRequestBody)
                .then((v) => {
                  // logEvent('backtesting execute', {
                  //   'selected country to invest':
                  //     factorState.selectedNation.name,
                  //   'backtesting start date': getYYYYMMDD({
                  //     date: backtestState.startDate,
                  //   }),
                  //   'backtesting end date': getYYYYMMDD({
                  //     date: backtestState.endDate,
                  //   }),
                  //   'backtesting num total stocks': backtestState.top,
                  //   'backtesting transaction cost': backtestState.tradeCost,
                  //   'backtesting rebalancing period':
                  //     backtestState.rebalancingPeriod,
                  // });

                  history.push(`backtest-result`, {
                    request: backtestingRequestBody,
                    result: v.data,
                    nationCode: backtestingRequestBody.nationCode,
                    orders: backtestingRequestBody.orders,
                    conditions: backtestingRequestBody.factorQuery,
                    query: window.location.search?.slice(1),
                  });
                })
                .catch((e: AxiosError) => {
                  setIsLoading(false);
                  state?.showToast(
                    'error',
                    e.response?.status === 429 ? (
                      <ToastLimitComponent />
                    ) : (
                      <H5 noOfLines={10} whiteSpace="pre-wrap">
                        {`예상하지 못한 오류가 발생했습니다(${e.response?.status})\n해당 현상이 반복된다면 문의 바랍니다`}
                      </H5>
                    ),

                    {
                      position: 'bottom-left',
                      toastId: 'backtest-exec-error-toast',
                    },
                  );
                });
            }}
          >
            {t('text.backtestButton')}
          </StyledButton>
        </Box>
      </PopoverTrigger>
      {screenerCount < 0 || popOverState === 'enable' ? null : (
        <PopoverContent
          style={{ animation: 'smoothAppear .5s', boxShadow: 'none' }}
          bg={
            popOverState === 'disabled'
              ? themeData.colors.error
              : themeData.colors.atomicTangerine
          }
          padding="8px"
          borderRadius="8px"
          w="fit-content"
        >
          <PopoverArrow
            bg={
              popOverState === 'disabled'
                ? themeData.colors.error
                : themeData.colors.atomicTangerine
            }
          />
          <PopoverBody w="fit-content" maxW="300px">
            <H5 whiteSpace="normal" noOfLines={3} bold color="white">
              {popOverState === 'disabled'
                ? (factorState?.selectedFactors.size ?? 0) >
                  MAX_QUERY_FACTOR_COUNT
                  ? '팩터 조건이 30개를 넘을 수 없습니다'
                  : selectGroups.length === 0
                  ? t('text.sectorErrorPopover')
                  : t('text.zeroBacktestingSelected')
                : t('text.tenLessBacktestingSelected')}
            </H5>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
}

export default BacktestingExecButton;
