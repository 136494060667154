import { Flex } from '@chakra-ui/react';
import {
  Box,
  Button,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getThemeData } from '~/utils/theme';
import { ClearIcon } from '../Icons';
import NumberEditableInput from './NumberEditableInput';

type FocusType = 'left' | 'right';

type FactorControlDialogProps = {
  name: string;
  valueUnit: string;
  initialValue: number;
  focus: FocusType | undefined;
  onSubmit: (v: number | 'min' | 'max') => void;

  onClose: () => void;
};
export default function FactorControlDialog({
  name,
  valueUnit,
  initialValue,
  onSubmit,
  onClose,
  focus,
}: FactorControlDialogProps) {
  const [inputValue, setInputValue] = useState<number | 'min' | 'max'>(
    initialValue,
  );

  const [t] = useTranslation();
  const themeData = getThemeData();
  return (
    <DialogContent
      sx={{ minWidth: ['calc(100vw - 80px)', '550px'], padding: '0px' }}
    >
      {/* fontSize="lg" fontWeight="bold" */}
      <Stack direction="row" alignItems="center" p="16px">
        <Typography variant="headline2">{name}</Typography>
        <Flex flex={1} />
        <IconButton onClick={onClose}>
          <ClearIcon sx={{ width: '24px', height: '24px' }} />
        </IconButton>
      </Stack>

      <Box px="16px">
        <Box marginBottom="16px">
          <Typography variant="body1">
            {t('text.factorControlInputDescription', {
              value: focus === 'left' ? t('text.lower') : t('text.upper'),
            })}
          </Typography>
        </Box>

        <NumberEditableInput
          tailText={valueUnit}
          onUpdate={(number) => {
            setInputValue(number);
          }}
          initialValue={initialValue}
          canDecimals
        />
      </Box>
      <Stack direction="row" mt="32px" bgcolor="#FAFAFA" p="16px">
        <Button
          //   colorScheme="blue"
          onClick={() => {
            onSubmit(focus === 'left' ? 'min' : 'max');
            onClose();
          }}
          //   ml={3}
        >
          <Typography color={themeData.colors.primary[300]} variant="body2">
            {t('text.factorControlInputButton', {
              value: focus === 'left' ? t('text.min') : t('text.max'),
            })}
          </Typography>
        </Button>
        <Flex flex={1} />
        <Button
          variant="outlined"
          onClick={onClose}
          // ml={3}
        >
          {t('text.cancel')}
        </Button>
        <Button
          //   colorScheme="primary"
          sx={{ marginLeft: '8px' }}
          variant="contained"
          onClick={() => {
            onSubmit(
              inputValue && typeof inputValue === 'number' ? inputValue : 0,
            );
            onClose();
          }}
        >
          {t('text.submit')}
        </Button>
      </Stack>
    </DialogContent>
  );
}
