import { useTranslation } from 'react-i18next';
import { Button, ChakraProvider } from '@chakra-ui/react';
import { Box, Grid } from '@mui/material';

import { getThemeData } from '~/utils/theme';
import BackTestingChart from './BackTestingChart';
import BackTestingHistoryTable from './BackTestingHistoryTable';
import BackTestingMDDChart from './BackTestingMddChart';
import BacktestSummaryTable from './BackTestingSummaryTable';
import { YieldIndex } from '~/api/backtesting';

type Props = {
  result: YieldIndex;
  nationCode: number;
  isDI?: boolean;
  onDownloadMonthlyReportXlsx?: () => void;
};
function BackTestingResultGrid({
  result,
  isDI,
  nationCode,
  onDownloadMonthlyReportXlsx,
}: Props) {
  const themeData = getThemeData();

  const [t] = useTranslation();
  return (
    <Grid container columns={[1, 3]}>
      <Grid item xs={1} sm={2}>
        <BackTestingChart value={result} nationCode={nationCode} />
      </Grid>
      <Grid item xs={2} sm={1}>
        <Box display={['initial', 'none']}>
          <BackTestingMDDChart value={result} nationCode={nationCode} />
        </Box>
        <Box display={['none', 'initial']}>
          <BacktestSummaryTable value={result} nationCode={nationCode} />
          {isDI ? (
            <Button
              w="100%"
              size="lg"
              bg={themeData.colors.gray[100]}
              border={`solid 1px ${themeData.colors.primary[900]}`}
              _hover={{ backgroundColor: themeData.colors.gray[200] }}
              color={themeData.colors.primary[900]}
              onClick={() => {
                // console.log('CLICK');
              }}
            >
              {t('text.dummybuy')}
            </Button>
          ) : (
            <div />
          )}
        </Box>
      </Grid>
      <Grid item xs={1} sm={2}>
        <Box display={['initial', 'none']}>
          <BacktestSummaryTable value={result} nationCode={nationCode} />
        </Box>
        <Box display={['none', 'initial']}>
          <BackTestingMDDChart value={result} nationCode={nationCode} />
        </Box>
      </Grid>

      <Grid
        item
        xs={1}
        sm={3}
        borderTop={`solid 1px ${themeData.colors.gray[200]}`}
      >
        <BackTestingHistoryTable
          value={result}
          nationCode={nationCode}
          onDownloadXlsx={() => onDownloadMonthlyReportXlsx?.()}
        />
      </Grid>
    </Grid>
  );
}

BackTestingResultGrid.defaultProps = {
  isDI: false,
  onDownloadMonthlyReportXlsx: undefined,
};
export default BackTestingResultGrid;
