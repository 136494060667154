import React, {
  ReactElement,
  ReactPropTypes,
  useContext,
  useState,
} from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router';
import { Tag } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';

import SelectableStackItem from '~/components/strategy/creation/SelectableStackItem';
import { AppContext } from '~/AppContext';
import { Universe } from './UniverseList';
import { ExcludedCompany } from '~/api/backtesting';
import StrategyComingSoonCard from '../selection/StrategyComingSoonCard';
import StyledButton from '~/components/StyledButton';

export interface FactorWeight {
  factorId: number;
  weight: number;
  from: 'top' | 'bottom';
  rate: number;
}

export interface TextSpan {
  text: string;
  isBold?: boolean;
}
export interface FactorPreset {
  id: number;
  nameTextSpans: Array<TextSpan>;
  description: string;
  articleUrl?: string;
  expert?: {
    name: string;
    description: string;
  };
  factors?: FactorValue[];
  universe?: Universe;
  recommended?: number;
  excludedCategoryIds: number[];
  count?: number;
  startDate?: Date;
  endDate?: Date;
  excludedCompanies?: ExcludedCompany[];
  image?: string;
  tradeCost?: number;
  tags: Array<string>;
  isDisplay?: boolean;
}

export interface FactorValue {
  id: number;
  min?: number;
  max?: number;
  isAbsolute?: boolean;
  fixed?: boolean;
}
interface FactorPresetListProps {
  presets?: FactorPreset[];
  appliedCustom?: boolean;
}

FactorPresetList.defaultProps = {
  presets: [],
  appliedCustom: false,
};

export function PresetCard({
  nameTextSpans,
  description,
  onClick,
  tags,
  nationCode,
  articleUrl,
  image,
  id,
}: {
  nameTextSpans: Array<TextSpan>;
  description: string;

  onClick?: () => void;
  tags: Array<string>;
  nationCode?: number;
  articleUrl?: string;
  image?: string;
  id: number;
}) {
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();
  return (
    <Stack
      borderRadius="8px"
      sx={{
        height: 'auto',
        width: ['100%', '430px'],
        cursor: 'pointer',
        overflow: 'hidden',
        background: 'white',
        ':hover': { boxShadow: 3 },
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
      }}
      onClick={onClick}
    >
      <Box
        width="100%"
        sx={{
          backgroundSize: 'cover',
          background: 'rgba(0, 0, 0, 0.04);',
          backgroundImage: `url(${image})`,
          WebkitBackgroundSize: 'cover',
        }}
        minHeight="112px"
        p="16px"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Stack mb="8px" direction="row" spacing="12px">
          {[
            ...(nationCode ? [t(`nation.${nationCode}.flag`)] : []),
            ...tags,
          ].map((tag) => (
            <Box
              key={tag}
              width="36px"
              height="36px"
              borderRadius="100%"
              bgcolor="white"
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <Typography variant="headline1">{tag}</Typography>
            </Box>
          ))}
        </Stack>
        <Typography fontSize="20px" whiteSpace="pre-wrap" component="span">
          {nameTextSpans.map((textSpan) => (
            <div
              key={textSpan.text}
              style={{
                display: 'contents',
                fontWeight: textSpan.isBold ? 'bold' : 'normal',
              }}
            >
              {textSpan.text}
            </div>
          ))}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" flex={1} p="16px">
        {/* <Typography
          variant="headline1"
          fontSize={['16px', '20px']}
          fontWeight="normal"
        >
          {subtitle}
        </Typography> */}

        <Typography mt="4px" minHeight="72px" whiteSpace="pre-wrap">
          {description}
        </Typography>
        <Stack direction="row" spacing="16px" pt="16px">
          {articleUrl ? (
            <StyledButton
              sx={{
                flex: 1,
                background: '#F5F5F5',
                color: 'rgba(0, 0, 0, 0.87)',
                p: '10px 13px',
                ':hover': { background: '#aaaaaa' },
              }}
              onClick={(e) => {
                e.stopPropagation();
                logEvent('view article btn clicked ', {
                  'card id': id,
                  'page url': articleUrl,
                });
                window.open(articleUrl);
              }}
            >
              <Typography fontWeight="bold" color="text.secondary">
                {t('directIndex.reportButton')}
              </Typography>
            </StyledButton>
          ) : null}

          {onClick ? (
            <StyledButton
              sx={{
                flex: 1,
                background: '#332D41',
                color: 'white',
                p: '10px 13px',
                ':hover': { background: '#aaaaaa' },
              }}
            >
              <Typography fontWeight="bold">
                {id === -1
                  ? t('directIndex.createStrategyButton')
                  : t('directIndex.checkButton')}
              </Typography>
            </StyledButton>
          ) : (
            <Box flex={1} />
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
PresetCard.defaultProps = {
  articleUrl: undefined,
  onClick: undefined,
  image: undefined,
  nationCode: undefined,
};
// const SectionLabel = styled(Typography)(({ theme }) => ({
//   width: 'fit-content',
//   background: '#E5E7EB',
//   fontSize: '10px',
//   fontWeight: 700,
//   color: '#677380',
//   padding: theme.spacing(1),
//   borderRadius: theme.spacing(1),
// }));

function FactorPresetList({
  presets,
  appliedCustom,
  ...stackProps
}: FactorPresetListProps & Omit<StackProps, 'onChange'>) {
  const { state } = useContext(AppContext) ?? {};
  const history = useHistory();
  const { logEvent } = useAmplitude();
  const [t] = useTranslation();
  const presetCardList = (
    <>
      {presets?.map((preset) => {
        return (
          <Grid key={preset.id} item xs={3} sm={1}>
            <PresetCard
              key={preset.id}
              id={preset.id}
              image={preset.image}
              articleUrl={preset.articleUrl}
              nameTextSpans={preset.nameTextSpans}
              description={preset.description}
              onClick={() => {
                logEvent('view strategy btn clicked', {
                  'card id': preset.id,
                });
                history.push(`/create-strategy`, { preset });
              }}
              tags={preset.tags}
              nationCode={
                preset.universe?.universe === 'index'
                  ? preset.universe?.nationCode
                  : preset?.universe?.code ?? 840
              }
            />
          </Grid>
        );
      })}

      <Grid item xs={3} sm={1}>
        <PresetCard
          id={-1}
          nameTextSpans={[{ text: t('directIndex.makeYourselfTitle') }]}
          description={t('directIndex.makeYourselfDesc')}
          onClick={() => {
            logEvent('create my strategy btn clicked');
            history.push(`/create-strategy`);
          }}
          tags={['✍️']}
        />
      </Grid>
      <Grid item xs={3} sm={1}>
        <StrategyComingSoonCard
          key="StrategyComingSoonCard"
          width={['100%', '430px']}
          minHeight="280px"
          height={['280px', 'auto']}
        />
      </Grid>
    </>
  );

  return (
    <Stack {...stackProps}>
      <Grid container columns={3} spacing="24px">
        {presetCardList}
      </Grid>
    </Stack>
  );
}

export default FactorPresetList;
