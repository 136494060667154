import { Button, Stack, StackProps } from '@mui/material';

type SelectableStackItemProps = {
  selected?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
} & StackProps;

SelectableStackItem.defaultProps = {
  selected: false,
  disabled: false,
  hasBorder: true,
};

export default function SelectableStackItem({
  selected,
  disabled,
  hasBorder,
  children,
  ...stackProps
}: SelectableStackItemProps) {
  return (
    <Stack
      bgcolor={
        stackProps.bgcolor ?? disabled
          ? '#FAFAFA'
          : selected
          ? 'rgba(236, 233, 241, 0.4)'
          : '#FFFFFF'
      }
      border={
        hasBorder
          ? !disabled && selected
            ? '2px solid #9E7DF9'
            : '1px solid rgba(0, 0, 0, 0.08)'
          : 'none'
      }
      sx={{
        borderRadius: '8px',
        '&:hover': disabled
          ? null
          : {
              // background: '#FFFFFF',
              boxShadow:
                '0px 6px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)',
            },
      }}
      px={2}
      py="20px"
      {...stackProps}
    >
      {children}
    </Stack>
  );
}
