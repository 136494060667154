import { Button } from '@chakra-ui/button';
import { Flex, Center } from '@chakra-ui/layout';
import { Image, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { Factor } from '~/api/screener';
import { getThemeData } from '~/utils/theme';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from '../Icons';
import { Body2 } from '../Typography';
import iconInfo from '~/assets/icon/icon_info.svg';
import iconNewDot from '~/assets/icon/icon_new_dot.svg';

type FactorListItemProps = {
  factor: Factor;
  isSelected: boolean;
  onChange: (isSelected: boolean) => void;
};

const FactorListItem = ({
  factor,
  isSelected,
  onChange,
}: FactorListItemProps) => {
  const themeData = getThemeData();
  const [t] = useTranslation();
  return useMemo(
    () => (
      <Flex>
        <Button
          style={{ boxShadow: 'none' }}
          onClick={() => {
            onChange(!isSelected);
          }}
          variant="unstyled"
        >
          <Flex m="12px 24px" align="center" flexWrap="nowrap">
            {isSelected ? (
              <CheckBoxIcon
                sx={{
                  marginRight: '12px',
                  color: themeData.colors.primary[500],
                }}
                color="primary"
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                sx={{
                  marginRight: '12px',
                  color: themeData.colors.primary[500],
                }}
                color="primary"
              />
            )}
            <Body2 whiteSpace="nowrap" noOfLines={1}>
              {/* @ts-ignore */}
              {t(`factor.${factor.id}.name`)}
            </Body2>
            <Tooltip
              color={themeData.colors.text2}
              box-shadow="0px 3px 3px #ccc"
              whiteSpace="break-spaces"
              label={`${
                factor.id < 100
                  ? `${t(`factor.${factor.id}.conditionDescription`)}\n\n`
                  : ''
              }${t(`factor.${factor.id}.description`)}`}
              background="white"
              padding="8px"
              border={`solid 1px ${themeData.colors.primary[500]}`}
              borderRadius="8px"
              maxWidth="400px"
            >
              <Center tabIndex={0}>
                <Image
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  _focus={{ boxShadow: 'none', borderWidth: '0px' }}
                  marginLeft="5px"
                  src={iconInfo}
                />
              </Center>
            </Tooltip>
            {factor?.isNew ? (
              <Flex
                ml="3px"
                height="24px"
                alignItems="top"
                justifyContent="start"
              >
                <Image
                  src={iconNewDot}
                  width="6px"
                  height="6px"
                  objectPosition="top"
                  verticalAlign="top"
                />
              </Flex>
            ) : null}
          </Flex>
        </Button>
      </Flex>
    ),
    [isSelected, t],
  );
};

export default FactorListItem;
