import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Box, Button, Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { HStack } from '@chakra-ui/layout';
import { useAmplitude } from 'react-amplitude-hooks';
import { useLocation } from 'react-router';

import { Body2, H3, H5 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { ChartProps } from './BackTestResultContainer';
import { getIsMobile } from '~/utils/mediaQuery';

type BackTestingHistoryTableProps = ChartProps & {
  onDownloadXlsx?: () => void;
};

function BackTestingHistoryTable({
  value,
  nationCode,
  onDownloadXlsx,
}: BackTestingHistoryTableProps) {
  const isMobile = getIsMobile();
  const [t] = useTranslation();
  const themeData = getThemeData();
  const location = useLocation();
  const { logEvent } = useAmplitude();

  const headerData = value?.portBMYieldIndexes?.map((v) => {
    return `${v.endDate.split('-')[0]}-${v.endDate.split('-')[1]}`;
  });
  return (
    <Box
      width="100%"
      height="100%"
      padding={['0px', '24px']}
      marginTop={['12px', '0px']}
      overflow="hidden"
    >
      <HStack spacing="16px">
        <H3 bold>{t('text.performanceByMonthly')}</H3>
        <Button
          variant="outlined"
          size="small"
          startIcon={<Download />}
          onClick={() => {
            logEvent?.('backtesting portfolio btn clicked', {
              'page url': location.pathname,
            });
            onDownloadXlsx?.();
          }}
        >
          {t('text.download')}
        </Button>
        {!isMobile && <H5>{t('text.portfolio_download_free')}</H5>}
      </HStack>
      {isMobile && <H5>{t('text.portfolio_download_free')}</H5>}

      <Box width="100%" overflow="scroll">
        <Table size="md" marginTop="24px" w="100%">
          <Thead bg={themeData.colors.gray[100]}>
            <Tr>
              <Th />
              {headerData?.map((data) => (
                <Th p="12px" whiteSpace="nowrap" key={`header-data-${data}`}>
                  <Body2 whiteSpace="nowrap" noOfLines={1}>
                    {data}
                  </Body2>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr whiteSpace="nowrap">
              <Td p="12px">
                <Body2 whiteSpace="nowrap">{t('text.portfolio')}</Body2>
              </Td>
              {value?.portBMYieldIndexes?.map?.((v) => {
                return (
                  <Td p="12px" key={`table-data-port${v.startDate}`}>
                    <Body2 whiteSpace="nowrap" noOfLines={1}>
                      {(v.portYield * 100).toFixed(2)}%
                    </Body2>
                  </Td>
                );
              })}
            </Tr>
            <Tr>
              <Td p="12px">
                <Body2 whiteSpace="nowrap" noOfLines={1}>
                  {/* @ts-ignore */}
                  {t(`nation.benchmark.${nationCode}`)}
                </Body2>
              </Td>

              {value?.portBMYieldIndexes?.map?.((v) => {
                return (
                  <Td p="12px" key={`table-data-bench${v.startDate}`}>
                    <Body2 whiteSpace="nowrap" noOfLines={1}>
                      {' '}
                      {(v.bmYield * 100).toFixed(2)}%
                    </Body2>
                  </Td>
                );
              })}
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

BackTestingHistoryTable.defaultProps = {
  onDownloadXlsx: undefined,
};

export default BackTestingHistoryTable;
