import { CSSObject, Modal, ModalProps } from '@mui/material';
import { styled, Theme, ThemeProvider, useTheme } from '@mui/material/styles';

const mixins = (theme: Theme): CSSObject => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '0px',
    zIndex: theme.zIndex.modal + 3,
  },
  [theme.breakpoints.up('sm')]: {
    width: 'inherit',
    marginTop: '64px',
    zIndex: theme.zIndex.modal,
  },
});

const StyledModal = styled(Modal)<ModalProps>(({ theme }) => ({
  ...mixins(theme),
}));

export default StyledModal;
