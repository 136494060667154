import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { useState } from 'react';
import { useDidUpdateEffect } from '~/hooks/useDidUpdateEffect';
import { getThemeData } from '~/utils/theme';
import { Body2 } from '../Typography';

type Props = {
  tailText?: string;
  onUpdate?: (value: number) => void;
  initialValue?: number;
  canDecimals?: boolean;
  min?: number;
  max?: number;
};

function NumberEditableInput({
  tailText,
  onUpdate,
  initialValue,
  canDecimals,
  min,
  max,
}: Props) {
  const themeData = getThemeData();
  const [value, setValue] = useState<number | '-'>(initialValue ?? 0);

  const handleChange = (evt: any) => {
    if (evt.target.validity.valid) {
      if ((min && evt.target.value < min) || (max && evt.target.value > max)) {
        // console.log('break;');
      } else if (
        evt.target.value.slice(-1) === '.' ||
        evt.target.value.slice(-1) === '0'
      ) {
        setValue(evt.target.value);
      } else if (evt.target.value === '0-' || evt.target.value === '-') {
        setValue('-');
      } else {
        setValue(evt.target.value === '' ? 0 : +evt.target.value);
      }
    }
  };
  useDidUpdateEffect(() => {
    onUpdate?.(parseFloat(value.toString()));
  }, [value]);
  useDidUpdateEffect(() => {
    setValue(initialValue ?? 0);
  }, [initialValue]);
  return (
    <InputGroup
      size="md"
      bg="white"
      border={`solid 1px ${themeData.colors.primary[900]}`}
      borderRadius="8px"
      padding="8px 16px"
    >
      <Input
        w="100%"
        value={value?.toString()}
        textColor={themeData.colors.text1}
        pattern={
          canDecimals ? '(0-)|((^[-|0-9][0-9]*)(\\.*)[0-9]*$)' : '[0-9]*'
        }
        _focus={{ boxShadow: 'none', borderWidth: '0px' }}
        boxShadow="rgba(0, 0, 0, 0)"
        outline="0"
        onChange={handleChange}
      />
      <InputRightElement zIndex={0} p="8px">
        <Body2 whiteSpace="nowrap" color={themeData.colors.primary[500]}>
          {tailText}
        </Body2>
      </InputRightElement>
    </InputGroup>
  );
}
NumberEditableInput.defaultProps = {
  tailText: '',
  initialValue: 0,
  canDecimals: false,
  min: null,
  max: null,
  onUpdate: () => {
    ('');
  },
};

export default NumberEditableInput;
