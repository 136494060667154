import { createContext, Dispatch } from 'react';
import {
  CompanyGroupListResponse,
  Factor,
  FactorCategory,
  Nation,
  Strategy,
} from '~/api/screener';
import { FactorControlValue } from '~/components/factor/types';
import {
  ScreenerSortScroeType,
  ScreenerSortType,
} from '~/containers/screener/ScreenerSortSelector';

import { set, remove } from '~/utils/localStorage';

/*
Factor STATEMENT
*/
export type FactorState = {
  factorList: Array<Factor>;
  factorCategoryList: Array<FactorCategory>;
  companyGroupList: Map<
    CompanyGroupListResponse,
    Array<CompanyGroupListResponse>
  >;
  unselectedGroups: Set<number>;
  selectedFactors: Map<Factor, [FactorControlValue, FactorControlValue]>;
  selectedNation: Nation;
  sortData: Array<ScreenerSortType>;
  selectedConditions: string;
  isEditCurrentConditions: boolean;
  myConditionsCount: number;
  strategies: Array<Strategy>;
};

export type FactorAction =
  | { type: 'UPDATE_FACTOR_LIST'; factorList: Array<Factor> }
  | { type: 'UPDATE_STRATEGIES'; strategies: Array<Strategy> }
  | {
      type: 'UPDATE_COMPANY_GROUP_LIST';
      companyGroupList: Map<
        CompanyGroupListResponse,
        Array<CompanyGroupListResponse>
      >;
    }
  | { type: 'REMOVE_COMPANY_GROUP'; cosmosGroupId: number }
  | { type: 'ADD_COMPANY_GROUP'; cosmosGroupId: number }
  | { type: 'CLEAR_UNSELECTED_GROUPS' }
  | { type: 'SET_ALL_GROUPS' }
  | {
      type: 'UPDATE_FACTOR_CATEGORY_LIST';
      factorCategoryList: Array<FactorCategory>;
    }
  | { type: 'REMOVE_FACTOR'; factor: Factor }
  | {
      type: 'ADD_FACTOR';
      factor: Factor;
      range: [FactorControlValue, FactorControlValue];
    }
  | {
      type: 'UPDATE_RANGE';
      factor: Factor;
      range: [FactorControlValue, FactorControlValue];
    }
  | {
      type: 'SET_FACTORS';
      factors: {
        factor: Factor;
        range: [FactorControlValue, FactorControlValue];
      }[];
    }
  | { type: 'UPDATE_SELECTED_NATION'; nation: Nation }
  | {
      type: 'UPDATE_SORT_OPTION';
      sortData: Array<ScreenerSortType>;
    }
  | {
      type: 'UPDATE_ALL_SELECTED_DATA';
      selectedFactors: Map<Factor, [FactorControlValue, FactorControlValue]>;
      selectedNation: Nation;
      sortData: Array<ScreenerSortType>;
      selectedConditions: string;
      unselectedGroups: Set<number>;
    }
  | { type: 'CLEAR_FACTORS' }
  | { type: 'UPDATE_SELECTED_CONDITIONS'; selectedConditions: string }
  | { type: 'UPDATE_MY_CONDITIONS_COUNT'; myConditionsCount: number }
  | {
      type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS';
      isEditCurrentConditions: boolean;
    };

function FactorReducer(state: FactorState, action: FactorAction): FactorState {
  const oldSelectedFactors = state.selectedFactors;
  const oldunselectedGroups = state.unselectedGroups;

  let data: FactorState;
  switch (action.type) {
    case 'UPDATE_FACTOR_CATEGORY_LIST':
      data = {
        ...state,
        factorCategoryList: action.factorCategoryList,
        isEditCurrentConditions: true,
      };
      break;
    case 'UPDATE_COMPANY_GROUP_LIST':
      data = {
        ...state,
        companyGroupList: action.companyGroupList,
      };
      break;

    case 'ADD_COMPANY_GROUP':
      oldunselectedGroups.add(action.cosmosGroupId);
      data = {
        ...state,
        isEditCurrentConditions: true,
        unselectedGroups: new Set(oldunselectedGroups),
      };
      break;

    case 'UPDATE_STRATEGIES':
      data = {
        ...state,
        strategies: action.strategies,
      };
      break;

    case 'REMOVE_COMPANY_GROUP':
      oldunselectedGroups.delete(action.cosmosGroupId);

      data = {
        ...state,
        isEditCurrentConditions: true,
        unselectedGroups: new Set(oldunselectedGroups),
      };
      break;
    case 'CLEAR_UNSELECTED_GROUPS':
      data = {
        ...state,
        isEditCurrentConditions: true,
        unselectedGroups: new Set(),
      };

      break;
    case 'SET_ALL_GROUPS':
      data = {
        ...state,
        isEditCurrentConditions: true,

        unselectedGroups: new Set(
          Array.from(state.companyGroupList.entries())
            .map(([key, value]) => {
              if (value.length === 0) {
                return [];
              }
              if (value.length === 1) {
                return [key];
              }
              return value;
            })
            .flatMap((v) => v)
            .map((v) => v.cosmosGroupId),
        ),
      };
      break;

    case 'UPDATE_FACTOR_LIST':
      data = {
        ...state,
        factorList: action.factorList,
        isEditCurrentConditions: true,
      };
      break;
    case 'REMOVE_FACTOR':
      // @ts-ignore
      remove(`FACTOR_RANGE_VALUE_${action.factor.id}`);
      data = {
        ...state,
        selectedFactors: new Map<
          Factor,
          [FactorControlValue, FactorControlValue]
        >(
          Array.from(oldSelectedFactors).filter(
            (v) => v[0].id !== action.factor.id,
          ),
        ),
        isEditCurrentConditions: true,
      };
      break;

    case 'ADD_FACTOR':
      oldSelectedFactors.set(action.factor, action.range); // TODO : DEFAULT VALUE

      data = {
        ...state,
        selectedFactors: oldSelectedFactors,
        isEditCurrentConditions: true,
      };
      break;
    case 'UPDATE_RANGE':
      oldSelectedFactors.set(action.factor, action.range);
      data = {
        ...state,
        selectedFactors: new Map(oldSelectedFactors),
      };
      break;
    case 'UPDATE_SELECTED_CONDITIONS':
      data = {
        ...state,
        selectedConditions: action.selectedConditions,
        isEditCurrentConditions: false,
      };
      break;
    case 'CLEAR_FACTORS':
      Array.from(oldSelectedFactors).forEach(([key]) => {
        // @ts-ignore
        remove(`FACTOR_RANGE_VALUE_${key.id}`);
      });

      data = {
        ...state,
        selectedFactors: new Map(),
        isEditCurrentConditions: true,
      };
      break;
    case 'SET_FACTORS':
      Array.from(oldSelectedFactors).forEach(([key]) => {
        // @ts-ignore
        remove(`FACTOR_RANGE_VALUE_${key.id}`);
      });

      oldSelectedFactors.clear();

      action.factors.forEach(({ factor, range }) => {
        oldSelectedFactors.set(factor, range);
      });

      data = {
        ...state,
        selectedFactors: new Map<
          Factor,
          [FactorControlValue, FactorControlValue]
        >(oldSelectedFactors),
        isEditCurrentConditions: false,
      };
      break;

    case 'UPDATE_SELECTED_NATION':
      data = {
        ...state,
        selectedNation: action.nation,
        isEditCurrentConditions: true,
      };
      break;
    case 'UPDATE_SORT_OPTION':
      data = {
        ...state,
        sortData: action.sortData,
        isEditCurrentConditions: true,
      };
      break;

    case 'UPDATE_ALL_SELECTED_DATA':
      data = {
        ...state,
        selectedFactors: action.selectedFactors,
        selectedNation: action.selectedNation,
        sortData: action.sortData,
        selectedConditions: action.selectedConditions,
        isEditCurrentConditions: false,
        unselectedGroups: action.unselectedGroups,
      };
      break;

    case 'UPDATE_IS_EDIT_CURRENT_CONDITIONS':
      data = {
        ...state,
        isEditCurrentConditions: action.isEditCurrentConditions,
      };
      break;
    case 'UPDATE_MY_CONDITIONS_COUNT':
      data = {
        ...state,
        myConditionsCount: action.myConditionsCount,
      };
      break;
    default:
      data = {
        ...state,
        selectedFactors: new Map(),
      };
      break;
  }

  set('selectedFactors', JSON.stringify(Array.from(data.selectedFactors)));
  if (data.selectedNation) {
    set('selectedNation', JSON.stringify(data.selectedNation));
  }
  if (data.sortData) {
    // @ts-ignore
    set('selectedSortOption', JSON.stringify(data.sortData));
  }

  if (data.unselectedGroups) {
    set(
      // @ts-ignore
      'UNSELECTED_GROUPS',
      JSON.stringify(Array.from(data.unselectedGroups.values())),
    );
  }

  return data;
}

type FactorDispatch = Dispatch<FactorAction>;
const FactorDispatchContext = createContext<FactorDispatch | null>(null);
const FactorStateContext = createContext<FactorState | null>(null);

export { FactorReducer, FactorStateContext, FactorDispatchContext };
