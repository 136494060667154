import { Center, HStack, Image, Tooltip } from '@chakra-ui/react';
import { getThemeData } from '~/utils/theme';
import iconInfo from '~/assets/icon/icon_info.svg';
import { H3 } from './Typography';

type SubtitleWithTooltipProps = {
  label: string;
  description?: string;
};

function SubtitleWithTooltip({ label, description }: SubtitleWithTooltipProps) {
  const themeData = getThemeData();

  return (
    <HStack>
      <H3 bold noOfLines={1}>
        {label}
      </H3>
      {description ? (
        <Tooltip
          color={themeData.colors.text2}
          bg="white"
          box-shadow="0px 3px 3px #ccc"
          whiteSpace="pre-line"
          label={description}
          marginLeft="5px"
        >
          <Center tabIndex={0}>
            <Image
              onClick={(e) => {
                e.stopPropagation();
              }}
              _focus={{ boxShadow: 'none', borderWidth: '0px' }}
              marginLeft="5px"
              src={iconInfo}
            />
          </Center>
        </Tooltip>
      ) : (
        <div />
      )}
    </HStack>
  );
}
SubtitleWithTooltip.defaultProps = {
  description: null,
};

export default SubtitleWithTooltip;
