import { Box, Spacer, Image, Button, ButtonProps } from '@chakra-ui/react';
import AdapterDateFns from '@date-io/date-fns';
import { TextField } from '@mui/material';
import { ko } from 'date-fns/locale';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { getThemeData } from '~/utils/theme';
import { CalendarMonthIcon } from './Icons';
import { Caption } from './Typography';

type CustomDatePickerProps = {
  label: string;
  selected: Date | undefined;
  maxDate?: Date | undefined;
  minDate?: Date | undefined;
  onChange(date: Date | null, keyboardInputValue?: string): void;
};

function CustomDatePicker({
  label,
  selected,
  onChange,
  minDate,
  maxDate,
}: CustomDatePickerProps) {
  const themeData = getThemeData();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
      <Caption color={themeData.colors.text3}>{label}</Caption>
      <Spacer h="8px" />
      <DesktopDatePicker
        views={['year', 'month']}
        // PaperProps={{ color: 'blue', sx: { bgcolor: 'red' } }}
        InputProps={{
          size: 'small',
          sx: {
            borderRadius: '8px',
            backgroundColor: '#FFF',
          },
        }}
        inputFormat="yyyy/MM"
        mask="____/__"
        value={selected}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputLabel-root': { color: '#8964F2' }, // styles the label
              '& .MuiOutlinedInput-root': {
                '& > fieldset': { borderColor: '#8964F2' },
              },
              flex: 1,
              width: '100%',
              border: '0px',
              '& > .fieldset': {
                border: '0px',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
CustomDatePicker.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default CustomDatePicker;
