import { FactorFilterCondition, QueryFactor } from '~/api/backtesting';
import { Factor } from '~/api/screener';
import { FactorControlValue } from '~/components/factor/types';
import { FactorValue } from '~/components/strategy/creation/FactorPresetList';
import {
  ScreenerSortScroeType,
  ScreenerSortType,
} from '~/containers/screener/ScreenerSortSelector';

export type SymbolType = '<=' | '>=' | '<' | '>';

export type ExecConditions = {
  factorId: number;
  isAbsolute: boolean;
  symbol: SymbolType;
  value: number | null;
};

export const getExecConditions = (
  selectedFactors: Map<FactorValue, [FactorControlValue, FactorControlValue]>,
  sortType: ScreenerSortType,
): Array<QueryFactor> => {
  const conditions: Array<QueryFactor> = [];

  selectedFactors.forEach((range, factor) => {
    const { isAbsolute } = range[0];

    if (isAbsolute) {
      conditions.push({
        factorId: factor.id,
        gt: typeof range[0].value === 'number' ? Number(range[0].value) : null,
        gte: true,
        lt: typeof range[1].value === 'number' ? Number(range[1].value) : null,
        lte: !isAbsolute,
        queryType: typeof sortType.data === 'string' ? sortType.data : 'FILTER',
        valueType: isAbsolute ? 'ABSOLUTE' : 'RELATIVE',
        weight: 1,
      });
    } else {
      conditions.push({
        factorId: factor.id,
        gt: typeof range[0].value === 'number' ? Number(range[0].value) : null,
        gte: true,
        lt: typeof range[1].value === 'number' ? Number(range[1].value) : null,
        lte: true,
        queryType: typeof sortType.data === 'string' ? sortType.data : 'FILTER',
        valueType: isAbsolute ? 'ABSOLUTE' : 'RELATIVE',
        weight: 1,
      });
    }
  });
  return conditions;
};
