import { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  StackProps,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { chunk, flatten, isNumber, transform } from 'lodash';
import bluebird from 'bluebird';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RefreshIcon from '@mui/icons-material/Refresh';

import dayjs from '~/utils/dayjs';
import { Portfolio, ModelAsset, SimulationRecipe } from '~/api/portfolio';
import { CompanyPricesResult, Strategy } from '~/api/strategy';
import api, { CurrencyRates, Currency } from '~/api';
import AssetAllocationSimulator, {
  findCurrencyLabel,
} from '~/components/portfolio/AssetAllocationSimulator';
import StyledButton from '~/components/StyledButton';

export type StrategyPortfolioApplierProps = {
  strategyId: string;
  onCompleted: (
    portfolio: Portfolio,
    modelAsset: ModelAsset[],
    recipe: SimulationRecipe,
  ) => void;
  onClose?: () => void;
} & StackProps;

const StrategyPortfolioApplier = forwardRef(
  (
    {
      strategyId,
      onCompleted,
      onClose,
      ...stackProps
    }: StrategyPortfolioApplierProps,
    ref,
  ) => {
    const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);
    const [phase, setPhase] = useState<'input' | 'simulation'>('input');
    const [recipe, setRecipe] = useState<SimulationRecipe | null>(null);
    const [assets, setAssets] = useState<ModelAsset[]>([]);
    const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
    const [selectedPortfolioIndex, setSelectedPortfolioIndex] = useState<
      number | ''
    >('');
    const [assetSize, setAssetSize] = useState<number>(10000);
    const [priceHistory, setPriceHistory] = useState<{
      [c: number]: CompanyPricesResult;
    }>({});

    const [currency, setCurrency] = useState<Currency>('USD');
    const [currencyRates, setCurrencyRates] = useState<CurrencyRates | null>(
      null,
    );

    useEffect(() => {
      api.currencyRatesBaseUSD().then(
        (response) => {
          setCurrencyRates(response.data.rates);
        },
        (error) => {
          console.log('fail', error);
        },
      );
      api.portfolio.portfolios().then(
        (response) => {
          setPortfolios(
            response.data.filter((portfolio) => !portfolio.strategyId),
          );
        },
        (error) => {
          console.log('fail', error);
        },
      );
    }, []);

    useEffect(() => {
      if (!strategyId) {
        setAssets((state) => {
          if (state.length > 0) {
            return [];
          }
          return state;
        });
        return;
      }
      setIsLoadingPrices(true);
      api.strategy
        .getStrategyCompanies(strategyId)
        .then(
          async (response) => {
            setAssets(response.data);

            const codes = response.data.map((asset) => asset.id);
            const priceResponses = flatten(
              await bluebird.mapSeries(
                chunk(codes, 10),
                async (chuckedCodes) => {
                  return bluebird.map(
                    chuckedCodes,
                    (code) => {
                      return api.strategy
                        .getCompanyPriceHistory(
                          code,
                          dayjs().subtract(7, 'day').toDate(),
                          new Date(),
                        )
                        .catch((err) => {
                          console.log('fail price', err);
                          return null;
                        });
                    },
                    {
                      concurrency: 10,
                    },
                  );
                },
              ),
            );
            setCurrency(
              (priceResponses?.[0]?.data?.currency ?? 'USD') as typeof currency,
            );
            setPriceHistory(
              transform(
                codes,
                (r, code, i) => {
                  if (priceResponses?.[i]?.data) {
                    r[code] = priceResponses?.[i]?.data as CompanyPricesResult;
                  }
                },
                {} as typeof priceHistory,
              ),
            );
          },
          (error) => {
            console.log('fail', error);
          },
        )
        .finally(() => {
          setIsLoadingPrices(false);
        });
    }, [strategyId]);

    const exchangeKrwRate = useMemo(() => {
      if (!currencyRates) {
        return 1;
      }
      const usdToKrwRate = currencyRates['KRW'];
      const usdToTargetRate = currencyRates?.[currency as Currency] ?? 1;

      return usdToKrwRate / usdToTargetRate;
    }, [currency, currencyRates]);

    return (
      <Stack
        width="100%"
        height="100%"
        direction="column"
        p={2}
        {...stackProps}
        ref={ref}
        spacing={2}
        overflow="hidden"
      >
        <Stack
          direction="row"
          width="100%"
          height="60px"
          flexShrink={0}
          alignItems="center"
          spacing={1}
        >
          {phase === 'input' ? (
            <>
              <AddBoxIcon color="secondary" />
              <Typography flex={1} fontSize="20px" fontWeight="bold">
                포트폴리오 만들기
              </Typography>
            </>
          ) : (
            <Typography flex={1} fontSize="20px" fontWeight="bold">
              구성 종목
            </Typography>
          )}
          <IconButton onClick={() => onClose?.()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {phase === 'input' ? (
          <Stack spacing={2}>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>Step1.</span> 포트폴리오를
              생성할 고객을 선택해주세요
            </Typography>
            {portfolios.length > 0 ? (
              <FormControl>
                <InputLabel>적용 계좌</InputLabel>
                <Select
                  displayEmpty
                  label="적용 가능한 계좌"
                  value={selectedPortfolioIndex}
                  onChange={(e) =>
                    setSelectedPortfolioIndex(
                      isNumber(e.target.value) ? Number(e.target.value) : '',
                    )
                  }
                >
                  {portfolios.map((portfolio, index) => {
                    return (
                      <MenuItem key={portfolio.id} value={index}>
                        {`${portfolio.name} : ${portfolio.user?.name}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="body2">
                적용할 수 있는 계좌가 없습니다.
              </Typography>
            )}
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>Step2.</span> 본 전략을
              운용할 포트폴리오의 자산 크기를 알려주세요
            </Typography>
            <Stack direction="column" width="100%">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">
                  금액
                </InputLabel>
                <OutlinedInput
                  type="number"
                  value={assetSize}
                  onChange={(e) => {
                    setAssetSize(Number(e.target.value));
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      {findCurrencyLabel(currency)}
                    </InputAdornment>
                  }
                  inputProps={{
                    min: 100,
                    max: 10000000,
                    step: 100,
                  }}
                  label="금액"
                />
              </FormControl>
              <Typography variant="overline" color="text.disabled">
                {(assetSize * exchangeKrwRate).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                원, $1 당{' '}
                {exchangeKrwRate.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                })}
                원
              </Typography>
            </Stack>
            <StyledButton
              variant="contained"
              sx={{
                width: 'fit-content',
                alignSelf: 'end',
              }}
              disabled={selectedPortfolioIndex < 0}
              onClick={() => setPhase('simulation')}
            >
              구성종목 미리보기
            </StyledButton>
          </Stack>
        ) : (
          <>
            <StyledButton
              sx={{ width: 'fit-content' }}
              onClick={() => setPhase('input')}
              startIcon={<RefreshIcon />}
            >
              운용자산 재입력
            </StyledButton>
            <AssetAllocationSimulator
              overflow="auto"
              assets={assets}
              assetSize={assetSize}
              currency={currency}
              priceHistory={priceHistory}
              onUpdate={(recipe) => setRecipe(recipe)}
            />
            <LoadingButton
              variant="contained"
              sx={{ width: 'fit-content', alignSelf: 'end' }}
              loading={isLoadingPrices}
              disabled={!isNumber(selectedPortfolioIndex) || !recipe}
              onClick={() => {
                if (selectedPortfolioIndex !== '' && recipe) {
                  onCompleted?.(
                    portfolios[selectedPortfolioIndex],
                    assets,
                    recipe,
                  );
                }
              }}
            >
              포트폴리오 생성하고 자동 리밸런싱 받기
            </LoadingButton>
          </>
        )}
      </Stack>
    );
  },
);

StrategyPortfolioApplier.displayName = 'StrategyPortfolioApplier';
StrategyPortfolioApplier.defaultProps = {
  onClose: undefined,
};

export default StrategyPortfolioApplier;
