import React, {
  ElementType,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { chunk, flatten, transform } from 'lodash';
import bluebird from 'bluebird';
import {
  AddBox,
  Error as ErrorIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { useAmplitude } from 'react-amplitude-hooks';
import NumberFormat, {
  InputAttributes,
  NumberFormatProps,
} from 'react-number-format';

import dayjs from '~/utils/dayjs';
import { AssetRecipe, ModelAsset, SimulationRecipe } from '~/api/portfolio';
import { CompanyPricesResult } from '~/api/strategy';
import api, { CurrencyRates, Currency } from '~/api';
import AssetAllocationSimulator from '~/components/portfolio/AssetAllocationSimulator';
import StyledButton from '~/components/StyledButton';

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  NumberFormatProps<InputBaseComponentProps> & {
    onChange: (event: { target: { value: string } }) => void;
  }
>(function NumberFormatCustom({ onChange, ...other }, ref) {
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

type ModelAssetBuyingGuideProps = {
  modelAssets: ModelAsset[];
  onClose?: () => void;
  onAllocate?: (orders: AssetRecipe[]) => void;
};

const ModelAssetBuyingGuide = forwardRef(
  ({ modelAssets, onClose, onAllocate }: ModelAssetBuyingGuideProps, ref) => {
    const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);
    const [phase, setPhase] = useState<'input' | 'simulation'>('input');
    const { logEvent } = useAmplitude();
    const [priceHistory, setPriceHistory] = useState<{
      [c: number]: CompanyPricesResult;
    }>({});
    const [assetSize, setAssetSize] = useState<number>(0);

    const [exchangeLogDate, setExchangeLogDate] = useState<Date | null>(null);
    const [currencyRates, setCurrencyRates] = useState<CurrencyRates | null>(
      null,
    );

    const [recipe, setRecipe] = useState<SimulationRecipe | null>(null);

    useEffect(() => {
      setIsLoadingPrices(true);
      (async () => {
        const currencyRes = await api.currencyRatesBaseUSD();
        setExchangeLogDate(currencyRes.data.time_last_update_utc);
        setCurrencyRates(currencyRes.data.rates);

        const codes = modelAssets
          .map((asset) => asset.id)
          .filter((code) => !(code in priceHistory));

        const priceResponses = flatten(
          await bluebird.mapSeries(chunk(codes, 10), async (chuckedCodes) => {
            return bluebird.map(
              chuckedCodes,
              (code) => {
                return api.strategy
                  .getCompanyPriceHistory(
                    code,
                    dayjs().subtract(1, 'month').toDate(),
                    new Date(),
                  )
                  .catch((err) => {
                    console.log('fail price', err);
                    return null;
                  });
              },
              {
                concurrency: 10,
              },
            );
          }),
        );

        setPriceHistory((state) => {
          return transform(
            codes,
            (r, code, i) => {
              if (priceResponses?.[i]?.data) {
                r[code] = priceResponses[i]!.data as CompanyPricesResult;
              }
            },
            {
              ...state,
            },
          );
        });
      })()
        .catch((error) => {
          console.log('fail', error);
        })
        .finally(() => {
          setIsLoadingPrices(false);
        });
    }, [modelAssets]);

    const currency: Currency | null = useMemo(() => {
      const sampleTicker = Object.keys(priceHistory)?.[0];
      if (sampleTicker) {
        return priceHistory[Number(sampleTicker)].currency as Currency;
      }
      return null;
    }, [priceHistory]);

    useEffect(() => {
      if (currency === 'KRW') {
        setAssetSize(10000000);
      } else {
        setAssetSize(10000);
      }
    }, [currency]);

    const exchangeKrwRate = useMemo(() => {
      if (!currencyRates) {
        return null;
      }
      const usdToKrwRate = currencyRates['KRW'];
      const usdToTargetRate = currencyRates?.[currency as Currency] ?? 1;

      return usdToKrwRate / usdToTargetRate;
    }, [currency, currencyRates]);

    if (phase === 'input') {
      return (
        <Stack
          direction="column"
          ref={ref}
          overflow="hidden"
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '424px',
            maxWidth: '90vw',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          borderRadius="4px"
        >
          <Stack spacing={2} p={2}>
            <Stack
              direction="row"
              width="100%"
              height="60px"
              flexShrink={0}
              alignItems="center"
              spacing={1}
            >
              <Box
                display="flex"
                width="40px"
                height="40px"
                bgcolor="#F6EDFF"
                borderRadius="20px"
                justifyContent="center"
                alignItems="center"
              >
                <AddBox color="secondary" />
              </Box>

              <Typography flex={1} fontSize="20px" fontWeight="bold">
                포트폴리오 운용
              </Typography>
              <IconButton onClick={() => onClose?.()}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction="column" width="100%">
              <Stack
                direction="column"
                alignItems="center"
                py="24px"
                sx={{
                  backgroundColor: 'background.grey',
                }}
                borderRadius="4px"
                spacing="10px"
              >
                <Typography
                  whiteSpace="pre-wrap"
                  textAlign="center"
                  variant="body2"
                  color="text.disabled"
                >
                  실제 매매 기능 지원 전까지, 예상 운용 금액을 아래 입력하시면,
                  <br />
                  현재 포트폴리오의 투자 자산 구성에 맞추어 매수 가이드를
                  드립니다.
                </Typography>
              </Stack>
              <FormControl fullWidth variant="outlined" sx={{ mt: '10px' }}>
                <InputLabel>예상 금액</InputLabel>
                <OutlinedInput
                  label="예상 금액"
                  value={assetSize}
                  disabled={isLoadingPrices}
                  onChange={(e) => {
                    setAssetSize(Number(e.target.value));
                  }}
                  startAdornment={
                    <InputAdornment position="start">{currency}</InputAdornment>
                  }
                  inputComponent={NumberFormatCustom}
                />
              </FormControl>
              {!isLoadingPrices &&
              exchangeKrwRate !== null &&
              currency !== 'KRW' ? (
                <Typography variant="overline" color="text.disabled">
                  {(assetSize * exchangeKrwRate).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                  원, (기준 환율 : 1달러 당{' '}
                  {exchangeKrwRate.toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                  })}
                  원 /{' '}
                  {dayjs(exchangeLogDate).tz('Asia/seoul').format('YYYY-MM-DD')}{' '}
                  기준)
                </Typography>
              ) : null}
            </Stack>
          </Stack>
          <Stack
            p="16px"
            sx={{ backgroundColor: 'background.grey' }}
            alignItems="end"
          >
            <LoadingButton
              variant="contained"
              loading={isLoadingPrices}
              sx={{ width: 'fit-content' }}
              onClick={() => {
                logEvent('check up invest preview btn clicked', {
                  'Expected assets to invest': assetSize,
                });
                setPhase('simulation');
              }}
            >
              구성종목 미리보기
            </LoadingButton>
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack
        // width="100%"
        height="100%"
        direction="column"
        ref={ref}
        spacing={2}
        overflow="hidden"
        tabIndex={-1}
        sx={{
          position: 'absolute',
          top: '0',
          right: '0',
          height: '100%',
          minWidth: [null, '600px'],
          width: ['100%', '50%'],
          // transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
        }}
        p={2}
      >
        <Stack
          direction="row"
          width="100%"
          height="60px"
          flexShrink={0}
          alignItems="center"
          spacing={1}
        >
          <Typography flex={1} fontSize="20px" fontWeight="bold">
            매수 가이드 확인
          </Typography>
          <IconButton onClick={() => onClose?.()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <StyledButton
          sx={{ width: 'fit-content' }}
          onClick={() => setPhase('input')}
          startIcon={<RefreshIcon />}
        >
          예상 투자 금액 재 입력하기
        </StyledButton>
        <AssetAllocationSimulator
          overflow="auto"
          assets={modelAssets}
          assetSize={assetSize}
          currency={currency ?? 'USD'}
          priceHistory={priceHistory}
          onUpdate={(recipe) => setRecipe(recipe)}
        />
        {onAllocate && recipe ? (
          <StyledButton
            variant="contained"
            onClick={() => {
              onAllocate(recipe.portfolio);
            }}
          >
            실자산 매수하기
          </StyledButton>
        ) : null}
      </Stack>
    );
  },
);

ModelAssetBuyingGuide.displayName = 'ModelAssetBuyingGuide';
ModelAssetBuyingGuide.defaultProps = {
  onClose: undefined,
  onAllocate: undefined,
};

export default ModelAssetBuyingGuide;
