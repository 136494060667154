import { useEffect, useState } from 'react';
import CSS from 'csstype';

import { lightOption } from '~/theme';
import { set, get } from '~/utils/localStorage';
import { DefaultTheme, light } from '~/utils/theme';

type Themes = 'light';
export const useTheme = () => {
  const [theme, setTheme] = useState<Themes>('light');

  const setMode = (mode: Themes) => {
    set('theme', mode);
    setTheme(mode);
  };
  const getThemeData = (t: Themes): DefaultTheme => {
    return {
      id: 'light',
      name: 'light',
      colors: light,
      typography: getTypographyTheme(),
    };
  };

  useEffect(() => {
    const localTheme = get('theme');
    if (localTheme === 'light') {
      setMode(localTheme);
    } else {
      setMode('light');
    }
  }, []);

  return { theme, setMode, getThemeData };
};

// import { ColorThemeInterface } from './colorThemes';

const getTypographyTheme = (): // colorTheme: ColorThemeInterface,
TypographyThemeInterface => {
  return {
    h1: { fontSize: '32px', fontFamily: 'Noto Sans KR' },
    h2: { fontSize: '21px', fontFamily: 'Noto Sans KR' },
    h3: { fontSize: '18px', fontFamily: 'Noto Sans KR' },
    h4: { fontSize: '16px', fontFamily: 'Noto Sans KR' },
    h5: { fontSize: '14px', fontFamily: 'Noto Sans KR' },
    body1: { fontSize: '16px', fontFamily: 'Noto Sans KR' },
    body2: { fontSize: '14px', fontFamily: 'Noto Sans KR' },
    sub: { fontSize: '14px', fontFamily: 'Noto Sans KR' },
    caption: {
      fontSize: '12px',
      fontFamily: 'Noto Sans KR',
    },
  };
};
export interface TypographyThemeInterface {
  h1: CSS.Properties;
  h2: CSS.Properties;
  h3: CSS.Properties;
  h4: CSS.Properties;
  h5: CSS.Properties;
  body1: CSS.Properties;
  body2: CSS.Properties;
  sub: CSS.Properties;
  caption: CSS.Properties;
}
export { getTypographyTheme };
