import { Box } from '@chakra-ui/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getThemeData } from '~/utils/theme';
import FinancialValues from './FinancialValues';
import PriceValues from './PriceValues';

type Props = {
  cosmosCode: number;
};

function TabBody({ cosmosCode }: Props) {
  const [t] = useTranslation();
  const themeData = getThemeData();
  const [value, setValue] = useState<string>(
    t('companyDetail.tab.performance'),
  );
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        width: ['100%', 'initial'],
        height: '100%',
        overflow: 'scroll',
        margin: ['4px', '0px 4px'],

        mt: '16px',
        marginLeft: ['0px', '24px'],
        background: 'white',
        borderRadius: '8px',
      }}
    >
      <TabContext value={value}>
        {/* <Tabs
          //  colorScheme="primary"
          value={value}
          onChange={handleChange}
        > */}
        <Box sx={{ borderBottom: `solid 1px ${themeData.colors.gray[200]}` }}>
          <TabList onChange={handleChange} indicatorColor="secondary">
            {[t('companyDetail.tab.performance')].map((v) => (
              <Tab
                label={v}
                value={v}
                style={{
                  color: themeData.colors.primary[500],
                  fontWeight: value === v ? 'bold' : 'inherit',
                }}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel value={value} tabIndex={0}>
          <Stack>
            <PriceValues cosmosCode={cosmosCode} />
            <FinancialValues cosmosCode={cosmosCode} />
          </Stack>
        </TabPanel>
        {/* </Tabs> */}
      </TabContext>
    </Box>
  );
}

export default TabBody;
