export type StorageKey =
  | 'accessToken'
  | 'theme'
  | 'language'
  | 'mode'
  | 'selectedFactors'
  | 'selectedNation'
  | 'selectedSortOption';

export const set = <V extends string>(key: StorageKey, value: V) => {
  window.localStorage.setItem(key, value);
};

export const get = <V extends string>(
  key: StorageKey,
  option?: {
    ignoreEmpty?: boolean;
  },
): V | null => {
  const item = window.localStorage.getItem(key);
  if (option?.ignoreEmpty && (item?.length ?? 0) < 1) {
    return null;
  }
  return item as V;
};

export const remove = (key: StorageKey) => {
  window.localStorage.removeItem(key);
};
