import { Text } from '@chakra-ui/layout';
import * as CSS from 'csstype';
import { ReactNode } from 'react';

import { getThemeData } from '~/utils/theme';

type TypographyProps = {
  children: string | ReactNode;
  bold?: boolean;
  color?: string;
  noOfLines?: number;
  whiteSpace?: CSS.Property.WhiteSpace;
  align?: CSS.Property.TextAlign;
  decoration?: CSS.Property.TextDecoration;
};

const defaultProps = {
  bold: false,
  noOfLines: 1,
  color: 'text1',
  whiteSpace: 'initial',
  align: 'initial',
  decoration: 'initial',
};
export function H1({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();

  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      style={themeData.typography.h1}
      fontWeight={bold ? 'bold' : 'normal'}
      decoration={decoration}
    >
      {children}
    </Text>
  );
}
H1.defaultProps = defaultProps;

export function H2({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();
  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      style={themeData.typography.h2}
      fontWeight={bold ? 'bold' : 'normal'}
      decoration={decoration}
    >
      {children}
    </Text>
  );
}
H2.defaultProps = defaultProps;

export function H3({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();
  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      style={themeData.typography.h3}
      fontWeight={bold ? 'bold' : 'normal'}
      decoration={decoration}
    >
      {children}
    </Text>
  );
}
H3.defaultProps = defaultProps;

export function H4({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();
  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      style={themeData.typography.h4}
      fontWeight={bold ? 'bold' : 'normal'}
      decoration={decoration}
    >
      {children}
    </Text>
  );
}
H4.defaultProps = defaultProps;

export function H5({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();
  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      fontWeight={bold ? 'bold' : 'normal'}
      style={themeData.typography.h5}
      decoration={decoration}
    >
      {children}
    </Text>
  );
}

H5.defaultProps = defaultProps;

export function Body1({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();
  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      fontWeight={bold ? 'bold' : 'normal'}
      style={themeData.typography.body1}
      decoration={decoration}
    >
      {children}
    </Text>
  );
}

Body1.defaultProps = defaultProps;

export function Body2({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();
  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      fontWeight={bold ? 'bold' : 'normal'}
      style={themeData.typography.body2}
      decoration={decoration}
      textTransform="initial"
    >
      {children}
    </Text>
  );
}

Body2.defaultProps = defaultProps;

export function Caption({
  children,
  bold,
  noOfLines,
  color,
  whiteSpace,
  align,
  decoration,
}: TypographyProps) {
  const themeData = getThemeData();
  return (
    <Text
      align={align}
      whiteSpace={whiteSpace}
      noOfLines={noOfLines}
      color={color}
      fontWeight={bold ? 'bold' : 'normal'}
      style={themeData.typography.caption}
      decoration={decoration}
    >
      {children}
    </Text>
  );
}

Caption.defaultProps = defaultProps;
