import { AxiosInstance, AxiosResponse } from 'axios';

export interface Profile {
  id: string;
  uid: string;
  name: string;
  email: string;
  phoneNumber?: string;
  imageUrl?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Permission {
  backtesting?: boolean;
  trading?: boolean | { default?: boolean; [nationCode: number]: boolean };
}

export type ServiceTermsAgreementState = {
  id: string;
  uid: string;
  type: string;
  version: string;
  link?: string;
  isRequired: boolean;
  duration: string;
  createdAt: Date;
  updatedAt: Date;
  isAgreed?: boolean;
  signedAt?: Date;
};

export const createService = (client: AxiosInstance) => {
  return {
    async profile() {
      return client.get<Profile>('/user/me');
    },
    async updateProfile(profile: {
      name?: string;
      phoneNumber?: string;
      imageUrl?: string;
    }) {
      return client.patch<{ updated: boolean }>('/user/me', profile);
    },
    async permission() {
      return client.get<Permission | null>('/user/permission');
    },
    async serviceTermState() {
      return client.get<ServiceTermsAgreementState[]>('/user/service-terms');
    },
    async signServiceTerms(agreements: { uid: string; isAgreed: boolean }[]) {
      return client.post('/user/service-terms/sign', {
        agreements,
      });
    },
  };
};
