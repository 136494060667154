import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getAuth, signInWithPopup, Unsubscribe } from 'firebase/auth';
import { useAmplitude } from 'react-amplitude-hooks';
import { Stack, Button, IconButton } from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import ConditionsList from './ConditionsList';
import { getThemeData } from '~/utils/theme';
import { Body1, Body2, H3 } from '~/components/Typography';
import { set } from '~/utils/localStorage';
import ConditionSotrageSaveButton from './ConditionStroageSaveButton';
import { FactorDispatchContext, FactorStateContext } from '../factor/context';
import { FactorControlValue } from '~/components/factor/types';
import { ClearIcon } from '~/components/Icons';
import api from '~/api';
import { Factor } from '~/api/screener';
import { AppContext } from '~/AppContext';

type Props = {
  onUpdate: () => void;
  isFullScreen: boolean;
};
function StorageCountBadge({ count }: { count: number }) {
  const themeData = getThemeData();

  return (
    <Center
      w="24px"
      h="24px"
      position="absolute"
      overflow="visible"
      right="-4px"
      top="-4px"
      borderRadius="12px"
      backgroundColor={themeData.colors.error}
    >
      <Body1 bold color="white">
        {count}
      </Body1>
    </Center>
  );
}
function StorageBadgeButton({
  onClick,
  count,
}: {
  onClick: () => void;
  count: number;
}) {
  const themeData = getThemeData();
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  const [t] = useTranslation();
  let unsub: Unsubscribe | null;
  const { amplitudeInstance } = useAmplitude();

  // useEffect(() => {
  //   if (authState?.user && !unsub) {
  //     unsub = onSnapshot(
  //       collection(getFirestore(), `storage/${authState.user.uid}/conditions`),
  //       (conditions) => {
  //         factorDispatch?.({
  //           type: 'UPDATE_MY_CONDITIONS_COUNT',
  //           myConditionsCount: conditions.docs.length,
  //         });
  //         amplitudeInstance?.setUserProperties({
  //           'num total of saved strategies': conditions.docs.length,
  //         });
  //       },
  //     );
  //   } else if (!authState?.user && unsub) {
  //     unsub();
  //   }
  // }, [authState?.user]);

  return (
    <Button
      sx={{
        height: '60px',
        overflow: 'visible',
        padding: ['3px 0px', '8px 0px'],
        marginLeft: ['8px', '30px'],
        borderWidth: '2px',
        background: themeData.colors.navy,
        borderRadius: ['24px', '100px'],
      }}
      onClick={onClick}
    >
      <StorageCountBadge count={count} />
      <Center h="40px" flexDirection="row" display="flex" margin="0px 16px">
        <FolderOpenIcon sx={{ color: 'white', marginRight: ['0px', '8px'] }} />
        <Stack display={['none', 'inherit']}>
          <Body2 bold color="white">
            {t('strategy.title')}
            {factorState?.selectedConditions === ''
              ? ''
              : `: ${factorState?.selectedConditions}`}
          </Body2>
        </Stack>
      </Center>
    </Button>
  );
}
function ConditionsStorageContainer({ onUpdate, isFullScreen }: Props) {
  const context = useContext(AppContext);
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);

  const [t] = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (context?.state?.user) {
      api.screener.getStrategy().then((v) => {
        factorDispatch?.({ type: 'UPDATE_STRATEGIES', strategies: v.data });
      });
    }
  }, [context?.state?.user]);

  if (!factorState) return <div />;

  if (!isActive)
    return (
      <>
        <Center
          position="absolute"
          top="-12px"
          left="8px"
          display={['inherit', 'none']}
        >
          <Body2 bold color="black">
            {factorState?.selectedConditions === ''
              ? ''
              : `${factorState?.selectedConditions}`}
          </Body2>
        </Center>

        <StorageBadgeButton
          count={factorState.strategies.length}
          onClick={() => {
            setIsActive(true);
          }}
        />
      </>
    );

  function SaveConditionsBanner() {
    const themeData = getThemeData();

    if (
      factorState?.selectedFactors.size === 0 ||
      !factorState?.isEditCurrentConditions
    ) {
      return <div />;
    }

    return (
      <HStack
        backgroundColor={themeData.colors.primary[100]}
        p="20px"
        width="100%"
      >
        <Box width="100%">
          <Body2 noOfLines={5} whiteSpace="break-spaces">
            {t('strategy.mobileSaveBanner')}
          </Body2>
        </Box>
        <ConditionSotrageSaveButton
          onComplete={() => {
            setIsActive(false);
            api.screener.getStrategy().then((v) =>
              factorDispatch?.({
                type: 'UPDATE_STRATEGIES',
                strategies: v.data,
              }),
            );
          }}
        />
      </HStack>
    );
  }
  function Header() {
    return (
      <Flex marginBottom="16px">
        <H3 bold>
          {t('strategy.title')}
          {factorState?.selectedConditions === ''
            ? ''
            : `: ${factorState?.selectedConditions}`}
        </H3>
        <Spacer />
        <IconButton
          sx={{
            alignSelf: 'end',
            p: '0px',
            // width: '50px',
          }}
          onClick={(e) => {
            e.preventDefault();
            setIsActive(false);
          }}
        >
          <ClearIcon />
        </IconButton>
      </Flex>
    );
  }
  const getSelectedFactors = (
    old: Array<[number, [FactorControlValue, FactorControlValue]]>,
  ): Map<Factor, [FactorControlValue, FactorControlValue]> => {
    const result = new Map<Factor, [FactorControlValue, FactorControlValue]>();

    old.forEach((v) => {
      result.set({ id: v[0] }, v[1]);
    });

    return result;
  };
  return (
    <Box
      shadow="3px 3px 3px #e0e0e0"
      border="solid 1px #e0e0e0"
      borderRadius="24px"
      marginRight={['0px', '4px']}
      p="24px"
      background="white"
      position={isFullScreen ? 'absolute' : undefined}
      height={isFullScreen ? 'calc(100vh - 140px)' : '480px'}
      width={isFullScreen ? '100vw' : '400px'}
      overflow="scroll"
      bottom="0px"
      zIndex={1000}
    >
      <Header />
      {isFullScreen ? <SaveConditionsBanner /> : <div />}
      <ConditionsList
        strategies={factorState.strategies}
        checkDuplicatedStrategy={(strategy) => {
          return (
            factorState.strategies.findIndex((v) => v.name === strategy.name) >
            0
          );
          // const promise1 = new Promise((resolve, reject) => {
          //   resolve('Success!');
          // });

          // return promise1.then((value) => {
          //   return false;
          // });

          //   return getDoc(
          //     doc(getFirestore(), `storage/${user.uid}/conditions/${strategy}`),
          //   ).then((v) => {
          //     if (v.data()) {
          //       return true;
          //     }
          //     return false;
          //   });
        }}
        onSelectStrategy={(strategy) => {
          api.screener.getStrategy().then((v) => {
            const find = v.data.find(
              (_strategy) => _strategy.uid === strategy.uid,
            );

            if (find) {
              // @ts-ignore
              set('BACKTEST_START_DATETIME', find?.startDate);
              // @ts-ignore
              set('BACKTEST_END_DATETIME', find?.endDate);
              // @ts-ignore
              set('BACKTEST_TRADE_COST', find?.tradeCost);
              // @ts-ignore
              set('BACKTEST_TOP', find?.count);
              // @ts-ignore
              set('BACKTEST_REBALANCINGPERIOD', find?.rebalancingPeriod);
              set(
                // @ts-ignore
                'UNSELECTED_GROUPS',
                JSON.stringify(find?.unselectedCategoryIds ?? []),
              );
              factorDispatch?.({ type: 'CLEAR_FACTORS' });
              factorDispatch?.({
                type: 'UPDATE_ALL_SELECTED_DATA',
                selectedFactors: getSelectedFactors(find.selectedFactors!),
                selectedNation: find.selectedNation!,
                sortData: find.orderFactor!,
                selectedConditions: find.name!,
                unselectedGroups: new Set<number>(find.unselectedCategoryIds),
              });
              onUpdate();
            }
          });
        }}
        onRemoveStrategy={(strategy) => {
          if (
            factorState.selectedConditions === strategy.name &&
            factorDispatch
          ) {
            factorDispatch({
              type: 'UPDATE_SELECTED_CONDITIONS',
              selectedConditions: '',
            });
          }
          api.screener.deleteStrategy(strategy.uid).then((v) => {
            api.screener.getStrategy().then((v) => {
              factorDispatch?.({
                type: 'UPDATE_STRATEGIES',
                strategies: v.data,
              });
            });
          });
          // deleteDoc(
          //   doc(getFirestore(), `storage/${user.uid}/conditions/${strategy}`),
          // );
        }}
        onEditStrategy={(prev, next) => {
          if (prev?.uid) {
            api.screener
              .patchStrategy(prev?.uid, { name: next.name })
              .then(() => {
                api.screener.getStrategy().then((v) => {
                  factorDispatch?.({
                    type: 'UPDATE_STRATEGIES',
                    strategies: v.data,
                  });
                });
              });
            factorDispatch?.({
              type: 'UPDATE_SELECTED_CONDITIONS',
              selectedConditions: next.name,
            });
          }
          // TODO
          // if (factorState.selectedConditions === prev && factorDispatch) {
          //   factorDispatch({
          //     type: 'UPDATE_SELECTED_CONDITIONS',
          //     selectedConditions: next,
          //   });
          // }
          // getDoc(
          //   doc(getFirestore(), `storage/${user.uid}/conditions/${prev}`),
          // ).then((v) => {
          //   const data = v.data();
          //   deleteDoc(
          //     doc(getFirestore(), `storage/${user.uid}/conditions/${prev}`),
          //   );
          //   setDoc(
          //     doc(getFirestore(), `storage/${user.uid}/conditions/${next}`),
          //     data,
          //   );
          // });
        }}
      />
    </Box>
  );
}

export default ConditionsStorageContainer;
