import { useTranslation } from 'react-i18next';
import { VegaLite } from 'react-vega';
import { Price } from '~/api/screener';
import { intToLocaledString } from '~/utils/number';

function PriceChart({
  // currency,
  values,
}: {
  // currency: string;
  values: Array<Price>;
}) {
  const minClose = Math.min(...values.map((v) => v.close));
  // const maxOpen = Math.max(...chartValues.map((v) => v.open));
  const maxVolume = Math.max(...values.map((v) => v.volume));
  const minDate = values[0].date;
  const maxDate = values[values.length - 1].date;
  // currency;
  const minDateTime = {
    year: parseInt(minDate.split('-')[0], 10),
    month: parseInt(minDate.split('-')[1], 10),
    date: parseInt(minDate.split('-')[2], 10),
  };

  const maxDateTime = {
    year: parseInt(maxDate.split('-')[0], 10),
    month: parseInt(maxDate.split('-')[1], 10),
    date: parseInt(maxDate.split('-')[2], 10),
  };
  const [t] = useTranslation();
  return (
    <VegaLite
      style={{ width: '100%', height: '100%', marginTop: '8px' }}
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 'container',
        height: 300,
        autosize: 'fit',
        data: {
          values: values.map((v) => {
            return {
              open: v.open,
              close: v.close,
              high: v.high,
              low: v.low,
              volume: v.volume,
              date: v.date,
              day_yield: `${intToLocaledString((1 - v.open / v.close) * 100)}%`,
              localeVolume: `${intToLocaledString(v.volume)}`,
            };
          }),
        },
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },
        },
        transform: [
          {
            calculate: `((datum.volume/${maxVolume})*${minClose / 3})+${
              (minClose * 2) / 3
            }`,
            as: 'volume2',
          },
          // {
          //   calculate: `(1-(datum.open/datum.close))*100`,
          //   as: 'day_yield',
          // },
        ],
        encoding: {
          x: {
            field: 'date',
            type: 'quantitative',
            timeUnit: 'yearmonthdate',
            scale: {
              zero: false,
              domainMax: maxDateTime,
              domainMin: minDateTime,
            },
            axis: {
              format: '%Y.%m.%d',
              formatType: '1',
              title: null,
              gridColor: 'transparent',
            },
          },
        },
        layer: [
          {
            mark: {
              type: 'bar',
            },
            encoding: {
              color: {
                condition: {
                  test: 'datum.open < datum.close',
                  value: '#E6BDB8',
                },
                value: '#B5EAD0',
              },
              y: {
                field: 'volume2',
                type: 'quantitative',

                scale: {
                  // domainMin: minClose,
                },
                axis: {
                  title: null,
                  orient: 'right',
                },
              },
            },
          },
          {
            mark: {
              type: 'area',
              line: true,
              point: false,
            },
            encoding: {
              color: {
                value: '#A0C4EC88',
              },
              y: {
                type: 'quantitative',
                field: 'close',
                scale: {
                  zero: false,
                },
                axis: {
                  title: null,
                  orient: 'right',
                  domainColor: 'transparent',
                  tickColor: 'transparent',
                  // labelColor: themeData.colors.text2,
                  gridColor: 'transparent',
                },
              },
            },
          },
          {
            mark: 'rule',
            encoding: {
              opacity: {
                condition: { value: 0.3, param: 'hover', empty: false },
                value: 0,
              },
              tooltip: [
                {
                  field: 'date',
                  type: 'temporal',
                  title: t('text.date'),
                  format: '%Y-%m-%d',
                },
                {
                  field: 'open',
                  type: 'nominal',
                  title: t('text.openingPrice'),
                },
                {
                  field: 'close',
                  type: 'nominal',
                  title: t('text.closingPrice'),
                },
                {
                  field: 'day_yield',
                  type: 'nominal',
                  title: t('text.fluctuations'),
                },
                {
                  field: 'high',
                  type: 'nominal',
                  title: t('text.high'),
                },
                {
                  field: 'low',
                  type: 'nominal',
                  title: t('text.low'),
                },
                {
                  field: 'localeVolume',
                  type: 'ordinal',
                  title: t('text.volume'),
                },
              ],
            },
            params: [
              {
                name: 'hover',
                select: {
                  type: 'point',
                  fields: ['date'],
                  nearest: true,
                  on: 'mouseover',
                  clear: 'mouseout',
                },
              },
            ],
          },
        ],
      }}
    />
  );
}

export default PriceChart;
