import { AxiosInstance, AxiosResponse } from 'axios';
import { Profile } from './user';
import { Company, Strategy } from '~/api/strategy';
import {
  BacktestingRebalancingResult,
  BenchmarkInfo,
  YieldIndex,
} from '~/api/backtesting';

export interface Portfolio {
  id: string;
  uid: string;
  userId?: string;
  strategyId?: string;
  name?: string;
  modelAllocation: ModelAllocation;
  simulatingAssetSize?: number;
  yield?: number;
  mdd?: number;
  sharpeRatio?: number;
  isClosed: boolean;
  createdAt: Date;
  updatedAt: Date;
  closedAt?: Date;
  user?: Profile;
  strategy?: Strategy;
  account?: Account;
  dailyYield?: number;
  performanceUpdatedAt?: Date;
}

export type ModelAsset = {
  id: number;
  name: string;
  categoryId: number;
  ticker: string;
  value: number;
  currency: string;
  exchange?: string;
  ratio: number;
};

export const nationCurrencies: { [code: number]: string } = {
  840: 'USD',
  410: 'KRW',
};

export type ModelAllocationRatios = {
  [id: number]: ModelAsset;
};

export type ModelRebalancingLog = {
  value: number;
  ratios: ModelAllocationRatios;
  date: Date;
};

export type ModelAllocation = {
  version?: number;
  assets: ModelAsset[];
  rebalancingHistory?: ModelRebalancingLog[];
  passRebalancingDates?: Date[];
  recipe?: SimulationRecipe;
};

export type AssetRecipe = {
  asset: ModelAsset;
  size: number;
  count: number;
  price: number;
};

export type SimulationRecipe = {
  totalSize?: number;
  portfolio: AssetRecipe[];
  freeSize?: number;
};

export type Account = {
  id: string;
  uid: string;
  portfolioId: string;
  ownerName?: string;
  ownerEmail?: string;
  ownerPhoneNumber?: string;
  provider: string;
  providerKey?: string;
  providerSecret?: string;
  environment: 'real' | 'virtual';
  accountNumber: string;
  createdAt: Date;
  updatedAt: Date;
};

export type AssetBalance = {
  ticker: string;
  name: string;
  shares: number;
  orderableShares: number;
  averageFillPrice: number;
  fillAmount: number;
  currentPrice: number;
  evaluatedAmount: number;
  evaluatedComprehensiveIncomeAmount: number;
  evaluatedComprehensiveIncomePercent: number;
  currency: string;
  exchange: string;
};

export type AccountBalance = {
  currency: string;
  depositReceivedAmount: number;
  availableAmount: number;
  evaluatedAmount: number;
  evaluatedComprehensiveIncomeAmount: number;
  totalAmount: number;
  assets: AssetBalance[];
};

export type StockPrice = {
  exchange: string;
  marketName: string;
  current: number;
  market: number;
  high?: number;
  low?: number;
  maxLimit?: number;
  lowLimit?: number;
  standard?: number;
  transactionVolume: number;
  transactionAmount: number;
  yesterdayTransactionVolumeRate: number;
  unit?: number;
  tradingUnit?: number;
};

export type ExecutionStatus =
  | 'prepared'
  | 'failed'
  | 'open'
  | 'filled'
  | 'verified'
  | 'revised'
  | 'canceled'
  | 'rejected';

export type PositioningExecution = {
  orderType: 'buy' | 'sell';
  currency: string;
  exchange: string;
  ticker: string;
  name: string;
  baseShares: number;
  limitPrice: number | string;
  shares: number;
  orderNo: string | null;
  orderDate: string | null;
  orderTime: string | null;

  fillShares: number;
  fillAmount: number;
  remainingShares: number; // 잔여수량
  rejectShares: number; // 거부수량
  cancelShares: number; // 취소수량
  averageFillPrice: number;

  errorCode?: string;
  errorMessage?: string;

  status: ExecutionStatus; // 취소, 정정이 있을수 있다?
  executedAt: Date;
};

export type PositioningPhase =
  | 'simulating'
  | 'sellPlanning'
  | 'selling'
  | 'buyPlanning'
  | 'buying'
  | 'done';

export type Positioning = {
  id: string;
  uid: string;
  portfolioId: string;
  modelAssets?: ModelAsset[];
  realAssets?: AssetBalance[];
  executions?: PositioningExecution[];
  phase: PositioningPhase;
  createdAt: Date;
  updatedAt: Date;
};

export type StockOrder = {
  orderType: 'buy' | 'sell';
  ticker: string;
  name: string;
  baseShares: number;
  shares: number;
  limitPrice: number;
  exchange: string;
  currency: string;
};

export type OrderableState = {
  currency: string;
  testTicker?: string;
  maxOrderableCash: number;
  maxOrderableCashShares: number;
  maxOrderableAmount: number;
  maxOrderableShares: number;
};

export type CompanyInfo = {
  cosmosCode: number;
  cosmosGroupId: number;
  exchangeCode: number;
  ticker: string;
  companyName: string;
  companyLocalName: string;
  currency: string;
};

export type RebalancingStat = {
  date: Date;
  totalCompanyCount: number;
};
export type CompanyRebalancingInfo = {
  date: Date;
  cosmosCode: number;
  weight: number;
  score: number;
  marketDate: Date;
  marketCap: number;
  marketPrice: number;
  factorValueMap: Map<number, number>;
};

export type PortfolioBacktestingResult = {
  // portResult: Company[];
  // yieldResult: YieldIndex;
  bmIndexInfo: BenchmarkInfo;
  rebalancingResult: BacktestingRebalancingResult;
  // -> YieldResult
  yieldIndexResult: YieldIndex;
};

export const createService = (client: AxiosInstance) => {
  return {
    async portfolio(id: string): Promise<AxiosResponse<Portfolio>> {
      return client.get<Portfolio>(`/portfolio/${id}`);
    },
    async portfolios(): Promise<AxiosResponse<Portfolio[]>> {
      return client.get<Portfolio[]>('/portfolio');
    },
    async createPortfolio(form: {
      name: string;
    }): Promise<AxiosResponse<{ id: string; uid: string }>> {
      return client.post<{ id: string; uid: string }>('/portfolio', form);
    },
    async updatePortfolio(uid: string, form: { name?: string }) {
      return client.patch<{ updated: boolean }>(`/portfolio/${uid}`, form);
    },
    async closePortfolio(id: string) {
      return client.post<{ updated: boolean }>(`/portfolio/${id}/close`);
    },
    async applyStrategy(
      id: string,
      strategyId: string,
      assets?: ModelAsset[],
      recipe?: SimulationRecipe,
    ) {
      return client.post<{ modelAllocation: ModelAllocation }>(
        `/portfolio/${id}/apply-strategy`,
        {
          strategyId,
          assets,
          recipe,
        },
      );
    },
    async addRebalancingPassDate(id: string, date: Date) {
      return client.post<{ modelAllocation: ModelAllocation }>(
        `/portfolio/${id}/rebalancing/pass`,
        {
          date,
        },
      );
    },
    async detachStrategy(id: string) {
      return client.post<{ updated: boolean }>(
        `/portfolio/${id}/detach-strategy`,
      );
    },
    async updatePortfolioPerformance(id: string) {
      return client.post<{ updated: boolean }>(`/portfolio/${id}/performance`);
    },

    async linkAccount(
      portfolioId: string,
      info: {
        provider: string;
        environment?: Account['environment'];
        providerKey?: string;
        providerSecret?: string;
        accountNumber: string;
      },
    ) {
      return client.post<{ id: string }>(
        `/portfolio/${portfolioId}/link`,
        info,
      );
    },

    async unlinkAccount(portfolioId: string) {
      return client.delete(`/portfolio/${portfolioId}/link`);
    },

    async linkInfo(portfolioId: string) {
      return client.get<Account>(`/portfolio/${portfolioId}/link`);
    },

    async realBalance(portfolioId: string, currency?: string) {
      return client.get<AccountBalance>(
        `/portfolio/${portfolioId}/link/balance`,
        {
          params: {
            currency,
          },
        },
      );
    },
    async orderable(portfolioId: string, currency?: string) {
      return client.get<OrderableState>(
        `/portfolio/${portfolioId}/link/orderable`,
        {
          params: {
            currency,
          },
        },
      );
    },

    async realStockPrice(
      portfolioId: string,
      stocks: { exchange: string; ticker: string }[],
    ) {
      return client.post<{
        [ticker: string]: StockPrice;
      }>(`/portfolio/${portfolioId}/link/inquire-stock-prices`, stocks);
    },

    async latestPositioning(portfolioId: string) {
      return client.get<Positioning | null>(
        `/portfolio/${portfolioId}/positioning/latest`,
      );
    },

    async executedPositioningLogs(portfolioId: string) {
      return client.get<Positioning[]>(
        `/portfolio/${portfolioId}/positioning/executed`,
      );
    },

    async createPositioning(
      portfolioId: string,
      positioning: {
        currency: string;
        modelAssets?: ModelAsset[];
        phase?: string;
        orders?: StockOrder[];
      },
    ) {
      return client.post<{
        positioningId: string;
      }>(`/portfolio/${portfolioId}/positioning`, positioning);
    },

    async executePositioning(
      portfolioId: string,
      positioning: {
        orders: StockOrder[];
      },
    ) {
      return client.post<{
        positioningId: string;
        positioning: Positioning;
      }>(`/portfolio/${portfolioId}/positioning/execute`, {
        orders: positioning.orders,
      });
    },

    async cancelExecutions(portfolioId: string) {
      return client.post<{
        positioning: Positioning;
      }>(`/portfolio/${portfolioId}/positioning/cancel-executions`);
    },

    async syncPositioning(portfolioId: string, currency?: string) {
      return client.post<Positioning>(
        `/portfolio/${portfolioId}/positioning/sync`,
        {
          currency,
        },
      );
    },

    async changePositioningPhase(portfolioId: string, phase: PositioningPhase) {
      return client.put<Positioning>(
        `/portfolio/${portfolioId}/positioning/phase`,
        {
          phase,
        },
      );
    },

    async backtestingModelAllocationLogs(portfolioId: string) {
      return client.post<PortfolioBacktestingResult | null>(
        `/portfolio/${portfolioId}/model-allocation/backtesting`,
      );
    },
    async backtestingPositioningLogs(portfolioId: string) {
      return client.post<PortfolioBacktestingResult | null>(
        `/portfolio/${portfolioId}/positioning/backtesting`,
      );
    },
  };
};
