import { SearchIcon } from '@chakra-ui/icons';
import {
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Spacer,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ArrowRightOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';

import { useEffect, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import api from '~/api';
import { SearchCompanyResponse } from '~/api/screener';
import LoadingComponent from '~/components/LoadingComponent';
import StyledModal from '~/components/StyledModal';
import { Body2, Caption, H4 } from '~/components/Typography';
import { useDidUpdateEffect } from '~/hooks/useDidUpdateEffect';
import { getThemeData } from '~/utils/theme';

function SearchContainer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const themeData = getThemeData();
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();
  const [keyword, setKeyword] = useState<string>();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  function SearchButton() {
    return (
      <>
        <Box display={['none', 'inherit']}>
          <HStack
            border={`solid 1px ${themeData.colors.gray[200]}`}
            borderRadius="8px"
            p="8px 16px"
            w="100%"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => {
              onOpen();
              logEvent('searchbox initialized');
            }}
          >
            <Body2 color={themeData.colors.gray[300]}>
              {t('text.searchPlaceholer')}
            </Body2>
            <Spacer />
            <SearchIcon color={themeData.colors.gray[300]} />
          </HStack>
        </Box>
        <Box display={['inherit', 'none']} alignItems="end">
          <Spacer />
          <HStack
            marginRight="8px"
            border={`solid 1px ${themeData.colors.gray[300]}`}
            borderRadius="8px"
            p="8px 16px"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => {
              onOpen();
              logEvent('searchbox initialized');
            }}
          >
            <Body2 color={themeData.colors.gray[300]}>
              {t('text.searchPlaceholder')}
            </Body2>
            <SearchIcon />
          </HStack>
        </Box>
      </>
    );
  }

  useDidUpdateEffect(() => {
    setIsLoading(true);
    const update = setTimeout(() => {
      logEvent('company searched', { 'searchbar input value': value });
      setKeyword(value.replaceAll(/(^\s*)|(\s*$)/g, '').replaceAll('/', '.'));
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(update);
  }, [value]);

  return (
    <>
      <SearchButton />
      <StyledModal open={isOpen} onClose={onClose}>
        <Box
          sx={{
            padding: '24px',
            position: 'absolute',
            top: '10%',
            left: '50%',
            minWidth: ['80vw', '600px'],
            width: '50%',
            transform: 'translate(-50%, 0%)',
            background: 'white',
            borderRadius: '8px',
          }}
        >
          <InputGroup
            size="md"
            bg="white"
            p="8px"
            w="100%"
            border="solid 1px #e5e5e5"
            mb="16px"
          >
            <Input
              autoFocus
              w="100%"
              borderRadius="8px"
              _focusVisible={{ outline: 'none' }}
              value={value?.toString()}
              textColor={themeData.colors.text2}
              onChange={(evt) => {
                // setValue(
                //   evt.target.value.replaceAll(
                //     /([^(0-9)|(a-z)|(A-Z)])*/g,
                //     '',
                //   ),
                // );
                setValue(evt.target.value);
              }}
              placeholder={t('text.searchPlaceholer')}
            />
            <InputRightAddon pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputRightAddon>
          </InputGroup>

          {loading ? (
            <LoadingComponent height="100px" />
          ) : !keyword || keyword === '' ? (
            <div />
          ) : (
            <SearchResult keyword={keyword} onClose={onClose} />
          )}
        </Box>
      </StyledModal>
    </>
  );
}

function SearchResult({
  keyword,
  onClose,
}: {
  keyword: string;
  onClose: () => void;
}) {
  const [searchResult, setSearchResult] = useState<SearchCompanyResponse>();

  useEffect(() => {
    api.screener.getCompany(keyword).then((v) => setSearchResult(v.data));
  }, [keyword]);

  if (!searchResult) return <LoadingComponent height="100px" />;
  return (
    <Box
      sx={{
        marginBottom: '8px',
        width: '100%',
      }}
    >
      {searchResult.map((v) => (
        <SearchResultItem
          companyName={
            v.companyLocalName && v.companyLocalName !== ''
              ? v.companyLocalName
              : v.companyName
          }
          ticker={v.ticker}
          cosmosCode={v.cosmosCode}
          onClose={onClose}
          highlighted={keyword}
        />
      ))}
    </Box>
  );
}

function SearchResultItem({
  companyName,
  ticker,
  cosmosCode,
  onClose,
  highlighted,
}: {
  companyName: string;
  ticker: string;
  cosmosCode: number;
  highlighted: string;
  onClose: () => void;
}) {
  const themeData = getThemeData();

  const highlightedText = ({
    text,
    query,
  }: {
    text: string;
    query: string;
  }) => {
    const split = query.split(' ').map((e) => e.toLowerCase());
    const parts = (text ?? '').split(new RegExp(`(${split.join('|')})`, 'gi'));

    return (
      <>
        {parts.map((part) =>
          split.includes(part.toLowerCase()) ? (
            <mark
              style={{
                backgroundColor: themeData.colors.primary[300],
              }}
            >
              {part}
            </mark>
          ) : (
            part
          ),
        )}
      </>
    );
  };
  const history = useHistory();
  const { amplitudeInstance, logEvent } = useAmplitude();

  return (
    <Box
      sx={{
        padding: '12px',
        marginBottom: '8px',
        borderRadius: '12px',
        border: `solid 1px ${themeData.colors.gray[200]}`,
        backgroundColor: themeData.colors.gray[100],
        // _hover:{{
        //   backgroundColor: themeData.colors.primary[500],
        //   cursor: 'pointer',
        // }}
      }}
      onClick={() => {
        logEvent('searchbox results clicked', {
          'clicked company': ticker,
        });
        // addCriticalActionsCountAmplitude({ amplitudeInstance });
        onClose();
        // history.push(`/company-detail/${cosmosCode}`);

        window.open(
          `/company-detail/${cosmosCode}`,
          `company-detail-${cosmosCode}`,
        );
      }}
    >
      <HStack>
        <VStack align="start">
          <Caption color={themeData.colors.text2}>
            {highlightedText({ text: ticker, query: highlighted })}
          </Caption>
          <H4>{highlightedText({ text: companyName, query: highlighted })}</H4>
        </VStack>
        <Spacer />
        <ArrowRightOutlined />
      </HStack>
    </Box>
  );
}
export default SearchContainer;
