import { useContext } from 'react';
import { Center, Flex, Spacer } from '@chakra-ui/layout';
import { Redirect, useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Box, IconButton } from '@mui/material';

import { H1, H2, H5 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { AppContext } from '~/AppContext';
import { PortfolioBacktestingResult } from '~/api/portfolio';
import BackTestResultContainer from '~/containers/backtest_result/BackTestResultContainer';
import StyledButton from '~/components/StyledButton';
import { PostBacktestingBody, SortData } from '~/api/screener';
import { QueryFactor } from '~/api/backtesting';
import { ReactComponent as ShareIcon } from '~/assets/icon/icon_share_link.svg';

type ParamTypes = {
  requestKey: string;
};
function Header() {
  const history = useHistory<{ query?: string }>();
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Flex
      style={{
        display: 'sticky',
        width: '100%',
        borderBottom: 'solid 1px #e5e5e5',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      padding={['12px 24px', '24px 24px']}
    >
      <Flex flexDirection="column">
        <H2 bold>{t('text.backtestResult')}</H2>
      </Flex>
      <Spacer />
      <StyledButton
        variant="outlined"
        sx={{
          display: ['none', 'inherit'],
          height: '100%',
          width: 'fit-content',
          px: '16px',
          m: '8px',
          borderRadius: '8px',
        }}
        onClick={() => {
          if (history.action === 'POP') {
            history.replace('/backtest');
          } else if (
            history.action === 'PUSH' ||
            history.action === 'REPLACE'
          ) {
            history.goBack();
          }
        }}
      >
        {t('text.backButtonTitle')}
      </StyledButton>
      <IconButton
        size="medium"
        sx={{
          height: '100%',
          border: '1px solid #D9DCE0',
          borderRadius: '4px',
        }}
        onClick={() => {
          if (history.location.state.query) {
            navigator.clipboard
              .writeText(
                `${window.location.origin}/backtest?${history.location.state.query}`,
              )
              .finally(() => {
                alert('URL 링크가 복사되었습니다.');
              });
          }
        }}
      >
        <ShareIcon />
      </IconButton>
    </Flex>
  );
}

function BacktestResultView() {
  const [t] = useTranslation();
  const history = useHistory();

  const { location } = useHistory<{
    request: PostBacktestingBody;
    result: PortfolioBacktestingResult;
    nationCode: number;
    orders: Array<SortData>;
    conditions: Array<QueryFactor>;
    query?: string;
  }>();

  if (!location?.state?.result) return <Redirect to="/backtest" />;

  const themeData = getThemeData();
  return (
    <Box
      sx={{
        width: '100%',
        height: ['-webkit-fill-available', '100%'],
        overflow: 'hidden',
      }}
    >
      <Header />
      <Box
        sx={{
          height: ['calc(100vh - 216px)', 'calc(100vh - 160px)'],
          padding: ['0px', '24px'],
          background: themeData.colors.gray[100],
        }}
      >
        <BackTestResultContainer
          request={location?.state?.request}
          result={location?.state?.result}
          nationCode={location?.state?.nationCode}
          orders={location?.state?.orders}
          conditions={location?.state?.conditions}
        />
      </Box>
      <Box
        position="fixed"
        bottom="0px"
        left="0px"
        right="0px"
        m="8px"
        display={['inherit', 'none']}
        bgcolor="white"
      >
        <StyledButton
          variant="outlined"
          sx={{
            height: '100%',
            width: '100%',
            px: '16px',
            borderRadius: '8px',
          }}
          onClick={() => {
            if (history.action === 'POP') {
              history.replace('/backtest');
            } else if (
              history.action === 'PUSH' ||
              history.action === 'REPLACE'
            ) {
              history.goBack();
            }
          }}
        >
          {t('text.backButtonTitle')}
        </StyledButton>
      </Box>
    </Box>
  );
}
export default BacktestResultView;
