import { Box } from '@mui/material';
import FactorControl from './FactorControl';
import { FactorHistogramItemProps } from './types';

function FactorHistogramItem({
  factor,
  onUpdateRange,
  factorHistogram,
  value,
  onDelete,
}: FactorHistogramItemProps) {
  return (
    <Box
      sx={{ animation: 'smoothAppear .5s' }}
      key={`key-${factor.id}-${factor.isAbsolute}`}
    >
      <FactorControl
        factor={factor}
        factorHistogram={factorHistogram}
        onUpdateRange={onUpdateRange}
        value={value}
        onDelete={onDelete}
      />
    </Box>
  );
}

export default FactorHistogramItem;
