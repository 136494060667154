import { createContext, Dispatch } from 'react';
import { set } from '~/utils/localStorage';

/*
BACKTEST STATEMENT
*/
export type BackTestState = {
  startDate: Date;
  endDate: Date;
  tradeCost: number;
  top: number;
  rebalancingPeriod: string;
};

export type BackTestAction =
  | { type: 'UPDATE_START_DATE'; date: Date }
  | { type: 'UPDATE_END_DATE'; date: Date }
  | { type: 'UPDATE_TRADE_COST'; tradeCost: number }
  | { type: 'UPDATE_TOP'; top: number }
  | { type: 'UPDATE_REBALANCING_PERIOD'; rebalancingPeriod: string }
  | {
      type: 'UPDATE_BACKTEST_VALUES';
      startDate: Date;
      endDate: Date;
      tradeCost: number;
      top: number;
      rebalancingPeriod: string;
    };

function BackTestReducer(
  state: BackTestState,
  action: BackTestAction,
): BackTestState {
  let data: BackTestState;

  switch (action.type) {
    case 'UPDATE_START_DATE':
      data = { ...state, startDate: action.date };
      break;
    case 'UPDATE_END_DATE':
      data = { ...state, endDate: action.date };
      break;
    case 'UPDATE_TRADE_COST':
      data = { ...state, tradeCost: action.tradeCost };
      break;
    case 'UPDATE_TOP':
      data = { ...state, top: action.top };
      break;
    case 'UPDATE_REBALANCING_PERIOD':
      data = { ...state, rebalancingPeriod: action.rebalancingPeriod };
      break;

    case 'UPDATE_BACKTEST_VALUES':
      data = {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        tradeCost: action.tradeCost,
        top: action.top,
        rebalancingPeriod: action.rebalancingPeriod,
      };
      break;

    default:
      data = { ...state };
  }
  if (data.startDate) {
    // @ts-ignore
    set('BACKTEST_START_DATETIME', JSON.stringify(data.startDate.getTime()));
  }

  if (data.endDate) {
    // @ts-ignore
    set('BACKTEST_END_DATETIME', JSON.stringify(data.endDate.getTime()));
  }

  if (data.tradeCost !== undefined) {
    // @ts-ignore
    set('BACKTEST_TRADE_COST', JSON.stringify(data.tradeCost));
  }

  if (data.top) {
    // @ts-ignore
    set('BACKTEST_TOP', JSON.stringify(data.top));
  }

  if (data.rebalancingPeriod) {
    // @ts-ignore
    set('BACKTEST_REBALANCINGPERIOD', data.rebalancingPeriod);
  }

  return data;
}

type BackTestDispatch = Dispatch<BackTestAction>;
const BackTestDispatchContext = createContext<BackTestDispatch | null>(null);
const BackTestStateContext = createContext<BackTestState | null>(null);

export { BackTestReducer, BackTestStateContext, BackTestDispatchContext };
