import {
  Box,
  Button,
  Checkbox,
  HStack,
  Spacer,
  useDisclosure,
  Wrap,
  WrapItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  AccordionPanel,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  VStack,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  CloseIcon,
} from '~/components/Icons';
import StyledModal from '~/components/StyledModal';
import { Body1, Body2, Caption, H1 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { FactorDispatchContext, FactorStateContext } from './context';

function SectorSelect() {
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const themeData = getThemeData();
  const [t] = useTranslation();

  if (!factorState?.companyGroupList) return <div />;

  const unselectedGroups = factorState?.unselectedGroups;
  const allGroups = Array.from(factorState?.companyGroupList.entries() ?? [])
    .map(([key, value]) => {
      if (value.length === 0) {
        return [];
      }
      if (value.length === 1) {
        return [key];
      }
      return value;
    })
    .flatMap((v) => v);
  const selectGroups = allGroups.filter(
    (v) => !factorState?.unselectedGroups.has(v.cosmosGroupId),
  );

  const isAllChecked = unselectedGroups.size === 0;
  const isAllIndeterminate = selectGroups.length > 0 && !isAllChecked;
  return (
    <Box>
      <VStack w="100%" align="start">
        <HStack w="100%">
          <Caption color={themeData.colors.gray[400]}>
            {t('text.sector')}
          </Caption>
          <Caption
            color={themeData.colors.primary[700]}
          >{`(${selectGroups.length})`}</Caption>
        </HStack>
        <Button
          w="100%"
          padding="8px"
          bg="white"
          justifyContent="start"
          style={{
            border: `solid 1px ${themeData.colors.primary[900]}`,
            borderRadius: 8,
            color: themeData.colors.text2,
            fontSize: themeData.typography.h5.fontSize,
          }}
          onClick={() => {
            onOpen();
          }}
        >
          <Body2>
            {/* eslint-disable-next-line no-nested-ternary */}
            {unselectedGroups.size === 0
              ? t('text.allSelected')
              : selectGroups.length === 0
              ? t('text.noneSelected')
              : selectGroups
                  // @ts-ignore
                  .map((v) => t(`category.${v.name}`, v.name))
                  .join(', ')}
          </Body2>
        </Button>
      </VStack>

      <StyledModal
        open={isOpen}
        onClose={onClose}
        sx={{ alignItems: 'center' }}
      >
        <Box
          style={{
            padding: '24px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            minWidth: '400px',
            maxWidth: '100wh',
            width: '30%',
            transform: 'translate(-50%, -50%)',
          }}
          background="white"
        >
          <HStack fontWeight="bold">
            <H1>{t('text.selectSector')}</H1>
            <Spacer />
            <Button color={themeData.colors.gray[300]} onClick={onClose}>
              <CloseIcon />
            </Button>
          </HStack>

          <HStack w="100%">
            <Spacer />
            <Checkbox
              colorScheme="primary"
              icon={
                isAllChecked ? (
                  <CheckBoxIcon sx={{ color: themeData.colors.primary[500] }} />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    sx={{ color: themeData.colors.primary[500] }}
                  />
                )
              }
              onChange={(e) => {
                if (!e.target.checked) {
                  factorDispatch?.({
                    type: 'SET_ALL_GROUPS',
                  });
                } else {
                  factorDispatch?.({
                    type: 'CLEAR_UNSELECTED_GROUPS',
                  });
                }
              }}
              isIndeterminate={isAllIndeterminate}
              isChecked={isAllChecked}
            >
              <Caption color={themeData.colors.text2}>
                {t('text.checkAll')}
              </Caption>
            </Checkbox>
          </HStack>
          <Box>
            {[...factorState.companyGroupList.entries()].map(([key, value]) => {
              if (value.length === 0) {
                return <div key={key.cosmosGroupId} />;
              }
              if (value.length === 1) {
                return (
                  <Box key={key.cosmosGroupId} mb="0px" p="8px">
                    <Checkbox
                      colorScheme="primary"
                      icon={
                        !unselectedGroups.has(key.cosmosGroupId) ? (
                          <CheckBoxIcon
                            sx={{ color: themeData.colors.primary[500] }}
                          />
                        ) : (
                          <CheckBoxOutlineBlankIcon
                            sx={{ color: themeData.colors.primary[500] }}
                          />
                        )
                      }
                      isChecked={!unselectedGroups.has(key.cosmosGroupId)}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          factorDispatch?.({
                            type: 'ADD_COMPANY_GROUP',
                            cosmosGroupId: key.cosmosGroupId,
                          });
                        } else {
                          factorDispatch?.({
                            type: 'REMOVE_COMPANY_GROUP',
                            cosmosGroupId: key.cosmosGroupId,
                          });
                        }
                      }}
                    >
                      <Body1 noOfLines={2}>
                        {/* @ts-ignore */}
                        {t(`category.${key.name}`, key.name)}
                      </Body1>
                    </Checkbox>
                  </Box>
                );
              }
              const isChecked = value.every(
                (v) => !unselectedGroups.has(v.cosmosGroupId),
              );
              const isIndeterminate =
                value.some((v) => !unselectedGroups.has(v.cosmosGroupId)) &&
                !isChecked;

              return (
                <Accordion
                  key={key.cosmosGroupId}
                  allowMultiple
                  mb="16px"
                  mt="16px"
                >
                  <AccordionItem border="none" padding="0px">
                    <AccordionButton
                      color="black"
                      bg="white"
                      p="0px 8px"
                      dir="column"
                      style={{
                        boxShadow: 'none',
                      }}
                    >
                      <Checkbox
                        mr="8px"
                        colorScheme="primary"
                        isChecked={isChecked}
                        icon={
                          isChecked ? (
                            <CheckBoxIcon
                              sx={{ color: themeData.colors.primary[500] }}
                            />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              sx={{ color: themeData.colors.primary[500] }}
                            />
                          )
                        }
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            value.forEach((v) => {
                              factorDispatch?.({
                                type: 'ADD_COMPANY_GROUP',
                                cosmosGroupId: v.cosmosGroupId,
                              });
                            });
                          } else {
                            value.forEach((v) => {
                              factorDispatch?.({
                                type: 'REMOVE_COMPANY_GROUP',
                                cosmosGroupId: v.cosmosGroupId,
                              });
                            });
                          }
                        }}
                      />
                      <Body1 noOfLines={2}>
                        {/* @ts-ignore */}
                        {t(`category.${key.name}`, key.name)}
                      </Body1>
                      <Spacer />
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel p="8px 16px">
                      <Wrap mt="8px">
                        {value.map((group) => {
                          return (
                            <WrapItem key={group.cosmosGroupId}>
                              <Checkbox
                                colorScheme="primary"
                                icon={
                                  !unselectedGroups.has(group.cosmosGroupId) ? (
                                    <CheckBoxIcon
                                      sx={{
                                        color: themeData.colors.primary[500],
                                      }}
                                    />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon
                                      sx={{
                                        color: themeData.colors.primary[500],
                                      }}
                                    />
                                  )
                                }
                                isChecked={
                                  !unselectedGroups.has(group.cosmosGroupId)
                                }
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    factorDispatch?.({
                                      type: 'ADD_COMPANY_GROUP',
                                      cosmosGroupId: group.cosmosGroupId,
                                    });
                                  } else {
                                    factorDispatch?.({
                                      type: 'REMOVE_COMPANY_GROUP',
                                      cosmosGroupId: group.cosmosGroupId,
                                    });
                                  }
                                }}
                              >
                                <Caption noOfLines={2}>
                                  {/* @ts-ignore */}
                                  {t(`category.${group.name}`, group.name)}
                                </Caption>
                              </Checkbox>
                            </WrapItem>
                          );
                        })}
                      </Wrap>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </Box>
          {/* </ModalBody> */}
          {/* </ModalContent> */}
        </Box>
      </StyledModal>
    </Box>
  );
}
export default SectorSelect;
