import React, { useContext, useMemo } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import migrationLogo from '~/assets/icon/migration_logo.svg';
import { AppContext } from '~/AppContext';

export default function MigrationFailView() {
  const { state } = useContext(AppContext) ?? {};
  const { state: locationState, search } = useLocation<{
    targetAccount?: string;
  }>();

  const targetAccount = useMemo(() => {
    const query = new URLSearchParams(search);

    return locationState?.targetAccount ?? query.get('targetAccount');
  }, [search, locationState?.targetAccount]);

  const message = useMemo(() => {
    return [
      'To : support@tailor.im',
      `테일러에 연동에 실패했습니다. 아래 내용을 확인해주세요.`,
      '',
      `- 올라떼 계정의 메일주소${targetAccount ? ` : ${targetAccount}` : ''}`,
      `- 연동 하기 위해 선택했던 메일주소${
        state?.user?.email ? ` : ${state?.user?.email}` : ''
      }`,
    ].join(`\n`);
  }, [state, targetAccount]);

  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      bgcolor="background.grey"
    >
      <Helmet>
        <meta content="#FFFFFF" name="theme-color" />
        <meta content="#FFFFFF" name="msapplication-navbutton-color" />
        <meta content="#FFFFFF" name="apple-mobile-web-app-status-bar-style" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
      </Helmet>
      <Stack
        width="100%"
        bgcolor="#FFFFFF"
        sx={{
          flex: [1, 0],
          maxWidth: [undefined, '758px'],
          p: [0, '100px'],
          border: [null, '1px solid rgba(0, 0, 0, 0.08)'],
          borderRadius: [0, '20px'],
        }}
      >
        <Stack
          sx={{
            flex: [1, 0],
            pt: ['20px', 0],
            px: ['20px', 0],
            overflow: ['auto', 'unset'],
          }}
        >
          <img alt="logo" src={migrationLogo} width="244px" />
          <Typography
            mt="52px"
            variant="headline1"
            fontSize="24px"
            whiteSpace="pre-wrap"
          >
            테일러에 연동에 실패했습니다.
            <br />
            아래 내용을 확인해주세요.
          </Typography>
          <Typography variant="body1" whiteSpace="pre-wrap" mt="32px">
            다시 한번 시도해보신 후, 이 메시지가 반복 된다면 아래 두가지 내용을
            <br />
            support@tailor.im으로 보내주세요.
          </Typography>
          <Stack mt="20px" p="16px" bgcolor="background.grey" spacing="4px">
            <Typography
              variant="body1"
              color="text.secondary"
              whiteSpace="pre-wrap"
            >
              {message}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          justifyContent="end"
          mt={['10px', '40px']}
          p={['23px', 0]}
          boxShadow={['inset 0px 1px 0px rgba(0, 0, 0, 0.05);', undefined]}
        >
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{
              height: ['52px', '48px'],
            }}
            onClick={() => {
              navigator.clipboard.writeText(message);
            }}
          >
            텍스트 내용 복사하기
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
