import { AxiosResponse } from 'axios';
import {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import api from '~/api';
import {
  CompanyGroupListResponse,
  Factor,
  FactorCategory,
} from '~/api/screener';
import { FactorControlValue } from '~/components/factor/types';
import {
  screenerSortData,
  ScreenerSortType,
} from '~/containers/screener/ScreenerSortSelector';
import useFetch from '~/hooks/useFetch';
import { get } from '~/utils/localStorage';
import { AppContext } from '~/AppContext';

import { FactorDispatchContext, FactorReducer, FactorStateContext } from '.';

export function FactorContextProviderWrapper({
  children,
}: {
  children: ReactElement;
}) {
  const { state } = useContext(AppContext) ?? {};

  const [factorCategoryListState, setFactorCategoryListState] =
    useState<Array<FactorCategory>>();
  const [factorListState, setFactorListState] = useState<Array<Factor>>();

  const [companyGroupListState, setCompanyGroupListState] =
    useState<Map<CompanyGroupListResponse, Array<CompanyGroupListResponse>>>();

  useEffect(() => {
    if (state?.user) {
      api.screener.getFactorCategory().then((v) => {
        setFactorCategoryListState(v.data);
      });

      api.screener.getFactor().then((v) => {
        setFactorListState(v.data);
      });

      const f = async () => {
        const result = new Map<
          CompanyGroupListResponse,
          Array<CompanyGroupListResponse>
        >();
        const lv1Result: AxiosResponse<Array<CompanyGroupListResponse>> =
          await api.screener.getCategory({ level: 1 });
        const lv2Result: AxiosResponse<Array<CompanyGroupListResponse>> =
          await api.screener.getCategory({ level: 2 });

        lv1Result.data.forEach((v) => {
          // ex v.cosmosGroupId = 71

          result.set(v, []);
        });

        lv2Result.data.forEach((v) => {
          result.forEach((_, lv1K) => {
            if (Math.floor(v.cosmosGroupId / 100) === lv1K.cosmosGroupId) {
              result.get(lv1K)?.push(v);
            }
          });
        });

        setCompanyGroupListState(result);
      };
      f();
    }
  }, [state?.user]);

  const [factorState, factorDispatch] = useReducer(FactorReducer, {
    factorList: [],
    factorCategoryList: [],
    companyGroupList: new Map(),
    unselectedGroups: new Set<number>(
      // @ts-ignore
      JSON.parse(get('UNSELECTED_GROUPS', { ignoreEmpty: true }) ?? '[]'),
    ),
    selectedFactors: new Map<Factor, [FactorControlValue, FactorControlValue]>(
      JSON.parse(get('selectedFactors', { ignoreEmpty: true }) ?? '[]'),
    ),

    selectedNation: JSON.parse(
      get('selectedNation', {
        ignoreEmpty: true,
      }) ?? '{"code": 840,"name": "UNITED STATES"}',
    ),
    sortData: JSON.parse(
      get('selectedSortOption', { ignoreEmpty: true }) ??
        JSON.stringify([screenerSortData[0]]),
    ) as Array<ScreenerSortType>,
    selectedConditions: '',
    isEditCurrentConditions: true,
    myConditionsCount: 0,
    strategies: [],
  });

  useEffect(() => {
    factorDispatch({
      type: 'UPDATE_FACTOR_CATEGORY_LIST',
      factorCategoryList: factorCategoryListState ?? [],
    });
  }, [factorCategoryListState]);

  useEffect(() => {
    factorDispatch({
      type: 'UPDATE_FACTOR_LIST',
      factorList: factorListState ?? [],
    });
  }, [factorListState]);

  useEffect(() => {
    factorDispatch({
      type: 'UPDATE_COMPANY_GROUP_LIST',
      companyGroupList: companyGroupListState ?? new Map(),
    });
  }, [companyGroupListState]);

  return (
    <FactorStateContext.Provider value={factorState}>
      <FactorDispatchContext.Provider value={factorDispatch}>
        {children}
      </FactorDispatchContext.Provider>
    </FactorStateContext.Provider>
  );
}
