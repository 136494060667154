import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  StackProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isNumber, map, uniqBy } from 'lodash';
import { Tooltip } from '@chakra-ui/react';
import { styled } from '@mui/material/styles';
import { Download } from '@mui/icons-material';
import { Spacer } from '@chakra-ui/layout';

import dayjs from '~/utils/dayjs';
import {
  Backtesting,
  BacktestingComapnyRebalancingInfo,
  YieldBenchmarkSnapshot,
} from '~/api/backtesting';
import { Company } from '~/api/strategy';
import { ReactComponent as RebalancingIcon } from '~/assets/icon/icon_rebalancing.svg';

// const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip
//     {...props}
//     classes={{ popper: className }}
//     enterDelay={0}
//     enterTouchDelay={0}
//     disableInteractive
//   />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     // maxWidth: '50vw',
//     backgroundColor: 'white',
//     color: 'black',
//     border: '1px solid rgba(0, 0, 0, 0.08)',
//     borderRadius: '8px',
//   },
// });

type MonthlyPortfolio = {
  [year: number]: {
    [month: number]: YieldBenchmarkSnapshot;
  };
};

export type MonthlyYieldListProps = {
  backtestingResult: Backtesting;
  displayingAllYears?: boolean;
  onDownloadXlsx?: (backtesting: Backtesting) => void;
};

export default function MonthlyYieldList({
  backtestingResult,
  displayingAllYears,
  onDownloadXlsx,
  ...stackProps
}: MonthlyYieldListProps & StackProps) {
  const [t] = useTranslation();
  const [selectedYear, setSelectedYear] = useState<number>(
    dayjs(
      backtestingResult?.rebalancingResult?.companyRebalancingInfos?.[0]?.date,
    ).year(),
  );

  const benchmarkName = useMemo(() => {
    return backtestingResult?.benchmarkInfo?.name;
  }, [backtestingResult?.benchmarkInfo?.name]);

  useEffect(() => {
    setSelectedYear(
      dayjs(
        backtestingResult?.rebalancingResult?.companyRebalancingInfos?.[0]
          ?.date,
      ).year(),
    );
  }, [backtestingResult]);

  const monthlyYieldTable = useMemo(() => {
    return (
      backtestingResult?.yieldIndex?.portBMYieldIndexes?.reduce?.(
        (r, result) => {
          const date = dayjs(result.endDate);

          const y = date.get('year');
          const m = date.get('month');
          if (!r?.[y]) {
            r[y] = {};
          }
          r[y][m + 1] = result;
          return r;
        },
        {} as MonthlyPortfolio,
      ) ?? ({} as MonthlyPortfolio)
    );
  }, [backtestingResult]);

  const createSnapshotTooltips = (
    snapshots?: (Company & BacktestingComapnyRebalancingInfo)[],
  ) => {
    return snapshots?.length === 0 ? (
      <div />
    ) : (
      <Tooltip
        label={
          <Box
            bgcolor="white"
            border="1px solid #f0f0f0"
            borderRadius="4px"
            p="4px"
          >
            <Typography pb="4px" textAlign="center" fontWeight="bold">
              {t('text.target_ratio')}
            </Typography>

            {snapshots?.slice(0, Math.min(snapshots.length, 5)).map((v) => {
              return (
                <Stack
                  key={v.cosmosCode}
                  direction="row"
                  display="flex"
                  flex={1}
                >
                  <Box flex={1} px="4px">
                    <Typography noWrap>
                      {v.companyLocalName && v.companyLocalName.length > 0
                        ? v.companyLocalName
                        : v.companyName}
                    </Typography>
                  </Box>
                  <Box px="4px">
                    <Typography textAlign="right">
                      {((v?.weight ?? 0) * 100).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                      %
                    </Typography>
                  </Box>
                </Stack>
              );
            })}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <RebalancingIcon />
      </Tooltip>
    );
  };

  const partialTable = useMemo(() => {
    if (displayingAllYears) {
      return null;
    }

    const selectedMonths = monthlyYieldTable[selectedYear];

    return (
      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow
            sx={{
              '> th': {
                backgroundColor: (theme) => theme.palette.background.grey,
                borderBottom: 0,
                ':first-child': {
                  borderLeftRadius: '8px',
                },
                ':last-child': {
                  borderRightRadius: '8px',
                },
              },
            }}
          >
            <TableCell sx={{ typography: 'subtitle2' }}>
              {t('text.period')}
            </TableCell>
            <TableCell sx={{ typography: 'subtitle2' }} align="right">
              {t('text.my_investments')}
            </TableCell>
            <TableCell sx={{ typography: 'subtitle2' }} align="right">
              {benchmarkName ? t(`benchmark.${benchmarkName}`) : '벤치마크'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(selectedMonths, (month, key) => {
            const currentPortfolio =
              backtestingResult?.rebalancingResult?.companyRebalancingInfos
                ?.filter?.((v) => {
                  const day = dayjs(v.date);

                  return (
                    day.year() === selectedYear &&
                    (day.month() + 2).toString() === key
                  );
                })
                ?.map((rebalancing) => {
                  const company =
                    backtestingResult.rebalancingResult?.companyInfos.find(
                      (c) => c.cosmosCode === rebalancing.cosmosCode,
                    );
                  return {
                    ...rebalancing,
                    ...company,
                  } as Company & BacktestingComapnyRebalancingInfo;
                });
            return (
              <TableRow key={month.endDate}>
                <TableCell sx={{ typography: 'body2' }}>
                  {dayjs(month.endDate).format('YYYY-MM')}
                </TableCell>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="end"
                    spacing="4px"
                  >
                    <Typography variant="subtitle1" color="secondary.main">
                      {((month?.portYield ?? 0) * 100).toFixed(2)}%
                    </Typography>
                    {createSnapshotTooltips(currentPortfolio)}
                  </Stack>
                </TableCell>
                <TableCell align="right" sx={{ typography: 'subtitle1' }}>
                  {((month?.bmYield ?? 0) * 100).toFixed(2)}%
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }, [selectedYear, benchmarkName, displayingAllYears, monthlyYieldTable]);

  const allTable = useMemo(() => {
    if (!displayingAllYears) {
      return null;
    }

    return Object.keys(monthlyYieldTable).map((year) => {
      const months = monthlyYieldTable[Number(year)];
      return (
        <Box
          key={year}
          width="100%"
          sx={{
            borderRadius: '8px',
          }}
          overflow="auto"
        >
          <Stack direction="column" width="fit-content">
            <Stack direction="row">
              <Typography
                variant="subtitle1"
                sx={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#FAFAFA',
                  borderBottom: '2px solid #FFF',
                  borderRight: '2px solid #FFF',
                }}
                borderRadius="8px"
                py="12px"
                px="16px"
                width="128px"
              >
                {t('text.period')}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: '#FAFAFA',
                  borderBottom: '2px solid #FFF',
                }}
                borderRadius="8px"
              >
                {Object.keys(months).map((month) => {
                  return (
                    <Typography
                      key={month}
                      variant="body1"
                      py="12px"
                      px="16px"
                      width="128px"
                    >
                      {dayjs(`${year}-${month}`).format('YYYY-MM')}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
            <Stack direction="row">
              <Typography
                variant="subtitle1"
                sx={{
                  whiteSpace: 'nowrap',
                  backgroundColor: 'rgba(158, 125, 249, 0.08);',
                  borderBottom: '3px solid #FFF',
                  borderRight: '3px solid #FFF',
                }}
                borderRadius="8px"
                py="12px"
                px="16px"
                width="128px"
              >
                {t('text.my_investments')}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: 'rgba(158, 125, 249, 0.08);',
                  borderBottom: '3px solid #FFF',
                }}
                borderRadius="8px"
              >
                {map(months, (month, key) => {
                  const currentPortfolio =
                    backtestingResult?.rebalancingResult?.companyRebalancingInfos
                      ?.filter?.((v) => {
                        const day = dayjs(v.date);

                        return (
                          day.year().toString() === year &&
                          (day.month() + 2).toString() === key
                        );
                      })
                      ?.map((rebalancing) => {
                        const company =
                          backtestingResult.rebalancingResult?.companyInfos.find(
                            (c) => c.cosmosCode === rebalancing.cosmosCode,
                          );
                        return {
                          ...rebalancing,
                          ...company,
                        } as Company & BacktestingComapnyRebalancingInfo;
                      });

                  return (
                    <Stack
                      key={key}
                      direction="row"
                      py="12px"
                      px="16px"
                      alignItems="center"
                      justifyContent="center"
                      width="128px"
                      spacing="4px"
                    >
                      <Typography key={month.endDate} variant="subtitle1">
                        {`${((month?.portYield ?? 0) * 100).toFixed(2)}%`}
                      </Typography>
                      {createSnapshotTooltips(currentPortfolio)}
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
            <Stack direction="row">
              <Typography
                variant="subtitle1"
                sx={{
                  whiteSpace: 'nowrap',
                  backgroundColor: 'rgba(217, 220, 224, 0.08);',
                  borderBottom: '3px solid #FFF',
                  borderRight: '3px solid #FFF',
                }}
                borderRadius="8px"
                py="12px"
                px="16px"
                width="128px"
              >
                {benchmarkName ? t(`benchmark.${benchmarkName}`) : '벤치마크'}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderBottom: '3px solid #FFF',
                }}
                borderRadius="8px"
              >
                {map(months, (month, key) => {
                  return (
                    <Stack
                      key={month.startDate}
                      direction="row"
                      py="12px"
                      px="16px"
                      justifyContent="center"
                      width="128px"
                      sx={{
                        backgroundColor: 'rgba(217, 220, 224, 0.08);',
                      }}
                    >
                      <Typography key={month.endDate} variant="subtitle1">{`${(
                        (month?.bmYield ?? 0) * 100
                      ).toFixed(2)}%`}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      );
    });
  }, [displayingAllYears, benchmarkName, monthlyYieldTable]);

  const yearList = useMemo(() => {
    return uniqBy(
      backtestingResult?.rebalancingResult?.companyRebalancingInfos,
      (item) => dayjs(item.date).year(),
    )?.map((item) => {
      const year = dayjs(item.date).year();
      return (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      );
    });
  }, [backtestingResult?.rebalancingResult?.companyRebalancingInfos]);

  return (
    <Stack direction="column" width="100%" spacing="24px" {...stackProps}>
      <Stack spacing="16px">
        <Stack direction="row" alignItems="center">
          <Typography flex={1} variant="h5" fontWeight="bold">
            {t('text.performanceByMonthly')}
          </Typography>
          {displayingAllYears ? (
            <Button
              size="small"
              variant="outlined"
              startIcon={<Download />}
              onClick={() => onDownloadXlsx?.(backtestingResult)}
            >
              {t('text.download')}
            </Button>
          ) : null}
        </Stack>
        <Typography
          variant="caption"
          whiteSpace="pre-line"
          mt="4px"
          color="text.secondary"
        >
          {backtestingResult.rebalancingPeriod === 'QUARTERLY'
            ? t('text.quarterly_caption')
            : backtestingResult.rebalancingPeriod === 'SEMIANNUALLY'
            ? t('text.semiannually_caption')
            : t('text.annually_caption')}
          <br />

          {t('text.portfolio_download_free')}
        </Typography>
        {!displayingAllYears ? (
          <Stack direction="row">
            <Select
              variant="outlined"
              sx={{
                backgroundColor: '#FFF',
              }}
              size="small"
              value={selectedYear}
              onChange={(e) => {
                if (isNumber(e.target.value)) {
                  setSelectedYear(e.target.value);
                }
              }}
            >
              {yearList}
            </Select>
            <Spacer />
            <Button
              size="small"
              variant="outlined"
              startIcon={<Download />}
              onClick={() => onDownloadXlsx?.(backtestingResult)}
            >
              {t('text.download')}
            </Button>
          </Stack>
        ) : null}
      </Stack>
      {allTable}
      {partialTable}
    </Stack>
  );
}

MonthlyYieldList.defaultProps = {
  displayingAllYears: true,
  onDownloadXlsx: undefined,
};
