interface Props {
  date: Date;
}
export const getYYYYMMDDHHmm = ({ date }: Props) => {
  return `${Intl.DateTimeFormat('ko-KR').format(
    date,
  )} ${date.getHours()}:${date.getMinutes()}`;
};

export const getYYYYMMDD = ({ date }: Props) => {
  return `${Intl.DateTimeFormat('ko-KR').format(date)}`;
};

export function getFormatedDate({ date }: Props) {
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  const year = date.getFullYear().toString();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function getformatedYYYYMMDD({ date }: Props) {
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  const year = date.getFullYear().toString();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('');
}
