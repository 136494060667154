import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Slider, SliderThumb, styled } from '@mui/material';

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0px !important',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#8160DB',
    border: '2px solid #9E7DF9',
    '&.second-thumb': {
      backgroundColor: 'white',
    },
    borderRadius: '10px',
    // '&:hover': {
    //   boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    // },
    '& .custom-bar': {
      height: 9,
      width: 1,
      marginLeft: 1,
      marginRight: 1,
    },
    // '&.first-thumb .custom-bar': {
    //   backgroundColor: 'red',
    // },
    // '&.second-thumb .custom-bar': {
    //   backgroundColor: 'currentColor',
    // },
  },
  '& .MuiSlider-track': {
    color: '#9E7DF9',
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: '#9E7DF9',
    height: 3,
  },
}));

export function CustomThumbComponent(props: any) {
  const { children, className, ...other } = props;
  const extraClassName =
    other?.['data-index'] === 0 ? 'first-thumb' : 'second-thumb';
  return (
    <SliderThumb {...other} className={clsx(className, extraClassName)}>
      {children}
    </SliderThumb>
  );
}

CustomThumbComponent.propTypes = {
  children: PropTypes.node,
};
CustomThumbComponent.defaultProps = {
  children: undefined,
};

export default CustomSlider;
