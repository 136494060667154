import { Image } from '@chakra-ui/image';
import { Center } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';

import api from '~/api';
import { Factor } from '~/api/screener';
import { FactorControlSpec } from '~/api/strategy';
import { AppContext } from '~/AppContext';
import FactorControl, {
  findValueIndexInHistogram,
} from '~/components/factor/FactorControl';
import { FactorControlValue } from '~/components/factor/types';
import {
  getAbsoluteInitialRange,
  getDefaultFreqIndex,
  getReletiveInitialRange,
} from '~/components/factor/utils/initializedValue';
import { BackIcon } from '~/components/Icons';
import { Body2 } from '~/components/Typography';
import { FactorDispatchContext, FactorStateContext } from './context';
import { ReactComponent as CircleArrow } from '~/assets/icon/circle_arrow.svg';
import { getThemeData } from '~/utils/theme';
import iconInfo from '~/assets/icon/icon_info.svg';

type FactorHistogramItemProps = {
  factor: Factor;
  onUpdateRange: (range: [FactorControlValue, FactorControlValue]) => void;
  nationCode: number;
  value: [FactorControlValue, FactorControlValue];
};

function FactorHistogramItem({
  factor,
  onUpdateRange,
  nationCode,
  value,
}: FactorHistogramItemProps) {
  const { state } = useContext(AppContext) ?? {};

  const factorDispatch = useContext(FactorDispatchContext);

  const [factorHistogram, setFactorHistogram] = useState<FactorControlSpec>();

  useEffect(() => {
    if (state?.user && nationCode) {
      api.strategy
        .getFactorControlSpec(factor.id, {
          nationCode,
        })
        .then((v) => {
          const factorHistogram = v.data;
          Object.assign<FactorControlSpec, Partial<FactorControlSpec>>(v.data, {
            factorName: factorHistogram?.factorName ?? factor.name,
            categoryName: factorHistogram?.categoryName ?? factor.categoryName,
            defaultRange: factorHistogram?.defaultRange ?? factor.defaultRange,
            valueUnit: factorHistogram?.valueUnit ?? factor.valueUnit,
            defaultFreqIndex: getDefaultFreqIndex(factorHistogram),
          });

          setFactorHistogram(factorHistogram);
        });
    }
  }, [nationCode, state?.user]);

  useEffect(() => {
    if (factorHistogram && (value[0].freqIndex < 0 || value[1].freqIndex < 0)) {
      if (value[0].value === 'min' && value[1].value === 'max') {
        const newRange =
          ((factor.rangeTypes?.length ?? 0) < 2 &&
            factor.rangeTypes?.[0].toUpperCase() === 'ABSOLUTE') ||
          factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
            ? getAbsoluteInitialRange(
                factorHistogram,
                factorHistogram.defaultRange,
              )
            : getReletiveInitialRange(
                factorHistogram,
                factorHistogram.defaultRange,
              );
        onUpdateRange(newRange);
      } else {
        onUpdateRange(
          value.map((v) => {
            if (v.value === 'min') {
              v.freqIndex = 0;
            } else if (v.value === 'max') {
              v.freqIndex = factorHistogram.bins.length - 1;
            } else if (v.freqIndex < 0) {
              v.freqIndex = value[0].isAbsolute
                ? findValueIndexInHistogram(factorHistogram, v.value)
                : v.value;
            }
            return v;
          }) as [FactorControlValue, FactorControlValue],
        );
      }
    }
  }, [factorHistogram, value]);

  // if (state.loading) return <Skeleton h="200px" />;

  // if (state.error) {
  //   if (factorDispatch != null)
  //     factorDispatch({
  //       type: 'REMOVE_FACTOR',
  //       factorId,
  //     });
  // }

  if (factorHistogram) {
    return (
      <Box style={{ animation: 'smoothAppear .5s' }}>
        <FactorControl
          factor={factor}
          onUpdateRange={onUpdateRange}
          factorHistogram={factorHistogram}
          value={value}
          onDelete={(factor) => {
            if (factorDispatch != null)
              factorDispatch({
                type: 'REMOVE_FACTOR',
                factor,
              });
          }}
        />
      </Box>
    );
  }

  return <div />;
}

function FactorHistogramContainer() {
  const themeData = getThemeData();
  const factorDispatch = useContext(FactorDispatchContext);
  const factorContext = useContext(FactorStateContext);
  const [t] = useTranslation();

  if (!factorContext) return <div>ERROR</div>;

  return (
    <Box
      className="factor-histogram-container"
      padding="0px 0px 36px 0px"
      height="100%"
      width="100%"
    >
      <Box height="100%" borderLeft={['none', 'solid 2px #f7f7f7']}>
        {/* {factorContext.sortData[0].data === 'F_SCORE' ? (
          <Stack direction="row" bgcolor="#f0f0f0" p="12px" mx="20px" mt="12px">
            <Image
              onClick={(e) => {
                e.stopPropagation();
              }}
              sx={{ mr: '4px' }}
              _focus={{ boxShadow: 'none', borderWidth: '0px' }}
              marginLeft="5px"
              src={iconInfo}
            />
            <Typography variant="body2" color="#404040">
              시장에 등록된 모든 종목을 대상으로 선택한 팩터의 F-Score 산출
              결과를 조회합니다.
            </Typography>
          </Stack>
        ) : null} */}
        {factorContext.selectedFactors.size === 0 ? (
          <Center h="100%" flexDirection="column" display="flex">
            <Box mb="16px">
              <CircleArrow />
            </Box>
            <Body2 whiteSpace="nowrap" color={themeData.colors.text3}>
              {t('text.factorEmpty')}
            </Body2>
          </Center>
        ) : (
          <>
            {Array.from(factorContext.selectedFactors.entries()).map(
              ([factor, value]) => (
                <Box borderBottom="solid 1px #f0f0f0" key={factor.id}>
                  <FactorHistogramItem
                    value={value}
                    onUpdateRange={(range) => {
                      if (factorDispatch != null) {
                        factorDispatch({
                          type: 'UPDATE_RANGE',
                          factor,
                          range,
                        });
                      }
                    }}
                    factor={factor}
                    nationCode={factorContext.selectedNation.code}
                  />
                </Box>
              ),
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default FactorHistogramContainer;
