import { useContext } from 'react';
import { Flex, Box, Spacer } from '@chakra-ui/react';
import { IconButton } from '@mui/material';
import { HStack } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';

import { getIsMobile } from '~/utils/mediaQuery';
import api from '~/api';
import { H1, H2 } from '~/components/Typography';
import ConditionSotrageSaveButton from '../conditions/ConditionStroageSaveButton';
import { FactorDispatchContext } from '../factor/context';
import BacktestingExecButton from './BacktestingExecButton';
import { ReactComponent as ShareIcon } from '~/assets/icon/icon_share_link.svg';

function BacktestingHeader() {
  const isMobile = getIsMobile();
  const [t] = useTranslation();
  const factorDispatchContext = useContext(FactorDispatchContext);

  return (
    <Flex
      style={{
        position: 'sticky',
        height: '92px',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: 'solid 1px #e5e5e5',
      }}
      p={['12px 24px', '24px 24px']}
      bg="white"
      alignItems="flex-end"
    >
      <Flex flexDirection="column" alignSelf="center">
        <Box>
          <H2 bold> {t('text.backtestMenu')}</H2>
        </Box>
      </Flex>
      <Spacer />
      {/* <Box display={['inherit', 'none']} alignSelf="center">
        <Drawer />
      </Box> */}

      <HStack
        display={isMobile ? 'none' : 'inherit'}
        sx={{ height: '100%' }}
        spacing="12px"
      >
        <BacktestingExecButton />
        <ConditionSotrageSaveButton
          onComplete={() => {
            api.screener.getStrategy().then((v) =>
              factorDispatchContext?.({
                type: 'UPDATE_STRATEGIES',
                strategies: v.data,
              }),
            );
          }}
        />
        <IconButton
          size="large"
          sx={{
            height: '100%',
            border: '1px solid #D9DCE0',
            borderRadius: '4px',
          }}
          onClick={() => {
            navigator.clipboard.writeText(window.location.href).finally(() => {
              alert('URL 링크가 복사되었습니다.');
            });
          }}
        >
          <ShareIcon />
        </IconButton>
      </HStack>
    </Flex>
  );
}

export default BacktestingHeader;
