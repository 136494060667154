import { useContext, useState } from 'react';
import {
  Center,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useBreakpointValue,
  useDisclosure,
  Stack,
  ChakraProvider,
} from '@chakra-ui/react';
import { getAuth, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import { Typography, Button, Box } from '@mui/material';

import { getThemeData } from '~/utils/theme';
import { Body2, Caption } from '~/components/Typography';
import { get } from '~/utils/localStorage';
import { getYYYYMMDDHHmm } from '~/utils/datetime';
import RequiredLoginPopupContent from './RequiredLoginPopupContent';
import { FactorDispatchContext, FactorStateContext } from '../factor/context';
import api from '~/api';
import { AppContext } from '~/AppContext';
import { getIsMobile } from '~/utils/mediaQuery';
import StyledButton from '~/components/StyledButton';
import { FactorControlValue } from '~/components/factor/types';

type Props = {
  onComplete?: () => void;
};
function ConditionSotrageSaveButton({ onComplete }: Props) {
  const { logEvent } = useAmplitude();
  const { state } = useContext(AppContext) ?? {};
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  if (!factorState) return <div />;
  const [t] = useTranslation();
  const themeData = getThemeData();
  const [strategyName, setStrategyName] = useState('');
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const isMobile = getIsMobile();
  const checkDuplicatedStrategy = (strategy: string): Promise<boolean> => {
    return api.screener.getStrategy().then((v) => {
      return v.data.findIndex((v) => v.name === strategy) >= 0;
    });
    // return getDoc(

    //   doc(getFirestore(), `storage/${user?.uid}/conditions/${strategy}`),
    // ).then((v) => {
    //   if (v.data()) {
    //     return true;
    //   }
    //   return false;
    // });
  };
  const addCondition = (isDuplicated: boolean) => {
    const selectedFactors: Array<
      [number, [FactorControlValue, FactorControlValue]]
    > = [];

    Array.from(factorState.selectedFactors).forEach((v) => {
      selectedFactors.push([v[0].id, v[1]]);
    });

    if (isDuplicated) {
      api.screener.getStrategy().then((v) => {
        const index = v.data.findIndex((v) => v.name === strategyName);

        api.screener
          .patchStrategy(v.data[index].uid, {
            name: strategyName,
            selectedNation: factorState.selectedNation,
            // indexCode: number;
            startDate: parseInt(
              // @ts-ignore
              get('BACKTEST_START_DATETIME') ?? '1107244432000',
              10,
            ).toString(),
            endDate: parseInt(
              // @ts-ignore
              get('BACKTEST_END_DATETIME') ?? new Date().getTime().toString(),
              10,
            ).toString(),
            // selectedNation: Nation;
            selectedFactors,

            orderFactor: factorState.sortData,
            // @ts-ignore
            rebalancingPeriod: get('BACKTEST_REBALANCINGPERIOD') ?? 'ANNUALLY',
            unselectedCategoryIds: JSON.parse(
              // @ts-ignore
              get('UNSELECTED_GROUPS', { ignoreEmpty: true }) ?? '[]',
            ),
            // @ts-ignore
            tradeCost: parseFloat(get('BACKTEST_TRADE_COST') ?? '0.6'),
            // @ts-ignore
            count: parseInt(get('BACKTEST_TOP') ?? '30', 10),
          })
          .then((v) => {
            onComplete?.();
          });
      });
    } else {
      api.screener
        .postStrategy({
          name: strategyName,
          selectedNation: factorState.selectedNation,
          // indexCode: number;
          startDate: parseInt(
            // @ts-ignore
            get('BACKTEST_START_DATETIME') ?? '1107244432000',
            10,
          ).toString(),
          endDate: parseInt(
            // @ts-ignore
            get('BACKTEST_END_DATETIME') ?? new Date().getTime().toString(),
            10,
          ).toString(),
          // selectedNation: Nation;
          selectedFactors,

          orderFactor: factorState.sortData,
          // @ts-ignore
          rebalancingPeriod: get('BACKTEST_REBALANCINGPERIOD') ?? 'ANNUALLY',

          unselectedCategoryIds: JSON.parse(
            // @ts-ignore
            get('UNSELECTED_GROUPS', { ignoreEmpty: true }) ?? '[]',
          ),
          // @ts-ignore
          tradeCost: parseFloat(get('BACKTEST_TRADE_COST') ?? '0.6'),
          // @ts-ignore
          count: parseInt(get('BACKTEST_TOP') ?? '30', 10),
        })
        .then(() => {
          onComplete?.();
        });
    }

    factorDispatch?.({
      type: 'UPDATE_SELECTED_CONDITIONS',
      selectedConditions: strategyName,
    });
    // setDoc(
    //   doc(
    //     getFirestore(),
    //     `storage/${user?.uid}/conditions/${strategy.replaceAll(
    //       /(^\s*)|(\s*$)/g,
    //       '',
    //     )}`,
    //   ),
    //   {
    //     /// COMMON
    //     createDateTime: getYYYYMMDDHHmm({ date: new Date() }),
    //     // selectedFactors: JSON.stringify(
    //     //   Array.from(factorState.selectedFactors),
    //     // ),
    //     /// BACKTEST
    //      // @ts-ignore
    //       // @ts-ignore
    //      rebalancingPeriod: get('BACKTEST_REBALANCINGPERIOD') ??  'ANNUALLY',

    //      unselectedGroups: get('UNSELECTED_GROUPS') ??  '[]',

    //     /// TOAST MESSAGE
    //   },
    // );
    if (isMobile) {
      state?.showToast('success', t('strategy.mobileSaveToast'), {
        position: 'bottom-center',
      });
    }
    // logEvent('strategy save completed', {
    //   'strategy name': strategyName,
    // });
    onComplete?.();
  };
  const onKeySubmit = () => {
    // /// SAVE
    checkDuplicatedStrategy(strategyName).then((v) => {
      if (v) {
        setIsDuplicated(true);
      } else {
        addCondition(false);
        onClose();
      }
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const disabled =
    factorState.selectedFactors.size === 0 ||
    !factorState.isEditCurrentConditions;
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <StyledButton
        variant="outlined"
        disabled={disabled}
        sx={{
          height: '100%',

          width: 'fit-content',
          px: '16px',
          borderRadius: '8px',
        }}
        onClick={
          disabled
            ? undefined
            : () => {
                setStrategyName(factorState.selectedConditions);
                // logEvent('strategy save clicked');
                onOpen();
              }
        }
      >
        <Typography>{t('strategy.saveButton')}</Typography>
      </StyledButton>
      {/* <Button
          borderColor={
            disabled
              ? themeData.colors.gray[200]
              : themeData.colors.primary[400]
          }
          p={['4px 0px', '8px']}
          // @ts-ignore
          variant={isMobile ? null : 'outline'}
          borderWidth="2px"
          _hover={{
            background: disabled
              ? themeData.colors.gray[200]
              : themeData.colors.primary[200],
          }}
          colorScheme={disabled ? 'blackAlpha' : 'primary'}
          size="xl"
          color="primary"
          borderRadius={['base', 'full']}
         
        >
          <Box h="40px" flexDirection="row" display="flex" margin="0px 16px">
            <Body2
              whiteSpace="break-spaces"
              bold
              color={
                disabled
                  ? themeData.colors.gray[300]
                  : isMobile
                  ? 'white'
                  : themeData.colors.primary[500]
              }
            >
              {t('strategy.saveButton')}
            </Body2>
          </Box>
        </Button> */}
      <ChakraProvider>
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent borderRadius="16px">
            <ModalHeader fontWeight="bold">
              {t('strategy.saveModalTitle')}
            </ModalHeader>
            <ModalCloseButton color={themeData.colors.gray[300]} />
            <ModalBody pb={2}>
              {isDuplicated ? (
                <>
                  <Body2>
                    {t('strategy.overwriteDescription', {
                      value: strategyName,
                    })}
                  </Body2>
                  <ModalFooter justifyContent="center">
                    <Button
                      variant="outlined"
                      sx={{
                        marginRight: 3,
                      }}
                      onClick={() => {
                        setIsDuplicated(false);
                      }}
                    >
                      {t('text.cancel')}
                    </Button>
                    <Button
                      variant="contained"
                      // colorScheme="primary"
                      onClick={() => {
                        addCondition(true);
                        setIsDuplicated(false);
                        onClose();
                      }}
                    >
                      {t('strategy.overwrite')}
                    </Button>
                  </ModalFooter>
                </>
              ) : (
                <>
                  <FormControl>
                    <Input
                      value={strategyName}
                      onChange={(event) => {
                        if (event.target.value.length > 15) {
                          setErrorMessage(t('strategy.error.tooLong'));
                        } else if (errorMessage) {
                          setErrorMessage(undefined);
                        }
                        setStrategyName(event.target.value);
                      }}
                      placeholder={t('strategy.alias')}
                    />
                    <Stack marginTop="8px">
                      {errorMessage ? (
                        <Box marginTop="8px">
                          <Caption color={themeData.colors.error}>
                            {errorMessage}
                          </Caption>
                        </Box>
                      ) : (
                        <div />
                      )}
                      <Spacer />
                      <Caption
                        color={themeData.colors.text3}
                      >{`${strategyName.length} / 15`}</Caption>
                    </Stack>
                  </FormControl>
                  <ModalFooter justifyContent="center">
                    <Button
                      variant="outlined"
                      sx={{
                        marginRight: 3,
                      }}
                      onClick={onClose}
                    >
                      {t('text.cancel')}
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        strategyName.replaceAll(' ', '').length === 0 ||
                        errorMessage !== undefined
                      }
                      // color={themeData.colors.primary[900]}
                      // _disabled={{
                      //   color: themeData.colors.gray[200],
                      //   backgroundColor: themeData.colors.gray[100],
                      // }}
                      onClick={onKeySubmit}
                    >
                      {t('text.save')}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </Box>
  );
}
ConditionSotrageSaveButton.defaultProps = {
  onComplete: undefined,
};
export default ConditionSotrageSaveButton;
