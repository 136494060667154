import { AxiosInstance } from 'axios';

export interface CreatedUserResponse {
  id: string;
}

export const createService = (client: AxiosInstance) => {
  return {
    async signIn({
      name,
      email,
      phoneNumber,
    }: {
      name: string;
      email: string;
      phoneNumber: string;
    }) {
      return client.post<CreatedUserResponse>('/user/sign-in', {
        name,
        email,
        phoneNumber,
      });
    },
    async signOut() {
      return client.post<CreatedUserResponse>('/auth/sign-out');
    },
    //   async signOut() {
    //     return client.patch('/auth/signout');
    //   },
    //   async emailAuthentication(email) {
    //     return client.post('/auth/emailAuthentication', {
    //       email,
    //     });
    //   },
    //   async codeAuthentication(email, authenticationCode) {
    //     return client.post('/auth/codeAuthentication', {
    //       email,
    //       authenticationCode,
    //     });
    //   },
    //   async signUp({
    //     email,
    //     password,
    //     name,
    //     contactNo,
    //     occupation,
    //     birthday,
    //     authenticationCode,
    //     isPushAgreed,
    //     isMarketingAgreed,
    //     application,
    //   }) {
    //     return client.post('/auth/signup', {
    //       email,
    //       password,
    //       name,
    //       contactNo,
    //       occupation,
    //       birthday,
    //       authenticationCode,
    //       isPushAgreed,
    //       isMarketingAgreed,
    //       application,
    //     });
    //   },
    //   async resetPassword(email, authenticationCode, password, application) {
    //     return client.patch('/auth/resetPassword', {
    //       email,
    //       authenticationCode,
    //       password,
    //       application,
    //     });
    //   },
    // };
  };
};
