import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { pick, flatMap } from 'lodash';
import { LoadingButton } from '@mui/lab';

import { AppContext } from '~/AppContext';
import api from '~/api';
import { ServiceTermsAgreementState } from '~/api/user';
import newLogo from '~/assets/icon/new_logo2.svg';
import { getIsMobile } from '~/utils/mediaQuery';

function phoneNumberFormatter(num: string) {
  const filtered = num.replace(/[^0-9]/g, '');
  const parts = [
    filtered.slice(0, 3),
    filtered.slice(3, 7),
    filtered.slice(7, 11),
  ];
  return parts.filter((v) => v.length > 0).join('-');
}

export default function ServiceTermsView() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch, state } = useContext(AppContext) ?? {};
  const { search } = useLocation();

  const { target, linkScreenerUid } = useMemo(() => {
    const query = new URLSearchParams(search);

    return {
      target: query.get('target'),
      linkScreenerUid: query.get('linkScreenerUid'),
    };
  }, [search]);

  const isMobile = getIsMobile();

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [agreements, setAgreements] = useState<{
    [key in 'service' | 'privacy']: boolean | null;
  }>({
    service: null,
    privacy: null,
  });

  const isAllAgreed = useMemo(
    () =>
      Object.keys(agreements).length > 0 &&
      Object.values(agreements).every((isAgreed) => isAgreed),
    [agreements],
  );

  const isRequiredAgreed = useMemo(
    () =>
      Object.keys(agreements).length > 0 &&
      Object.values(pick(agreements, 'service', 'privacy')).every(
        (isAgreed) => isAgreed,
      ),
    [agreements],
  );

  const [serviceTerms, setServiceTerms] = useState<
    ServiceTermsAgreementState[] | null
  >(null);

  useEffect(() => {
    if (!state?.user) {
      return;
    }

    api.user
      .serviceTermState()
      .then((response) => {
        setServiceTerms(response.data);
      })
      .catch((e) => {
        console.log('fail', e);
      });
  }, [state?.user]);

  const sign = useCallback(() => {
    if (!serviceTerms) {
      return;
    }

    if (
      !phoneNumber ||
      !/01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/.test(phoneNumber)
    ) {
      setErrorMessage('전화번호를 확인해주세요.');
      return;
    }
    if (errorMessage) {
      setErrorMessage(null);
    }

    const signedList = flatMap(agreements, (isAgree, type) => {
      const terms = serviceTerms.find((t) => t.type === type);

      if (!terms?.uid) {
        return [];
      }
      return [
        {
          uid: terms.uid,
          isAgreed: isAgree ?? false,
        },
      ];
    });

    setLoading(true);
    Promise.all([
      api.user.updateProfile({ phoneNumber }),
      api.user.signServiceTerms(signedList),
    ])
      .then(async () => {
        if (linkScreenerUid) {
          try {
            await api.screener.registerUser(linkScreenerUid);

            const usageRes = await api.screener.usage();
            dispatch?.({
              type: 'SET_USAGE',
              screenerUsage: usageRes.data,
            });
          } catch (e: any) {
            history.replace('/migration-fail', {
              targetAccount: e?.response?.data?.targetAccount,
            });
            return;
          }
          history.replace('/migration-success');
        } else if (target) {
          window.location.href = target;
        } else {
          history.replace('/');
        }
      })
      .catch((e) => console.error('fail', e))
      .finally(() => {
        setLoading(false);
      });
  }, [serviceTerms, agreements, phoneNumber, errorMessage, linkScreenerUid]);

  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      bgcolor="background.grey"
    >
      <Helmet>
        <meta content="#FFFFFF" name="theme-color" />
        <meta content="#FFFFFF" name="msapplication-navbutton-color" />
        <meta content="#FFFFFF" name="apple-mobile-web-app-status-bar-style" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
      </Helmet>
      <Stack
        width="100%"
        bgcolor="#FFFFFF"
        sx={{
          flex: [1, 0],
          maxWidth: [undefined, 'calc(758px - 200px)'],
          p: [0, '100px'],
          border: [null, '1px solid rgba(0, 0, 0, 0.08)'],
          borderRadius: [0, '20px'],
        }}
      >
        <Stack
          sx={{
            flex: [1, 0],
            pt: ['20px', 0],
            px: ['20px', 0],
            overflow: ['auto', 'unset'],
          }}
        >
          <Box>
            <img alt="logo" src={newLogo} width="100px" />
          </Box>
          <Box mt="48px">
            <Typography variant="headline1" color="secondary.main">
              {state?.user?.name}
            </Typography>
            <Typography variant="headline1">님</Typography>
          </Box>
          <Typography variant="headline1">
            테일러 사용을 위해{isMobile ? <br /> : ' '}본인 정보를 입력해주세요
          </Typography>
          <Stack mt="32px" spacing="16px">
            <Stack spacing="8px">
              <Typography variant="subtitle1">이메일 주소</Typography>
              <TextField
                size="small"
                value={state?.user?.email ?? ''}
                disabled
                sx={{
                  backgroundColor: 'background.grey',
                }}
              />
            </Stack>
            <Stack spacing="8px">
              <Stack direction="row" height="fit-content" alignItems="center">
                <Typography variant="subtitle1">휴대 전화번호</Typography>
                <Typography variant="subtitle1" color="red">
                  *
                </Typography>
              </Stack>
              <TextField
                error={!!errorMessage}
                size="small"
                placeholder="010"
                value={phoneNumber ?? ''}
                helperText={errorMessage}
                onChange={(e) => {
                  setPhoneNumber(phoneNumberFormatter(e.target.value));
                }}
              />
            </Stack>
          </Stack>
          <Stack mt="40px" spacing="16px">
            <Stack direction="row" spacing="10px" alignItems="center">
              <Checkbox
                sx={{
                  p: 0,
                }}
                color="secondary"
                checked={isAllAgreed}
                onChange={(e) => {
                  setAgreements((state) => ({
                    service: e.target.checked,
                    privacy: e.target.checked,
                  }));
                }}
              />
              <Typography variant="subtitle1">약관 전체 동의하기</Typography>
            </Stack>
            <Stack direction="row" spacing="10px" alignItems="center">
              <Checkbox
                sx={{
                  p: 0,
                }}
                color="secondary"
                checked={agreements?.service ?? false}
                onChange={(e) => {
                  setAgreements((state) => ({
                    ...state,
                    service: e.target.checked,
                  }));
                }}
              />
              <Typography
                color="text.secondary"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  const link = serviceTerms?.find(
                    (t) => t.type === 'service',
                  )?.link;
                  if (link) {
                    window.open(link, '_blank');
                  }
                }}
              >
                서비스 이용약관 필수 동의
              </Typography>
            </Stack>
            <Stack direction="row" spacing="10px" alignItems="center">
              <Checkbox
                sx={{
                  p: 0,
                }}
                color="secondary"
                checked={agreements?.privacy ?? false}
                onChange={(e) => {
                  setAgreements((state) => ({
                    ...state,
                    privacy: e.target.checked,
                  }));
                }}
              />
              <Typography
                color="text.secondary"
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const link = serviceTerms?.find(
                    (t) => t.type === 'privacy',
                  )?.link;
                  if (link) {
                    window.open(link, '_blank');
                  }
                }}
              >
                개인정보 처리방침 필수 동의
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          justifyContent="end"
          mt={['10px', '40px']}
          p={['23px', 0]}
          boxShadow={['inset 0px 1px 0px rgba(0, 0, 0, 0.05);', undefined]}
        >
          <LoadingButton
            loading={loading}
            disabled={!isRequiredAgreed}
            fullWidth
            variant="contained"
            size="large"
            sx={{
              height: ['52px', '48px'],
            }}
            onClick={() => {
              sign();
            }}
          >
            테일러 시작하기
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
