import React, { useContext, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import dayjs from '~/utils/dayjs';
import { AppContext } from '~/AppContext';
import migrationLogo from '~/assets/icon/migration_logo.svg';
import migrationSample1 from '~/assets/image/migration_sample_1.svg';
import migrationSample2 from '~/assets/image/migration_sample_2.svg';

export default function MigrationSuccessView() {
  const context = useContext(AppContext);
  const history = useHistory();

  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      bgcolor="background.grey"
    >
      <Helmet>
        <meta content="#FFFFFF" name="theme-color" />
        <meta content="#FFFFFF" name="msapplication-navbutton-color" />
        <meta content="#FFFFFF" name="apple-mobile-web-app-status-bar-style" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
      </Helmet>
      <Stack
        width="100%"
        bgcolor="#FFFFFF"
        sx={{
          flex: [1, 0],
          maxWidth: [undefined, '758px'],
          p: [0, '100px'],
          border: [null, '1px solid rgba(0, 0, 0, 0.08)'],
          borderRadius: [0, '20px'],
        }}
      >
        <Stack
          sx={{
            flex: [1, 0],
            pt: ['20px', 0],
            px: ['20px', 0],
            overflow: ['auto', 'unset'],
          }}
        >
          <img alt="logo" src={migrationLogo} width="244px" />
          <Typography mt="52px" variant="headline1" fontSize="24px">
            계정연동이 성공적으로 완료되었습니다!
          </Typography>
          <Stack mt="32px" p="16px" bgcolor="background.grey" spacing="4px">
            <Typography variant="body1" color="text.secondary">
              로그인된 나의 계정: {context?.state?.user?.email}
            </Typography>
            {context?.state?.screenerUsage &&
            context?.state?.screenerUsage?.planeName !== 'FREE' ? (
              <Typography variant="body1" color="text.secondary">
                이용권 :{' '}
                <span
                  style={{
                    padding: '4px',
                    color: '#FC916F',
                    backgroundColor: '#FFEBE4',
                    borderRadius: '4px',
                  }}
                >
                  {context?.state.screenerUsage?.planeName}
                </span>{' '}
                {context?.state.screenerUsage
                  ? `~${dayjs(context?.state.screenerUsage?.endAt).format(
                      'YYYY.MM.DD',
                    )} 까지 이용`
                  : '없음'}
              </Typography>
            ) : null}
            <Typography variant="body1" color="text.secondary">
              이용 범위 : 종목찾기 1일{' '}
              <span
                style={{
                  color: '#FC916F',
                }}
              >
                {(
                  context?.state?.screenerUsage?.policy?.screening?.limit ?? 0
                ).toLocaleString()}
                회
              </span>
              , 백테스팅 1일{' '}
              <span
                style={{
                  color: '#FC916F',
                }}
              >
                {(
                  context?.state?.screenerUsage?.policy?.backtesting?.limit ?? 0
                ).toLocaleString()}
              </span>
              회
            </Typography>
          </Stack>
          <Typography variant="body1" whiteSpace="pre-wrap" mt="20px">
            아래 메뉴에서 기존 올라떼의 종목찾기, 백테스트를 동일하게 사용하실
            수 있으며 아직 등록하지 않은 코드가 있다면 등록해서 사용하실 수
            있습니다.
            <br />
            종목찾기에서 새롭게 선보이는 기능인{' '}
            <span style={{ fontWeight: 'bold' }}>F-Score</span>
            도 바로 사용해보세요!
            <br />
            이제부터 테일러에 더 많은 기능들이 추가될 예정입니다.
          </Typography>
          <Stack
            direction="row"
            width="100%"
            mt="36px"
            justifyContent="space-around"
            overflow="hidden"
          >
            <img alt="sample1" src={migrationSample1} />
            <img alt="sample2" src={migrationSample2} />
          </Stack>
        </Stack>
        <Stack
          justifyContent="end"
          mt={['10px', '40px']}
          p={['23px', 0]}
          boxShadow={['inset 0px 1px 0px rgba(0, 0, 0, 0.05);', undefined]}
        >
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{
              height: ['52px', '48px'],
            }}
            onClick={() => {
              history.replace('/screener', {
                defaultSortOptionKey: 'F_SCORE',
              });
            }}
          >
            F-Score 사용해보기
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
