import { AddBoxOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack } from '@chakra-ui/layout';

import ConditionsStorageContainer from '~/containers/conditions/ConditionsStorageContainer';
import { getThemeData } from '~/utils/theme';
import StyledButton from './StyledButton';

export type IndexedTabItem = {
  title: string;
  child: ReactElement;
};
type Props = {
  items: Array<IndexedTabItem>;
  hasBottomButtons?: boolean;
  lastActionButton: ReactElement;
  hasNextButton?: boolean;
  hasStorageButton?: boolean;
};

type IndexButtonProps = {
  item: IndexedTabItem;
  index: number;
  onUpdateIndex: (index: number) => void;
  isSelected: boolean;
};
function IndexButton({
  item,
  index,
  onUpdateIndex,
  isSelected,
}: IndexButtonProps) {
  const themeData = getThemeData();
  return (
    <StyledButton
      variant="outlined"
      sx={{
        paddingX: '0px',
        width: '100%',
        border: `solid 1px ${
          isSelected ? themeData.colors.primary[500] : themeData.colors.text3
        }`,
        // background: isSelected
        //   ? themeData.colors.primary[100]
        //   : themeData.colors.gray[100],
      }}
      onClick={() => onUpdateIndex(index)}
    >
      <Typography
        variant="body1"
        whiteSpace="nowrap"
        // bold
        color={
          isSelected ? themeData.colors.primary[500] : themeData.colors.text3
        }
      >
        {item.title}
      </Typography>
    </StyledButton>
  );
}
function IndexedTab({
  items,
  lastActionButton,
  hasNextButton,
  hasBottomButtons,
  hasStorageButton,
}: Props) {
  const [t] = useTranslation();
  const [index, setIndex] = useState(0);

  return (
    <Stack direction="column" width="100%">
      <Stack p="12px" direction="row">
        {items.map((item, itemIndex) => (
          <Box key={`tab-${item.title}`} flex={1} mx="8px">
            <IndexButton
              item={item}
              index={itemIndex}
              onUpdateIndex={setIndex}
              isSelected={itemIndex === index}
            />
          </Box>
        ))}
      </Stack>
      <Stack
        height="-webkit-fill-available"
        width="100%"
        flex={1}
        sx={{
          overflowY: 'scroll',
          marginBottom: hasBottomButtons ? '64px' : '0px',
        }}
      >
        {items[index].child}
      </Stack>
      {hasBottomButtons ? (
        <Stack
          direction="row"
          position="fixed"
          width="100%"
          bottom="0px"
          bgcolor="white"
          alignItems="center"
        >
          {hasStorageButton ? (
            <ConditionsStorageContainer
              isFullScreen
              onUpdate={() => {
                // console.log('');
              }}
            />
          ) : null}
          {hasNextButton ? (
            <Stack
              flex={1}
              padding="16px 8px"
              height="100%"
              direction="row"
              spacing="12px"
            >
              {index === items.length - 1 ? (
                lastActionButton
              ) : (
                <StyledButton
                  sx={{
                    flex: 1,
                    height: '100%',
                  }}
                  variant="contained"
                  //   color={themeData.colors.primary[100]}
                  //   bg={themeData.colors.primary[500]}
                  //   variant="outline"
                  //   _hover={{ backgroundColor: themeData.colors.primary[400] }}
                  //   w="100%"
                  // size="lg"
                  onClick={() => {
                    setIndex(index + 1);
                  }}
                >
                  {t('text.next')}
                </StyledButton>
              )}
            </Stack>
          ) : null}
        </Stack>
      ) : (
        <div />
      )}
    </Stack>
  );
}
IndexedTab.defaultProps = {
  hasNextButton: true,
  hasBottomButtons: true,
  hasStorageButton: false,
};
export default IndexedTab;
