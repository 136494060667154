import { Box, Spacer } from '@chakra-ui/layout';
import { useContext, useEffect, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { isNil, isNumber, isString, omitBy, some } from 'lodash';
import JsonURL from '@jsonurl/jsonurl';

import { H3 } from '~/components/Typography';
import { get } from '~/utils/localStorage';
import { getThemeData } from '~/utils/theme';
import { FactorDispatchContext, FactorStateContext } from '../factor/context';
import NationSelector from '../factor/NationSelector';
import SectorSelect from '../factor/SectorSelect';
import ScreenerSortSelector, {
  screenerSortData,
  ScreenerSortType,
} from './ScreenerSortSelector';
import api from '~/api';
import { AppContext } from '~/AppContext';
import {
  parseOption,
  stringifyScreenerOption,
} from '~/utils/backtestingOptionQuery';

function ScreenerOptionContainer() {
  const location = useLocation<{ defaultSortOptionKey?: string }>();
  const context = useContext(AppContext);
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  const themeData = getThemeData();
  const { logEvent } = useAmplitude();
  const [t] = useTranslation();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  // TODO: 여기서 하는 것보다 view layer에서 초기화 값을 다뤄서 전달해주는 게 좋을 듯
  useEffect(() => {
    if (
      context?.state?.user &&
      factorState?.factorList &&
      (factorState?.factorList?.length ?? 0) > 0 &&
      factorState?.companyGroupList.size > 0
    ) {
      if (!isInitialized) {
        const inputParams = parseOption(location.search, {
          isOnlyScreening: true,
        });

        if (some(inputParams, (v, k) => !isNil(v))) {
          const { nationCode, categoryIds, factorQuery, orders } = inputParams;
          const allGroups = Array.from(
            factorState?.companyGroupList.entries() ?? [],
          )
            .map(([key, value]) => {
              if (value.length === 0) {
                return [];
              }
              if (value.length === 1) {
                return [key];
              }
              return value;
            })
            .flat();

          if (nationCode) {
            api.screener.getNation().then((res) => {
              const selectedNation = res.data.find(
                (n) => n.code === Number(nationCode),
              );
              if (selectedNation) {
                factorDispatch?.({
                  type: 'UPDATE_SELECTED_NATION',
                  nation: selectedNation,
                });
              }
            });
          }

          factorDispatch?.({
            type: 'SET_FACTORS',
            factors:
              factorQuery?.flatMap((query) => {
                const factor = factorState?.factorList.find(
                  (f) => f.id === Number(query.factorId),
                );
                if (!factor) {
                  return [];
                }
                return [
                  {
                    factor,
                    range: [
                      {
                        freqIndex: -1,
                        value: isNumber(query.gt) ? query.gt : 'min',
                        isAbsolute:
                          query.valueType.toUpperCase() === 'ABSOLUTE',
                      },
                      {
                        freqIndex: -1,
                        value: isNumber(query.lt) ? query.lt : 'max',
                        isAbsolute:
                          query.valueType.toUpperCase() === 'ABSOLUTE',
                      },
                    ],
                  },
                ];
              }) ?? [],
          });
          factorDispatch?.({
            type: 'CLEAR_UNSELECTED_GROUPS',
          });
          const unselectedGroupIds = categoryIds
            ? allGroups.filter(
                (v) =>
                  !categoryIds?.some(
                    (sid: any) => Number(sid) === v.cosmosGroupId,
                  ),
              )
            : [];
          unselectedGroupIds.forEach((unselectedGroup) => {
            factorDispatch?.({
              type: 'ADD_COMPANY_GROUP',
              cosmosGroupId: unselectedGroup.cosmosGroupId,
            });
          });

          const selectedOrder = factorQuery?.some(
            (q) => q.queryType === 'F_SCORE',
          )
            ? screenerSortData.find((d) => d.data === 'F_SCORE')
            : screenerSortData.find(
                (d) =>
                  d.data !== 'F_SCORE' &&
                  d.data.order.toUpperCase() ===
                    orders?.[0].order.toUpperCase() &&
                  d.data.factorId === Number(orders?.[0].factorId),
              );
          if (selectedOrder) {
            factorDispatch?.({
              type: 'UPDATE_SORT_OPTION',
              sortData: [selectedOrder],
            });
          }
        } else if (location?.state?.defaultSortOptionKey) {
          const targetOption = screenerSortData.find(
            (d) => d.name === location?.state?.defaultSortOptionKey,
          );
          if (targetOption) {
            factorDispatch?.({
              type: 'UPDATE_SORT_OPTION',
              sortData: [targetOption],
            });
          }
        }
        setIsInitialized(true);
      }
    }
  }, [
    isInitialized,
    context?.state?.user,
    factorState?.factorList,
    factorState?.companyGroupList,
    location?.state?.defaultSortOptionKey,
  ]);

  useEffect(() => {
    if (
      isInitialized &&
      context?.state?.user &&
      factorState?.factorList &&
      (factorState?.factorList?.length ?? 0) > 0 &&
      factorState?.companyGroupList.size > 0
    ) {
      const queryString = stringifyScreenerOption(factorState, location.search);

      const newUrl = `${window.location.origin}${window.location.pathname}?${queryString}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
  }, [
    isInitialized,
    factorState?.unselectedGroups,
    factorState?.selectedFactors,
    factorState?.selectedNation,
    factorState?.sortData,
  ]);

  if (!factorState) return <div />;

  return (
    <Box
      className="screener-option-container"
      bg={themeData.colors.background}
      h="100%"
      p="24px 32px"
      w="100%"
    >
      <Box marginBottom="24px">
        <H3 bold>{t('text.defaultSettings')}</H3>
      </Box>
      <NationSelector
        selectedNation={
          factorState?.selectedNation ??
          JSON.parse(
            get('selectedNation', { ignoreEmpty: true }) ??
              '{"code": 840,"name": "UNITED STATES"}',
          )
        }
        onSelectNation={(nation) => {
          logEvent('screening basic setting adjusted', {
            'selected country to invest': nation.name,
          });
          if (nation && factorDispatch) {
            factorDispatch({
              type: 'UPDATE_SELECTED_NATION',
              nation,
            });
          }
        }}
      />
      <Spacer h="24px" />
      <ScreenerSortSelector
        onSelect={(data) => {
          if (data != null && factorDispatch != null) {
            factorDispatch({
              type: 'UPDATE_SORT_OPTION',
              sortData: [data],
            });
          }
        }}
        selectedData={
          factorState.sortData[0] ??
          (JSON.parse(
            get('selectedSortOption') ?? JSON.stringify(screenerSortData[0]),
          ) as ScreenerSortType)
        }
      />
      <Spacer h="24px" />

      <SectorSelect />
    </Box>
  );
}

export default ScreenerOptionContainer;
