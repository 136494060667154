import { Box } from '@chakra-ui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { VegaLite } from 'react-vega';
import { useTranslation } from 'react-i18next';
import api from '~/api';
import iconRelativeTooltipDesc from '~/assets/icon/relative_tooltip_desc.svg';
import { BackTestStateContext } from '~/containers/backtest/context';
import { FactorStateContext } from '~/containers/factor/context';

import useFetch from '~/hooks/useFetch';
import { getFormatedDate } from '~/utils/datetime';
import { getThemeData } from '~/utils/theme';
import { StrategyFactorUnitPerformanceResponse } from './types';
import './RelativeFactorHistogramChart.css';
import { AppContext } from '~/AppContext';

function RelativeFactorHistogramChart({ factorId }: { factorId: number }) {
  const context = useContext(AppContext);
  const backTestState = useContext(BackTestStateContext);
  const factorState = useContext(FactorStateContext);
  const themeData = getThemeData();
  const [t] = useTranslation();

  const categoryIds = useMemo(() => {
    const selectGroups = Array.from(
      factorState?.companyGroupList.entries() ?? [],
    )
      .map(([key, value]) => {
        if (value.length === 0) {
          return [];
        }
        if (value.length === 1) {
          return [key];
        }
        return value;
      })
      .flatMap((v) => v)
      .filter((v) => !factorState?.unselectedGroups.has(v.cosmosGroupId));

    return selectGroups.map((v) => v.cosmosGroupId);
  }, [factorState?.companyGroupList, factorState?.unselectedGroups]);

  const [tryCnt, setTryCnt] = useState<number>(0);

  const [companySummaryInfoResponse, setCompanySummaryInfoResponse] = useState<
    StrategyFactorUnitPerformanceResponse | string | null
  >();

  useEffect(() => {
    if (context?.state?.user) {
      api.strategy
        .postStrategyFactorUnitPerformance(factorId, {
          nationCode: factorState?.selectedNation.code ?? 840,
          categoryIds,
          startDate: backTestState?.startDate
            ? getFormatedDate({ date: backTestState.startDate })
            : undefined,
          endDate: backTestState?.endDate
            ? getFormatedDate({ date: backTestState.endDate })
            : undefined,
          rebalancingPeriod: backTestState?.rebalancingPeriod,
          count: backTestState?.top ?? 30,
          tradeCost: backTestState?.tradeCost ?? 0.6,
        })
        .then((v) => {
          setCompanySummaryInfoResponse(v.data);
        });
    }
  }, [
    context?.state?.user,
    categoryIds,
    factorState?.selectedNation.code,
    backTestState?.startDate,
    backTestState?.endDate,
    backTestState?.rebalancingPeriod,
    backTestState?.top,
    backTestState?.tradeCost,
    tryCnt,
  ]);

  // 요청하지 않은 케이스에 대해 null 반환
  if (!companySummaryInfoResponse) {
    return null;
  }

  if (typeof companySummaryInfoResponse === 'string') {
    if (tryCnt < 9) {
      setTimeout(() => {
        setTryCnt(tryCnt + 1);
      }, 3000);
    }
    return <Box h="50px" w="100%" />;
  }

  const companySummaryInfoResponseData = companySummaryInfoResponse;

  const getColor = (value: number): string => {
    if (value <= -0.25) {
      return themeData.colors.primary[100];
    }
    if (value < -0.1) {
      return themeData.colors.primary[300];
    }
    if (value < 0.1) {
      return themeData.colors.primary[500];
    }
    if (value < 0.25) {
      return themeData.colors.primary[700];
    }
    return themeData.colors.primary[900];
  };

  const values = (companySummaryInfoResponseData?.performances ?? []).map(
    (v) => {
      return {
        period: `${companySummaryInfoResponseData.startDate} ~ ${companySummaryInfoResponseData.endDate}`,
        range: 100 - (v.range[0] + v.range[1]) / 2,
        zero: 0,
        diffAnnualYield: (v.diffAnnualYield * 100).toFixed(2),
        diffMdd: (v.diffMinMdd * 100).toFixed(2),
        diffSharpe: (v.diffAnnualSharpe ?? 0).toFixed(2),
        c: getColor(v.diffMinMdd),
        image: iconRelativeTooltipDesc,
      };
    },
  );

  function g(value: any[], fn: (n: any) => number) {
    return Math.max(...value.map(fn));
  }
  function kn(k: string) {
    return (n: any) => Math.abs(Number(n[k]));
  }
  const yieldMax = g(values, kn('diffAnnualYield'));
  const mddMax = g(values, kn('diffMdd'));
  return (
    <VegaLite
      style={{ width: '100%' }}
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 'container',
        height: 50,
        // @ts-ignore
        background: null,
        autosize: 'fit',
        data: {
          values,
        },
        encoding: {
          x: { field: 'range' },
          tooltip: [
            {
              field: 'period',
              type: 'nominal',
              title: t('text.period'),
            },

            { field: 'image' },
            {
              field: 'diffAnnualYield',
              type: 'nominal',
              title: t('text.sharpRatioDiff'),
            },
            {
              field: 'diffMdd',
              type: 'nominal',
              title: t('text.MDDDiff'),
            },
            {
              field: 'diffSharpe',
              type: 'nominal',
              title: t('text.sharpRatioDiff'),
            },
          ],
        },

        layer: [
          {
            mark: 'line',
            encoding: {
              y: { field: 'zero', type: 'quantitative' },
              color: { value: '#e0e0e0' },
            },
          },
          {
            mark: { type: 'bar', width: 20 },
            encoding: {
              x: {
                field: 'range',
                type: 'temporal',
                scale: { domainMin: 0, domainMax: 100 },
              },
              y: {
                field: 'diffAnnualYield',
                scale: { domain: [yieldMax * -1, yieldMax] },
                type: 'quantitative',
              },
              color: {
                condition: {
                  test: 'datum.diffAnnualYield > 0',
                  value: themeData.colors.primary[500],
                },
                value: '#BDBDBD',
              },
            },
          },
          {
            mark: 'rule',
            params: [
              {
                name: 'hover',
                select: { type: 'point', on: 'mouseover', nearest: true },
              },
            ],
            encoding: {
              color: {
                condition: {
                  param: 'hover',
                  empty: false,
                  value: '#aaaaaa',
                },
                value: 'transparent',
              },
            },
          },
          {
            mark: 'line',
            encoding: {
              x: { field: 'range', type: 'temporal' },
              y: {
                field: 'diffMdd',
                scale: { domain: [mddMax * -1, mddMax] },
                type: 'quantitative',
              },
              color: { value: '#FF9393' },
            },
          },
        ],
        resolve: { scale: { y: 'independent' } },
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },

          padding: 0,

          axisX: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
          axisY: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
        },
      }}
    />
  );
}

export default RelativeFactorHistogramChart;
