import { AxiosInstance, AxiosResponse } from 'axios';
import { isUndefined, omitBy } from 'lodash';
import {
  FactorOrder,
  FactorFilterCondition,
  FactorWeightCondition,
  RebalancingPeriod,
  BacktestingPerformance,
  ExcludedCompany,
  PortWeightPolicy,
  BacktestingComapnyRebalancingInfo,
  FactorQueryCondition,
} from '~/api/backtesting';
import { ModelAsset } from '~/api/portfolio';

export interface Strategy {
  id: string;
  uid: string;
  userId: string;
  name: string;
  description?: string;
  indexCode?: number;
  nationCode?: number;
  factorFilter: FactorFilterCondition[];
  factorQuery: FactorQueryCondition[];
  factorWeight: FactorWeightCondition[];
  factorOrder?: FactorOrder[];
  portWeightPolicy: PortWeightPolicy;
  rebalancingPeriod: RebalancingPeriod;
  latestBacktestingId?: number;
  lastBacktestingPerformance?: BacktestingPerformance;
  categoryIds?: number[] | null;
  excludedCompanies?: ExcludedCompany[];
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export type Index = {
  code: number;
  currency: string;
  ldb?: string;
  mnem?: string;
  name: string;
  nationCode: number;
  region: string;
};

export type FactorCategory = {
  id: number;
  name: string;
};

export type FactorDefaultRange =
  | 'ALL'
  | 'BOTTOM20'
  | 'BOTTOM20POSITIVE'
  | 'MIDDLE20'
  | 'TOP20';

export type Factor = {
  id: number;
  categoryId: number;
  name: string;
  categoryName: string;
  conditionPriority?: number;
  defaultRange: FactorDefaultRange;
  returnPriority?: number;
  valueUnit?: string;
  rangeTypes?: ('relative' | 'absolute')[];
  isNew?: boolean;
};

export type FactorControlSpec = {
  factorId: number;
  categoryName: string;
  nationCode: number;
  indexCode: number;
  min: number;
  max: number;
  bins: Array<number>;
  freqs: Array<number>;
  defaultRange: FactorDefaultRange;
  factorName: string;
  valueUnit: string;

  defaultFreqIndex?: Map<FactorDefaultRange, [number, number]>;
};

export type Category = {
  cosmosGroupId: number;
  level: number;
  name: string;
};

export type Nation = {
  code: number;
  name: string;
};

export type FactorValueResult = {
  [key: number]: number;
};

export type Company = {
  companyLocalName: string | null;
  companyName: string;
  cosmosCode: number;
  cosmosGroupId: number;
  currency: string;
  // factorValueMap?: FactorValueResult;
  // marketCap: number;
  // marketPrice: number;
  exchangeCode: number;
  ticker: string;
  // date: Date;
  // weight?: number;
};

export type ScreeningResult = {
  list: Company[];
  rebalancingList: BacktestingComapnyRebalancingInfo[];
  count: number;
};

export type IndexPrice = {
  marketValue: number;
  priceIndex: number;
  returnIndex: number;
  valueDate: Date;
};

export type CompanyPrice = {
  date: Date;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
};

export type CompanyPricesResult = {
  currency: string;
  priceList: CompanyPrice[];
};

export const createService = (client: AxiosInstance) => {
  return {
    async strategies(): Promise<AxiosResponse<Strategy[]>> {
      return client.get<Strategy[]>('/strategy');
    },

    async importBacktesting(params: {
      name: string;
      description?: string;
      backtestingId: string;
      targetStrategyId?: string;
    }) {
      return client.post<{ id: string }>(
        `/strategy/import-backtesting`,
        params,
      );
    },
    async getFactors() {
      return client.get<Factor[]>(`/strategy/factor`);
    },
    async getFactorCategories() {
      return client.get<FactorCategory[]>(`/strategy/factor-category`);
    },
    async getFactorControlSpec(
      factorId: number,
      universe: { nationCode: number; indexCode?: number },
    ) {
      return client.get<FactorControlSpec>(
        `/strategy/factor/${factorId}/spec`,
        {
          params: universe,
        },
      );
    },
    async getCategories(level: number) {
      return client.get<Category[]>(`/strategy/category`, {
        params: { level },
      });
    },
    async getSectors() {
      return client.get<Category[]>(`/strategy/sector`);
    },
    async getIndexes() {
      return client.get<Index[]>(`/strategy/index`);
    },
    async getNations() {
      return client.get<Nation[]>(`/strategy/nation`);
    },
    async getStrategy(id: string) {
      return client.get<Strategy>(`/strategy/${id}`);
    },
    async deleteStrategy(id: string) {
      return client.delete<Strategy>(`/strategy/${id}`);
    },
    async getStrategyCompanies(id: string) {
      return client.get<ModelAsset[]>(`/strategy/${id}/model-assets`);
    },
    async getIndexPriceHistory(code: number, endDate?: Date, top?: number) {
      return client.get<IndexPrice[]>(`/strategy/index/${code}/price`, {
        params: omitBy(
          {
            endDate,
            top,
          },
          (v) => isUndefined(v),
        ),
      });
    },
    async getCompanyPriceHistory(
      code: number,
      startDate?: Date,
      endDate?: Date,
    ) {
      return client.get<CompanyPricesResult>(
        `/strategy/company/${code}/price`,
        {
          params: omitBy(
            {
              startDate,
              endDate,
            },
            (v) => isUndefined(v),
          ),
        },
      );
    },
    async getCompanies({
      nationCode,
      indexCode,
      categoryIds,
      filter,
      weightPolicy,
      count,
      offset,
      orders = [
        {
          factorId: 1133,
          order: 'desc',
        },
      ],
    }: {
      nationCode?: number;
      indexCode?: number;
      categoryIds?: number[] | null;
      filter?: FactorFilterCondition[];
      weightPolicy?: PortWeightPolicy;
      count?: number;
      offset?: number;
      orders?: FactorOrder[];
    }) {
      return client.get<ScreeningResult>(`/strategy/company`, {
        params: {
          nationCode,
          indexCode,
          categoryIds: categoryIds ?? undefined,
          filter,
          weightPolicy,
          count,
          offset,
          orders,
        },
      });
    },
    async postStrategyFactorUnitPerformance(
      id: number,
      body: {
        nationCode: number;
        categoryIds?: Array<number>;
        startDate?: string;
        endDate?: string;
        rebalancingPeriod?: string;
        orders?: FactorOrder[];
        count?: number;
        tradeCost?: number;
      },
    ) {
      return client.post(`/strategy/factor/${id}/unit-performance`, body);
    },
  };
};
