import { Box, Stack } from '@mui/material';
import PriceHistoryChart from './PriceHistoryChart';

type Props = {
  cosmosCode: number;
};

function PriceValues({ cosmosCode }: Props) {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);

  return (
    <Stack
      width="100%"
      // align="start"
    >
      <Box
        sx={{
          marginBottom: '12px',
          width: '100%',
        }}
      >
        <PriceHistoryChart
          endDate={endDate}
          startDate={startDate}
          cosmosCode={cosmosCode}
        />

        {/* 
        <SubtitleWithTooltip
          label="Price VS Data"
          description="Price VS Data"
        />
        <PriceVSChart /> */}
      </Box>
    </Stack>
  );
}
export default PriceValues;
