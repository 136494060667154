import { useEffect, useRef } from 'react';

export const useDidUpdateEffect = (fn: () => void, inputs: Array<any>): any => {
  const didMountRef = useRef(false);

  return useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
    return () => {
      //
    };
  }, inputs);
};
