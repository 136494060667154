import RadarChart from 'react-svg-radar-chart';
import { getThemeData } from '~/utils/theme';
import './SpiderChart.css';

function SpiderChart({ data }: { data: [string, number][] }) {
  const themeData = getThemeData();
  const captions: {
    [key: string]: string;
  } = {};
  const values: {
    [key: string]: number;
  } = {};

  data.forEach((v) => {
    const [caption, value] = v;
    captions[caption] = caption;
    values[caption] = value;
  });

  return (
    // @ts-ignore
    <RadarChart
      captions={captions}
      // @ts-ignore
      style={{
        overflow: 'initial',
      }}
      options={{
        captionProps: () => {
          return {
            className: 'caption',
            textAnchor: 'middle',
            fontSize: 11,
            fontFamily: '',
          };
        },
      }}
      data={[
        // data
        {
          data: values,
          meta: {
            color: themeData.colors.primary[300],
          },
        },
      ]}
    />
  );
}

export default SpiderChart;
