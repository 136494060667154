import { useContext, useEffect, useState } from 'react';
import { Center, Flex, HStack, Spacer } from '@chakra-ui/layout';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Column } from 'react-table';
import { Redirect, useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Image } from '@chakra-ui/react';
import { isNil, uniq } from 'lodash';

import { Caption, H1, H2, H5 } from '~/components/Typography';
import { FactorStateContext } from '~/containers/factor/context';
import {
  ExecScreeneOrderType,
  Factor,
  GetScreeningResponse,
  SortData,
} from '~/api/screener';
import CsvDownloadButton from '~/components/CsvDownloadButton';
import MappedTable from '~/components/MappedTable';
import Pagination from '~/components/Pagination';
import { AppContext } from '~/AppContext';
import api from '~/api';
import { QueryFactor, QueryType } from '~/api/backtesting';
import ToastLimitComponent from '~/components/ToastLimitComponent';
import StyledButton from '~/components/StyledButton';
import iconInfo from '~/assets/icon/icon_info.svg';
import { ReactComponent as ShareIcon } from '~/assets/icon/icon_share_link.svg';
import { getIsMobile } from '~/utils/mediaQuery';

function Buttons() {
  const history = useHistory<{ query?: string }>();
  const [t] = useTranslation();

  return (
    <>
      <StyledButton
        sx={{
          height: '100%',
          width: ['100%', 'fit-content'],
          borderRadius: '8px',
        }}
        variant="outlined"
        onClick={() => {
          if (history.action === 'POP') {
            history.replace('/screener');
          } else if (
            history.action === 'PUSH' ||
            history.action === 'REPLACE'
          ) {
            history.goBack();
          }
        }}
      >
        {t('text.backButtonTitle')}
      </StyledButton>

      <StyledButton
        sx={{
          height: '100%',
          width: ['100%', 'fit-content'],
          borderRadius: '8px',
        }}
        variant="contained"
        onClick={() => {
          history.push('/backtest');
        }}
      >
        {t('text.goToBacktest')}
      </StyledButton>
    </>
  );
}
function Header() {
  const isMobile = getIsMobile();
  const history = useHistory<{ query?: string }>();
  const [t] = useTranslation();

  return (
    <Flex
      style={{
        display: 'sticky',
        height: '92px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderBottom: 'solid 1px #e5e5e5',
        alignContent: 'center',
      }}
      p={['12px 12px', '24px 24px']}
    >
      <Center>
        <H2 bold>{t('text.screenerResult')}</H2>
      </Center>
      <Spacer />
      <HStack height="100%">
        {!isMobile ? <Buttons /> : null}
        <IconButton
          size="medium"
          sx={{
            height: '100%',
            border: '1px solid #D9DCE0',
            borderRadius: '4px',
          }}
          onClick={() => {
            if (history.location.state.query) {
              navigator.clipboard
                .writeText(
                  `${window.location.origin}/screener?${history.location.state.query}`,
                )
                .finally(() => {
                  alert('URL 링크가 복사되었습니다.');
                });
            }
          }}
        >
          <ShareIcon />
        </IconButton>
      </HStack>
    </Flex>
  );
}
function ScreenerResultView() {
  const isMobile = getIsMobile();
  const [page, setPage] = useState<number>(0);
  const history = useHistory<{
    conditions: Array<QueryFactor>;
    query?: string;
  }>();
  const [t] = useTranslation();
  if (!history.location.state?.conditions) {
    history.replace('/backtest');
    return <div />;
  }
  const { conditions } = history.location.state;
  const count = 30;
  const [totalCount, setTotalCount] = useState<number>(0);
  const { state } = useContext(AppContext) ?? {};
  const factorState = useContext(FactorStateContext);
  const factorList = factorState?.factorList;

  const [screenerResult, setScreenerResult] = useState<GetScreeningResponse>();

  const selectGroups = Array.from(factorState?.companyGroupList.entries() ?? [])
    .map(([key, value]) => {
      if (value.length === 0) {
        return [];
      }
      if (value.length === 1) {
        return [key];
      }
      return value;
    })
    .flatMap((v) => v)
    .filter((v) => !factorState!.unselectedGroups.has(v.cosmosGroupId));

  // const [sortData, setSortData] = useState<SortData>();
  const queryParams = new URLSearchParams(window.location.search);

  /// {factorId}:{order}
  const orderByFactorsQueryParams = queryParams.getAll('orderByFactors') ?? [];

  const [orderByFactors, setOrderByFactors] = useState(
    orderByFactorsQueryParams.map<SortData>((v) => {
      const value = v.split(':');
      return {
        factorId: parseInt(value[0], 10),
        order: value[1] as ExecScreeneOrderType,
      };
    }),
  );

  useEffect(() => {
    if (state?.user) {
      api.screener
        .getScreeningCount({
          key: screenerResult?.key,
          nationCode: factorState!.selectedNation.code,
          categoryIds: selectGroups.map((v) => v.cosmosGroupId),
          factorIds: Array.from(
            new Set(conditions.map((v) => v.factorId)).values(),
          ),
          query: conditions,
        })
        .then((v) => {
          setTotalCount(v.data.totalCount);
        });

      const selectedCategoryIds = uniq(
        selectGroups.map((v) => v.cosmosGroupId),
      );
      api.screener
        .getScreening({
          key: screenerResult?.key, // page 갱신시 key 참조
          nationCode: factorState!.selectedNation.code,
          categoryIds:
            (factorState?.unselectedGroups.size ?? 0) > 0
              ? selectedCategoryIds
              : undefined,
          factorIds: Array.from(
            new Set(conditions.map((v) => v.factorId)).values(),
          ),
          orders: orderByFactors,
          query: conditions,
          count,
          offset: page * count,
        })
        .then((v) => {
          setScreenerResult(v.data);
          // logEvent('screening execute', {
          //   'num total screening result':
          //     screenerCountState.data.result.totalCount,
          //   'selected country to invest':
          //     factorState!.selectedNation.name,
          // });
        })
        .catch((e: AxiosError) => {
          history.goBack();
          // setIsLoading(false);
          state?.showToast(
            'error',
            e.response?.status === 429 ? (
              <ToastLimitComponent />
            ) : (
              <H5 noOfLines={10} whiteSpace="pre-wrap">
                {`예상하지 못한 오류가 발생했습니다(${e.response?.status})\n해당 현상이 반복된다면 문의 바랍니다`}
              </H5>
            ),

            {
              position: 'bottom-left',
              toastId: 'screener-exec-error-toast',
            },
          );
        });
    }
  }, [state?.user, orderByFactors, page]);
  function getRowDataFromScreenerResult(
    screenerResult: GetScreeningResponse,
  ): Array<Record<string, any>> {
    return screenerResult.companyInfos.map((result) => {
      const companyRebalancingInfo =
        screenerResult.companyRebalancingInfos.find(
          (v) => v.cosmosCode === result.cosmosCode,
        );
      return {
        company:
          result.companyLocalName && result.companyLocalName !== ''
            ? result.companyLocalName
            : result.companyName,
        ticker: result.ticker,
        score: companyRebalancingInfo?.score,
        cosmosCode: result.cosmosCode,
        marketDate: companyRebalancingInfo?.marketDate,
        marketPrice: companyRebalancingInfo?.marketPrice?.toLocaleString(
          undefined,
          {
            maximumFractionDigits: 2,
          },
        ),
        ...getMapFromRecord(
          companyRebalancingInfo?.factorValueMap ?? new Map(),
        ),
      };
    });
  }
  function getColumn(factors: Factor[], queryType: QueryType): Array<Column> {
    const fixeddFactorIds = [1133, 1100, 1096, 1087, 1112];

    const score =
      queryType === 'FILTER'
        ? []
        : [
            {
              Header: queryType,
              accessor: 'score',
            },
          ];
    return [
      // @ts-ignore
      { Header: 'company', accessor: 'company', sticky: 'left' },
      { Header: 'ticker', accessor: 'ticker' },
      { Header: 'cosmosCode', accessor: 'cosmosCode' },
      ...score,
      { Header: 'marketPrice', accessor: 'marketPrice' },
      { Header: 'marketDate', accessor: 'marketDate' },
      ...fixeddFactorIds.map((factorId) => {
        return {
          // @ts-ignore
          Header: factorId,
          accessor: factorId.toString(),
        };
      }),
      ...factors
        .filter((e) => !fixeddFactorIds.includes(e.id))
        .map((factor) => {
          return {
            // @ts-ignore
            Header: factor?.id,
            accessor: factor?.id.toString(),
          };
        }),
    ];
  }

  // const [screenerResultState] = useFetch<
  //   ResponseBody<ScreenerResult>
  // >(async () => {
  //   appState.repository
  //     .getScreenerCountResult(requestKey)
  //     .then((_totalCount) => {
  //       if (typeof _totalCount === 'number') {
  //         setTotalCount(_totalCount);
  //       }
  //     });

  //   return appState.repository.getScreenerResult({
  //     offset: page * count,
  //     count,
  //     requestKey,
  //     sortFactors: orderByFactors,
  //   });
  // }, [page, orderByFactors]);

  // if (screenerResultState.error || screenerResultState?.data?.code === 500) {
  //   useHistory().push('/screener', {
  //     error: screenerResultState.error,
  //   });
  //   return <H1>ERROR</H1>;
  // }
  if (!screenerResult) return <div />;

  return (
    <Stack direction="column" width="100%" height="-webkit-fill-available">
      <Header />
      <Stack
        padding={['12px 0px', '24px 48px']}
        width={['100vw', '100%']}
        height={['calc(100vh - 300px)', 'calc(100vh - 240px)']}
      >
        <Box paddingBottom="8px">
          <HStack paddingLeft="16px">
            <Caption
            // color={themeData.colors.text2}
            >
              Total
            </Caption>
            <Caption
              bold
              // color={themeData.colors.primary[500]}
            >
              {totalCount}
            </Caption>
            <Spacer />
            <CsvDownloadButton
              // requestKey={requestKey}
              conditions={conditions}
              orderByFactors={orderByFactors}
            />
          </HStack>
        </Box>

        {factorState?.sortData[0].data === 'F_SCORE' ? (
          <Stack direction="row" bgcolor="#f0f0f0" p="12px" mt="8px">
            <Image
              sx={{ mr: '4px' }}
              _focus={{ boxShadow: 'none', borderWidth: '0px' }}
              marginLeft="5px"
              src={iconInfo}
            />
            <Typography variant="body2" color="#404040">
              F-Score가 최소 1점인 이상인 종목들이 검색되었습니다.
            </Typography>
          </Stack>
        ) : null}

        <MappedTable
          orderByFactors={orderByFactors}
          updateSortData={(factorId) => {
            const orderByFactorIndex =
              orderByFactors?.findIndex((v) => v?.factorId === factorId) ?? -1;

            let updatedOrderByFactors: SortData[] = [];

            if (orderByFactorIndex < 0) {
              /// desc
              updatedOrderByFactors = [{ factorId, order: 'DESC' }];
            } else if (orderByFactors[orderByFactorIndex].order === 'DESC') {
              /// asc
              updatedOrderByFactors = [{ factorId, order: 'ASC' }];
            } else {
              /// delete
            }

            history.replace(
              `/screener-result${getQueryParams(updatedOrderByFactors)}`,
              { conditions },
            );

            setOrderByFactors(updatedOrderByFactors);

            //   /*
            //         sort 조건이 2개 이상 가능 할 때 이 로직을 쓴다

            //     const updatedOrderByFactors = [...orderByFactors];
            //     if (orderByFactorIndex < 0) {
            //       /// asc
            //       updatedOrderByFactors.push({ factorId, order: 'ASC' });
            //     } else if (orderByFactors[orderByFactorIndex].order === 'ASC') {
            //       /// desc
            //       updatedOrderByFactors[orderByFactorIndex] = {
            //         factorId,
            //         order: 'DESC',
            //       };
            //     } else {
            //       /// delete
            //       updatedOrderByFactors.splice(orderByFactorIndex, 1);
            //     }
            //     history.replace(
            //       `/screener-result/${requestKey}${getQueryParams(
            //         updatedOrderByFactors,
            //       )}`,
            //     );
            //     setOrderByFactors(updatedOrderByFactors);

            //     */
          }}
          columns={getColumn(
            screenerResult?.companyInfos.length === 0
              ? []
              : factorList?.filter((factor) => {
                  return !isNil(
                    Object(
                      screenerResult?.companyRebalancingInfos[0].factorValueMap,
                    )[factor.id.toString()],
                  );
                }) ?? [],
            conditions[0].queryType,
          )}
          data={getRowDataFromScreenerResult(screenerResult ?? [])}
        />
      </Stack>
      <Center p="16px 0px">
        <Pagination
          current={page}
          length={Math.ceil(totalCount / count)}
          onChange={(v) => setPage(v)}
        />
      </Center>
      <HStack
        display={isMobile ? 'inherit' : 'none'}
        width="100%"
        paddingX="16px"
      >
        <Buttons />
      </HStack>
    </Stack>
  );
}
const getQueryParams = (orderByFactors: SortData[]) => {
  return `?${orderByFactors
    .map((v) => `orderByFactors=${v.factorId}:${v.order}`)
    .join('&')}`;
};

function getMapFromRecord(
  factorValueMap: Map<string, number>,
): Record<string, string> {
  const r: Record<string, string> = {};

  Object.entries(factorValueMap).forEach(([i, v]) => {
    // const [i, v] = value;
    if (v === undefined || v === null) return;
    r[i.toString()] =
      parseInt(i, 10) <= 100
        ? v.toLocaleString(undefined, { maximumFractionDigits: 0 })
        : v.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
  });
  return r;
}
export default ScreenerResultView;
