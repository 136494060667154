import React, { ReactElement, useContext, useRef } from 'react';
import { Box, Button, CardMedia, Stack, Typography } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom';

import logo from '~/assets/logo.svg';
import { AppContext } from '~/AppContext';

export default function Landing() {
  const context = useContext(AppContext);
  const history = useHistory();

  if (context?.state?.user) {
    return <Redirect to="/select-strategy" />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#332d41',
      }}
    >
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        spacing={4}
      >
        <CardMedia
          sx={{ flex: 1, marginTop: '-104px', border: 'none' }}
          component="iframe"
          src="https://tailor.imweb.me/"
        />
      </Stack>
    </Box>
  );
}
