import { Box, Flex, Image } from '@chakra-ui/react';
import { useContext } from 'react';
import ReactSelect, {
  components,
  ControlProps,
  OptionProps,
} from 'react-select';

import { AppContext } from '~/AppContext';

// const getIcon = (value: string) => {
//   if (value === 'kr') {
//     return iconOvalKor;
//   }
//   return iconOvalEng;
// };

const getText = (value: string) => {
  if (value === 'kr') {
    return '한국어';
  }
  return 'ENG';
};

function LanguageSelector() {
  const appContext = useContext(AppContext);

  return (
    <Box m="0px 16px">
      <ReactSelect
        defaultValue={appContext?.state.language === 'kr' ? '한국어' : 'ENG'}
        menuPlacement="top"
        value={{
          // @ts-ignore
          label: getText(appContext?.state?.language),
          value: appContext?.state?.language,
          // icon: getIcon(appContext?.language ?? ''),
        }}
        // @ts-ignore
        onChange={({ value }) => {
          if (
            appContext?.dispatch &&
            appContext?.state &&
            value !== appContext?.state.language
          ) {
            appContext?.dispatch({ type: 'SET_LANGUAGE', language: value });
          }
        }}
        styles={{
          indicatorsContainer: (base) => ({
            ...base,

            // background: themeData.colors.primary[500],
          }),

          control: (base) => ({
            ...base,
            // background: themeData.colors.primary[500],
            // borderColor: themeData.colors.gray[200],
            padding: '0px',
            fontSize: '12px',
            textOverflow: 'initial',
          }),
          container: (base) => ({
            ...base,
          }),

          option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? '#A98BFA' : '',
            fontSize: '12px',
            ':hover': state.isSelected
              ? undefined
              : {
                  background: '#F6EDFF',
                },
          }),
          singleValue: (base) => ({
            ...base,

            color: 'black',
          }),
        }}
        isSearchable={false}
        components={{
          Option: (props: OptionProps<string, false, any>) => {
            return (
              <components.Option {...props}>
                <Flex style={{ color: 'black' }}>
                  {/*  eslint-disable-next-line react/destructuring-assignment */}
                  {props.children}
                </Flex>
              </components.Option>
            );
          },
          // DropdownIndicator: () => <Image src={iconDownside} w="12px" />,
          IndicatorSeparator: () => <div />,
          Control: ({ children, ...props }: ControlProps<any, false>) => {
            return (
              <components.Control {...props}>
                {/* <Box paddingLeft="4px"> */}
                {/* <Image src={iconGlobal} /> */}
                {/* </Box> */}

                {children}
              </components.Control>
            );
          },
        }}
        options={
          [
            { label: '한국어', value: 'kr' },
            { label: 'ENG', value: 'en' },
          ].map((e) => {
            return { value: e.value, label: e.label };
          }) as Array<any>
        }
      />
    </Box>
  );
}

export default LanguageSelector;
