import React, { useContext, useMemo, useState } from 'react';
import {
  Stack,
  Typography,
  TextField,
  Box,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  StackProps,
  InputAdornment,
} from '@mui/material';
import { toNumber, isFinite, clamp, isNumber } from 'lodash';
import { ko } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@date-io/date-fns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import dayjs from '~/utils/dayjs';
import { FactorOrder, RebalancingPeriod } from '~/api/backtesting';
import SelectableStackItem from './SelectableStackItem';
import { Universe } from './UniverseList';
import { ReactComponent as IconInfo } from '~/assets/icon/icon_info.svg';

export type OrderOption = {
  name: string;
  ordering: FactorOrder;
};

interface BacktestingOption {
  startDate?: Date | null;
  endDate?: Date | null;
  count?: number | null;
  tradeCost?: number | null;
  period?: RebalancingPeriod | null;
}

interface BacktestingOptionProp {
  // params: 백테스팅 옵션값 던져야함
  option?: BacktestingOption;
  isCountable?: boolean;
  maxCount?: number;
  universe?: Universe | null;
  onUpdate?: (option: BacktestingOption) => void;

  orderOptionIndex: number | '';
  indexOrderOptions: OrderOption[];
  nationOrderOptions: OrderOption[];
  onUpdateOrderOptionIndex?: (index: number | '') => void;
}

BacktestingOption.defaultProps = {
  option: {},
  isCountable: true,
  onUpdate: null,
  onUpdateOrderOptionIndex: null,
  maxCount: 50,
  universe: null,
};

function BacktestingOption({
  option,
  isCountable,
  maxCount,
  universe,
  onUpdate,

  orderOptionIndex,
  indexOrderOptions,
  nationOrderOptions,
  onUpdateOrderOptionIndex,
}: BacktestingOptionProp) {
  const [t] = useTranslation();
  const [inputCount, setInputCount] = useState<string>(
    (option?.count ?? 30).toString(),
  );

  const validatedCount = useMemo(() => {
    const n = toNumber(inputCount);
    return isFinite(n) && n >= 5 && n <= (maxCount ?? 50);
  }, [inputCount]);

  const minStartDate = useMemo(() => {
    return universe?.universe === 'nation'
      ? dayjs('2005-02-01T00:00:00Z').toDate()
      : dayjs('2011-01-01T00:00:00Z').toDate();
  }, [universe?.universe]);

  const orderOption =
    universe?.universe === 'index' ? indexOrderOptions : nationOrderOptions;
  return (
    <>
      <SelectableStackItem hasBorder={false} bgcolor="#FAFAFA" selected={false}>
        <Typography variant="subtitle2" flex={1} pb="16px">
          {t('directIndex.portfolio_configuration')}
        </Typography>
        <Stack direction="column" spacing={4}>
          <Box>
            <Stack direction="row" spacing={1} py={1}>
              <FormControl sx={{ flex: 1 }} variant="outlined">
                <InputLabel>{t('text.stock_priority')}</InputLabel>
                <Select
                  label={t('text.stock_priority')}
                  sx={{
                    backgroundColor: '#FFF',
                  }}
                  size="small"
                  displayEmpty
                  value={orderOptionIndex}
                  onChange={(e) =>
                    onUpdateOrderOptionIndex?.(
                      isNumber(e.target.value) ? Number(e.target.value) : '',
                    )
                  }
                >
                  {(universe?.universe === 'index'
                    ? indexOrderOptions
                    : nationOrderOptions
                  ).map((option, index) => {
                    return (
                      <MenuItem key={option.name} value={index}>
                        {t(`screenerSort.${option.name}`)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ flex: 1 }} variant="outlined">
                <InputLabel>{t('text.investment_weight')}</InputLabel>
                <Select
                  sx={{
                    backgroundColor: '#FFF',
                  }}
                  size="small"
                  label={t('text.investment_weight')}
                  value={0}
                >
                  {universe?.universe === 'index' ? (
                    <MenuItem value={0}>{t('text.indexProportion')}</MenuItem>
                  ) : (
                    <MenuItem value={0}>{t('text.sameProportion')}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Stack>
          </Box>
        </Stack>
      </SelectableStackItem>
      <SelectableStackItem hasBorder={false} bgcolor="#FAFAFA" selected={false}>
        <Typography variant="subtitle2" flex={1} pb="16px">
          {t('directIndex.rebalancing_configuration')}
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            flex: 1,
          }}
        >
          {isCountable ? (
            <TextField
              sx={{
                flex: 1,
                backgroundColor: '#FFF',
              }}
              size="small"
              error={!validatedCount}
              type="number"
              label={t('text.number_of_components')}
              variant="outlined"
              defaultValue={inputCount}
              InputProps={{ inputProps: { min: 5, max: maxCount ?? 50 } }}
              helperText={!validatedCount ? '5 ~ 50 사이의 값' : null}
              onChange={(e) => {
                setInputCount(e.target.value);
                onUpdate?.({
                  count: clamp(toNumber(e.target.value), 5, maxCount ?? 50),
                });
              }}
            />
          ) : null}
          <FormControl
            sx={{
              flex: 1,
            }}
          >
            <InputLabel>{t('text.portfolioRebalancingCycle')}</InputLabel>
            <Select
              size="small"
              sx={{
                backgroundColor: '#FFF',
              }}
              label={t('text.portfolioRebalancingCycle')}
              value={option?.period}
              onChange={(e) =>
                onUpdate?.({
                  period: e.target.value as RebalancingPeriod,
                })
              }
            >
              {['ANNUALLY', 'SEMIANNUALLY', 'QUARTERLY'].map((period) => {
                return (
                  <MenuItem key={period} value={period}>
                    {t(`period.${period}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      </SelectableStackItem>
      <SelectableStackItem hasBorder={false} bgcolor="#FAFAFA" selected={false}>
        <Typography variant="subtitle2" flex={1} pb="16px">
          {t('directIndex.performance_measurement_criteria')}
        </Typography>
        <Stack direction="column" spacing={4}>
          <Stack>
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                <DesktopDatePicker
                  views={['year', 'month']}
                  InputProps={{
                    size: 'small',
                    sx: {
                      backgroundColor: '#FFF',
                    },
                  }}
                  label={t('text.start_date')}
                  inputFormat="yyyy/MM"
                  mask="____/__"
                  value={option?.startDate}
                  minDate={minStartDate}
                  maxDate={dayjs(option?.endDate ?? undefined)
                    .subtract(1, 'month')
                    .toDate()}
                  onChange={(v: any) =>
                    onUpdate?.({
                      startDate: dayjs
                        .max([dayjs(minStartDate), dayjs(v).startOf('month')])
                        .toDate(),
                    })
                  }
                  renderInput={(params: any) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField
                      {...params}
                      sx={{ flex: 1 }}
                      variant="outlined"
                    />
                  )}
                />
                <DesktopDatePicker
                  views={['year', 'month']}
                  InputProps={{
                    size: 'small',
                    sx: {
                      backgroundColor: '#FFF',
                    },
                  }}
                  label={t('text.end_date')}
                  inputFormat="yyyy/MM"
                  mask="____/__"
                  value={option?.endDate}
                  maxDate={dayjs().add(-1, 'month').endOf('month').toDate()}
                  onChange={(v: any) =>
                    onUpdate?.({
                      endDate: dayjs
                        .min([
                          dayjs(v).endOf('month'),
                          dayjs().add(-1, 'month').endOf('month'),
                        ])
                        .toDate(),
                    })
                  }
                  renderInput={(params: any) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField
                      {...params}
                      sx={{ flex: 1 }}
                      variant="outlined"
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Typography marginTop="8px" variant="body2" color="text.secondary">
              {t('text.startDateCaption', {
                value: dayjs(minStartDate).format('YYYY-MM'),
              })}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              flex: 1,
            }}
          >
            <TextField
              sx={{
                flex: 1,
                backgroundColor: '#FFF',
              }}
              size="small"
              type="number"
              label={t('text.transaction_cost_setting')}
              variant="outlined"
              value={option?.tradeCost}
              InputProps={{
                inputProps: { min: 0, max: 10, step: 0.05 },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(e) =>
                onUpdate?.({
                  tradeCost: Number(e.target.value),
                })
              }
            />
            <FormControl
              sx={{
                flex: 1,
              }}
            >
              {universe ? (
                <>
                  <InputLabel>{t('text.benchmark')}</InputLabel>
                  <Select
                    size="small"
                    sx={{
                      backgroundColor: '#FFF',
                    }}
                    label={t('text.benchmark')}
                    value={
                      universe.universe === 'index'
                        ? t(`index.${universe.name}.name`)
                        : t(`nation.${universe.name}.benchmark`)
                    }
                  >
                    <MenuItem
                      value={
                        universe.universe === 'index'
                          ? t(`index.${universe.name}.name`)
                          : t(`nation.${universe.name}.benchmark`)
                      }
                    >
                      {universe.universe === 'index'
                        ? t(`index.${universe.name}.name`)
                        : t(`nation.${universe.name}.benchmark`)}
                    </MenuItem>
                  </Select>
                </>
              ) : null}
            </FormControl>
          </Stack>
        </Stack>
      </SelectableStackItem>
    </>
  );
}

export default BacktestingOption;
