import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';

type Props = {
  animationData: any;
  height?: string;
  width?: string;
};
function LottieAnimation({ height, width, animationData }: Props) {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (element.current)
      // add this
      lottie.loadAnimation({
        animationData,
        container: element.current,
        loop: true,
      });
  }, [element]);

  return <div style={{ height, width }} ref={element} />;
}
LottieAnimation.defaultProps = {
  height: null,
  width: null,
};
export default LottieAnimation;
