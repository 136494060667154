import { Box } from '@chakra-ui/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StyledButton from '~/components/StyledButton';
import { getThemeData } from '~/utils/theme';
import FinancialValues from './FinancialValues';
import PriceValues from './PriceValues';
import Summary from './Summary';

type Props = {
  cosmosCode: number;
};

function MobileTabBody({ cosmosCode }: Props) {
  const [t] = useTranslation();
  const history = useHistory();
  const themeData = getThemeData();
  const [value, setValue] = useState<string>('요약 정보');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        // mx: '12px',
        background: 'white',
        borderRadius: '8px',
        boxShadow: '0px',
        paddingBottom: '156px',
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: `solid 1px ${themeData.colors.gray[200]}`,
          }}
        >
          <TabList onChange={handleChange} indicatorColor="secondary">
            {['요약 정보', '재무 데이터'].map((v) => (
              <Tab
                label={v}
                value={v}
                style={{
                  color:
                    value === v
                      ? themeData.colors.primary[500]
                      : themeData.colors.gray[500],
                  fontWeight: value === v ? 'bold' : 'inherit',
                }}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel
          value="요약 정보"
          sx={{
            padding: '0px 12px',
            height: '100%',
            overflow: 'scroll',
          }}
        >
          <Summary cosmosCode={cosmosCode} />
        </TabPanel>

        <TabPanel
          value="재무 데이터"
          sx={{ padding: '0px', height: '100%', overflow: 'scroll' }}
        >
          <Box
            height="100%"
            mt="16px"
            p="24px 12px"
            bg="white"
            borderRadius="8px"
          >
            <PriceValues cosmosCode={cosmosCode} />
            <FinancialValues cosmosCode={cosmosCode} />
          </Box>
        </TabPanel>

        {/* </Tabs> */}
      </TabContext>
      <Box px="8px">
        <StyledButton
          variant="contained"
          sx={{ width: '100%', borderRadius: '12px' }}
          onClick={() => {
            if (
              history.action === 'POP' &&
              window.name.startsWith('company-detail')
            ) {
              window.close();
            } else if (history.action === 'POP') {
              // history.replace('/screener');
              history.replace('/');
            } else if (
              history.action === 'PUSH' ||
              history.action === 'REPLACE'
            ) {
              history.goBack();
            }
          }}
        >
          이전 결과로 돌아가기
        </StyledButton>
      </Box>
    </Box>
  );
}

export default MobileTabBody;
