import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import './assets/style/theme/font.css';
import './assets/style/index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';

if (process.env.REACT_APP_FB_API_KEY) {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    appId: process.env.REACT_APP_FB_APP_ID,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    measurementId: process.env.REACT_APP_FB_MESUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
