import { CSSObject, Button, ButtonProps } from '@mui/material';
import { styled, Theme, ThemeProvider, useTheme } from '@mui/material/styles';

const mixins = (theme: Theme): CSSObject => ({
  [theme.breakpoints.down('sm')]: {
    height: '52px',
  },
  [theme.breakpoints.up('sm')]: {},
});

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  ...mixins(theme),
}));

export default StyledButton;
