import { ReactElement, useEffect, useMemo, useState } from 'react';
import amplitude from 'amplitude-js';
import { Amplitude, AmplitudeProvider } from 'react-amplitude-hooks';
import { useLocation } from 'react-router';

function AmplitudeWrapper({ children }: { children: ReactElement }) {
  const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY ?? '';
  const [prevPath, setPrevPath] = useState<string>('');

  const instance = useMemo(() => {
    return amplitude.getInstance();
  }, [amplitude]);

  const location = useLocation();
  useEffect(() => {
    instance.logEvent('page view', {
      'prev page url': prevPath,
      'page url': location.pathname,
    });

    setPrevPath(location.pathname);
  }, [instance, location]);

  useEffect(() => {
    setTimeout(() => {
      instance.logEvent('APP_INITIALIZED', {
        referrer:
          (document.referrer?.length ?? 0) > 0 ? document.referrer : 'unknown',
      });
      instance.setUserProperties({
        'last visit date': new Date(),
      });
    }, 10000);
  }, [instance]);

  return (
    <AmplitudeProvider amplitudeInstance={instance} apiKey={AMPLITUDE_KEY}>
      <Amplitude>{children}</Amplitude>
    </AmplitudeProvider>
  );
}

export default AmplitudeWrapper;
