import { Flex, Spacer, Button, Center } from '@chakra-ui/react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { H1, H5 } from '~/components/Typography';
import SearchContainer from '~/containers/search/SearchContainer';
import { getThemeData } from '~/utils/theme';
import StyledButton from '../StyledButton';

function CompanyDetailHeader() {
  const history = useHistory();
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Stack
      direction="row"
      borderBottom={{ xs: '0px', sm: 'solid 1px #e5e5e5' }}
      style={{
        display: 'sticky',
        height: '92px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'white',
        alignContent: 'center',
      }}
      p={{ xs: '12px', sm: '24px' }}
    >
      <Flex flexDirection="column">
        <Typography variant="headline1">{t('companyDetail.stocks')}</Typography>
      </Flex>
      <Stack
        marginLeft="24px"
        sx={{ display: { xs: 'none', sm: 'inherit' } }}
        alignSelf="center"
      >
        <SearchContainer />
      </Stack>
      <Spacer />
      <StyledButton
        variant="outlined"
        sx={{
          height: '100%',

          width: 'fit-content',
          px: '16px',
          borderRadius: '8px',
        }}
        onClick={() => {
          if (
            history.action === 'POP' &&
            window.name.startsWith('company-detail')
          ) {
            window.close();
          } else if (history.action === 'POP') {
            // history.replace('/screener');
            history.replace('/');
          } else if (
            history.action === 'PUSH' ||
            history.action === 'REPLACE'
          ) {
            history.goBack();
          }
        }}
      >
        {t('text.backButtonTitle')}
      </StyledButton>
    </Stack>
  );
}

export default CompanyDetailHeader;
