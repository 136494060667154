import { useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack } from '@mui/material';

import CompanyDetailHeader from '~/components/company_detail/CompanyDetailHeader';
import Summary from '~/containers/company_detail/Summary';
import TabBody from '~/containers/company_detail/TabBody';
import { AppContext } from '~/AppContext';
import MobileTabBody from '~/containers/company_detail/MobileTabBody';

type Props = {
  cosmosCode: number;
};

interface ParamTypes {
  cosmosCode: string;
}

function CompanyDetailBody({ cosmosCode }: Props) {
  const [t] = useTranslation();
  const { state } = useContext(AppContext) ?? {};

  return useMemo(() => {
    if (state?.user) {
      return (
        <>
          <Grid
            sx={{
              display: {
                xs: 'none',
                sm: 'inherit',
              },
            }}
            paddingBottom="30px"
            container
            width="100%"
            height="100%"
            columns={3}
            px="16px"
          >
            <Grid height="100%" item xs={3} sm={1}>
              <Summary cosmosCode={cosmosCode} />
            </Grid>
            <Grid height="100%" item xs={3} sm={2}>
              <TabBody cosmosCode={cosmosCode} />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: {
                xs: 'inherit',
                sm: 'none',
              },
            }}
            width="100%"
            height="100%"
          >
            <MobileTabBody cosmosCode={cosmosCode} />
          </Box>
        </>
      );
    }
    return <div />;
  }, [state?.user, cosmosCode]);
}

function CompanyDetailView() {
  const { cosmosCode } = useParams<ParamTypes>();
  return (
    <Stack
      height={['-webkit-fill-available', '100%']}
      width="100%"
      direction="column"
    >
      <CompanyDetailHeader />

      <Stack
        sx={
          {
            // background: {themeData.colors.background}
          }
        }
        height={['calc(100vh - 80px)', 'calc(100vh - 156px)']}
      >
        <CompanyDetailBody cosmosCode={parseInt(cosmosCode, 10)} />
      </Stack>
    </Stack>
  );
}

export default CompanyDetailView;
