import { Box, BoxProps } from '@mui/material';
import { ReactComponent as ComingSoonContents } from '~/assets/icon/coming_soon_contents.svg';

export default function StrategyComingSoonCard({ ...boxProps }: BoxProps) {
  return (
    <Box
      {...boxProps}
      bgcolor="#FAFAFA"
      border="2px dashed rgba(0, 0, 0, 0.08);"
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <ComingSoonContents />
    </Box>
  );
}
