import React, { useMemo } from 'react';
import { Box, BoxProps } from '@mui/material';
import { includes, isNil, some } from 'lodash';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { useHistory } from 'react-router';

import { Company } from '~/api/strategy';
import {
  BacktestingComapnyRebalancingInfo,
  ExcludedCompany,
} from '~/api/backtesting';

interface CompanyTreeMapProps {
  companies: Company[];
  rebalancingList: BacktestingComapnyRebalancingInfo[];
  count?: number;
  weightMode?: 'equal' | 'value';
  excludedCompanies?: ExcludedCompany[];
}

PortfolioTreeMap.defaultProps = {
  count: undefined,
  excludedCompanies: [],
  weightMode: 'value',
};

function PortfolioTreeMap({
  companies,
  rebalancingList,
  count,
  weightMode,
  excludedCompanies,
  ...boxProps
}: CompanyTreeMapProps & BoxProps) {
  const companyRatioList = useMemo(() => {
    const filteredCompanies = companies.filter(
      (company) => !some(excludedCompanies, (c) => c.id === company.cosmosCode),
    );
    if (!isNil(count)) {
      filteredCompanies.splice(count);
    }

    const list: (Company & Partial<BacktestingComapnyRebalancingInfo>)[] =
      filteredCompanies.slice(0, 50).map((company) => {
        const rebalancingInfo = rebalancingList.find(
          (rebalancing) => rebalancing.cosmosCode === company.cosmosCode,
        );
        return {
          ...rebalancingInfo,
          ...company,
        };
      });

    if (filteredCompanies.length > 50) {
      list.push(
        filteredCompanies.slice(50).reduce(
          (
            r: Company & Partial<BacktestingComapnyRebalancingInfo>,
            company,
          ) => {
            const rebalancingInfo = rebalancingList.find(
              (rebalancing) => rebalancing.cosmosCode === company.cosmosCode,
            );
            r.weight = (r.weight ?? 0) + (rebalancingInfo?.weight ?? 0);
            return r;
          },
          {
            ticker: 'unknown',
            companyLocalName: null,
            companyName: 'unknown',
            cosmosCode: -1,
            weight: 0,
            marketCap: 0,
            cosmosGroupId: 0,
            currency: 'unknown',
            exchangeCode: 0,
          },
        ),
      );
    }

    const totalValue = list.reduce((r, company) => {
      return r + (weightMode === 'value' ? company?.weight ?? 0 : 1);
    }, 0);

    return list.map((company) => {
      return {
        id: company.cosmosCode,
        ticker: company.ticker,
        name: company.companyName,
        ratio:
          totalValue !== 0
            ? (weightMode === 'value' ? company?.weight ?? 0 : 1) / totalValue
            : 0,
      };
    });
  }, [companies, excludedCompanies]);

  return (
    <Box {...boxProps}>
      <ResponsiveTreeMap
        data={{
          name: 'stocks',
          fullName: 'stocks',
          children:
            companyRatioList.map((stock, index) => {
              return {
                id: stock.id,
                name: stock.ticker,
                fullName: stock.name,
                ratio: stock.ratio,
                index,
              };
            }) ?? [],
        }}
        colors={(d) => {
          if (companyRatioList.length < 1) {
            return 'rgba(94, 65, 176)';
          }

          const rank = companyRatioList.findIndex(
            (item) => item.ratio <= d.value,
          );

          return `rgba(94, 65, 176, ${
            (companyRatioList.length - rank) / companyRatioList.length / 2 + 0.5
          })`;
        }}
        leavesOnly
        label={(node) => node.data.name}
        identity="id"
        value="ratio"
        valueFormat=".02%"
        onClick={(node) => {
          // history.push(`/company-detail/${node.id}`);

          window.open(
            `/company-detail/${node.id}`,
            `company-detail-${node.id}`,
          );
        }}
        tooltip={(node) => {
          return (
            <div
              style={{
                borderRadius: '8px',
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
              }}
            >
              <div>
                {
                  // @ts-ignore
                  (node.node.data?.fullName as string) ?? ''
                }{' '}
                {node.node.formattedValue}
              </div>
            </div>
          );
        }}
        nodeOpacity={1}
        margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
        labelSkipSize={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['brighter', 4]],
        }}
        parentLabelPosition="top"
        parentLabelTextColor={{
          from: 'color',
          modifiers: [['brighter', 4]],
        }}
        borderColor={{
          from: 'color',
          modifiers: [['brighter', 4]],
        }}
      />
    </Box>
  );
}

export default PortfolioTreeMap;
