import { InputAdornment, TextField } from '@mui/material';
import { clamp, floor } from 'lodash';
import { useEffect, useState } from 'react';

export default function BufferRateTextField({
  initialBufferRate,
  onChange,
}: {
  initialBufferRate: number;
  onChange: (v: number) => void;
}) {
  const [bufferRate, setBufferRate] = useState(initialBufferRate * 100);

  useEffect(() => {
    const update = setTimeout(() => {
      const value = clamp(bufferRate, 0.5, 5);

      setBufferRate(value);
      onChange(value / 100);
    }, 1000);
    return () => clearTimeout(update);
  }, [bufferRate]);

  return (
    <TextField
      size="small"
      type="number"
      variant="standard"
      value={bufferRate}
      sx={{ width: '80px' }}
      InputProps={{
        inputProps: {
          min: 0.5,
          max: 5,
          step: 0.1,
          style: { textAlign: 'right' },
        },
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      onChange={(e) => {
        setBufferRate(floor(Number(e.target.value), 1));
      }}
    />
  );
}
