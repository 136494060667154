import { ThemeOptions } from '@mui/material/styles';

import { light as lightPalette } from './palette';
import typography from './typography';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: false;
    lg: false;
    xl: false;
  }
}

export const lightOption: ThemeOptions = {
  palette: lightPalette,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
    },
  },
};
