import text from './locale/text.json';
import factor from './locale/factor.json';
import factorCategory from './locale/factorCategory.json';
import index from './locale/index.json';
import nation from './locale/nation.json';
import screenerSort from './locale/screenerSort.json';
import period from './locale/period.json';
import directIndex from './locale/directIndexing.json';
import category from './locale/category.json';
import companyDetail from './locale/company_detail.json';
import companyDetailFactor from './locale/company_detail_factor.json';
import strategy from './locale/strategy.json';
import benchmark from './locale/benchmark.json';

export default {
  text,
  factor,
  factorCategory,
  index,
  nation,
  screenerSort,
  period,
  directIndex,
  category,
  companyDetail,
  companyDetailFactor,
  strategy,
  benchmark,
};
