import {
  Box,
  Step,
  StepIconProps,
  Stepper,
  StepperProps,
  styled,
  StepLabelProps,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import LottieAnimation from './LottieAnimation';
import lottieStepperCircle from '~/assets/lottie/lottie_stepper_circle.json';

interface CustomStepperProps {
  activeStep?: number;
  titleList: Array<string>;
}

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'rgba(0, 0, 0, 0.08);',
  },
}));

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
  }),
);

const StyledStepLabel = styled(StepLabel)<StepLabelProps>(({ theme }) => ({
  [`& .${stepLabelClasses.alternativeLabel}`]: {
    marginTop: '0px',
  },
}));
function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      <Box display={active ? 'initial' : 'none'} position="absolute" zIndex={1}>
        <LottieAnimation
          width="30px"
          height="30px"
          animationData={lottieStepperCircle}
        />
      </Box>
      <Box
        zIndex={2}
        sx={{
          margin: '10px',
          width: 10,
          height: 10,
          background: completed || active ? '#9E7DF9' : 'rgba(0, 0, 0, 0.08);',
          borderRadius: 5,
        }}
      />
    </CustomStepIconRoot>
  );
}

export default function CustomStepper({
  activeStep,
  titleList,
  ...stepperProps
}: CustomStepperProps & StepperProps) {
  return (
    <Stepper
      key={`stepper-${activeStep}`}
      activeStep={activeStep}
      connector={<CustomStepConnector />}
      {...stepperProps}
    >
      {titleList.map((title) => (
        <Step key={`stepper-${activeStep}=${title}`}>
          <StyledStepLabel StepIconComponent={CustomStepIcon}>
            {title}
          </StyledStepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
CustomStepper.defaultProps = {
  activeStep: undefined,
};
