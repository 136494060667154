import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  findLast,
  groupBy,
  isNil,
  last,
  map,
  sum,
  transform,
  uniq,
} from 'lodash';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  MenuItem,
  Menu,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
} from '@mui/material';
import {
  Close as CloseIcon,
  Public as PublicIcon,
  RemoveCircle,
  AddBox,
  Refresh,
  Event as EventIcon,
  Factory,
  MoreVert,
  DeleteOutline,
  DashboardCustomize,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import { ModalUnstyledProps } from '@mui/base/ModalUnstyled/ModalUnstyled.types';

import dayjs from '~/utils/dayjs';
import { AppContext } from '~/AppContext';
import api from '~/api';
import { Portfolio, ModelAsset, SimulationRecipe } from '~/api/portfolio';
import { Category, Strategy } from '~/api/strategy';
import StrategyPortfolioApplier from '~/containers/StrategyPortfolioApplier';
import StyledModal from '~/components/StyledModal';
import StyledButton from '~/components/StyledButton';
import { convertFactorFilterToQuery } from '~/api/backtesting';

function phoneFormatter(num: string) {
  const filtered = num.replace(/[^0-9]/g, '');
  const parts = [filtered.slice(0, 3), filtered.slice(3, 7), filtered.slice(7)];
  return parts.filter((v) => v.length > 0).join('-');
}

function PortfolioCreationModal({
  strategy,
  onCreate,
  ...modalProps
}: Omit<ModalUnstyledProps, 'children'> & {
  strategy: Strategy;
  onCreate: (strategy: Strategy, name: string) => void;
}) {
  const [name, setName] = useState<string>(strategy?.name ?? '');
  useEffect(() => {
    setName(strategy?.name ?? '');
  }, [strategy]);

  const [t] = useTranslation();
  return (
    <StyledModal disableAutoFocus disableEnforceFocus {...modalProps}>
      <Stack
        sx={{
          borderRadius: '8px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          boxShadow: 24,
        }}
      >
        <Stack
          sx={{
            p: '16px',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Box
              display="flex"
              width="40px"
              height="40px"
              bgcolor="#F6EDFF"
              borderRadius="20px"
              justifyContent="center"
              alignItems="center"
            >
              <DashboardCustomize color="secondary" />
            </Box>
            <Typography ml="8px" fontWeight="bold" flex={1}>
              {t('text.startMonitoring')}
            </Typography>
            <IconButton
              onClick={(e) => modalProps.onClose?.(e, 'backdropClick')}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography variant="body2" mt="16px">
            {t('text.startMonitoringDesc')}
          </Typography>
          <TextField
            sx={{
              flex: 1,
              backgroundColor: '#FFF',
              marginTop: '12px',
            }}
            size="small"
            type="text"
            label="이름"
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Stack>

        <Box
          sx={{
            background: '#FAFAFA',
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
          }}
          py="16px"
          pr="16px"
        >
          <StyledButton
            sx={{
              width: 'fit-content',
            }}
            variant="contained"
            disabled={(name?.length ?? 0) < 1}
            onClick={() => onCreate(strategy, name)}
          >
            시작하기
          </StyledButton>
        </Box>
      </Stack>
    </StyledModal>
  );
}

export default function StrategyListView() {
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();

  const { state, dispatch } = useContext(AppContext) ?? {};
  const history = useHistory();

  const { amplitudeInstance } = useAmplitude();
  const [targetApplyStrategyId, setTargetApplyStrategyId] = useState<
    string | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [isOpenAlertDeleteStrategy, setIsOpenAlertDeleteStrategy] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteStrategyId, setIsOpenConfirmDeleteStrategyId] =
    useState<string | null>(null);

  const [openDetailStrategy, setOpenDetailStrategy] = useState<Strategy | null>(
    null,
  );

  const [openStrategyMenuState, setOpenStrategyMenuState] = useState<null | {
    anchorEl: HTMLElement;
    strategyId: string;
  }>(null);

  useEffect(() => {
    amplitudeInstance?.setUserProperties({
      'num total of saved strategies': strategies.length,
    });
  }, [strategies]);
  useEffect(() => {
    amplitudeInstance?.setUserProperties({
      'num total of saved portfolios': portfolios.length,
    });
  }, [portfolios]);

  const requestData = () => {
    setIsLoading(true);
    Promise.all([api.portfolio.portfolios(), api.strategy.strategies()])
      .then(
        ([portfoliosRes, strategiesRes]) => {
          setPortfolios(portfoliosRes.data);
          setStrategies(strategiesRes.data);
        },
        (error) => {
          console.log('fail', error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const applyStrategy = (
    portfolioId: string,
    strategyId: string,
    assets?: ModelAsset[],
    recipe?: SimulationRecipe,
  ) => {
    api.portfolio.applyStrategy(portfolioId, strategyId, assets, recipe).then(
      () => {
        requestData();
      },
      (error) => {
        console.log('fail', error);
      },
    );
  };

  const detachStrategy = (portfolioId: string) => {
    api.portfolio.detachStrategy(portfolioId).then(
      () => {
        requestData();
      },
      (error) => {
        console.log('fail', error);
      },
    );
  };

  const deleteStrategy = (strategyId: string) => {
    api.strategy.deleteStrategy(strategyId).then(
      () => {
        requestData();
      },
      (error) => {
        console.log('fail', error);
      },
    );
  };

  // 계좌 전체리스트 가지고 있기 vs 적용할 수 있는 전략들+적용된 전략들 조회
  useEffect(() => {
    if (state?.user) {
      api.strategy
        .getSectors()
        .then((response) => {
          setCategoryList(response.data);
        })
        .catch((error) => {
          console.log('fail', error);
        });
      requestData();
    }
  }, [state?.user]);

  const handleCloseMenu = () => {
    setOpenStrategyMenuState(null);
  };

  const [portfolioCreationTargetStrategy, setPortfolioCreationTargetStrategy] =
    useState<Strategy | null>(null);

  const requestCreatePortfolio = (strategy: Strategy, name: string) => {
    api.portfolio
      .createPortfolio({
        name,
      })
      .then(
        async (res) => {
          await api.portfolio.applyStrategy(res.data.id, strategy.id);
          // 새로고침

          history.push(`/portfolio/${res.data.uid}`);
        },
        (e) => {
          alert(e.message);
        },
      )
      .finally(() => {
        setPortfolioCreationTargetStrategy(null);
      });
  };

  // const appliedStrategyPortfolioStateMap = useMemo(() => {
  //   return transform<Strategy, { [key: number]: Portfolio[] }>(
  //     strategies,
  //     (r, strategy) => {
  //       return Object.assign(r, {
  //         [strategy.id]: portfolios?.filter(
  //           (portfolio) => portfolio?.strategy?.id === strategy.id,
  //         ),
  //       });
  //     },
  //     {},
  //   );
  // }, [portfolios, strategies]);
  //
  // const applicableStrategyPortfolioStates = useMemo(() => {
  //   return portfolios?.filter((portfolio) => !portfolio.strategy);
  // }, [portfolios, strategies]);

  const portfolioApplierModal = useMemo(() => {
    if (!targetApplyStrategyId) {
      return null;
    }
    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={!!targetApplyStrategyId}
        onClose={() => setTargetApplyStrategyId(null)}
      >
        <StrategyPortfolioApplier
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            minWidth: '600px',
            width: '50%',
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          p={2}
          strategyId={targetApplyStrategyId}
          onCompleted={(portfolio, modelAsset, recipe) => {
            applyStrategy(portfolio.id, targetApplyStrategyId, modelAsset);
            setTargetApplyStrategyId(null);
          }}
        />
      </StyledModal>
    );
  }, [targetApplyStrategyId]);

  const strategyDetailModal = useMemo(() => {
    if (!openDetailStrategy) {
      return null;
    }

    const appliedFactors = [
      ...(openDetailStrategy?.factorQuery ?? []),
      ...convertFactorFilterToQuery(openDetailStrategy.factorFilter),
    ];

    const appliedFactorCount = sum(
      appliedFactors.map((f) => [f.gt, f.lt].filter((v) => !isNil(v)).length),
    );

    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={!!openDetailStrategy}
        onClose={() => setOpenDetailStrategy(null)}
      >
        <Stack
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            minWidth: ['100vw', '600px'],
            overflow: 'auto',
            // minWidth: '600px',
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          p={2}
          spacing="8px"
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="headline1" flex={1}>
              {t('text.details')}
            </Typography>
            <IconButton
              size="small"
              onClick={() => setOpenDetailStrategy(null)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <EventIcon />
            <Typography variant="subtitle2" flex={1}>
              {t('text.check_start_date')}
            </Typography>
            <Typography>
              {dayjs(
                openDetailStrategy.lastBacktestingPerformance?.startDate,
              ).format('YYYY-MM')}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <EventIcon />
            <Typography variant="subtitle2" flex={1}>
              {t('text.check_end_date')}
            </Typography>
            <Typography>
              {dayjs(
                openDetailStrategy.lastBacktestingPerformance?.endDate,
              ).format('YYYY-MM')}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <Refresh />
            <Typography variant="subtitle2" flex={1}>
              {t('text.portfolio_rebalancing_period')}
            </Typography>
            <Typography>
              {openDetailStrategy?.rebalancingPeriod
                ? t(`period.${openDetailStrategy?.rebalancingPeriod}`)
                : t('text.none')}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <EventIcon />
            <Typography variant="subtitle2" flex={1}>
              {t('text.transactionCost')}
            </Typography>
            <Typography>
              {openDetailStrategy.lastBacktestingPerformance?.tradeCost ?? 0}%
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <PublicIcon />
            <Typography variant="subtitle2" flex={1}>
              {t('text.selected')}{' '}
              {(state?.mode ?? 'normal') === 'normal'
                ? t('text.market')
                : t('text.universe')}
            </Typography>
            {openDetailStrategy.indexCode ? (
              <Typography variant="body2">
                {t(`index.${openDetailStrategy.indexCode}`)}
              </Typography>
            ) : (
              <Typography variant="body2">
                {t(`nation.${openDetailStrategy.nationCode}.flag`)}{' '}
                {t(`nation.${openDetailStrategy.nationCode}.name`)}
              </Typography>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <Factory />
            <Typography variant="subtitle2" flex={1}>
              {t('text.selected_industry')}
            </Typography>
            <Typography>
              {(openDetailStrategy.categoryIds?.length ?? 0) > 0
                ? `${openDetailStrategy.categoryIds?.length}개`
                : t('text.all')}
            </Typography>
          </Stack>
          {(openDetailStrategy.categoryIds?.length ?? 0) > 0 ? (
            <Stack maxWidth="500px" direction="row" flexWrap="wrap">
              {openDetailStrategy.categoryIds?.map((categoryId) => {
                const category = findLast(categoryList, (c) =>
                  categoryId.toString().startsWith(c.cosmosGroupId.toString()),
                );
                if (!category) {
                  return null;
                }
                return (
                  <Box
                    sx={{ background: '#E5E7EB' }}
                    key={category.cosmosGroupId}
                    mt="8px"
                    ml="8px"
                    p="4px"
                    borderRadius="4px"
                    width="fit-content"
                  >
                    <Typography variant="body2" color="#677380">
                      {t(`category.${category.name}`)}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          ) : null}
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <AddBox />
            <Typography variant="subtitle2" flex={1}>
              {t('text.selection_criteria')}
            </Typography>
            <Typography>
              {appliedFactorCount > 0
                ? `${appliedFactors.length}개`
                : t('text.none')}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" spacing="8px">
            {map(appliedFactors, (condition) => {
              return (
                <Typography
                  key={condition.factorId}
                  variant="body2"
                  color="#677380"
                  sx={{ background: '#E5E7EB' }}
                  width="fit-content"
                  p="4px"
                  borderRadius="4px"
                >
                  {!isNil(condition.gt)
                    ? `${condition.gt} ${condition.gte ? '<=' : '<'} `
                    : null}
                  {t(`factor.${condition.factorId}.name`)}
                  {!isNil(condition.lt)
                    ? ` ${condition.lte ? '<=' : '<'} ${condition.lt}`
                    : null}
                </Typography>
              );
            })}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            minHeight="54px"
            alignItems="center"
            spacing={1}
          >
            <RemoveCircle />
            <Typography variant="subtitle2" flex={1}>
              {t('text.excluded_stocks')}
            </Typography>
            <Typography>
              {(openDetailStrategy?.excludedCompanies?.length ?? 0) > 0
                ? `${openDetailStrategy?.excludedCompanies?.length}개`
                : t('text.none')}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" spacing="8px">
            {openDetailStrategy?.excludedCompanies?.map?.((company) => {
              return (
                <Typography
                  key={company.id}
                  variant="body2"
                  color="#677380"
                  sx={{ background: '#E5E7EB' }}
                  width="fit-content"
                  p="4px"
                  borderRadius="4px"
                >
                  {company.name}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
      </StyledModal>
    );
  }, [openDetailStrategy]);

  function MobileStrategyItem({ strategy }: { strategy: Strategy }) {
    return (
      <Grid
        container
        columns={6}
        rowSpacing={2}
        py="24px"
        borderBottom="1px solid rgba(0, 0, 0, 0.08)"
      >
        <Grid item xs={2}>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            투자 전략명
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body2">{strategy.name}</Typography>
          <Typography
            variant="caption"
            color="rgba(0, 0, 0, 0.38);"
          >{`생성일 : ${strategy.createdAt}`}</Typography>

          <Stack direction="row" mt="8px" spacing="12px">
            <Button
              variant="outlined"
              onClick={() => setOpenDetailStrategy(strategy)}
            >
              <Typography variant="body2">{t('text.details')}</Typography>
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                if (portfolios?.some((p) => p.strategyId === strategy.id)) {
                  // 경고
                  setIsOpenAlertDeleteStrategy(true);
                } else {
                  // 삭제 다이얼로그
                  setIsOpenConfirmDeleteStrategyId(strategy.id);
                }
              }}
            >
              {t('text.delete')}
            </Button>
          </Stack>
        </Grid>
        {strategy?.lastBacktestingPerformance ? (
          <>
            <Grid item xs={2}>
              <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                {t('text.testPeriod')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {`${dayjs(strategy?.lastBacktestingPerformance.startDate).format(
                'YYYY-MM',
              )} ~ ${dayjs(strategy?.lastBacktestingPerformance.endDate).format(
                'YYYY-MM',
              )}`}
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                {t('text.testResult')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Grid container item columns={4}>
                  {[
                    [
                      t('text.return'),
                      `${(
                        (strategy?.lastBacktestingPerformance?.accumYield ??
                          0) * 100
                      ).toFixed(2)}
                    %`,
                    ],
                    [
                      'MDD',
                      `${(
                        (strategy?.lastBacktestingPerformance?.minMdd ?? 0) *
                        100
                      ).toFixed(2)}
                    %`,
                    ],
                    [
                      `${t('text.volatility')}${(
                        (strategy?.lastBacktestingPerformance?.annualStdev ??
                          0) * 100
                      ).toFixed(2)}
                      %`,
                    ],
                    [
                      t('text.sharpRatio'),
                      `${(
                        strategy?.lastBacktestingPerformance
                          ?.annualSharpeRatio ?? 0
                      ).toFixed(2)}`,
                    ],
                  ].map(([title, value]) => (
                    <React.Fragment key={title}>
                      <Grid item xs={1}>
                        <Typography
                          variant="caption"
                          color="rgba(0, 0, 0, 0.6)"
                        >
                          {title}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {value}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <Button
                  sx={{ marginTop: '12px' }}
                  variant="outlined"
                  onClick={() => {
                    logEvent('monitoring btn clicked');
                    setPortfolioCreationTargetStrategy(strategy);
                  }}
                >
                  <Typography variant="body2">
                    {t('text.startMonitoring')}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={2}>
              {t('text.testPeriod')}
            </Grid>
            <Grid item xs={4}>
              -
            </Grid>

            <Grid item xs={2}>
              {t('text.testResult')}
            </Grid>
            <Grid item xs={4}>
              -
            </Grid>
          </>
        )}
      </Grid>
    );
  }
  const mobileStrategyListView = (
    <Box>
      {strategies.length < 1 ? (
        <Box
        // align="center"
        // sx={{
        //   height: '240px',
        //   typography: 'body',
        //   color: '#00000061',
        // }}
        >
          {t('text.pleaseCreateStrategyFirst')}
        </Box>
      ) : (
        strategies.map((strategy) => (
          <MobileStrategyItem key={strategy.uid} strategy={strategy} />
        ))
      )}
    </Box>
  );
  const strategyTable = (
    <TableContainer
      sx={{
        mt: '36px',
        flex: 1,
      }}
    >
      <Table stickyHeader sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow
            sx={{
              '> th': { backgroundColor: '#FAFAFA' },
            }}
          >
            <TableCell align="left" sx={{ typography: 'subtitle2' }}>
              {t('text.createdDate')}
            </TableCell>
            <TableCell align="left" sx={{ typography: 'subtitle2' }}>
              {t('text.name')}
            </TableCell>
            <TableCell align="left" sx={{ typography: 'subtitle2' }}>
              {t('text.testPeriod')}
            </TableCell>
            <TableCell align="right" sx={{ typography: 'subtitle2' }}>
              {t('text.return')}
            </TableCell>
            <TableCell align="right" sx={{ typography: 'subtitle2' }}>
              MDD
            </TableCell>
            <TableCell align="right" sx={{ typography: 'subtitle2' }}>
              {t('text.volatility')}
            </TableCell>
            <TableCell align="right" sx={{ typography: 'subtitle2' }}>
              {t('text.sharpRatio')}
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {strategies.length < 1 ? (
            <TableRow>
              <TableCell
                align="center"
                colSpan={12}
                sx={{
                  height: '240px',
                  typography: 'body',
                  color: '#00000061',
                }}
              >
                먼저 전략을 생성해 주세요.
              </TableCell>
            </TableRow>
          ) : null}
          {strategies.map((strategy) => (
            <TableRow key={`row-${strategy.id}`}>
              <TableCell align="left" sx={{ typography: 'body1' }}>
                {dayjs(strategy.createdAt).format('YYYY-MM-DD')}
              </TableCell>
              <TableCell align="left" sx={{ typography: 'body1' }}>
                {strategy.name}
              </TableCell>
              {strategy?.lastBacktestingPerformance ? (
                <>
                  <TableCell align="left" sx={{ typography: 'body1' }}>
                    {`${dayjs(
                      strategy?.lastBacktestingPerformance.startDate,
                    ).format('YYYY-MM')} ~ ${dayjs(
                      strategy?.lastBacktestingPerformance.endDate,
                    ).format('YYYY-MM')}`}
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    {(
                      (strategy?.lastBacktestingPerformance?.accumYield ?? 0) *
                      100
                    ).toFixed(2)}
                    %
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    {(
                      (strategy?.lastBacktestingPerformance?.minMdd ?? 0) * 100
                    ).toFixed(2)}
                    %
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    {(
                      (strategy?.lastBacktestingPerformance?.annualStdev ?? 0) *
                      100
                    ).toFixed(2)}
                    %
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    {(
                      strategy?.lastBacktestingPerformance?.annualSharpeRatio ??
                      0
                    ).toFixed(2)}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    -
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    -
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    -
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    -
                  </TableCell>
                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    -
                  </TableCell>
                </>
              )}
              <TableCell>
                <Stack spacing={2} direction="row" justifyContent="center">
                  <StyledButton
                    variant="outlined"
                    onClick={() => {
                      logEvent('monitoring btn clicked');
                      setPortfolioCreationTargetStrategy(strategy);
                    }}
                  >
                    <Typography variant="body2">
                      {t('text.startMonitoring')}
                    </Typography>
                  </StyledButton>
                  <StyledButton
                    variant="outlined"
                    onClick={() => setOpenDetailStrategy(strategy)}
                  >
                    <Typography variant="body2">{t('text.details')}</Typography>
                  </StyledButton>
                </Stack>
              </TableCell>

              <TableCell align="center" width="30px">
                <IconButton
                  aria-label="more"
                  aria-controls={
                    openStrategyMenuState?.strategyId === strategy.id
                      ? 'long-menu'
                      : undefined
                  }
                  aria-expanded={
                    openStrategyMenuState?.strategyId === strategy.id
                      ? 'true'
                      : undefined
                  }
                  aria-haspopup="true"
                  onClick={(e) => {
                    setOpenStrategyMenuState({
                      anchorEl: e.currentTarget,
                      strategyId: strategy.id,
                    });
                  }}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={openStrategyMenuState?.anchorEl}
                  open={openStrategyMenuState?.strategyId === strategy.id}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      if (
                        portfolios?.some((p) => p.strategyId === strategy.id)
                      ) {
                        // 경고
                        setIsOpenAlertDeleteStrategy(true);
                      } else {
                        // 삭제 다이얼로그
                        setIsOpenConfirmDeleteStrategyId(strategy.id);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutline color="error" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ color: 'error' }}
                      primary={t('text.delete')}
                    />
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const createStrategyButton = (
    <StyledButton
      variant="contained"
      size="medium"
      sx={{ width: '-webkit-fill-available' }}
      onClick={() => {
        history.push('/create-strategy');
      }}
    >
      {t('text.direct_indexing')}
    </StyledButton>
  );
  return (
    <Stack
      width="100%"
      height="100%"
      overflow="hidden"
      direction="column"
      alignItems="center"
    >
      <Stack
        px="16px"
        direction="column"
        maxWidth="1176px"
        width="100%"
        height="-webkit-fill-available"
        // height="100%"
        paddingTop="12px"
        paddingBottom={['36px', '0px']}
      >
        <Stack width="100%" direction="row" alignItems="center" spacing="8px">
          <Typography variant="headline1" mt="16px" flex={1}>
            {t('text.myStrategy')}
          </Typography>
          <Box display={['none', 'flex']}>{createStrategyButton}</Box>
        </Stack>

        <Box display={['none', 'inherit']} overflow="scroll">
          {strategyTable}
        </Box>
        <Box
          display={['inherit', 'none']}
          height="-webkit-fill-available"
          //  height="100%"
          overflow="scroll"
        >
          {mobileStrategyListView}
        </Box>
      </Stack>
      <Box
        display={['flex', 'none']}
        position="fixed"
        bottom="0px"
        width="100%"
        alignItems="center"
        py="12px"
        px="12px"
        // px="24px"
      >
        {createStrategyButton}
      </Box>
      {isOpenAlertDeleteStrategy ? (
        <Dialog fullWidth open={isOpenAlertDeleteStrategy}>
          <DialogTitle>
            <DialogContentText>
              이 전략을 운용하고 있는 포트폴리오를 먼저 삭제해주세요.
            </DialogContentText>
          </DialogTitle>
          <DialogActions>
            <StyledButton onClick={() => setIsOpenAlertDeleteStrategy(false)}>
              {t('text.submit')}
            </StyledButton>
          </DialogActions>
        </Dialog>
      ) : null}
      {isOpenConfirmDeleteStrategyId !== null ? (
        <Dialog fullWidth open={isOpenAlertDeleteStrategy !== null}>
          <DialogTitle>
            <DialogContentText>이 전략을 삭제하나요?</DialogContentText>
          </DialogTitle>
          <DialogActions>
            <StyledButton
              onClick={() => setIsOpenConfirmDeleteStrategyId(null)}
            >
              {t('text.cancel')}
            </StyledButton>
            <StyledButton
              color="error"
              variant="contained"
              onClick={() => {
                deleteStrategy(isOpenConfirmDeleteStrategyId);
                setIsOpenConfirmDeleteStrategyId(null);
              }}
            >
              {t('text.delete')}
            </StyledButton>
          </DialogActions>
        </Dialog>
      ) : null}
      {portfolioCreationTargetStrategy ? (
        <PortfolioCreationModal
          open={!!portfolioCreationTargetStrategy}
          strategy={portfolioCreationTargetStrategy}
          onCreate={(strategy, name) => {
            logEvent('portfolio name save btn clicked', {
              'portfolio name': name,
            });
            requestCreatePortfolio(strategy, name);
          }}
          onClose={() => {
            setPortfolioCreationTargetStrategy(null);
          }}
        />
      ) : null}
      {portfolioApplierModal}
      {strategyDetailModal}
    </Stack>
  );
}
