import { Image, Tooltip } from '@chakra-ui/react';
import { useContext, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';

import api from '~/api';
import { FactorFilterCondition, QueryFactor } from '~/api/backtesting';
import { GetScreeningResponse, SortData } from '~/api/screener';
import { ScreeningResult } from '~/api/strategy';
import { FactorStateContext } from '~/containers/factor/context';
import { getformatedYYYYMMDD } from '~/utils/datetime';
import iconDownload from '~/assets/icon/icon_download.svg';

type Props = {
  orderByFactors: Array<SortData>;
  conditions: Array<QueryFactor>;
};

function CsvDownloadButton({ orderByFactors, conditions }: Props) {
  const [screenerResult, setScreenerResult] = useState<GetScreeningResponse>();
  const factorState = useContext(FactorStateContext);
  const [t] = useTranslation();
  const csvLink = useRef();
  const factorContext = useContext(FactorStateContext);

  const getDataFromResult = () => {
    if (!screenerResult) return [];

    const factorList =
      screenerResult?.companyInfos?.length === 0
        ? []
        : factorContext?.factorList.filter((factor) =>
            Object.keys(
              screenerResult.companyRebalancingInfos[0]?.factorValueMap,
            ).includes(factor.id.toString()),
          ) ?? [];

    function getRowDataFromFactorValueMap(
      factorValueMap: Map<string, number>,
    ): Array<string> {
      const r = new Array<string>();
      const objs = new Map(Object.entries(factorValueMap));
      factorList?.forEach((factor) => {
        const v = objs.get(factor.id.toString());
        if (!v) {
          r.push('');
          return;
        }
        r.push(v);
      });
      return r;
    }

    const data = [
      [
        'COMPANY',
        'TICKER',
        'MARKET_PRICE',
        ...(factorList ?? []).map((factor) => {
          // @ts-ignore
          return t(`factor.${factor.id}.name`);
        }),
      ],

      ...screenerResult.companyInfos.map((v) => {
        const companyRebalancingInfo =
          screenerResult.companyRebalancingInfos.find(
            (w) => w.cosmosCode === v.cosmosCode,
          );

        return [
          v.companyLocalName && v.companyLocalName !== ''
            ? v.companyLocalName
            : v.companyName,
          v.ticker,
          companyRebalancingInfo?.marketPrice,
          ...getRowDataFromFactorValueMap(
            companyRebalancingInfo?.factorValueMap ?? new Map(),
          ),
        ];
      }),
    ];

    return data;
  };
  const getScreenerResult = async () => {
    const selectGroups = Array.from(
      factorState?.companyGroupList.entries() ?? [],
    )
      .map(([key, value]) => {
        if (value.length === 0) {
          return [];
        }
        if (value.length === 1) {
          return [key];
        }
        return value;
      })
      .flatMap((v) => v)
      .filter((v) => !factorState!.unselectedGroups.has(v.cosmosGroupId));

    api.screener
      .getScreening({
        nationCode: factorState!.selectedNation.code,
        categoryIds: selectGroups.map((v) => v.cosmosGroupId),
        factorIds: Array.from(
          new Set(conditions.map((v) => v.factorId)).values(),
        ),
        query: conditions,
        offset: 0,
        count: 100,
        orders: orderByFactors,
      })
      .then((v) => {
        if (v instanceof Error) {
          throw v;
        }

        setScreenerResult(v.data);
        // @ts-ignore
        csvLink.current.link.click();
      });
  };

  const getFileName = () => {
    return `${getformatedYYYYMMDD({ date: new Date() })}_${
      orderByFactors.length === 0
        ? ''
        : // @ts-ignore
          `_${t(`factor.${orderByFactors[0].factorId}.name`)}_${
            orderByFactors[0].order
          }`
    }.csv`
      .replaceAll('/', '_')
      .replaceAll(' ', '_');
  };
  return (
    <>
      <Tooltip
        // color={themeData.colors.text2}
        // bg="white"
        box-shadow="0px 3px 3px #ccc"
        whiteSpace="pre-line"
        label={t('text.downloadTooltip')}
      >
        <IconButton
          onClick={getScreenerResult}
          sx={{
            border: 'solid 1px #808080',
            borderRadius: '8px',
          }}
          // variant="outlined"
          // sx={{
          //   padding: '0px',
          //   margin: '0px',
          //   width: '48px',
          //   height: '48px',
          // }}
        >
          <Image src={iconDownload} />
        </IconButton>
      </Tooltip>
      <CSVLink
        data={getDataFromResult()}
        // @ts-ignore
        ref={csvLink}
        asyncOnClick
        filename={getFileName()}
      />
    </>
  );
}
export default CsvDownloadButton;
