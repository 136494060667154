import { TypographyVariantsOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    headline1: React.CSSProperties;
    headline2: React.CSSProperties;
    overline: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
    headline1?: React.CSSProperties;
    headline2?: React.CSSProperties;
    overline?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    headline1: true;
    headline2: true;
    overline: true;
  }
}

const fontFamilyName = 'Noto Sans KR';

export default {
  h1: { fontSize: '32px', lineHeight: 1.6, fontFamily: fontFamilyName },
  h2: { fontSize: '21px', lineHeight: 1.6, fontFamily: fontFamilyName },
  h3: { fontSize: '18px', lineHeight: 1.6, fontFamily: fontFamilyName },
  h4: { fontSize: '16px', lineHeight: 1.6, fontFamily: fontFamilyName },
  h5: { fontSize: '14px', lineHeight: 1.6, fontFamily: fontFamilyName },
  body1: { fontSize: '14px', lineHeight: 1.6, fontFamily: fontFamilyName },
  body2: { fontSize: '12px', lineHeight: 1.6, fontFamily: fontFamilyName },
  body3: { fontSize: '10px', lineHeight: 1.6, fontFamily: fontFamilyName },
  caption: {
    fontSize: '12px',
    fontFamily: fontFamilyName,
    lineHeight: 1.6,
  },
  headline1: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: fontFamilyName,
    lineHeight: 1.6,
  },
  headline2: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: fontFamilyName,
    lineHeight: 1.6,
  },
  subtitle1: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: fontFamilyName,
    lineHeight: 1.6,
  },
  subtitle2: {
    fontSize: '12px',
    fontWeight: 'bold',
    fontFamily: fontFamilyName,
    lineHeight: 1.6,
  },
  overline: {
    fontSize: '10px',
    fontFamily: fontFamilyName,
    lineHeight: 1.6,
  },
} as TypographyVariantsOptions;
