import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Link,
  ModalProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import StyledModal from '~/components/StyledModal';
import StyledButton from '~/components/StyledButton';

type VoucherRegisterModalProp = {
  onConfirm?: (code: string) => void;
  errorMessage?: string | null;
} & Omit<ModalProps, 'children'>;

export default function VoucherRegisterModal({
  onConfirm,
  errorMessage,
  ...modalProps
}: VoucherRegisterModalProp) {
  const [code, setCode] = useState<string>('');
  useEffect(() => {
    if (modalProps?.open) {
      setCode('');
    }
  }, [modalProps?.open]);

  const [t] = useTranslation();

  return (
    <StyledModal disableAutoFocus disableEnforceFocus {...modalProps}>
      <Box
        sx={{
          borderRadius: '8px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        boxShadow="24px"
        width="400px"
        maxWidth="90vw"
        bgcolor="background.paper"
        overflow="hidden"
        pt="24px"
      >
        <Stack px="16px">
          <Stack direction="row" spacing="16px">
            <Typography variant="headline2" flex={1}>
              {t('text.codeRegistrationTitle')}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => modalProps?.onClose?.(e, 'backdropClick')}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {t('text.codeRegistrationInputCaption')}
          </Typography>
          <TextField
            sx={{
              flex: 1,
              marginTop: '12px',
            }}
            size="small"
            type="text"
            variant="outlined"
            value={code}
            error={!!errorMessage}
            placeholder={t('text.codeRegistrationInputHint')}
            helperText={errorMessage}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          spacing="10px"
          mt="32px"
          p="16px"
          bgcolor="background.grey"
        >
          <Link
            rel="noreferrer"
            flex={1}
            color="secondary"
            underline="always"
            target="_blank"
            href="https://doomoolmori.notion.site/a75e8c144e3b4332bda180f3ee5e7e95"
          >
            {t('text.codeRegistrationLink')}
          </Link>
          <StyledButton
            sx={{ width: 'fit-contents' }}
            variant="outlined"
            onClick={(e) => modalProps?.onClose?.(e, 'backdropClick')}
          >
            {t('text.cancel')}
          </StyledButton>
          <StyledButton
            sx={{ width: 'fit-content' }}
            variant="contained"
            disabled={(code?.length ?? 0) < 1 || !!errorMessage}
            onClick={() => {
              onConfirm?.(code);
            }}
          >
            {t('text.submit')}
          </StyledButton>
        </Stack>
      </Box>
    </StyledModal>
  );
}

VoucherRegisterModal.defaultProps = {
  onConfirm: undefined,
  errorMessage: undefined,
};
