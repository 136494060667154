import { AxiosInstance } from 'axios';

import dayjs from '~/utils/dayjs';
import { FactorControlValue } from '~/components/factor/types';
import { FactorValue } from '~/components/strategy/creation/FactorPresetList';
import {
  ScreenerSortScroeType,
  ScreenerSortType,
} from '~/containers/screener/ScreenerSortSelector';
import { ExecConditions } from '~/utils/factor';
import {
  FactorFilterCondition,
  PortWeightPolicy,
  QueryFactor,
} from './backtesting';
import { PortfolioBacktestingResult } from './portfolio';

export type FinancialStatementValuesRequest = {
  cosmosCode: number;
  period: 'ANNUALLY' | 'QUARTERLY' | 'SEMIANNUALLY';
  startDate?: string;
  type: 'BALANCE_SHEET' | 'CASH_FLOW' | 'INCOME';
};

export type FactorHistogramDefaultRange =
  | 'ALL'
  | 'BOTTOM20'
  | 'BOTTOM20POSITIVE'
  | 'MIDDLE20'
  | 'TOP20';

export type FinancialStatementValuesResponse = {
  currency: string;
  financialNameList: Array<string>;
  financialItemList: Array<number>;
  financialValueMap: Map<string, Map<string, number>>;
  periodInfoList: Array<PeriodInfo>;
};

export type CompanyMarketInfo = {
  currency: string;
  currentMarketPrice: number;
  localMarketCap: number;
  maxNationLocalMarketCap: number;
  minNationLocalMarketCap: number;
  yearHighMarketPrice: number;
  yearLowMarketPrice: number;
  maxNationMarketCapRank: number;
  nationMarketCapRank: number;
};

export type CompanyDetailInfo = {
  companyName: string;
  companyLocalName: string;
  exchange: string;
  incorporationDate: string;
  ticker: string;
  url: string;
};

export type CompanyFinancialRatioValuesRequest = {
  cosmosCode: number;
  period: 'ANNUALLY';
  startDate?: string;
  type: 'EFFICIENCY' | 'GROWTH' | 'PROFITABILITY' | 'SHARE' | 'STABILITY';
};
export type CompanyFinancialRatioValuesResponse = {
  currency: string;
  financialNameList: Array<string>;
  financialItemList: Array<number>;
  periodInfoList: Array<PeriodInfo>;
  financialValueMap: Map<string, Map<string, number>>;
};

export type CompanyMarketPricesRequest = {
  cosmosCode: number;
  endDate: string;
  startDate: string;
};
export type CompanyMarketPricesResponse = {
  currency: string;
  priceList: Array<Price>;
};

export type Price = {
  close: number;
  open: number;
  high: number;
  low: number;
  volume: number;
  date: string;
};
type PeriodInfo = {
  calcEndDate: string;
  code: string;
  year: number;
};

export type SearchCompanyRequest = {
  keyword: string;
};

export type SearchCompanyResponse = Array<{
  companyName: string;
  companyLocalName: string;
  cosmosCode: number;
  currency: string;
  nationCode: number;
  ticker: string;
}>;

export type Factor = {
  name?: string;
  id: number;
  categoryId?: number;
  categoryName?: string;
  conditionPriority?: number;
  defaultRange?: FactorHistogramDefaultRange;
  returnPriority?: number;
  valueUnit?: string;
  rangeTypes?: ('relative' | 'absolute')[];
  isNew?: boolean;
};
export type FactorCategory = {
  id: number;
  name: string;
};

export type Nation = {
  code: number;
  name: string;
};
export type Index = {
  // TODO :
  code: number;
  name: string;
};

export type GetScreeningParams = {
  key?: string;
  nationCode?: number;
  indexCode?: number;
  categoryIds?: Array<number> | null;
  factorIds?: Array<number>;
  query: Array<QueryFactor>;
  orders: Array<SortData>;
  count?: number;
  offset?: number;
};

export type GetScreeningCountParams = {
  key?: string;
  nationCode?: number;
  indexCode?: number;
  categoryIds?: Array<number>;
  factorIds?: Array<number>;
  query: Array<QueryFactor>;
};

export type GetScreeningResponse = {
  key: string;
  companyInfos: Array<{
    companyLocalName: string;
    companyName: string;
    cosmosCode: number;
    cosmosGroupId: number;
    currency: string;
    exchangeCode: number;
    ticker: string;
  }>;
  companyRebalancingInfos: Array<{
    cosmosCode: number;
    date: Date;
    factorValueMap: Map<string, number>;
    marketCap: number;
    marketDate: Date;
    marketPrice: number;
    score: number;
    weight: number;
  }>;
};

export type PostBacktestingBody = {
  indexCode?: number;
  nationCode: number;
  startDate: string;
  endDate: string;
  categoryIds?: Array<number> | null;
  factorQuery: Array<QueryFactor>;
  orders: Array<SortData>;
  portWeightPolicy: PortWeightPolicy;
  rebalancingPeriod: string;
  excludedCosmosCodes?: Array<number>;
  minScore?: number;
  count: number;
  tradeCost: number;
};

export type CompanySummaryInfoResponse = {
  companyDetailInfo: CompanyDetailInfo;
  companyMarketInfo: CompanyMarketInfo;
  financialValueTimeSeries: FinancialStatementValuesResponse;
  scoreFactorMap: Map<string, number>;
  maxScoreValueMap: Map<string, number>;
  statisticFactorMap: Map<string, number>;
};
export type Strategy = {
  id: string;
  uid: string;
  userId: string;
  name: string;
  indexCode?: number;
  startDate?: string;
  endDate?: string;
  selectedNation?: Nation;
  selectedFactors?: Array<[number, [FactorControlValue, FactorControlValue]]>;
  orderFactor?: Array<ScreenerSortType>;
  rebalancingPeriod?: string;
  unselectedCategoryIds?: Array<number>;
  tradeCost?: number;
  count?: number;
};

export type CompanyGroupListResponse = {
  cosmosGroupId: number;
  level: number;
  name: string;
};

export type SortData = {
  factorId: number;
  order: ExecScreeneOrderType;
};
export type ExecScreeneOrderType = 'DESC' | 'ASC';

export const screenerActions = ['screening', 'backtesting'] as const;
export type ScreenerAction = typeof screenerActions[number];

export type ScreenerPlanPolicy = {
  [key in ScreenerAction]?: {
    base?: Date;
    limit: number;
    unit: dayjs.ManipulateType;
    interval: number;
    delay?: number;
  };
};

export type Usage = {
  planUid: string;
  planeName: string;
  startAt?: Date;
  endAt?: Date;
  policy: ScreenerPlanPolicy;
};

type PostStrategyBody = {
  name: string;
  indexCode?: number;
  startDate?: string;
  endDate?: string;
  selectedNation?: Nation;
  selectedFactors?: Array<[number, [FactorControlValue, FactorControlValue]]>;
  orderFactor?: Array<ScreenerSortType>;
  rebalancingPeriod?: string;
  unselectedCategoryIds?: Array<number>;
  tradeCost?: number;
  count?: number;
};

export type CompanyGptReportStatus = 'pending' | 'completed' | 'failure' | null;
export type CompanyGptReport = {
  wait: number;
  status: CompanyGptReportStatus;
  content: string | null;
};

export const createService = (client: AxiosInstance) => {
  return {
    async usage() {
      return client.get<Usage | null>(`/screener/usage`);
    },
    async registerUser(firebaseUid: string) {
      return client.post(`/screener/register-user`, {
        uid: firebaseUid,
      });
    },
    async registerVoucher(code: string) {
      return client.post(`screener/voucher/${code}/register`);
    },
    async getCompany(keyword: string) {
      return client.get<SearchCompanyResponse>(`/screener/company`, {
        params: { keyword },
      });
    },
    // async getCompanyDetail(cosmosCode: number) {
    //   return client.get<CompanySummaryInfoResponse>(
    //     `/screener/company/${cosmosCode}`,
    //   );
    // },

    async getCompanyFinancialRatio(cosmosCode: number, type: string) {
      return client.get<CompanyFinancialRatioValuesResponse>(
        `/screener/company/${cosmosCode}/financial-ratio/${type}`,
      );
    },

    async getCompanyPrice(cosmosCode: number) {
      return client.get<CompanyMarketPricesResponse>(
        `/screener/company/${cosmosCode}/price`,
      );
    },

    async postScreenerRegisterUser(uid: string) {
      return client.post<CompanyMarketPricesResponse>(
        `/screener/screener/register-user`,
        { uid },
      );
    },

    async postVoucherRegister(code: string) {
      return client.post<CompanyMarketPricesResponse>(
        `/screener/voucher/${code}/register`,
      );
    },

    async postPromotionCodeRegister(code: string) {
      return client.post<CompanyMarketPricesResponse>(
        `/screener/promotion-code/register`,
        { code },
      );
    },

    async getFactor() {
      return client.get<Array<Factor>>(`/screener/factor`);
    },

    async getFactorCategory() {
      return client.get<Array<FactorCategory>>(`/screener/factor-category`);
    },

    async getNation() {
      return client.get<Array<Nation>>(`/screener/nation`);
    },

    async getIndex() {
      return client.get<Array<Index>>(`/screener/index`);
    },

    async getCategory({ level }: { level: number }) {
      return client.get<Array<CompanyGroupListResponse>>(`/screener/category`, {
        params: { level },
      });
    },

    async getScreening(params: GetScreeningParams) {
      return client.get<GetScreeningResponse>(`/screener/screening`, {
        params,
      });
    },

    async getScreeningCount(params: GetScreeningCountParams) {
      return client.get<{ totalCount: number }>(`/screener/screening/count`, {
        params,
      });
    },

    async postBacktesting(body: PostBacktestingBody) {
      return client.post<PortfolioBacktestingResult>(
        `/screener/backtesting`,
        body,
      );
    },
    async downloadBacktestingMonthlyReportXlsx(body: PostBacktestingBody) {
      return client.post<PortfolioBacktestingResult>(
        `/screener/backtesting/monthly-report.xlsx`,
        body,
        {
          responseType: 'blob',
        },
      );
    },

    async getCompanySummary(code: number) {
      // response 정의안됨
      return client.get<CompanySummaryInfoResponse>(
        `/screener/company/${code}/summary`,
      );
    },

    async getCompanyFinancialStatement(code: number, type: string) {
      return client.get<FinancialStatementValuesResponse>(
        `/screener/company/${code}/financial-statement/${type}`,
      );
    },

    async postStrategy(body: PostStrategyBody) {
      return client.post<{ uid: string }>(`/screener/strategy`, body);
    },

    async patchStrategy(uid: string, body: PostStrategyBody) {
      return client.patch<Array<Strategy>>(`/screener/strategy/${uid}`, body);
    },

    async getStrategy() {
      return client.get<Array<Strategy>>(`/screener/strategy`);
    },

    async deleteStrategy(uid: string) {
      return client.delete(`/screener/strategy/${uid}`);
    },

    async getCompanyReport(cosmosCode: number) {
      return client.get<CompanyGptReport>(
        `/screener/report/company/${cosmosCode}`,
      );
    },
    async requestCompanyReport(cosmosCode: number) {
      return client.post<CompanyGptReport>(
        `/screener/report/company/${cosmosCode}`,
      );
    },
  };
};
