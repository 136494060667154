import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Box,
  useBreakpointValue,
  ChakraProvider,
  BoxProps,
} from '@chakra-ui/react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Button, Typography } from '@mui/material';

import useFetch from '~/hooks/useFetch';
import { FactorStateContext } from '../factor/context';
import { ExecButton } from '~/components/CustomButton';
import { getExecConditions } from '~/utils/factor';
import api from '~/api';
import { getThemeData } from '~/utils/theme';
import { H5 } from '~/components/Typography';
import { getIsMobile } from '~/utils/mediaQuery';
import StyledButton from '~/components/StyledButton';
import { AppContext, MAX_QUERY_FACTOR_COUNT } from '~/AppContext';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */

function ScreenerExecButton({ sx, ...props }: BoxProps) {
  const history = useHistory();
  const factorState = useContext(FactorStateContext);
  const { state } = useContext(AppContext) ?? {};
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logEvent } = useAmplitude();
  const themeData = getThemeData();

  const selectGroups = Array.from(factorState?.companyGroupList.entries() ?? [])
    .map(([key, value]) => {
      if (value.length === 0) {
        return [];
      }
      if (value.length === 1) {
        return [key];
      }
      return value;
    })
    .flatMap((v) => v)
    .filter((v) => !factorState!.unselectedGroups.has(v.cosmosGroupId));

  const [screenerCount, setScreenerCount] = useState<number>();

  useEffect(() => {
    if (state?.user) {
      const conditions = factorState?.selectedFactors
        ? getExecConditions(
            factorState!.selectedFactors,
            factorState!.sortData[0],
          )
        : [];

      api.screener
        .getScreeningCount({
          nationCode: factorState!.selectedNation.code,
          query: conditions,
          categoryIds: selectGroups.map((v) => v.cosmosGroupId),
        })
        .then((v) => {
          setScreenerCount(v.data.totalCount);
        });
    }
  }, [
    factorState?.selectedFactors,
    factorState?.selectedNation,
    factorState?.sortData,
    factorState?.unselectedGroups,
    state?.user,
  ]);
  const disabled =
    (factorState?.selectedFactors.size ?? 0) > MAX_QUERY_FACTOR_COUNT ||
    factorState?.selectedFactors.size === 0 ||
    // screenerCountState.error ||
    // screenerCountState.loading ||
    // screenerCountState.data?.result?.totalCount === 0 ||
    selectGroups.length === 0;

  const placement = getIsMobile() ? 'top' : 'bottom';

  return (
    <Popover
      isOpen={
        factorState?.selectedFactors.size !== 0
        // &&factorState?.sortData[0].data !== 'F_SCORE'
      }
      placement={placement}
    >
      {/* @ts-ignore */}
      <PopoverTrigger>
        <Box sx={{ height: '100%', ...sx }} {...props}>
          <StyledButton
            sx={{ height: '100%', width: '100%', borderRadius: '8px' }}
            variant="contained"
            disabled={disabled}
            onClick={() => {
              setIsLoading(true);

              // addCriticalActionsCountAmplitude({
              //   amplitudeInstance,
              // });

              const conditions = factorState?.selectedFactors
                ? getExecConditions(
                    factorState!.selectedFactors,
                    factorState!.sortData[0],
                  )
                : [];
              if (typeof factorState!.sortData[0].data === 'string') {
                history.push(`/screener-result`, {
                  conditions,
                  query: window.location.search?.slice(1),
                });
              } else {
                history.push(
                  `/screener-result?orderByFactors=${
                    factorState!.sortData[0].data.factorId
                  }:${factorState!.sortData[0].data.order}`,
                  { conditions, query: window.location.search?.slice(1) },
                );
              }
              // api.screener
              //   .getScreening({
              //     nationCode: factorState!.selectedNation.code,
              //     filter: conditions,
              //     // cosmosGroupIds: [],
              //     // indexCode?: number;
              //     // categoryIds?: Array<number>;
              //     // factorIds?: Array<number>;
              //     // filter: Array<ScreeningFilter>;
              //     // count?: number;
              //     // offset?: number;
              //   })
              //   .then((v) => {
              //     // logEvent('screening execute', {
              //     //   'num total screening result':
              //     //     screenerCountState.data.result.totalCount,
              //     //   'selected country to invest':
              //     //     factorState!.selectedNation.name,
              //     // });
              //     // history.push(
              //     //   `screener-result/${
              //     //     (v as ResponseBody<ScreenerExecResult>).result.key
              //     //   }?orderByFactors=${factorState!.sortData.data.factorId}:${
              //     //     factorState!.sortData.data.order
              //     //   }`,
              //     // );
              //   })
              //   .catch((e: AxiosError) => {
              //     setIsLoading(false);
              //     // appState?.showToast(
              //     //   'error',
              //     //   e.response?.status === 429 ? (
              //     //     <ToastLimitComponent />
              //     //   ) : (
              //     //     <H5 noOfLines={10} whiteSpace="pre-wrap">
              //     //       {`예상하지 못한 오류가 발생했습니다(${e.response?.status})\n해당 현상이 반복된다면 문의 바랍니다`}
              //     //     </H5>
              //     //   ),

              //     //   {
              //     //     position: 'bottom-left',
              //     //     toastId: 'screener-exec-error-toast',
              //     //   },
              //     // );
              //   });
            }}
          >
            <Typography>{t('text.screenerButton')}</Typography>
          </StyledButton>
        </Box>
      </PopoverTrigger>

      {(factorState?.selectedFactors.size ?? 0) > MAX_QUERY_FACTOR_COUNT ||
      selectGroups.length === 0 ? (
        <PopoverContent
          style={{
            animation: 'smoothAppear .5s',
            boxShadow: 'none',
            padding: '8px',
            borderRadius: '8px',
          }}
          bg={themeData.colors.error}
          w="fit-content"
        >
          <PopoverArrow bg={themeData.colors.error} />
          <PopoverBody w="fit-content" display="flex">
            <H5 whiteSpace="break-spaces" bold color="white">
              {(factorState?.selectedFactors.size ?? 0) > MAX_QUERY_FACTOR_COUNT
                ? '팩터 조건이 30개를 넘을 수 없습니다'
                : t('text.sectorErrorPopover')}
            </H5>
          </PopoverBody>
        </PopoverContent>
      ) : (
        <PopoverContent
          style={{
            animation: 'smoothAppear .5s',
            boxShadow: 'none',
            padding: '8px',
            borderRadius: '8px',
          }}
          bg={themeData.colors.navy}
          w="fit-content"
        >
          <PopoverArrow bg={themeData.colors.navy} />
          <PopoverBody w="fit-content" display="flex">
            <H5
              whiteSpace="break-spaces"
              bold
              color={themeData.colors.primary[500]}
            >
              {screenerCount ?? 0}
            </H5>
            <H5 whiteSpace="break-spaces" bold color="white">
              {`${t('text.screenerCountTooltip')}`}
            </H5>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
}

export default ScreenerExecButton;
