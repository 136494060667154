import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Flex, Box, Spacer } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/layout';
import { IconButton } from '@mui/material';

import { getIsMobile } from '~/utils/mediaQuery';
import { H1, H2 } from '~/components/Typography';
import ScreenerExecButton from './ScreenerExecButton';
import SearchContainer from '../search/SearchContainer';
import ConditionSotrageSaveButton from '../conditions/ConditionStroageSaveButton';
import { FactorDispatchContext } from '../factor/context';
import { ReactComponent as ShareIcon } from '~/assets/icon/icon_share_link.svg';
import api from '~/api';

function ScreenerHeader() {
  const isMobile = getIsMobile();
  const [t] = useTranslation();
  const factorDispatch = useContext(FactorDispatchContext);
  return (
    <Flex
      style={{
        height: '92px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        borderBottom: 'solid 1px #e5e5e5',
      }}
      p={['12px 24px', '24px 24px']}
      bg="white"
      alignItems="flex-end"
    >
      <Flex flexDirection="column" alignSelf="center">
        <Box>
          <H2 bold>{t('text.screenerMenu')}</H2>
        </Box>
      </Flex>

      <Box display={['flex', 'none']} flex={1} />

      <Box width="fit-content" marginLeft="24px" alignSelf="center">
        <SearchContainer />
      </Box>

      <Spacer />

      <HStack
        display={isMobile ? 'none' : 'inherit'}
        sx={{ height: '100%' }}
        spacing="12px"
      >
        <ScreenerExecButton />
        <ConditionSotrageSaveButton
          onComplete={() => {
            api.screener.getStrategy().then((v) =>
              factorDispatch?.({
                type: 'UPDATE_STRATEGIES',
                strategies: v.data,
              }),
            );
          }}
        />
        <IconButton
          size="large"
          sx={{
            height: '100%',
            border: '1px solid #D9DCE0',
            borderRadius: '4px',
          }}
          onClick={() => {
            navigator.clipboard.writeText(window.location.href).finally(() => {
              alert('URL 링크가 복사되었습니다.');
            });
          }}
        >
          <ShareIcon />
        </IconButton>
      </HStack>
    </Flex>
  );
}

export default ScreenerHeader;
