import { ThemeOptions } from '@mui/material';
import { useContext } from 'react';
import CSS from 'csstype';

import { useTheme } from '~/hooks/useTheme';
import { lightOption } from '~/theme';
import * as dark from '~/assets/json/dark.json';
import * as light from '~/assets/json/light.json';
import * as blue from '~/assets/json/blue.json';

export interface TypographyThemeInterface {
  h1: CSS.Properties;
  h2: CSS.Properties;
  h3: CSS.Properties;
  h4: CSS.Properties;
  h5: CSS.Properties;
  body1: CSS.Properties;
  body2: CSS.Properties;
  sub: CSS.Properties;
  caption: CSS.Properties;
}

export interface ColorThemeInterface {
  primary: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  text1: string;
  text2: string;
  text3: string;
  background: string;
  gray: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  navy: string;
  atomicTangerine: string;
  pink: string;
  error: string;
  chartUp: string;
  chartDown: string;
}
export { dark, light, blue };

export interface DefaultTheme {
  id: string;
  name: string;
  colors: ColorThemeInterface;
  typography: TypographyThemeInterface;
}

export const getThemeData = (): DefaultTheme => {
  return useTheme().getThemeData('light');
};
