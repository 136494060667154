import { PaletteOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }

  interface TypeBackground {
    grey: React.CSSProperties['color'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface ThemeOptions {
    status?: {
      danger?: React.CSSProperties['color'];
    };
  }
}

export const light: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#332d41',
  },
  secondary: {
    main: '#9E7DF9',
  },
  background: {
    default: '#FFFFFF',
    grey: '#FAFAFA',
  },
  text: {
    primary: '#000000DE',
    secondary: '#00000099',
    disabled: '#00000061',
  },
  grey: {
    '900': '#101010',
    '800': '#252525',
    '700': '#404040',
    '600': '#555555',
    '500': '#707070',
    '400': '#858585',
    '300': '#aaaaaa',
    '200': '#e5e5e5',
    '100': '#f7f7f7',
  },
};
