import { VegaLite } from 'react-vega';
import { intToCompressedString } from '~/utils/number';
import { getThemeData } from '~/utils/theme';

function NegativeBarChart({ data }: { data: [string, number][] }) {
  const themeData = getThemeData();
  const value = data.map((v) => {
    return { a: v[0], b: v[1], c: intToCompressedString(v[1]) };
  });
  return (
    <VegaLite
      style={{ width: '100%' }}
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 'container',
        height: 70,
        background: 'transparent',
        data: {
          values: value,
        },
        mark: 'bar',
        encoding: {
          x: {
            field: 'a',
            type: 'nominal',
            axis: {
              domain: false,
              ticks: false,
              labelAngle: 0,
              labelPadding: 24,
            },
          },
          y: {
            field: 'b',
            type: 'quantitative',
          },
        },
        layer: [
          {
            mark: 'bar',
            encoding: {
              color: {
                value: themeData.colors.primary[100],
                condition: {
                  test: 'datum.isExpected ===true',
                  value: themeData.colors.gray[100],
                },
              },
            },
          },

          {
            mark: {
              type: 'text',
              baseline: { expr: "datum.b < 0 ? 'top' : 'bottom'" },
              dy: { expr: 'datum.b < 0 ? 4 : -4' },
              color: 'black',
            },
            encoding: {
              text: { field: 'c' },
            },
          },
        ],
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },
          scale: { barBandPaddingInner: 0.15 },
          axisX: {
            title: null,
            labels: true,
            grid: false,
            ticks: false,
            domain: false,
          },
          axisY: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
        },
      }}
    />
  );
}
export default NegativeBarChart;
