import { Box, Image, Spacer } from '@chakra-ui/react';
import { Flex, HStack } from '@chakra-ui/layout';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

import { Caption } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { SortData } from '~/api/screener';
import iconInfo from '~/assets/icon/icon_info.svg';

export type ScreenerSortType = {
  name: string;
  data: SortData | ScreenerSortScroeType;
};

export type ScreenerSortScroeType = 'F_SCORE';
export const screenerSortData: Array<ScreenerSortType> = [
  {
    name: 'MarketCapitalizationDesc',
    data: {
      factorId: 1133,
      order: 'DESC',
    },
  },
  {
    name: 'MarketCapitalizationAsc',
    data: {
      factorId: 1133,
      order: 'ASC',
    },
  },
  {
    name: 'OperatingProfitMarginDesc',
    data: {
      factorId: 1087,
      order: 'DESC',
    },
  },
  {
    name: 'ReturnOnEquityDesc',
    data: {
      factorId: 1112,
      order: 'DESC',
    },
  },
  {
    name: 'F_SCORE',
    data: 'F_SCORE',
  },
];
//

type Props = {
  onSelect: (data: ScreenerSortType) => void;
  selectedData: ScreenerSortType;
};

function ScreenerSortSelector({ onSelect, selectedData }: Props) {
  const themeData = getThemeData();

  const { t } = useTranslation();

  return (
    <Box>
      <Flex flexDirection="row">
        <Caption color={themeData.colors.text3}>{t('text.sort')}</Caption>
      </Flex>
      <Spacer h="8px" />
      <ReactSelect
        value={{
          // @ts-ignore
          value: selectedData,
          // @ts-ignore
          label: t(`screenerSort.${selectedData.name}`),
        }}
        isSearchable={false}
        onChange={(v) => {
          // @ts-ignore
          onSelect(v.value);
        }}
        options={screenerSortData.map((e) => {
          return { value: e, label: t(`screenerSort.${e.name}`) ?? '' };
        })}
        styles={{
          option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? '#F6EDFF' : 'white',
            color: themeData.colors.text1,
            fontSize: themeData.typography.h5.fontSize,
          }),
          control: (provided) => ({
            ...provided,
            border: `solid 1px ${themeData.colors.primary[900]}`,
            borderRadius: 8,
            color: themeData.colors.text2,
            fontSize: themeData.typography.h5.fontSize,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
      />
    </Box>
  );
}

export default ScreenerSortSelector;
