import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import bluebird from 'bluebird';
import { useHistory, useLocation } from 'react-router-dom';
import {
  castArray,
  cloneDeep,
  compact,
  debounce,
  difference,
  flatMap,
  isNumber,
  isUndefined,
  map,
  omit,
  pullAll,
  reverse,
  sortBy,
  transform,
  uniq,
  uniqBy,
} from 'lodash';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import { Close as CloseIcon, DashboardCustomize } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAmplitude } from 'react-amplitude-hooks';

import dayjs from '~/utils/dayjs';
import {
  Backtesting,
  FactorFilterCondition,
  ExcludedCompany,
} from '~/api/backtesting';
import { AppContext } from '~/AppContext';
import api from '~/api';
import {
  FactorCategory,
  FactorControlSpec,
  Index,
  Company,
  Nation,
  Category,
  Factor,
  ScreeningResult,
} from '~/api/strategy';
import UniverseList, {
  Universe,
} from '~/components/strategy/creation/UniverseList';
import BacktestingResult from '~/components/strategy/creation/BacktestingResult';

import {
  FactorValue,
  FactorPreset,
  PresetCard,
} from '~/components/strategy/creation/FactorPresetList';
import BacktestingOption, {
  OrderOption,
} from '~/components/strategy/creation/BacktestingOption';
import CompanyList from '~/components/strategy/creation/CompanyList';
import PortfolioTreeMap from '~/components/strategy/creation/PortfolioTreeMap';
import FactorCustomizer from '~/components/strategy/creation/FactorCustomizer';
import StrategyPortfolioApplier from '~/containers/StrategyPortfolioApplier';
import CategoryList from '~/components/strategy/creation/CategoryList';
import SelectableStackItem from '~/components/strategy/creation/SelectableStackItem';
import LottieAnimation from '~/components/LottieAnimation';
import lottieBacktest from '~/assets/lottie/lottie_backtest.json';
import lottieLoading from '~/assets/lottie/lottie_loading.json';

import StyledModal from '~/components/StyledModal';
import StyledButton from '~/components/StyledButton';
import { getLv1Presets } from '~/views/StrategySelectionView';
import { FactorControlValue } from '~/components/factor/types';
import {
  getAbsoluteInitialRange,
  getDefaultFreqIndex,
  getReletiveInitialRange,
} from '~/components/factor/utils/initializedValue';
import FactorHistogramItem from '~/components/factor/FactorHistogramItem';
import { getIsMobile } from '~/utils/mediaQuery';

const DEFAULT_NATION_CODE = 840; // us
const DEFAULT_BACKTESTING_COMPANY_COUNT = 30;
const MAX_BACKTESTING_COMPANY_COUNT = 50;
const DEFAULT_TRADE_COST_RATE = 0.6;

const nationOrderOptions: OrderOption[] = [
  {
    name: 'MarketCapitalizationDesc',
    ordering: {
      factorId: 1133,
      order: 'desc',
    },
  },
  {
    name: 'MarketCapitalizationAsc',
    ordering: {
      factorId: 1133,
      order: 'asc',
    },
  },
  {
    name: 'OperatingProfitMarginDesc',
    ordering: {
      factorId: 1087,
      order: 'desc',
    },
  },
  {
    name: 'ReturnOnEquityDesc',
    ordering: {
      factorId: 1112,
      order: 'desc',
    },
  },
];

const indexOrderOptions: OrderOption[] = [
  {
    name: 'MarketCapitalizationDesc',
    ordering: {
      factorId: 1133,
      order: 'desc',
    },
  },
  // {
  //   name: 'MarketCapitalizationAsc',
  //   ordering: {
  //     factorId: 1133,
  //     order: 'asc',
  //   },
  // },
];

function decodeUniverse(universe?: Universe | null): {
  nationCode: Nation['code'];
  indexCode?: Index['code'];
} {
  return {
    nationCode:
      universe?.universe === 'nation'
        ? universe.code
        : universe?.universe === 'index'
        ? universe.nationCode
        : DEFAULT_NATION_CODE,
    indexCode: universe?.universe === 'index' ? universe.code : undefined,
  };
}

export default function StrategyCreationView() {
  const [t] = useTranslation();
  const isMobile = getIsMobile();
  const context = useContext(AppContext);

  const history = useHistory();
  const { logEvent } = useAmplitude();

  const { state: locationState, search } = useLocation<{
    preset?: FactorPreset;
  }>();

  const [isLoadingBacktesting, setIsLoadindgBacktesting] =
    useState<boolean>(false);
  const [isOpenResultModal, setIsOpenResultModal] = useState(false);

  const [modalIndex, setModalIndex] = useState<number | undefined>();

  const [mobileIndex, setMobileIndex] = useState<number>(0);

  const [modifyAlertModalCallback, setModifyAlertModalCallback] =
    useState<() => void | undefined>();

  const [isOpenAccountApplier, setIsOpenAccountApplier] =
    useState<boolean>(false);

  const [resultTabIndex, setResultTabIndex] = useState<'result' | 'option'>(
    'result',
  );
  const [strategyName, setStrategyName] = useState<string>('');

  const [
    [
      factorList,
      factorCategoryList,
      topCategoryList,
      categoryList,
      indexList,
      nationList,
    ] = [],
    setData,
  ] =
    useState<
      [Factor[], FactorCategory[], Category[], Category[], Index[], Nation[]]
    >();

  const [companyTotalCount, setCompanyTotalCount] = useState<number>(0);
  const [screeningResult, setScreeningResult] =
    useState<ScreeningResult | null>(null);
  const [selectedOrderOptionIndex, setSelectedOrderOptionIndex] = useState<
    number | ''
  >(0);

  const [selectedUniverse, setSelectedUniverse] = useState<
    Universe | undefined
  >({
    universe: 'nation',
    code: 840,
    name: 'UNITED STATES',
  });

  const initializedPreset = useMemo(() => {
    const query = new URLSearchParams(search);
    if (!query.has('case') || locationState?.preset) {
      return locationState?.preset;
    }
    const caseNumber = query.get('case');
    return getLv1Presets().find((preset) => preset.id === Number(caseNumber));
  }, [locationState, search]);

  const [
    isLoadingDebouncedRefreshCompanyList,
    setIsLoadingDebouncedRefreshCompanyList,
  ] = useState<boolean>(true);

  const getInitialPresetCondition = (
    factors: FactorValue[],
  ): {
    [id: number]: [FactorControlValue, FactorControlValue];
  } => {
    const r: {
      [id: number]: [FactorControlValue, FactorControlValue];
    } = {};

    factors.forEach((factor) => {
      if (!factor.fixed) {
        const factorHistogram = selectedPreset.specMap[factor.id];

        if (!factorHistogram?.bins) return;

        const minIndex = factorHistogram?.bins?.findIndex(
          (v) => v > (factor.min ?? 0),
        );
        const maxIndex = factorHistogram?.bins?.findIndex(
          (v) => v > (factor.max ?? 0),
        );

        r[factor.id] = [
          {
            value: minIndex < 0 ? 'min' : factor.min ?? 'min',
            freqIndex: minIndex >= 0 ? minIndex : 0,
            isAbsolute: factor.isAbsolute ?? true,
          },
          {
            value: maxIndex < 0 ? 'max' : factor.max ?? 'max',
            freqIndex: maxIndex >= 0 ? maxIndex : factorHistogram.bins.length,
            isAbsolute: factor.isAbsolute ?? true,
          },
        ];
      }
    });

    return r;
  };

  const [selectedPreset, setSelectedPreset] = useState<{
    preset: FactorPreset | null;
    specMap: {
      [id: number]: FactorControlSpec;
    };

    customCondition: {
      [id: number]: [FactorControlValue, FactorControlValue];
    };
  }>({
    preset: initializedPreset ?? null,
    specMap: {},
    customCondition: {},
  });

  const [excludedCategoryIds, setExcludedCategoryIds] = useState<number[]>(
    initializedPreset?.excludedCategoryIds ?? [],
  );

  const [backtestingOption, setBacktestingOption] = useState<BacktestingOption>(
    {
      startDate:
        initializedPreset?.startDate ??
        dayjs().subtract(3, 'year').startOf('month').toDate(),
      endDate:
        initializedPreset?.endDate ??
        dayjs().subtract(1, 'month').endOf('month').toDate(),
      count: initializedPreset?.count ?? DEFAULT_BACKTESTING_COMPANY_COUNT,
      tradeCost: initializedPreset?.tradeCost ?? DEFAULT_TRADE_COST_RATE,
      period: 'QUARTERLY',
    },
  );
  const [backtestingResult, setBacktestingResult] =
    useState<Backtesting | null>(null);
  const [createdStrategyId, setCreatedStrategyId] = useState<string | null>(
    null,
  );

  const [excludedCompanies, setExcludedCompanies] = useState<ExcludedCompany[]>(
    initializedPreset?.excludedCompanies ?? [],
  );

  const initialSpecMap = (factors: FactorValue[]) => {
    const refreshFactorIds = factors.map((v) => v.id);

    fetchFactorSpecs(refreshFactorIds).then((specs) => {
      setSelectedPreset((state) => {
        return {
          ...state,
          specMap: transform(
            specs,
            (r, spec) => {
              if (spec) {
                r[spec.factorId] = spec;
              }
            },
            {} as typeof selectedPreset.specMap,
          ),
        };
      });
    });
  };

  useEffect(() => {
    if (!context?.state?.user) {
      return;
    }
    if (initializedPreset) {
      updateUniverse(initializedPreset?.universe);
      initialSpecMap(initializedPreset.factors ?? []);
    } else {
      setModalIndex(0);
    }
    setStrategyName(
      initializedPreset?.nameTextSpans?.map((s) => s.text).join('') ??
        '나만의 전략',
    );
  }, [context?.state?.user, initializedPreset]);

  useEffect(() => {
    setSelectedPreset((state) => ({
      ...state,
      customCondition: getInitialPresetCondition(
        initializedPreset?.factors ?? [],
      ),
    }));
  }, [selectedPreset.specMap]);

  const sortedUniverseList = useMemo(() => {
    const indexUniverseList =
      sortBy(indexList, (i) => {
        const index = [
          'FTSE W KOREA DLY',
          'Nasdaq 100',
          'S&P 500',
          'EURO STOXX 50',
          'NIKKEI 225 CONSTITUENTJP',
          'Hang Seng Index',
          'S&P CNX NIFTY (50)',
        ].findIndex((v) => v === i.name);
        return index >= 0 ? index : (indexList?.length ?? 0) - 1;
      })?.map((v) => ({ ...v, universe: 'index' })) ?? [];

    const nationUniverseList = (
      nationList?.map((v) => ({
        ...v,
        universe: 'nation',
      })) ?? []
    ).reverse();

    if (context?.state?.mode === 'normal') {
      return [...indexUniverseList, ...nationUniverseList] as Universe[];
    }
    return [...nationUniverseList, ...indexUniverseList] as Universe[];
  }, [context?.state?.mode, nationList, indexList]);

  const isCustomized = useMemo(() => {
    return Object.keys(selectedPreset.customCondition).length > 0;
  }, [selectedPreset?.customCondition]);

  function buildCosmosFilterCondition(
    factorId: number,
    condition: FactorControlValue,
    type: 'min' | 'max',
  ): FactorFilterCondition | null {
    if (
      condition.value === null ||
      (condition.isAbsolute && condition.value === 'min' && type === 'min') ||
      (condition.isAbsolute && condition.value === 'max' && type === 'max') ||
      (!condition.isAbsolute && type === 'min' && condition.value === 0) ||
      (!condition.isAbsolute && type === 'max' && condition.value === 100)
    ) {
      return null;
    }
    const isAbsolute = condition?.isAbsolute ?? true;
    return {
      factorId,
      isAbsolute,
      // 이거 규격을 바꾸고싶긴함
      symbol: type === 'min' ? '>=' : '<=', // min.operator
      value: isAbsolute
        ? condition.value
        : condition.value === 'max'
        ? 0
        : condition.value === 'min'
        ? 100
        : 100 - condition.value,
    };
  }

  const requestForm: Parameters<typeof api.backtesting.requestBacktesting>[0] =
    useMemo(() => {
      const { nationCode, indexCode } = decodeUniverse(selectedUniverse);
      return {
        nationCode,
        indexCode,
        factorFilter: flatMap([selectedPreset.customCondition], (map) => {
          return flatMap<
            { [id: number]: [FactorControlValue, FactorControlValue] },
            FactorFilterCondition
          >(map, (v, factorId) => {
            if (v.length < 2) {
              return [];
            }
            const id = Number(factorId);
            const [min, max] = v;
            return compact([
              buildCosmosFilterCondition(id, min, 'min'),
              buildCosmosFilterCondition(id, max, 'max'),
            ]);
          });
        }),
        categoryIds:
          excludedCategoryIds.length > 0
            ? categoryList
                ?.filter((c) => !excludedCategoryIds.includes(c.cosmosGroupId))
                ?.map((c) => c.cosmosGroupId) ?? []
            : null,
        factorWeight: [],
        excludedCompanies,
        portWeightPolicy: indexCode ? 'MARKET_CAP' : 'EQUAL',
        startDate: backtestingOption.startDate ?? undefined,
        endDate: backtestingOption.endDate ?? undefined,
        rebalancingPeriod: backtestingOption.period ?? 'SEMIANNUALLY',
        orders:
          selectedOrderOptionIndex !== ''
            ? [
                (selectedUniverse?.universe === 'index'
                  ? indexOrderOptions
                  : nationOrderOptions)?.[selectedOrderOptionIndex]?.ordering,
              ]
            : undefined,
        count:
          selectedUniverse?.universe === 'index'
            ? undefined
            : Math.min(
                backtestingOption?.count ?? 0,
                MAX_BACKTESTING_COMPANY_COUNT, // TODO: 현재 최대 갯수 제한
              ),
        tradeCost: backtestingOption?.tradeCost ?? DEFAULT_TRADE_COST_RATE,
      };
    }, [
      categoryList,
      selectedUniverse,
      selectedPreset,
      excludedCategoryIds,
      selectedOrderOptionIndex,
      excludedCompanies,
      backtestingOption,
    ]);

  const onChangeExcludingCompany = (
    company: ExcludedCompany,
    excluded: boolean,
  ) => {
    setExcludedCompanies((state) => {
      if (excluded) {
        return uniqBy([...state, company], (c) => c.id);
      }
      return state.filter((c) => c.id !== company.id);
    });
  };

  useEffect(() => {
    if (context?.state?.user) {
      Promise.all([
        api.strategy.getFactors(),
        api.strategy.getFactorCategories(),
        api.strategy.getSectors(),
        api.strategy.getIndexes(),
        api.strategy.getNations(),
      ]).then(
        ([
          factorListResponse,
          factorCategoryListResponse,
          categoryListResponse,
          indexesResponse,
          nationsResponse,
        ]) => {
          setData([
            factorListResponse.data,
            factorCategoryListResponse.data,
            categoryListResponse.data.filter((c) => c.level === 1),
            categoryListResponse.data.filter((c) => c.level > 1),
            indexesResponse.data,
            nationsResponse.data,
          ]);
        },
        () => {
          console.log('fail');
        },
      );
    }
  }, [context?.state?.user]);

  const debouncedRefreshBacktestingResult = useMemo(() => {
    return debounce(
      (from: typeof requestForm) => {
        if (from?.indexCode || from?.nationCode) {
          setIsLoadindgBacktesting(true);
          api.backtesting
            .requestBacktesting(from)
            .then(
              async (backtestingResponse) => {
                const res = await api.backtesting.getBacktesting(
                  backtestingResponse.data.uid,
                );
                setBacktestingResult(res.data);
                setIsOpenResultModal(true);
              },
              (error) => {
                console.log('fail', error);
              },
            )
            .finally(() => {
              setIsLoadindgBacktesting(false);
            });
        }
      },
      1000,
      { maxWait: 3000 },
    );
  }, []);
  const debouncedRefreshCompanyList = useMemo(() => {
    return debounce(
      (form: typeof requestForm) => {
        (async () => {
          setIsLoadingDebouncedRefreshCompanyList(true);

          if (form.indexCode || form.nationCode) {
            const response = await api.strategy.getCompanies({
              nationCode: form.nationCode,
              indexCode: form.indexCode,
              categoryIds: form.categoryIds,
              weightPolicy: form.portWeightPolicy,
              filter: form.factorFilter,
              orders: form.orders,
              count: !form.indexCode
                ? (form.count ?? 50) + (form.excludedCompanies?.length ?? 0)
                : undefined,
              offset: 0,
            });

            setCompanyTotalCount(response.data.count);
            setScreeningResult(response.data);
            setIsLoadingDebouncedRefreshCompanyList(false);
          } else {
            setCompanyTotalCount(0);
            setScreeningResult(null);
            setIsLoadingDebouncedRefreshCompanyList(false);
          }
        })().catch((error) => {
          console.log('fail', error);
          setCompanyTotalCount(0);
          setScreeningResult(null);
          setIsLoadingDebouncedRefreshCompanyList(false);
        });
      },
      1000,
      { maxWait: 3000 },
    );
  }, []);

  useEffect(() => {
    if (selectedUniverse) {
      debouncedRefreshCompanyList(requestForm);
      debouncedRefreshBacktestingResult(requestForm);
    }
  }, [requestForm]);

  async function fetchFactorSpecs(factorIds: number[]) {
    if (selectedUniverse) {
      const { nationCode, indexCode } = decodeUniverse(selectedUniverse);
      try {
        const responses = await bluebird.all(
          factorIds.map(async (factorId) =>
            api.strategy.getFactorControlSpec(factorId, {
              nationCode,
              indexCode,
            }),
          ),
        );

        return responses.map((response) => response?.data || null);
      } catch (error) {
        console.log('fail', error);
        return [];
      }
    }

    return [];
  }

  function updateUniverse(universe: typeof selectedUniverse) {
    if (
      selectedUniverse?.universe !== universe?.universe ||
      selectedUniverse?.code !== universe?.code
    ) {
      const refreshFactorIds = Object.keys(selectedPreset?.specMap).map((v) =>
        Number(v),
      );
      fetchFactorSpecs(refreshFactorIds).then((specs) => {
        setSelectedPreset((state) => {
          return {
            ...state,
            specMap: transform(
              specs,
              (r, spec) => {
                if (spec) {
                  r[spec.factorId] = spec;
                }
              },
              {} as typeof selectedPreset.specMap,
            ),
          };
        });
      });
    }
    setSelectedOrderOptionIndex(0);
    setSelectedUniverse(universe);
  }

  function updateCustomFactorCondition(
    factorId: number,
    value: [FactorControlValue, FactorControlValue],
  ) {
    setSelectedPreset((state) => ({
      ...state,
      customCondition: {
        ...state.customCondition,
        [factorId]: cloneDeep(value),
      },
    }));
  }

  function addCustomFactorCondition(factorId: number) {
    fetchFactorSpecs(
      pullAll(
        [factorId],
        Object.keys(selectedPreset.specMap).map((id) => Number(id)),
      ),
    ).then(
      (newFactorSpecList: (FactorControlSpec | null)[]) => {
        // 새로 등록된건 새로 업데이트
        // 팩터를 새로 등록
        setSelectedPreset((state) => {
          const newSpecMap = transform(
            newFactorSpecList,
            (r, spec) => {
              if (spec) {
                spec.defaultFreqIndex = getDefaultFreqIndex(spec);

                r[spec.factorId] = spec;
              }
            },
            state.specMap,
          );

          const newCondition =
            newSpecMap[factorId].defaultRange === 'BOTTOM20POSITIVE'
              ? getAbsoluteInitialRange(
                  newSpecMap[factorId],
                  newSpecMap[factorId].defaultRange,
                )
              : getReletiveInitialRange(
                  newSpecMap[factorId],
                  newSpecMap[factorId].defaultRange,
                );

          return {
            ...state,
            specMap: newSpecMap,
            customCondition: {
              ...selectedPreset.customCondition,
              [factorId]: newCondition,
            },
          };
        });
      },
      (error) => {
        console.log('fail', error);
      },
    );
  }

  function removeCustomFactorCondition(factorId: number) {
    setSelectedPreset((state) => {
      const customCondition = omit(state.customCondition, factorId);
      return {
        ...state,
        customCondition,
      };
    });
  }

  const customFactorContainer = useMemo(() => {
    if (
      !selectedPreset ||
      Object.keys(selectedPreset.customCondition).length < 1
    ) {
      return <div />;
    }

    const { specMap, customCondition } = selectedPreset;

    return (
      <Stack direction="column" spacing={2}>
        {map(customCondition, (value: any, factorId: number) => {
          const factor = factorList?.find(
            (spec) => spec.id === Number(factorId),
          );
          if (!factor || !specMap[factorId] || !value) {
            return null;
          }

          return (
            <FactorHistogramItem
              key={`factor-${factor.id}`}
              factor={factor}
              factorHistogram={specMap[factor.id]}
              value={value}
              onUpdateRange={(
                range: [FactorControlValue, FactorControlValue],
              ) => {
                const callback = () => {
                  updateCustomFactorCondition(factorId, range);
                };
                if (selectedPreset.preset) {
                  setModifyAlertModalCallback(() => () => callback());
                } else {
                  callback();
                }
              }}
              onDelete={(factor) => {
                const callback = () => {
                  removeCustomFactorCondition(factor.id);
                };
                if (selectedPreset.preset) {
                  setModifyAlertModalCallback(() => () => callback());
                } else {
                  callback();
                }
              }}
            />
          );
        })}
      </Stack>
    );
  }, [
    sortedUniverseList,
    selectedUniverse,
    selectedPreset,
    selectedPreset?.customCondition,
    factorList,
  ]);

  const upsertStrategyFromBacktesting = useCallback(
    (strategyName: string) => {
      logEvent('invest btn clicked', {
        'backtesting start date': backtestingOption.startDate,
        'backtesting end date': backtestingOption.endDate,
        'factor category':
          // actor category는 상위 분류 (ex. 가치, 주주정책..)
          [
            ...new Set(
              Object.keys(selectedPreset.customCondition).map(
                (v) =>
                  factorList?.find((factor) => factor.id.toString() === v)
                    ?.categoryId,
              ),
            ),
          ].join(', '),
        'factor numb': Object.keys(selectedPreset.customCondition)
          // factor num은 상위 분류 내에 위치한 각 factor들의 번호
          .map(
            (v) => factorList?.find((factor) => factor.id.toString() === v)?.id,
          )
          .join(', '),
        'selected universe': selectedUniverse?.name,
        'sorting priority': (selectedUniverse?.universe === 'index'
          ? indexOrderOptions
          : nationOrderOptions)[selectedOrderOptionIndex as number].name,
        'backtesting num total stocks': backtestingOption.count,
        'excluding stock name': excludedCompanies
          .map((v) => v.ticker)
          .join(', '),
        'selected sector name':
          excludedCategoryIds.length > 0
            ? categoryList
                ?.filter(
                  (c) =>
                    !excludedCategoryIds.some((id) => id === c.cosmosGroupId),
                )
                .map((c) => c.name)
                .join(', ')
            : 'all',
        'num of total excluded stock': excludedCompanies.length,
        'custom state': isUndefined(initializedPreset)
          ? 'none'
          : selectedPreset?.preset === null,

        'sector title': categoryList
          ?.filter((c) => !excludedCategoryIds.includes(c.cosmosGroupId))
          ?.map((c) => c.cosmosGroupId),
      });
      if (backtestingResult?.id) {
        (async () => {
          const strategyResponse = await api.strategy.importBacktesting({
            name: strategyName,
            backtestingId: backtestingResult.id,
            targetStrategyId: createdStrategyId ?? undefined,
          });

          // setCreatedStrategyId(response.data.id);
          const portfolioResponse = await api.portfolio.createPortfolio({
            name: '제목없는 포트폴리오',
          });
          await api.portfolio.applyStrategy(
            portfolioResponse.data.id,
            strategyResponse.data.id,
          );
          history.push(`/portfolio/${portfolioResponse.data.uid}`);
        })().catch((error) => {
          console.log('fail', error);
        });
      }
    },
    [
      backtestingResult,
      createdStrategyId,
      isCustomized,
      selectedPreset?.preset?.nameTextSpans,
      selectedPreset?.customCondition,
    ],
  );

  const backtestingResultContainer = useMemo(() => {
    if (!isOpenResultModal || !backtestingResult) {
      return null;
    }

    return (
      <BacktestingResult
        strategyName={strategyName}
        backtestingResult={backtestingResult}
      />
    );
  }, [
    isOpenResultModal,
    strategyName,
    backtestingResult,
    initializedPreset,
    selectedPreset,
    upsertStrategyFromBacktesting,
  ]);

  const accountApplierModal = useMemo(() => {
    if (!createdStrategyId || !isOpenAccountApplier) {
      return null;
    }
    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        open={isOpenAccountApplier}
        onClose={() => setIsOpenAccountApplier(false)}
      >
        <StrategyPortfolioApplier
          tabIndex={-1}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            minWidth: '600px',
            width: '50%',
            // transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
          p={2}
          strategyId={createdStrategyId}
          onCompleted={(portfolio, modelAsset, recipe) => {
            api.portfolio
              .applyStrategy(portfolio.id, createdStrategyId, modelAsset)
              .then(
                () => {
                  history.push(`/portfolio/${portfolio.uid}`);
                },
                (error) => {
                  console.log('fail', error);
                },
              );
          }}
          onClose={() => setIsOpenAccountApplier(false)}
        />
      </StyledModal>
    );
  }, [isOpenAccountApplier, createdStrategyId]);

  const indexModal = useMemo(() => {
    // if (!createdStrategyId || !isOpenAccountApplier) {
    //   return null;
    // }
    if (modalIndex === undefined) return <div />;

    const stepTitleList = ['시장/지수', '투자산업', '투자조건', '기타설정'];

    function IndexModalStepper() {
      return (
        <Stepper
          sx={{ flex: 1, marginTop: '16px', display: ['inherit', 'none'] }}
          activeStep={modalIndex}
          alternativeLabel
        >
          {stepTitleList.map((title) => (
            <Step key={title}>
              <StepLabel>{title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      );
    }

    const headerList = [
      <Box>
        <Typography variant="headline2">
          {t('directIndex.market_selection')}
        </Typography>
      </Box>,
      <Box>
        <Typography variant="headline2">
          {t('directIndex.industry_selection')}
        </Typography>
      </Box>,
      <Box>
        <Typography variant="headline2">
          {' '}
          {t('directIndex.stock_selection_criteria')}
        </Typography>
      </Box>,
    ];

    const descList = [
      <Typography variant="body1">{t('text.selectIndexTitle')}</Typography>,
      <Typography variant="body1">{t('text.selectIndustry')}</Typography>,
      <Typography variant="body1" whiteSpace="pre-line">
        {t('text.selectFactor')}
      </Typography>,
    ];
    const contentsList = [
      <UniverseList
        pr="12px"
        pb="12px"
        px="24px"
        universes={sortedUniverseList}
        value={selectedUniverse}
        onChangeUniverse={(universe: Universe | undefined) => {
          if (selectedPreset.preset) {
            setModifyAlertModalCallback(() => () => updateUniverse(universe));
          } else {
            updateUniverse(universe);
          }
        }}
      />,
      <CategoryList
        px={[2, '24px']}
        py={['0px', 2]}
        excludedValues={excludedCategoryIds}
        onClickAllSelect={(isChecked: boolean) => {
          const callback = () => {
            if (isChecked) {
              setExcludedCategoryIds([]);
            } else {
              setExcludedCategoryIds(
                categoryList?.map((v) => v.cosmosGroupId) ?? [],
              );
            }
          };
          if (selectedPreset.preset) {
            setModifyAlertModalCallback(() => () => callback());
          } else {
            callback();
          }
        }}
        topCategoryList={topCategoryList ?? []}
        categoryList={categoryList ?? []}
        onExclude={(id, excluded) => {
          const callback = () => {
            if (excluded) {
              setExcludedCategoryIds((state) => [...state, ...castArray(id)]);
            } else {
              setExcludedCategoryIds((state) => {
                return difference(state, castArray(id));
              });
            }
          };
          if (selectedPreset.preset) {
            setModifyAlertModalCallback(() => () => callback());
          } else {
            callback();
          }
        }}
      />,
      !factorList || !factorCategoryList ? (
        <Box />
      ) : (
        <FactorCustomizer
          tabIndex={-1}
          height="100%"
          px="24px"
          specMap={selectedPreset.specMap}
          factors={factorList}
          factorCategories={factorCategoryList}
          conditionMap={selectedPreset.customCondition}
          onChangeCondition={(factorId: number, value) => {
            const callback = () => {
              updateCustomFactorCondition(factorId, value);
            };
            if (selectedPreset.preset) {
              setModifyAlertModalCallback(() => () => callback());
            } else {
              callback();
            }
          }}
          onAddFactor={(factorId) => {
            const callback = () => {
              addCustomFactorCondition(factorId);
            };
            if (selectedPreset.preset) {
              setModifyAlertModalCallback(() => () => callback());
            } else {
              callback();
            }
          }}
          onRemoveFactor={(factorId) => {
            const callback = () => {
              removeCustomFactorCondition(factorId);
            };
            if (selectedPreset.preset) {
              setModifyAlertModalCallback(() => () => callback());
            } else {
              callback();
            }
          }}
          onRemoveAllFactors={() => {
            setSelectedPreset((state) => ({
              ...state,
              customCondition: {},
            }));
          }}
        />
      ),
    ];
    const getCtaDisabled = (): boolean => {
      switch (modalIndex) {
        case 0:
        case 2:
          return !selectedUniverse;
        default:
          return false;
      }
    };
    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        BackdropProps={{ invisible: true }}
        open={!isUndefined(modalIndex)}
        onClose={(event, reason) => {
          // if (reason && reason === 'backdropClick') return;
          setModalIndex(undefined);
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            minWidth: ['inherit', modalIndex === 2 ? '900px' : '600px'],
            width: ['inherit', modalIndex === 2 ? '900px' : '600px'],
            bgcolor: 'background.paper',
            boxShadow: 24,
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
          }}
        >
          <IndexModalStepper />
          <Stack direction="row" p="24px" alignItems="start">
            <Box flex={1} width="100%">
              <Stack direction="row" alignItems="center">
                <Box flex={1}>{headerList[modalIndex as number]}</Box>

                <Box display={['flex', 'none']}>
                  <IconButton onClick={() => setModalIndex(undefined)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Stack>
              {descList[modalIndex as number]}
            </Box>

            <Stack
              direction="row"
              spacing="12px"
              alignItems="center"
              display={['none', 'flex']}
            >
              <StyledButton
                disabled={getCtaDisabled()}
                variant={modalIndex === 2 ? 'contained' : 'outlined'}
                onClick={() => setModalIndex(undefined)}
              >
                {t('text.selectionCompleted')}
              </StyledButton>

              {modalIndex === contentsList.length - 1 ? (
                <div />
              ) : (
                <StyledButton
                  disabled={getCtaDisabled()}
                  variant="contained"
                  onClick={() => setModalIndex((modalIndex as number) + 1)}
                >
                  {t('text.next')}
                </StyledButton>
              )}
            </Stack>
          </Stack>

          <Box
            pb="48px"
            overflow={modalIndex === 2 ? 'hidden' : 'auto'}
            sx={{ height: ['calc(100vh - 240px)', 'calc(100vh - 120px)'] }}
          >
            {contentsList[modalIndex as number]}
          </Box>
          <Stack
            direction="row"
            spacing="12px"
            alignItems="center"
            flex={1}
            display={['flex', 'none']}
            px="8px"
            py="12px"
          >
            {modalIndex === 0 ? null : (
              <Box flex={1}>
                <StyledButton
                  disabled={getCtaDisabled()}
                  variant="outlined"
                  sx={{
                    width: '-webkit-fill-available',
                  }}
                  onClick={() => setModalIndex((modalIndex as number) - 1)}
                >
                  {t('text.prev')}
                </StyledButton>
              </Box>
            )}
            <Box flex={1}>
              <StyledButton
                disabled={getCtaDisabled()}
                variant="contained"
                onClick={
                  modalIndex === contentsList.length - 1
                    ? () => {
                        if (
                          Object.keys(selectedPreset?.customCondition)
                            .length === 0
                        ) {
                          setMobileIndex(1);
                        }

                        setModalIndex(undefined);
                      }
                    : () => setModalIndex((modalIndex as number) + 1)
                }
                sx={{
                  width: '-webkit-fill-available',
                }}
              >
                {t('text.next')}
              </StyledButton>
            </Box>
          </Stack>
        </Box>
      </StyledModal>
    );
  }, [
    modalIndex,
    sortedUniverseList,
    selectedUniverse,
    selectedPreset,
    excludedCategoryIds,
  ]);

  const modifyAlertModal = useMemo(() => {
    return (
      <StyledModal
        disableAutoFocus
        disableEnforceFocus
        BackdropProps={{ invisible: true }}
        open={modifyAlertModalCallback !== undefined}
        onClose={(event, reason) => {
          setModifyAlertModalCallback(undefined);
        }}
      >
        <Stack
          sx={{
            borderRadius: '8px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: '24px',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Box
              display="flex"
              width="40px"
              height="40px"
              bgcolor="#F6EDFF"
              borderRadius="20px"
              justifyContent="center"
              alignItems="center"
            >
              <DashboardCustomize color="secondary" />
            </Box>
            <Typography ml="8px" fontWeight="bold" flex={1}>
              {t('text.changeAlertTitle')}
            </Typography>
            <IconButton onClick={() => setModifyAlertModalCallback(undefined)}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <Box py="12px">
            <Typography variant="body1" textAlign="center">
              {t('text.changeAlertDesc')}
            </Typography>
          </Box>

          <Stack direction="row" mt="8px" spacing={1} justifyContent="end">
            <StyledButton
              variant={modalIndex === 2 ? 'contained' : 'outlined'}
              onClick={() => setModifyAlertModalCallback(undefined)}
            >
              {t('text.cancel')}
            </StyledButton>
            <StyledButton
              variant="contained"
              onClick={() => {
                setSelectedPreset((state) => {
                  return {
                    ...state,
                    preset: null,
                  };
                });
                modifyAlertModalCallback?.();
                setModifyAlertModalCallback(undefined);
              }}
            >
              {t('text.submit')}
            </StyledButton>
          </Stack>
        </Stack>
      </StyledModal>
    );
  }, [modifyAlertModalCallback]);

  const execButton = (
    <LoadingButton
      loading={isLoadingBacktesting}
      startIcon={
        <LottieAnimation
          width="30px"
          height="30px"
          animationData={lottieBacktest}
        />
      }
      disabled={
        !selectedUniverse ||
        excludedCategoryIds.length === (categoryList?.length ?? 0)
      }
      variant="contained"
      sx={{
        width: ['-webkit-fill-available', 'inherit'],
        height: ['52px', 'inherit'],
      }}
      onClick={() => {
        upsertStrategyFromBacktesting(strategyName);
      }}
    >
      {t('directIndex.invest_with_this_strategy')}
    </LoadingButton>
  );
  const optionContents = (
    <Stack spacing="24px">
      <SelectableStackItem
        selected={false}
        hasBorder={false}
        alignItems="center"
        direction="row"
        bgcolor="#FAFAFA"
        boxShadow={modalIndex === 0 ? 4 : 0}
        onClick={() => {
          setModalIndex(0);
        }}
      >
        <Typography variant="subtitle2" flex={1}>
          {t('directIndex.market_selection')}
        </Typography>
        <Typography variant="body2">
          {selectedUniverse?.universe === 'index' ? (
            <>{t(`index.${selectedUniverse.name}.name`)}</>
          ) : selectedUniverse?.universe === 'nation' ? (
            <>
              {t(`nation.${selectedUniverse.name}.flag`)}{' '}
              {t(`nation.${selectedUniverse.name}.name`)}
            </>
          ) : (
            '없음'
          )}
        </Typography>
        <NavigateNextIcon sx={{ color: 'text.secondary' }} />
      </SelectableStackItem>
      <SelectableStackItem
        selected={false}
        bgcolor="#FAFAFA"
        alignItems="center"
        hasBorder={false}
        direction="row"
        boxShadow={modalIndex === 1 ? 4 : 0}
        onClick={() => {
          setModalIndex(1);
        }}
      >
        <Typography variant="subtitle2" flex={1}>
          {t('directIndex.industry_selection')}
        </Typography>
        <Typography variant="body2">
          {excludedCategoryIds.length === 0
            ? t('text.all')
            : `${(categoryList?.length ?? 0) - excludedCategoryIds.length}`}
        </Typography>
        <NavigateNextIcon sx={{ color: 'text.secondary' }} />
      </SelectableStackItem>

      <SelectableStackItem
        bgcolor="#FAFAFA"
        boxShadow={modalIndex === 2 ? 4 : 0}
        selected={false}
        hasBorder={false}
      >
        <Stack direction="row">
          <Typography variant="subtitle2" flex={1}>
            {t('directIndex.stock_selection_criteria')}
          </Typography>

          <StyledButton
            variant="text"
            sx={{ color: '#3278FF', fontWeight: 'bold', padding: '0px' }}
            onClick={() => {
              setModalIndex(2);
            }}
          >
            {t('text.add_button_text')}
          </StyledButton>
          <NavigateNextIcon sx={{ color: 'text.secondary' }} />
        </Stack>

        {Object.keys(selectedPreset?.customCondition).length === 0 ? (
          <Typography
            variant="body2"
            color="text.secondary"
            whiteSpace="pre-line"
          >
            {t('text.add_condition_text')}
          </Typography>
        ) : (
          customFactorContainer
        )}
      </SelectableStackItem>

      <BacktestingOption
        option={backtestingOption}
        isCountable={selectedUniverse?.universe === 'nation' ?? true}
        maxCount={MAX_BACKTESTING_COMPANY_COUNT}
        universe={selectedUniverse}
        onUpdate={(option) => {
          setBacktestingOption((state) => {
            return {
              ...state,
              ...option,
            };
          });
        }}
        orderOptionIndex={selectedOrderOptionIndex}
        indexOrderOptions={indexOrderOptions}
        nationOrderOptions={nationOrderOptions}
        onUpdateOrderOptionIndex={setSelectedOrderOptionIndex}
      />

      <SelectableStackItem
        hasBorder={false}
        bgcolor="#FAFAFA"
        selected={false}
        mt="24px"
        spacing={2}
      >
        <Typography variant="headline2">
          {t('directIndex.excluded_stocks')}
        </Typography>
        <Typography variant="body2">
          {t('directIndex.exclude_stocks_function')}
        </Typography>

        <Stack direction="row" flexWrap="wrap" spacing={2}>
          {excludedCompanies.map((company) => {
            return (
              <Chip
                key={company.id}
                label={company.name ?? company.ticker}
                sx={{
                  mb: '4px',
                }}
                onDelete={() => {
                  onChangeExcludingCompany(company, false);
                }}
              />
            );
          })}
        </Stack>
      </SelectableStackItem>
    </Stack>
  );

  const resultContents = (
    <Stack pb={['0px', '112px']} flex={1}>
      <Tabs
        value={resultTabIndex}
        textColor="secondary"
        indicatorColor="secondary"
        onChange={(e, i) => {
          setResultTabIndex(i);
        }}
      >
        <Tab
          value="result"
          iconPosition="start"
          label={t('directIndex.past_investment_performance')}
        />
        <Tab value="option" label={t('directIndex.stock_list')} />
      </Tabs>
      <Stack spacing="20px" width="100%">
        {resultTabIndex === 'result' ? backtestingResultContainer : null}
        {resultTabIndex === 'option' && selectedUniverse ? (
          <>
            {screeningResult && (screeningResult?.list?.length ?? 0) > 0 ? (
              <PortfolioTreeMap
                height="300px"
                companies={screeningResult.list}
                rebalancingList={screeningResult.rebalancingList}
                count={requestForm.count}
                weightMode={
                  selectedUniverse?.universe === 'index' ? 'value' : 'equal'
                }
                excludedCompanies={excludedCompanies}
              />
            ) : null}

            <CompanyList
              companies={screeningResult?.list ?? []}
              rebalancingList={screeningResult?.rebalancingList ?? []}
              count={requestForm.count}
              matchedCount={companyTotalCount}
              isCompact={isMobile}
              excludedCompanies={excludedCompanies}
              onChangeExcluding={onChangeExcludingCompany}
            />
          </>
        ) : null}
      </Stack>
    </Stack>
  );
  return (
    <Stack
      px={['0px', '16px']}
      width={['initial', '100%']}
      maxWidth={['initial', '1380px']}
      direction="column"
      height="100%"
      mx="auto"
      overflow="hidden"
    >
      <Stack
        pt="32px"
        direction="row"
        height="fit-content"
        width="100%"
        alignItems="center"
        mb="16px"
        px="20px"
        spacing="8px"
      >
        <Stack direction={isMobile ? 'column' : 'row'} spacing="8px" flex={1}>
          <Typography variant="headline1">
            {selectedPreset?.preset?.nameTextSpans
              ?.map((s) => s.text)
              .join('') ?? t('text.direct_indexing')}
          </Typography>
          <Stack flex={1} alignItems={isMobile ? 'start' : 'end'}>
            <Button
              onClick={() => {
                history.push('/select-strategy');
              }}
              sx={{
                borderColor: '#D9DCE0',
                width: 'fit-content',
              }}
              variant="outlined"
            >
              {t('directIndex.alternative_strategy_selection')}
            </Button>
          </Stack>
        </Stack>
        <Stack
          display={['none', 'inherit']}
          direction="row"
          justifyContent="end"
        >
          {execButton}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        flex={1}
        display={['none', 'flex']}
        overflow={['inherit', 'hidden']}
        mt="16px"
        spacing="16px"
      >
        <Box overflow="auto" flex={1}>
          {optionContents}
        </Box>
        <Box flex={1} maxWidth="50%" position="relative">
          <Box overflow="auto" width="100%" height="100%">
            {resultContents}
          </Box>
          {isLoadingDebouncedRefreshCompanyList || isLoadingBacktesting ? (
            <Box
              top="0px"
              left={0}
              right={0}
              height="100%"
              position="absolute"
              zIndex={1000}
              bgcolor="rgba(255, 255, 255, 0.75)"
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              <Box
                bgcolor="background.paper"
                borderRadius="8px"
                p="16px"
                border="1px solid #ccc"
                width="fit-content"
              >
                <LottieAnimation height="100px" animationData={lottieLoading} />

                <Typography
                  whiteSpace="pre-line"
                  variant="body2"
                  textAlign="center"
                  color="black"
                >
                  {t('text.loading_text')}
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Stack>

      <Box
        display={['flex', 'none']}
        width={['100vw', 'inehrit']}
        height="100%"
        overflow="hidden"
      >
        <Stack width="100%" height="100%" overflow="auto">
          <Stack width="100%" pb="82px" px="20px">
            {mobileIndex === 0 ? resultContents : optionContents}
          </Stack>
        </Stack>
        {isLoadingDebouncedRefreshCompanyList || isLoadingBacktesting ? (
          <Box
            top="0px"
            left={0}
            right={0}
            height="100%"
            position="absolute"
            zIndex={1000}
            bgcolor="rgba(255, 255, 255, 0.75)"
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <Box
              bgcolor="background.paper"
              borderRadius="8px"
              p="16px"
              border="1px solid #ccc"
              width="fit-content"
            >
              <LottieAnimation height="100px" animationData={lottieLoading} />

              <Typography
                whiteSpace="pre-line"
                variant="body2"
                textAlign="center"
                color="black"
              >
                {t('text.loading_text')}
              </Typography>
            </Box>
          </Box>
        ) : null}
        <Box
          position="fixed"
          bottom="0px"
          left="0px"
          right="0px"
          p="12px"
          bgcolor="white"
          zIndex={100}
        >
          <Stack direction="row" spacing="10px">
            {mobileIndex === 0 ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setMobileIndex(1)}
                  size="small"
                  fullWidth
                >
                  {t('text.modifyStrategy')}
                </Button>
                <StyledButton
                  variant="contained"
                  onClick={() => upsertStrategyFromBacktesting(strategyName)}
                  size="small"
                  fullWidth
                >
                  {t('directIndex.invest_with_this_strategy')}
                </StyledButton>
              </>
            ) : (
              <StyledButton
                variant="outlined"
                onClick={() => setMobileIndex(0)}
                size="small"
                fullWidth
              >
                {t('text.viewPerformance')}
              </StyledButton>
            )}
          </Stack>
        </Box>
      </Box>

      {indexModal}
      {accountApplierModal}
      {modifyAlertModal}
    </Stack>
  );
}
