import qs from 'qs';
import JsonURL from '@jsonurl/jsonurl';
import {
  compact,
  isNil,
  isString,
  mapValues,
  omit,
  omitBy,
  pick,
  transform,
} from 'lodash';

import { FactorState } from '~/containers/factor/context';
import { getExecConditions } from '~/utils/factor';
import { getFormatedDate } from '~/utils/datetime';
import { SortData } from '~/api/screener';
import { BackTestState } from '~/containers/backtest/context';
import {
  FactorOrder,
  FactorQueryCondition,
  RebalancingPeriod,
} from '~/api/backtesting';

const buildScreeningOption = (factorState: FactorState) => {
  const conditions = factorState?.selectedFactors
    ? getExecConditions(factorState.selectedFactors, factorState!.sortData[0])
    : [];

  const selectedCategoryIds = Array.from(
    factorState?.companyGroupList.entries() ?? [],
  )
    .map(([key, value]) => {
      if (value.length === 0) {
        return [];
      }
      if (value.length === 1) {
        return [key];
      }
      return value;
    })
    .flatMap((v) => v)
    .filter((v) => !factorState?.unselectedGroups.has(v.cosmosGroupId))
    .map((v) => v.cosmosGroupId);

  return {
    nationCode: factorState.selectedNation.code,
    categoryIds:
      (factorState?.unselectedGroups.size ?? 0) > 0
        ? selectedCategoryIds
        : undefined,
    factorQuery: conditions.map((c) =>
      omitBy(
        c,
        (v, k) =>
          ['gte', 'lte', 'weight'].includes(k) ||
          (['gt', 'lt'].includes(k) && isNil(v)) ||
          (k === 'queryType' && v === 'FILTER'),
      ),
    ), // 기본값 제외
    orders:
      typeof factorState.sortData[0].data !== 'string'
        ? (factorState.sortData.map((v) => v.data) as Array<SortData>)
        : undefined,
  };
};

const buildBacktestingOption = (backtestState: BackTestState) => {
  return {
    tradeCost: backtestState?.tradeCost,
    rebalancingPeriod: backtestState?.rebalancingPeriod,
    count: backtestState?.top,
    startDate: getFormatedDate({ date: backtestState.startDate }),
    endDate: getFormatedDate({ date: backtestState.endDate }),
    // portWeightPolicy: 'EQUAL',
  };
};

export const stringifyScreenerOption = (
  factorState: FactorState,
  originalQuery?: string,
) => {
  return stringifyOption(factorState, undefined, originalQuery);
};

export const stringifyOption = (
  factorState: FactorState,
  backtestState?: BackTestState,
  originalQuery?: string,
) => {
  const params = {
    ...(originalQuery
      ? qs.parse(originalQuery, {
          arrayLimit: Infinity,
          ignoreQueryPrefix: true,
        })
      : null),
    ...buildScreeningOption(factorState),
  };

  if (backtestState) {
    Object.assign(params, buildBacktestingOption(backtestState));
  }

  (['categoryIds', 'factorQuery', 'orders'] as const).forEach((key) => {
    if (!isNil(params?.[key])) {
      Object.assign(params, {
        [key]: JsonURL.stringify(params[key]),
      });
    }
  });

  return qs.stringify(params, {
    arrayFormat: 'indices',
  });
};

export const parseOption = (
  query: string,
  {
    isOnlyScreening,
    ignoreQueryPrefix,
  }: {
    isOnlyScreening?: boolean;
    ignoreQueryPrefix?: boolean;
  } = { isOnlyScreening: false, ignoreQueryPrefix: true },
): {
  nationCode?: string;
  categoryIds?: string[];
  factorQuery?: FactorQueryCondition[];
  orders?: FactorOrder[];
  // portWeightPolicy?: PortWeightPolicy;

  startDate?: string;
  endDate?: string;
  tradeCost?: string;
  count?: string;
  rebalancingPeriod?: RebalancingPeriod;
} => {
  const inputParams = pick(
    qs.parse(query, {
      arrayLimit: Infinity,
      ignoreQueryPrefix: ignoreQueryPrefix ?? true,
    }),
    [
      'nationCode',
      'categoryIds',
      'factorQuery',
      'orders',
      'portWeightPolicy',

      ...(isOnlyScreening
        ? []
        : ['startDate', 'endDate', 'tradeCost', 'count', 'rebalancingPeriod']),
    ],
  );

  (['categoryIds', 'factorQuery', 'orders'] as const).forEach((key) => {
    if (isString(inputParams?.[key])) {
      try {
        inputParams[key] = JsonURL.parse(<string>inputParams[key]);
      } catch (e) {
        delete inputParams[key];
        console.error(e);
      }
    }
  });

  return <ReturnType<typeof parseOption>>inputParams;
};
