import { Spacer, Box } from '@chakra-ui/layout';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import { isNil, isNumber, some } from 'lodash';

import dayjs from '~/utils/dayjs';
import { H3, Caption } from '~/components/Typography';
import { get } from '~/utils/localStorage';
import { getThemeData } from '~/utils/theme';
import { getFormatedDate, getYYYYMMDD } from '~/utils/datetime';
import { FactorDispatchContext, FactorStateContext } from '../factor/context';
import { BackTestDispatchContext, BackTestStateContext } from './context';
import SectorSelect from '../factor/SectorSelect';
import ScreenerSortSelector, {
  screenerSortData,
  ScreenerSortType,
} from '../screener/ScreenerSortSelector';
import NationSelectorContainer from './NationSelectorContainer';
import CustomDatePicker from '~/components/CustomDatePicker';
import { AppContext } from '~/AppContext';
import NumberEditableInput from '~/components/factor/NumberEditableInput';
import api from '~/api';
import { parseOption, stringifyOption } from '~/utils/backtestingOptionQuery';

function BacktestOptionContainer() {
  const location = useLocation();
  const context = useContext(AppContext);
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  const backTestState = useContext(BackTestStateContext);
  const backtestDispatch = useContext(BackTestDispatchContext);
  const { state } = useContext(AppContext) ?? {};
  const themeData = getThemeData();
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      context?.state?.user &&
      factorState?.factorList &&
      (factorState?.factorList?.length ?? 0) > 0 &&
      factorState?.companyGroupList.size > 0
    ) {
      if (!isInitialized) {
        const inputParams = parseOption(location.search);

        if (some(inputParams, (v) => !isNil(v))) {
          const {
            nationCode,
            categoryIds,
            factorQuery,
            orders,
            // portWeightPolicy,

            startDate,
            endDate,
            tradeCost,
            count,
            rebalancingPeriod,
          } = inputParams;

          const allGroups = Array.from(
            factorState?.companyGroupList.entries() ?? [],
          )
            .map(([key, value]) => {
              if (value.length === 0) {
                return [];
              }
              if (value.length === 1) {
                return [key];
              }
              return value;
            })
            .flat();

          if (nationCode) {
            api.screener.getNation().then((res) => {
              const selectedNation = res.data.find(
                (n) => n.code === Number(nationCode),
              );
              if (selectedNation) {
                factorDispatch?.({
                  type: 'UPDATE_SELECTED_NATION',
                  nation: selectedNation,
                });
              }
            });
          }

          factorDispatch?.({
            type: 'SET_FACTORS',
            factors:
              factorQuery?.flatMap((query) => {
                const factor = factorState?.factorList.find(
                  (f) => f.id === Number(query.factorId),
                );
                if (!factor) {
                  return [];
                }
                return [
                  {
                    factor,
                    range: [
                      {
                        freqIndex: -1,
                        value: isNumber(query.gt) ? query.gt : 'min',
                        isAbsolute:
                          query.valueType.toUpperCase() === 'ABSOLUTE',
                      },
                      {
                        freqIndex: -1,
                        value: isNumber(query.lt) ? query.lt : 'max',
                        isAbsolute:
                          query.valueType.toUpperCase() === 'ABSOLUTE',
                      },
                    ],
                  },
                ];
              }) ?? [],
          });
          factorDispatch?.({
            type: 'CLEAR_UNSELECTED_GROUPS',
          });
          const unselectedGroupIds = categoryIds
            ? allGroups.filter(
                (v) =>
                  !categoryIds?.some(
                    (sid: any) => Number(sid) === v.cosmosGroupId,
                  ),
              )
            : [];
          unselectedGroupIds.forEach((unselectedGroup) => {
            factorDispatch?.({
              type: 'ADD_COMPANY_GROUP',
              cosmosGroupId: unselectedGroup.cosmosGroupId,
            });
          });

          const selectedOrder = factorQuery?.some(
            (q) => q.queryType === 'F_SCORE',
          )
            ? screenerSortData.find((d) => d.data === 'F_SCORE')
            : screenerSortData.find(
                (d) =>
                  d.data !== 'F_SCORE' &&
                  d.data.order.toUpperCase() ===
                    orders?.[0].order.toUpperCase() &&
                  d.data.factorId === Number(orders?.[0].factorId),
              );
          if (selectedOrder) {
            factorDispatch?.({
              type: 'UPDATE_SORT_OPTION',
              sortData: [selectedOrder],
            });
          }

          backtestDispatch?.({
            type: 'UPDATE_BACKTEST_VALUES',

            startDate: startDate
              ? new Date(startDate)
              : new Date(1107244432000),
            endDate: endDate ? new Date(endDate) : new Date(),
            tradeCost: parseFloat(tradeCost ?? '0.6'),
            top: parseInt(count ?? '30', 10),
            rebalancingPeriod: rebalancingPeriod ?? 'ANNUALLY',
          });
        }
        setIsInitialized(true);
      }
    }
  }, [
    isInitialized,
    context?.state?.user,
    factorState?.factorList,
    factorState?.companyGroupList,
  ]);

  useEffect(() => {
    if (
      isInitialized &&
      context?.state?.user &&
      factorState?.factorList &&
      (factorState?.factorList?.length ?? 0) > 0 &&
      factorState?.companyGroupList.size > 0 &&
      backTestState
    ) {
      // url 업데이트하는 과정?
      const queryString = stringifyOption(
        factorState,
        backTestState,
        location.search,
      );

      const newUrl = `${window.location.origin}${window.location.pathname}?${queryString}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
  }, [
    isInitialized,
    factorState?.unselectedGroups,
    factorState?.selectedFactors,
    factorState?.selectedNation,
    factorState?.sortData,
    backTestState,
  ]);

  if (!backTestState) return <div>ERROR</div>;

  const startEndDate = dayjs().add(-1, 'year').toDate();
  const endStartDate = dayjs(backTestState.startDate).add(11, 'month').toDate();

  return (
    <Box bg={themeData.colors.background} h="100%" p="24px 32px" w="100%">
      <Box marginBottom="24px">
        <H3 bold>{t('text.defaultSettings')}</H3>
      </Box>
      <NationSelectorContainer
        selectedNation={
          factorState?.selectedNation ??
          JSON.parse(
            get('selectedNation', { ignoreEmpty: true }) ??
              '{"code": 840,"name": "UNITED STATES"}',
          )
        }
        onSelectNation={(nation) => {
          logEvent('backtesting basic setting adjusted', {
            'selected country to invest': nation.name,
          });
          if (nation && factorDispatch) {
            factorDispatch({
              type: 'UPDATE_SELECTED_NATION',
              nation,
            });
          }
        }}
      />
      <Box marginTop="20px">
        <CustomDatePicker
          label={t('text.startDatetime')}
          selected={backTestState.startDate}
          minDate={new Date(2005, 1)}
          maxDate={startEndDate}
          onChange={(_date) => {
            const date = dayjs
              .max([
                dayjs(new Date(2005, 1)).startOf('month'),
                dayjs(_date).startOf('month'),
              ])
              .toDate();

            if (date) {
              logEvent('backtesting basic setting adjusted', {
                'backtesting start date': getYYYYMMDD({ date }),
              });
            }
            if (date && backtestDispatch) {
              factorDispatch?.({
                type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
                isEditCurrentConditions: true,
              });
              backtestDispatch({
                type: 'UPDATE_START_DATE',
                date: date as Date,
              });
              const diffMonth = dayjs(backTestState.endDate).diff(
                date,
                'month',
              );
              if (diffMonth < 11) {
                state?.showToast(
                  'info',
                  t('text.backtestDatetimeChangeAlert'),
                  {
                    position: 'bottom-left',
                  },
                );
                const fixedDate = dayjs(date).add(11, 'month').toDate();
                factorDispatch?.({
                  type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
                  isEditCurrentConditions: true,
                });
                backtestDispatch({
                  type: 'UPDATE_END_DATE',
                  date: fixedDate,
                });
              }
            }
          }}
        />
      </Box>
      <Box marginTop="20px">
        <CustomDatePicker
          label={t('text.endDatetime')}
          selected={backTestState.endDate}
          minDate={endStartDate}
          maxDate={new Date()}
          onChange={(_date) => {
            const date = dayjs
              .min([
                dayjs(_date).endOf('month'),
                dayjs().add(-1, 'month').endOf('month'),
              ])
              .toDate();
            if (date) {
              logEvent('backtesting basic setting adjusted', {
                'backtesting end date': getYYYYMMDD({ date }),
              });
            }
            if (date && backtestDispatch) {
              factorDispatch?.({
                type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
                isEditCurrentConditions: true,
              });
              backtestDispatch({
                type: 'UPDATE_END_DATE',
                date: date as Date,
              });
            }
          }}
        />
      </Box>

      <Box marginTop="20px">
        <Caption color={themeData.colors.text3}>
          {t('text.transactionCost')}
        </Caption>
        <Spacer h="8px" />
        <NumberEditableInput
          initialValue={backTestState.tradeCost}
          tailText="%"
          onUpdate={(tradeCost) => {
            logEvent('backtesting basic setting adjusted', {
              'backtesting transaction cost': tradeCost.toString(),
            });
            if (tradeCost !== undefined && backtestDispatch) {
              factorDispatch?.({
                type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
                isEditCurrentConditions: true,
              });
              backtestDispatch({
                type: 'UPDATE_TRADE_COST',
                tradeCost,
              });
            }
          }}
          canDecimals
        />
      </Box>

      <Box marginTop="20px">
        <Caption color={themeData.colors.text3}>
          {t('text.maxCount')} (max 50)
        </Caption>
        <Spacer h="8px" />
        <NumberEditableInput
          max={50}
          initialValue={backTestState.top}
          onUpdate={(top) => {
            logEvent('backtesting basic setting adjusted', {
              'backtesting num total stocks': top.toString(),
            });

            if (top && backtestDispatch) {
              factorDispatch?.({
                type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
                isEditCurrentConditions: true,
              });
              backtestDispatch({
                type: 'UPDATE_TOP',
                top,
              });
            }
          }}
        />
      </Box>

      <Box marginTop="20px">
        <Caption color={themeData.colors.text3}>
          {t('text.rebalancingPeriod')}
        </Caption>
        <Spacer h="8px" />
        <ReactSelect
          value={{
            value: backTestState.rebalancingPeriod,
            // @ts-ignore
            label: t(`period.${backTestState.rebalancingPeriod}`),
          }}
          isSearchable={false}
          // @ts-ignore
          onChange={({ value }) => {
            logEvent('backtesting basic setting adjusted', {
              'backtesting rebalancing period': value.toString(),
            });
            if (value && backtestDispatch) {
              factorDispatch?.({
                type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
                isEditCurrentConditions: true,
              });
              backtestDispatch({
                type: 'UPDATE_REBALANCING_PERIOD',
                rebalancingPeriod: value,
              });
            }
          }}
          options={
            ['ANNUALLY', 'QUARTERLY', 'SEMIANNUALLY'].map((e) => {
              // @ts-ignore
              return { value: e, label: t(`period.${e}`) };
            }) as Array<any>
          }
          styles={{
            option: (provided, state) => ({
              ...provided,
              background: state.isSelected ? '#F6EDFF' : 'white',
              color: themeData.colors.text1,
              fontSize: themeData.typography.h5.fontSize,
            }),
            control: (provided) => ({
              ...provided,
              border: `solid 1px ${themeData.colors.primary[900]}`,
              borderRadius: 8,
              color: themeData.colors.text2,
              fontSize: themeData.typography.h5.fontSize,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
        />
      </Box>
      <Box marginTop="20px">
        <ScreenerSortSelector
          onSelect={(data) => {
            if (data != null && factorDispatch != null) {
              // setToLS('SELECTED_SORT_DATA', JSON.stringify(data));
              factorDispatch({
                type: 'UPDATE_SORT_OPTION',
                sortData: [data],
              });
            }
          }}
          selectedData={
            factorState?.sortData[0] ??
            (JSON.parse(
              get('selectedSortOption', { ignoreEmpty: true }) ??
                JSON.stringify(screenerSortData[0]),
            ) as ScreenerSortType)
          }
        />
      </Box>
      <Box mt="20px">
        <SectorSelect />
      </Box>
    </Box>
  );
}

export default BacktestOptionContainer;
