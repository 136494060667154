import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Spacer } from '@chakra-ui/react';
import { flatMap, mapValues } from 'lodash';
import { useTranslation } from 'react-i18next';

import { FactorControlSpec } from '~/api/strategy';
import { useDidUpdateEffect } from '~/hooks/useDidUpdateEffect';
import { FactorControlValue, FocusType } from './types';
import ChartController from './ChartController';
import {
  getAbsoluteInitialRange,
  getReletiveInitialRange,
} from './utils/initializedValue';
import { ReactComponent as IconDelete } from '~/assets/icon/icon_delete.svg';
import FactorControlDialog from './FactorControlDialog';
import { AppContext } from '~/AppContext';
import { FactorDispatchContext } from '~/containers/factor/context';
import { set } from '~/utils/localStorage';
import { BorderButton } from '../CustomButton';
import { getThemeData } from '~/utils/theme';
import { Body2 } from '../Typography';
import { Factor } from '~/api/screener';

type FactorControlProps = {
  factor: Factor;
  factorHistogram: FactorControlSpec;
  onUpdateRange: (range: [FactorControlValue, FactorControlValue]) => void;
  value: [FactorControlValue, FactorControlValue];
  onDelete?: (factor: Factor) => void;
};

export const findValueIndexInHistogram = (
  histogram: FactorControlSpec,
  v: FactorControlValue['value'],
) => {
  if (v === 'min') {
    return 0;
  }
  if (v === 'max') {
    return histogram.bins.length - 1;
  }

  const index = histogram.bins.findIndex((e: number) => e > v);

  if (index >= 0) {
    return index;
  }
  if (v >= histogram.bins[histogram.bins.length - 1]) {
    return histogram.bins.length - 1;
  }
  return 0;
};

const FactorControl = ({
  factor,
  onUpdateRange,
  factorHistogram,
  value,
  onDelete,
}: FactorControlProps) => {
  const getIndexByValue = (_value: number): number =>
    findValueIndexInHistogram(factorHistogram, _value);
  // const isMobile = useBreakpointValue([true, false]);

  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [focus, setFocus] = useState<FocusType>();
  const factorDispatch = useContext(FactorDispatchContext);
  const [isAbsolute, setIsAbsolute] = useState<boolean>(
    value
      ? value[0].isAbsolute
      : (factor?.rangeTypes?.length ?? 0) > 1
      ? factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
      : factor?.rangeTypes?.[0]?.toUpperCase() === 'ABSOLUTE',
  );

  const [updateByInput, setUpdateByInput] = useState<[boolean, boolean]>([
    true,
    true,
  ]);
  const [range, setRange] =
    useState<[FactorControlValue, FactorControlValue]>(value);

  const { state } = useContext(AppContext) ?? {};

  useDidUpdateEffect(() => {
    const update1 = setTimeout(() => {
      set(
        // @ts-ignore
        `FACTOR_RANGE_VALUE_${factorHistogram.factorId}`,
        JSON.stringify(range),
      );
      if (isAbsolute !== range[0].isAbsolute) {
        setIsAbsolute(range[0].isAbsolute);
      }
      onUpdateRange([range[0], range[1]]);
    }, 300);
    return () => clearTimeout(update1);
  }, [range]);

  useDidUpdateEffect(() => {
    if (factorDispatch) {
      factorDispatch({
        type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
        isEditCurrentConditions: true,
      });
    }
    const update2 = setTimeout(() => {
      // logEvent('factor value adjusted', {
      //   'factor category': factorHistogram.categoryName,
      // });
    }, 300);
    return () => clearTimeout(update2);
  }, [range]);

  useDidUpdateEffect(() => {
    if (
      JSON.stringify(range[0]) !== JSON.stringify(value[0]) ||
      JSON.stringify(range[1]) !== JSON.stringify(value[1])
    ) {
      setRange(value);
    }
  }, [value]);

  const themeData = getThemeData();

  return useMemo(() => {
    const left = range[0].value;
    const right = range[1].value;

    return (
      <Stack flexDirection="column" p="16px">
        <Stack direction="row">
          <Stack direction="row" flex={1} marginBottom="16px">
            <Typography
              variant="body1"
              whiteSpace="break-spaces"
              fontWeight="500"
            >
              {t(`factor.${factorHistogram.factorId}.name`)}
            </Typography>
            <Typography
              whiteSpace="break-spaces"
              variant="body1"
              color="#9E9E9E"
              fontWeight="500"
            >
              {` | ${t(`factorCategory.${factorHistogram.categoryName}.name`)}`}
            </Typography>
          </Stack>

          <IconButton
            sx={{ width: '32px', height: '32px' }}
            onClick={() => {
              onDelete?.(factor);
              // if (factorDispatch != null)
              //   factorDispatch({
              //     type: 'REMOVE_FACTOR',
              //     factorId: factorHistogram.factorId,
              //   });
            }}
          >
            <IconDelete />
          </IconButton>
        </Stack>
        {(factor?.rangeTypes && factor.rangeTypes.length <= 1) ||
        factorHistogram.defaultRange === 'BOTTOM20POSITIVE' ? (
          <div />
        ) : (
          <Stack direction="row" spacing="0px">
            <Button
              sx={{
                color: isAbsolute ? '#9E9E9E' : '#9E7DF9',
                borderColor: isAbsolute ? '#9E9E9E' : '#9E7DF9',
                background: 'white',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
              variant="outlined"
              onClick={() => {
                if (!isAbsolute) return;

                setRange(
                  getReletiveInitialRange(
                    factorHistogram,
                    factorHistogram.defaultRange,
                  ),
                );
              }}
            >
              {t('text.percentileAdjust')}
            </Button>
            <Button
              sx={{
                color: !isAbsolute ? '#9E9E9E' : '#9E7DF9',
                borderColor: !isAbsolute ? '#9E9E9E' : '#9E7DF9',
                background: 'white',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
              }}
              variant="outlined"
              onClick={() => {
                if (isAbsolute) return;
                setRange(
                  getAbsoluteInitialRange(
                    factorHistogram,
                    factorHistogram.defaultRange,
                  ),
                );
              }}
            >
              {t('text.valueAdjust')}
            </Button>
          </Stack>
        )}
        <Grid container columns={5}>
          <Grid
            item
            xs={1}
            sm={1}
            sx={{
              alignSelf: 'end',
              height: 'fit-content',
              width: '100%',
              bgcolor: (theme) =>
                !isAbsolute ? theme.palette.grey[100] : 'white',
              border: (theme) => `solid 1px ${theme.palette.grey[200]}`,
              borderRadius: '4px',

              ':hover': { cursor: 'pointer' },
            }}
            onClick={() => {
              if (!isAbsolute) return;
              setFocus('left');
              setIsOpen(true);
            }}
            justifySelf="center"
          >
            <Box padding="8px 0px">
              <Typography variant="body2" whiteSpace="nowrap" align="center">
                {left === 'min'
                  ? 'MIN'
                  : `${((left ?? 0) as number).toLocaleString()}${
                      isAbsolute ? factorHistogram.valueUnit : '%'
                    }`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            display={['block', 'none']}
            item
            xs={3}
            sm={1}
            justifyContent="center"
            alignSelf="center"
          >
            <Body2 align="center">-</Body2>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            display={['block', 'none']}
            sx={{
              alignSelf: 'end',
              height: 'fit-content',
              width: '100%',
              bgcolor: (theme) =>
                !isAbsolute ? theme.palette.grey[100] : 'white',
              border: (theme) => `solid 1px ${theme.palette.grey[200]}`,
              borderRadius: '4px',
              ':hover': { cursor: 'pointer' },
            }}
            onClick={() => {
              if (!isAbsolute) return;

              setFocus('right');
              setIsOpen(true);
            }}
          >
            <Box alignItems="center" padding="8px 0px">
              <Typography variant="body2" whiteSpace="nowrap" align="center">
                {right === 'max'
                  ? 'MAX'
                  : `${((right ?? 1) as number).toLocaleString()}${
                      isAbsolute ? factorHistogram.valueUnit : '%'
                    }`}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={5}
            sm={3}
            px="24px"
            // colSpan={3} padding="0px 20px"
          >
            <ChartController
              key={`factor-controller-${factor.id}-${isAbsolute}`}
              range={range}
              isAbsolute={isAbsolute}
              setUpdateByInput={setUpdateByInput}
              updateByInput={updateByInput}
              bins={factorHistogram.bins}
              freqs={factorHistogram.freqs}
              factorId={factor.id}
              onUpdateRange={(v) => {
                setRange([v[0] ?? range[0], v[1] ?? range[1]]);
              }}

              // factorHistogramDefaultRange={factorHistogram.defaultRange}
            />
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            display={['none', 'block']}
            sx={{
              alignSelf: 'end',
              height: 'fit-content',
              width: '100%',
              bgcolor: (theme) =>
                !isAbsolute ? theme.palette.grey[100] : 'white',
              border: (theme) => `solid 1px ${theme.palette.grey[200]}`,
              borderRadius: '4px',
              ':hover': { cursor: 'pointer' },
            }}
            onClick={() => {
              if (!isAbsolute) return;

              setFocus('right');
              setIsOpen(true);
            }}
          >
            <Box alignItems="center" padding="8px 0px">
              <Typography variant="body2" whiteSpace="nowrap" align="center">
                {right === 'max'
                  ? 'MAX'
                  : `${((right ?? 1) as number).toLocaleString()}${
                      isAbsolute ? factorHistogram.valueUnit : '%'
                    }`}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {isAbsolute && factorHistogram.defaultRange === 'BOTTOM20POSITIVE' ? (
          <Stack direction="row" justifyContent="center" marginTop="12px">
            <BorderButton
              color={
                factorHistogram.defaultFreqIndex?.get(
                  'BOTTOM20POSITIVE',
                )?.[0] === range[0].freqIndex &&
                factorHistogram.defaultFreqIndex?.get(
                  'BOTTOM20POSITIVE',
                )?.[1] === range[1].freqIndex
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                if (factorHistogram.defaultRange === 'BOTTOM20POSITIVE') {
                  setRange(
                    getAbsoluteInitialRange(
                      factorHistogram,
                      'BOTTOM20POSITIVE',
                    ),
                  );
                } else {
                  setRange(
                    getAbsoluteInitialRange(factorHistogram, 'BOTTOM20'),
                  );
                }
              }}
              text={
                factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
                  ? t('text.BOTTOM20POSITIVE')
                  : t('text.BOTTOM20')
              }
            />
            <Box width="4px" />

            <BorderButton
              color={
                factorHistogram.defaultFreqIndex?.get('MIDDLE20')?.[0] ===
                  range[0].freqIndex &&
                factorHistogram.defaultFreqIndex?.get('MIDDLE20')?.[1] ===
                  range[1].freqIndex
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange(getAbsoluteInitialRange(factorHistogram, 'MIDDLE20'));
              }}
              text={t('text.MIDDLE20')}
            />
            <Box width="4px" />

            <BorderButton
              color={
                factorHistogram.defaultFreqIndex?.get('TOP20')?.[0] ===
                  range[0].freqIndex &&
                factorHistogram.defaultFreqIndex?.get('TOP20')?.[1] ===
                  range[1].freqIndex
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange(getAbsoluteInitialRange(factorHistogram, 'TOP20'));
              }}
              text={t('text.TOP20')}
            />
          </Stack>
        ) : (
          <div />
        )}

        {!isAbsolute && factorHistogram.defaultRange !== 'BOTTOM20POSITIVE' ? (
          <Stack direction="row" justifyContent="center" marginTop="12px">
            <BorderButton
              color={
                range[0].value === 0 && range[1].value === 10
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 0, value: 0, isAbsolute: false },
                  {
                    freqIndex: 10,
                    value: 10,
                    isAbsolute: false,
                  },
                ]);
              }}
              text={`${t('text.bottom')} 10%`}
            />
            <Box width="4px" />

            <BorderButton
              color={
                range[0].value === 0 && range[1].value === 20
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 0, value: 0, isAbsolute: false },
                  {
                    freqIndex: 20,
                    value: 20,
                    isAbsolute: false,
                  },
                ]);
              }}
              text={`${t('text.bottom')} 20%`}
            />
            <Spacer />

            <BorderButton
              color={
                range[0].value === 80 && range[1].value === 100
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 80, value: 80, isAbsolute: false },
                  {
                    freqIndex: 100,
                    value: 100,
                    isAbsolute: false,
                  },
                ]);
              }}
              text={`${t('text.top')} 20%`}
            />
            <Box width="4px" />
            <BorderButton
              color={
                range[0].value === 90 && range[1].value === 100
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 90, value: 90, isAbsolute: false },
                  {
                    freqIndex: 100,
                    value: 100,
                    isAbsolute: false,
                  },
                ]);
              }}
              text={`${t('text.top')} 10%`}
            />
          </Stack>
        ) : (
          <div />
        )}
        <Dialog
          open={isOpen}
          // leastDestructiveRef={undefined}
          onClose={() => {
            setIsOpen(false);
          }}
          // isCentered
        >
          <FactorControlDialog
            // @ts-ignore
            name={t(`factor.${factorHistogram.factorId}.name`)}
            valueUnit={factorHistogram.valueUnit}
            focus={focus}
            initialValue={
              focus === 'left'
                ? typeof range[0].value === 'number'
                  ? range[0].value
                  : factorHistogram.bins[0]
                : typeof range[1].value === 'number'
                ? range[1].value
                : factorHistogram.bins[factorHistogram.bins.length - 1]
            }
            onSubmit={(inputValue) => {
              if (focus === 'left') {
                if (
                  typeof inputValue === 'number' &&
                  inputValue >= range[1].value
                ) {
                  state?.showToast(
                    'error',
                    '최소값은 최대값보다 크거나 같을 수 없습니다',
                    {
                      position: 'bottom-left',
                    },
                  );
                  return;
                }
                setUpdateByInput([true, updateByInput[1]]);

                setRange([
                  {
                    freqIndex:
                      inputValue === 'min'
                        ? 0
                        : inputValue === 'max'
                        ? factorHistogram.bins.length + 1
                        : getIndexByValue(inputValue),
                    value: inputValue,
                    isAbsolute,
                  },
                  range[1],
                ]);
              } else if (focus === 'right') {
                if (
                  typeof inputValue === 'number' &&
                  inputValue <= range[0].value
                ) {
                  state?.showToast(
                    'error',
                    '최대값은 최소값보다 작거나 같을 수 없습니다',
                    {
                      position: 'bottom-left',
                    },
                  );
                  return;
                }
                setUpdateByInput([updateByInput[0], true]);

                setRange([
                  range[0],
                  {
                    freqIndex:
                      inputValue === 'min'
                        ? 0
                        : inputValue === 'max'
                        ? factorHistogram.bins.length + 1
                        : getIndexByValue(inputValue),
                    value: inputValue,
                    isAbsolute,
                  },
                ]);
              }
            }}
            onClose={() => {
              setIsOpen(false);
            }}
          />
        </Dialog>
      </Stack>
    );
  }, [
    factorHistogram,
    ...flatMap(range, (v) => mapValues(v, (p) => p)),
    isOpen,
    isAbsolute,
  ]);
};
export default FactorControl;
