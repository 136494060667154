import { Link } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '~/AppContext';
import { Body1 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';

function ToastLimitComponent() {
  const { state } = useContext(AppContext) ?? {};
  const [t] = useTranslation();
  const themeData = getThemeData();
  return (
    <>
      {t(state?.user ? 'text.apiLimitExcess' : 'text.apiLimitExcessOffline')}

      <Link
        isExternal
        href="https://doomoolmori.notion.site/PLAN-0c8792de6b0c464c827cb1c1b13b4caa"
      >
        <Body1 color={themeData.colors.primary[500]} decoration="underline">
          사용량을 늘리는 방법은?
        </Body1>
      </Link>
    </>
  );
}
export default ToastLimitComponent;
