import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { transform } from 'lodash';

import kr from './kr';
import en from './en';

// declare module 'react-i18next' {
//   interface Resources {
//     kr: typeof kr;
//     en: typeof en;
//   }
// }

export { kr, en };

export const defaultLanguage = navigator.language === 'ko' ? 'kr' : 'en';
export const initI18n = () => {
  if (i18next.isInitialized) {
    return;
  }
  i18next.use(initReactI18next).init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: ['kr', 'en'],
    resources: transform(
      {
        en,
        kr,
      },
      (res, lang, key) => {
        res[key] = {
          translation: lang,
        };
      },
      {} as Resource,
    ),
    lng: defaultLanguage,
    fallbackNS: defaultLanguage,
  });
};

export type Language = 'kr' | 'en';
