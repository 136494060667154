import { Center, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Body2, H3, H5 } from '~/components/Typography';

import { getThemeData } from '~/utils/theme';
import { ChartProps } from './BackTestResultContainer';

type TableData = {
  title: string;
  benchMarkValue: string;
  protfolioValue: string;
};
function BacktestSummaryTable({ value, nationCode }: ChartProps) {
  const [t] = useTranslation();
  const themeData = getThemeData();

  const benchMark = value.bmStats;
  const portfolio = value.portStats;

  const tableDataList: Array<TableData> = [
    {
      title: t('text.accumulatedReturn'),
      protfolioValue: `${(portfolio.accumYield * 100).toFixed(2)}%`,
      benchMarkValue: `${(benchMark.accumYield * 100).toFixed(2)}%`,
    },
    {
      title: t('text.annualizedReturn'),
      protfolioValue: `${(portfolio.annualYield * 100).toFixed(2)}%`,
      benchMarkValue: `${(benchMark.annualYield * 100).toFixed(2)}%`,
    },
    {
      title: t('text.annualizedStdev'),
      protfolioValue: `${(portfolio.annualStdev * 100).toFixed(2)}%`,
      benchMarkValue: `${(benchMark.annualStdev * 100).toFixed(2)}%`,
    },
    {
      title: t('text.sharpRatio'),
      protfolioValue: `${portfolio.annualSharp.toFixed(2)}`,
      benchMarkValue: `${benchMark.annualSharp.toFixed(2)}`,
    },
    {
      title: t('text.mdd'),
      protfolioValue: `${(portfolio.minMdd * 100).toFixed(2)}%`,
      benchMarkValue: `${(benchMark.minMdd * 100).toFixed(2)}%`,
    },
  ];
  const borderStyle = `solid 1px ${themeData.colors.gray[200]}`;
  return (
    <Box
      width="100%"
      padding={['0px', '24px']}
      marginTop={['12px', '0px']}
      overflow="auto"
    >
      <H3 bold>{t('text.performanceSummary')}</H3>
      <H5>{t('text.resultNotification')} </H5>

      <Table size="md" marginTop="24px" w="100%">
        <Thead>
          <Tr>
            <Th fontWeight="normal" bg={themeData.colors.gray[100]} />
            <Th bg={themeData.colors.primary[500]} color="white">
              <Center whiteSpace="pre-line" textAlign="center">
                {t('text.portfolio')}
              </Center>
            </Th>
            <Th align="center" bg={themeData.colors.gray[300]} color="white">
              <Center whiteSpace="pre-line" textAlign="center">
                {' '}
                {/* @ts-ignore */}
                {t(`nation.benchmark.${nationCode}`)}
              </Center>
            </Th>
          </Tr>
        </Thead>
        <Tbody borderTop={`solid 2px ${themeData.colors.gray[200]}`}>
          {tableDataList.map((v) => (
            <Tr whiteSpace="nowrap" key={`summary${v.title}`}>
              <Td p="12px" border={borderStyle} textOverflow="ellipsis">
                <Body2
                  whiteSpace="nowrap"
                  color={themeData.colors.text2}
                  noOfLines={1}
                >
                  {v.title}
                </Body2>
              </Td>
              <Td p="12px" border={borderStyle}>
                <Body2
                  whiteSpace="nowrap"
                  noOfLines={1}
                  color={themeData.colors.primary[500]}
                  bold
                >
                  {v.protfolioValue}
                </Body2>
              </Td>
              <Td p="12px" border={borderStyle}>
                <Body2
                  whiteSpace="nowrap"
                  noOfLines={1}
                  color={themeData.colors.text3}
                  bold
                >
                  {v.benchMarkValue}
                </Body2>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
export default BacktestSummaryTable;
