import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import minMax from 'dayjs/plugin/minMax';

[isSameOrBefore, timezone, utc, isoWeek, minMax].forEach((p) => {
  dayjs.extend(p);
});

export default dayjs;
